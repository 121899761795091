import { createContext, ReactNode, useContext, useMemo } from 'react';

const I18N_CONFIG_KEY = Application.env.VITE_I18N_CONFIG_KEY || 'i18nConfig';
const initialState = {
  selectedLang: 'en',
};

function getConfig() {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
}

const I18nContext = createContext(initialState);

export function useLang() {
  return useContext(I18nContext).selectedLang;
}

export const I18nConsumer = I18nContext.Consumer;

interface MetronicI18nProviderProps {
  children: ReactNode;
}

export function MetronicI18nProvider({ children }: MetronicI18nProviderProps): JSX.Element {
  const lang = useMemo(getConfig, []);

  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
}
