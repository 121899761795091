import React, {
  ComponentClass,
  createElement,
  ElementType,
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Either, isLeft, isRight, left } from 'fp-ts/Either';
import EventEmitter from 'events';
import { generatePath, useNavigate, useLocation, useParams, Outlet } from 'react-router';

function isRoutedDialog<R, P extends {}>(Component: Dialog<R, P> | RoutedDialog<R, P>): Component is RoutedDialog<R, P> {
  return typeof (Component as RoutedDialog<R, P>).path !== 'undefined';
}

const eventBroker = new EventEmitter();
export class Task<T> {
  private value: Either<Error, T> | null;
  constructor(private broker: EventEmitter, private namespace: string) {
    this.value = left(new Error('Task was flushed with initial value.'));
  }

  flush = () => {
    this.broker.emit(`${this.namespace}.value`, this.value);
  };

  complete = (value: Either<Error, T>) => {
    this.value = value;
    this.broker.emit(`${this.namespace}.close`);
  };
}

export interface RoutedDialog<R, P extends {} | void> {
  (): ReactElement<P & { task: Task<R> }>;
  path: string;
  dialogName: string;
}

export type Dialog<R, P extends {} | void> =
  | (FunctionComponent<P & { task: Task<R> }> | ComponentClass<P & { task: Task<R> }, any>) & {
      dialogName: string;
    };

export const routedDialog = <R, P extends {} | void>(name: string, path: string, component: ElementType<P & { task: Task<R> }>) => {
  const WrappedComponent = () => {
    const task = useMemo(() => new Task<R>(eventBroker, name), []);
    const params = useParams() as any;

    useEffect(() => {
      return () => {
        task.flush();
      };
    }, [task]);

    return createElement(component, { ...params, task: task });
  };

  Object.defineProperty(WrappedComponent, 'dialogName', {
    get() {
      return name;
    },
  });

  Object.defineProperty(WrappedComponent, 'path', {
    get() {
      return path;
    },
  });

  return WrappedComponent as RoutedDialog<R, P>;
};

export const dialog = <R, P extends {} | void>(
  name: string,
  component: FunctionComponent<P & { task: Task<R> }> | ComponentClass<P & { task: Task<R> }, any>,
) => {
  const WrappedComponent = (props: P) => {
    const task = useMemo(() => new Task<R>(eventBroker, name), []);
    const theProps: any = useMemo(() => props ?? {}, [props]);

    useEffect(() => {
      return () => {
        task.flush();
      };
    }, [task]);

    return createElement(component, { ...theProps, task: task });
  };

  Object.defineProperty(WrappedComponent, 'dialogName', {
    get() {
      return name;
    },
  });

  return WrappedComponent as unknown as Dialog<R, P>;
};

export function useDialog<R, P extends {} | void>(Component: Dialog<R, P>) {
  const [result, setResult] = useState<Either<Error, R>>();
  const [visible, setVisible] = useState(false);
  const parameters = useRef<any>();

  const show = useCallback((args: Omit<P, 'task'>) => {
    parameters.current = args;
    setVisible(true);
  }, []);

  const dismiss = useCallback(() => {
    parameters.current = undefined;
    setVisible(false);
  }, []);

  useEffect(() => {
    eventBroker.on(`${Component.dialogName}.value`, setResult);
    eventBroker.on(`${Component.dialogName}.close`, dismiss);

    return () => {
      eventBroker.off(`${Component.dialogName}.value`, setResult);
      eventBroker.off(`${Component.dialogName}.close`, dismiss);
    };
  }, [Component.dialogName, dismiss]);

  const data = useMemo(() => {
    if (!result || isLeft(result)) {
      return undefined;
    }

    return result.right;
  }, [result]);

  const error = useMemo(() => {
    if (!result || isRight(result)) {
      return undefined;
    }

    return result.left;
  }, [result]);

  const dialog = useMemo(() => {
    if (!visible) {
      return null;
    }

    return React.createElement(Component, parameters.current);
  }, [Component, visible]);

  if (isRoutedDialog(Component)) {
    throw new Error('RoutedDialog detected.  Please use useRoutedDialog hook');
  }

  return { show, data, error, dialog };
}

export function useRoutedDialog<R, P extends {} | void>(Component: RoutedDialog<R, P>) {
  const [result, setResult] = useState<Either<Error, R>>();
  const navServices = useNavigationServices();
  const navigateTo = useCallback(
    (path: string) => {
      const { location, navigate } = navServices;
      let thePath = path;
      if (location.hash) {
        thePath = `${thePath}${location.hash}`;
      }

      navigate(thePath);
    },
    [navServices],
  );
  const show = useCallback(
    (params: P) => {
      setResult(undefined);
      const thePath = generatePath(Component.path, params as any);
      navigateTo(thePath);
    },
    [navigateTo, Component],
  );

  const dismiss = useCallback(() => {
    const { location } = navServices;
    const segmentsInPath = Component.path.replace(/\/\*$/, '').split('/').length;
    const thePath = location.pathname.split('/').slice(0, -segmentsInPath).join('/');
    navigateTo(thePath);
  }, [Component.path, navServices, navigateTo]);

  useEffect(() => {
    eventBroker.on(`${Component.dialogName}.close`, dismiss);
    eventBroker.on(`${Component.dialogName}.value`, setResult);

    return () => {
      eventBroker.off(`${Component.dialogName}.close`, dismiss);
      eventBroker.off(`${Component.dialogName}.value`, setResult);
    };
  }, [Component.dialogName, dismiss]);

  const data = useMemo(() => {
    if (!result || isLeft(result)) {
      return undefined;
    }

    return result.right;
  }, [result]);

  const error = useMemo(() => {
    if (!result || isRight(result)) {
      return undefined;
    }

    return result.left;
  }, [result]);

  return { show, data, error };
}

export const dialogHost = (component: ElementType<any>) => {
  return React.createElement('div', {}, React.createElement(component), React.createElement(Outlet));
};

function useNavigationServices() {
  const location = useLocation();
  const navigate = useNavigate();

  const ref = useRef({ location, navigate });
  ref.current = { location, navigate };

  return useMemo(() => {
    return {
      get location() {
        return ref.current.location;
      },
      get navigate() {
        return ref.current.navigate;
      },
    };
  }, []);
}
