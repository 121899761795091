import { Modal } from 'react-bootstrap';
import { Button } from 'devextreme-react';
import { useI18n } from 'i18n';

interface PromptDialogProps {
  showDialog: any;
  cancelNavigation: any;
  confirmNavigation: any;
}

export const PromptDialog = ({ showDialog, cancelNavigation, confirmNavigation }: PromptDialogProps) => {
  const i18n = useI18n();

  return (
    <Modal show={showDialog} style={{ zIndex: 9999999 }} centered>
      <Modal.Header>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        There are some changes that will be <b>lost</b> if you leave.
      </Modal.Body>
      <Modal.Footer>
        <Button width={120} height={42} type="default" stylingMode="outlined" onClick={cancelNavigation}>
          {i18n((x) => x.Buttons.No)}
        </Button>
        <Button width={120} height={42} type="default" stylingMode="contained" onClick={confirmNavigation}>
          {i18n((x) => x.Buttons.Yes)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
