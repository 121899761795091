import { useI18n } from 'i18n';
import { useCallback, useMemo } from 'react';
import SubmitButton from 'shared/components/SubmitButton/SubmitButton';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import RadioGroup from 'devextreme-react/radio-group';
import TextBox from 'devextreme-react/text-box';
import { modal } from 'core/services';
import { FORM_ERROR } from 'final-form';
import { left, right } from 'fp-ts/lib/Either';
import { Alert, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Field, FieldRenderProps, Form as FForm } from 'react-final-form';
import type { GenerateReportModel } from 'redux-store/services';
import { useGenerateReportMutation } from 'redux-store/services';
import { selectApplicationUser } from 'redux-store/selectors';
import { validationStatus } from 'shared/forms/utils/validationStyle';
import './GenerateReportDialog.scss';
import { useNavBlocker } from '../NavBlockerProvider';
import CancelButton from '../CancelButton/CancelButton';

export type GenerateReportDialogProps = {
  config: Pick<GenerateReportModel, 'tables'> & { reportName: string };
};

type FormData = { email?: string; mode?: 'email' | 'download' };

export const GenerateReportDialog = modal<boolean, GenerateReportDialogProps>('DIALOG/GENERARE_REPORT', ({ config, visible, task }) => {
  const user = useSelector(selectApplicationUser);
  const i18n = useI18n();
  const initialValues = useMemo(() => ({ email: user?.email, mode: 'download' }), [user]);
  const cancelTask = useCallback(() => task.complete(left(new Error('Cancelled'))), [task]);
  const navBlocker = useNavBlocker();
  const [generateReport] = useGenerateReportMutation();
  const options = useMemo(() => {
    return [
      { text: 'Email', value: 'email' },
      { text: 'Download', value: 'download' },
    ];
  }, []);
  const generate = useCallback(
    async ({ mode, email }: FormData) => {
      if (!mode) {
        return { mode: 'required' };
      }
      navBlocker.setIsDirty(false);

      if (mode === 'email' && !email) {
        return { email: 'required' };
      }

      try {
        await generateReport({
          generateReportModel: {
            ...config,
            download: mode === 'download',
            email: mode === 'email' ? email : undefined,
          },
        }).unwrap();
        task.complete(right(true));
      } catch (e: unknown) {
        console.log(e);
        const error = e as any;
        return { [FORM_ERROR]: error.data.detail };
      }
    },
    [navBlocker, generateReport, config, task],
  );

  return (
    <FForm
      onSubmit={generate}
      initialValues={initialValues}
      destroyOnUnregister
      render={(props) => {
        const { handleSubmit, hasValidationErrors, submitting, values, submitError, pristine } = props;
        navBlocker.setIsDirty(!pristine);
        return (
          <Modal centered scrollable show={visible} onHide={cancelTask}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Generate Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit} id="generateReportForm">
                <Alert variant="danger" show={submitError !== undefined}>
                  <div className="markdown">
                    <ReactMarkdown>{submitError ?? ''}</ReactMarkdown>
                  </div>
                </Alert>
                <Container fluid className="generate-report-dialog ps-0 pe-0">
                  <Row>
                    <Col>
                      <Field name="mode" type="string">
                        {({ input, meta }: FieldRenderProps<string, any>) => (
                          <Form.Group>
                            <Form.Label>
                              <strong>How would you like the report?*</strong>
                            </Form.Label>
                            <Form.Control
                              as={RadioGroup}
                              className="form-control form-radio-list"
                              items={options}
                              value={input.value}
                              disabled={submitting}
                              validationStatus={validationStatus(meta)}
                              valueExpr="value"
                              displayExpr="text"
                              onValueChanged={(e: any) => input.onChange(e.value)}
                            />
                          </Form.Group>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field name="email" type="string">
                        {({ input, meta }: FieldRenderProps<string, any>) => (
                          <Form.Group>
                            <Form.Label>
                              <strong>Please enter an email address to receive the generated report when completed.*</strong>
                            </Form.Label>
                            <Form.Control
                              as={TextBox}
                              className="form-control"
                              onKeyUp={(event) => input.onChange((event as DevExEvent).event?.target?.value ?? '')}
                              value={input.value}
                              validationStatus={validationStatus(meta)}
                              disabled={submitting || values.mode !== 'email'}
                              onFocusIn={() => input.onFocus()}
                              onFocusOut={() => input.onBlur()}
                            />
                            <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                            <Form.Control.Feedback>{input.value}</Form.Control.Feedback>
                          </Form.Group>
                        )}
                      </Field>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <CancelButton onClick={cancelTask} disabled={submitting} form="generateReportForm" />
              <SubmitButton
                disabled={hasValidationErrors || submitting}
                submitting={submitting}
                title={i18n((x) => x.Buttons.Create)}
                form="generateReportForm"
              />
            </Modal.Footer>
          </Modal>
        );
      }}
    />
  );
});
