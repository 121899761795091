import { useI18n } from 'i18n';
import { Button, Spinner } from 'react-bootstrap';

type SubmitButtonProps = {
  disabled: boolean;
  submitting: boolean;
  title?: string;
  width?: number;
  className?: string;
  form?: string;
  onClick?: (e: any) => void;
};

const SubmitButton = ({ disabled, submitting, title, width = 120, className, form, onClick }: SubmitButtonProps) => {
  const i18n = useI18n();
  const text = title ?? i18n((x) => x.Buttons.Save);
  return (
    <Button
      style={{ width: width, height: 42 }}
      type="submit"
      variant="primary"
      disabled={disabled}
      className={className}
      form={form}
      onClick={onClick}
    >
      <Spinner className={!submitting ? 'd-none' : undefined} animation="border" variant="light" size="sm" />
      <span className={submitting ? 'd-none' : undefined}>{text}</span>
    </Button>
  );
};

export default SubmitButton;
