import { useI18n } from 'i18n';
import { Button } from 'react-bootstrap';

type CancelButtonProps = {
  disabled: boolean;
  onClick: () => void;
  customCancelText?: string;
  form?: string;
};

const CancelButton = ({ disabled, onClick, customCancelText, form }: CancelButtonProps) => {
  const i18n = useI18n();
  return (
    <Button
      style={{ width: customCancelText ? undefined : 120, height: 42 }}
      variant="outline-primary"
      onClick={onClick}
      disabled={disabled}
      id={form}
    >
      {customCancelText ? customCancelText : i18n((x) => x.Buttons.Cancel)}
    </Button>
  );
};

export default CancelButton;
