type AccessToken = {
  exp: number;
  name: string;
  given_name: string;
  family_name: string;
  email: string;
  roles: Array<string>;
  auth_time?: number;
};

export function parseAccessToken(value: string): AccessToken {
  const base64Url = value.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
}
