import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { CreateInboundModel, DockReceiptViewModel } from 'redux-store/services';
import type { JobNumberLookupResponse } from 'shared/components';
import type { PartEntryResponse } from 'shared/components/PartEntry';

export type InboundState = {
  inbounds: Record<string, CreateInboundModel>;
  inboundUseMocks: Record<string, 'yes' | 'no'>;
  inboundDockReceipts: Record<string, DockReceiptViewModel>;
  inboundJob: Record<string, JobNumberLookupResponse>;
  inboundParts: Record<string, PartEntryResponse[]>;
};

const initialInboundState: InboundState = {
  inbounds: {},
  inboundUseMocks: { test: 'yes' },
  inboundDockReceipts: {},
  inboundJob: {},
  inboundParts: {},
};

const inboundSlice = createSlice({
  name: 'inbound',
  initialState: initialInboundState,
  reducers: {
    create: (
      state,
      action: PayloadAction<{
        id: string;
        inbound: CreateInboundModel;
        useMocks: boolean;
        dockReceipt: DockReceiptViewModel;
        jobInfo: JobNumberLookupResponse;
      }>,
    ) => {
      return {
        inbounds: {
          ...state.inbounds,
          [action.payload.id]: action.payload.inbound,
        },
        inboundUseMocks: {
          ...state.inboundUseMocks,
          [action.payload.id]: action.payload.useMocks ? 'yes' : 'no',
        },
        inboundDockReceipts: {
          ...state.inboundDockReceipts,
          [action.payload.id]: action.payload.dockReceipt,
        },
        inboundJob: {
          ...state.inboundJob,
          [action.payload.id]: action.payload.jobInfo,
        },
        inboundParts: {
          ...state.inboundParts,
          [action.payload.id]: [],
        },
      };
    },
    edit: (
      state,
      action: PayloadAction<{
        id: string;
        inbound: CreateInboundModel;
        useMocks: boolean;
        dockReceipt: DockReceiptViewModel;
        jobInfo: JobNumberLookupResponse;
      }>,
    ) => {
      return {
        inbounds: {
          ...state.inbounds,
          [action.payload.id]: action.payload.inbound,
        },
        inboundUseMocks: {
          ...state.inboundUseMocks,
          [action.payload.id]: action.payload.useMocks ? 'yes' : 'no',
        },
        inboundDockReceipts: {
          ...state.inboundDockReceipts,
          [action.payload.id]: action.payload.dockReceipt,
        },
        inboundJob: {
          ...state.inboundJob,
          [action.payload.id]: action.payload.jobInfo,
        },
        inboundParts: {
          ...state.inboundParts,
          [action.payload.id]: [],
        },
      };
    },
    addPart: (state, action: PayloadAction<{ id: string; part: PartEntryResponse }>) => {
      // state.inbounds[action.payload.id].inboundPartRequests?.push(action.payload.part);
      state.inboundParts[action.payload.id].push(action.payload.part);
    },
    removePart: (state, action: PayloadAction<{ id: string; index: number }>) => {
      state.inboundParts[action.payload.id].splice(action.payload.index, 1);
      // state.inbounds[action.payload.id].inboundPartRequests?.splice(action.payload.index, 1);
    },
    delete: (state, action: PayloadAction<{ id: string }>) => {
      delete state.inbounds[action.payload.id];
      delete state.inboundUseMocks[action.payload.id];
      delete state.inboundDockReceipts[action.payload.id];
      delete state.inboundJob[action.payload.id];
      delete state.inboundParts[action.payload.id];
    },
  },
});

export const {
  reducer: inboundReducer,
  actions: { create, edit, addPart, removePart, delete: deleteInbound },
} = inboundSlice;

export const selectInbound = (id?: string) => (state: { inbound: InboundState }) => {
  if (!id) {
    return;
  }
  return state.inbound.inbounds[id];
};
export const selectDockReceipt = (id?: string) => (state: { inbound: InboundState }) => {
  if (!id) {
    return;
  }
  return state.inbound.inboundDockReceipts[id];
};
export const selectJobInfo = (id?: string) => (state: { inbound: InboundState }, id: string) => {
  if (!id) {
    return;
  }

  return state.inbound.inboundJob[id];
};

export const selectInboundJobInfo = (id?: string) => (state: { inbound: InboundState }) => {
  if (!id) {
    return;
  }

  return state.inbound.inboundJob[id];
};

export const selectInboundUseMocks = (id?: string) => (state: { inbound: InboundState }) => {
  if (!id) {
    return;
  }

  return state.inbound.inboundUseMocks[id] === 'yes' ? true : false;
};

export const selectInboundParts = (id?: string) => (state: { inbound: InboundState }) => {
  if (!id) {
    return [];
  }

  return state.inbound.inboundParts[id];
};
