import type { WarehouseDetailsModel } from './restApi.generated';
import { odataApi } from './odataApi.generated';

type entityAttachmentType = 'ConversationMessage' | 'MasterPart';

export const enhanceEndpoints = odataApi.enhanceEndpoints({
  addTagTypes: [
    'NotificationChannels',
    'NotificationChannel',
    'DataSubscriptions',
    'ConversationMessage',
    'MasterPart',
    'User',
    'Warehouse',
    'NotificationChannelMappings',
    'NotificationMessageTemplate',
    'Bin',
    'MasterPartTemplate',
    'Part',
    'Customer',
    'Transfer',
    'ExternalApplication',
    'ResourceGroup',
    'OutgoingJob',
    'Carrier',
    'DeliveryRecipient',
    'CycleCount',
    'MasterPartHistory',
    'MasterPart',
  ],
  endpoints: {
    listNotificationChannels: {
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'NotificationChannels' as const, id })), { type: 'NotificationChannels', id: 'LIST' }]
          : [{ type: 'NotificationChannels', id: 'LIST' }],
    },
    createNotificationChannel: {
      invalidatesTags: [{ type: 'NotificationChannels', id: 'LIST' }],
    },
    updateNotificationChannel: {
      invalidatesTags: (result) => {
        return [
          { type: 'NotificationChannels', id: 'LIST' },
          { type: 'NotificationChannel', id: result?.id },
        ];
      },
    },
    updateChannelMappings: {
      invalidatesTags: (result) => {
        return [
          { type: 'NotificationChannels', id: 'LIST' },
          { type: 'NotificationChannel', id: result?.id },
        ];
      },
    },
    getNotificationMessageTemplate: {
      providesTags: (result) => [{ type: 'NotificationMessageTemplate', id: result?.id }],
    },
    updateNotificationMessageTemplate: {
      invalidatesTags: (result) => {
        return [
          {
            type: 'NotificationMessageTemplate',
            id: result?.id,
          },
        ];
      },
    },
    getMasterPartTemplate: {
      providesTags: (result) => [{ type: 'MasterPartTemplate', id: result?.id }],
    },
    getOutbound: {
      keepUnusedDataFor: 0,
      providesTags: (result) => [{ type: 'OutgoingJob', id: result?.id }],
    },
    updateShippingInformation: {
      invalidatesTags: (result) => {
        return [
          {
            type: 'OutgoingJob',
            id: result?.id,
          },
        ];
      },
    },
    updateMasterPartTemplate: {
      invalidatesTags: (result) => {
        return [
          {
            type: 'MasterPartTemplate',
            id: result?.id,
          },
        ];
      },
    },
    getTransfer: {
      providesTags: (result) => [{ type: 'Transfer', id: result?.id }],
    },
    transferIn: {
      invalidatesTags: (result) => {
        return [
          {
            type: 'Transfer',
            id: result?.id,
          },
        ];
      },
    },
    transferOut: {
      invalidatesTags: (result) => {
        return [
          {
            type: 'Transfer',
            id: result?.id,
          },
        ];
      },
    },
    getExternalApplication: {
      providesTags: (result) => [{ type: 'ExternalApplication', id: result?.id }],
    },
    updateExternalApplication: {
      invalidatesTags: (result) => {
        return [
          {
            type: 'ExternalApplication',
            id: result?.id,
          },
        ];
      },
    },
    getResourceGroup: {
      providesTags: (result) => [{ type: 'ResourceGroup', id: result?.id }],
    },
    updateResourceGroup: {
      invalidatesTags: (result) => {
        return [
          {
            type: 'ResourceGroup',
            id: result?.id,
          },
        ];
      },
    },
    getMasterPartHistory: {
      providesTags: (result) => [{ type: 'MasterPartHistory', id: 'LIST' }],
    },
    getPart: {
      providesTags: (result) => [{ type: 'Part', id: result?.id }],
    },
    found: {
      invalidatesTags: (result) => {
        return ['Part'];
      },
    },
    hold: {
      invalidatesTags: (result) => {
        return [
          {
            type: 'Part',
            // id: result?.id,
          },
        ];
      },
    },
    release: {
      invalidatesTags: (result) => {
        return [
          {
            type: 'Part',
            // id: result?.id,
          },
        ];
      },
    },
    updatePartStatus: {
      invalidatesTags: (result) => {
        return ['Part'];
      },
    },
    updatePart: {
      invalidatesTags: (result) => {
        return ['Part'];
      },
    },
    deleteNotificationChannel: {
      invalidatesTags: [{ type: 'NotificationChannels', id: 'LIST' }],
    },
    getNotificationChannel: {
      providesTags: (result) => {
        return [{ type: 'NotificationChannel', id: result?.id }];
      },
    },
    getDataSubscription: {
      providesTags: (result) => {
        return [{ type: 'DataSubscriptions', id: result?.id }];
      },
    },
    createDataSubscription: {
      invalidatesTags: (result) => {
        return [{ type: 'DataSubscriptions', id: result?.id }];
      },
    },
    updateDataSubscription: {
      invalidatesTags: (result) => {
        return [{ type: 'DataSubscriptions', id: result?.id }];
      },
    },
    getWarehouse: {
      providesTags: (result) => {
        return [{ type: 'Warehouse', id: result?.id }];
      },
    },
    updateWarehouse: {
      invalidatesTags: (result) => {
        const res = result as WarehouseDetailsModel;
        return [{ type: 'Warehouse', id: res?.id }];
      },
    },
    getCarrier: {
      providesTags: (result) => {
        return [{ type: 'Carrier', id: result?.id }];
      },
    },
    updateCarrier: {
      invalidatesTags: (result) => {
        const res = result;
        return [{ type: 'Carrier', id: res?.id }];
      },
    },
    getDeliveryRecipient: {
      providesTags: (result) => {
        return [{ type: 'DeliveryRecipient', id: result?.id }];
      },
    },
    updateDeliveryRecipient: {
      invalidatesTags: (result) => {
        const res = result;
        return [{ type: 'DeliveryRecipient', id: res?.id }];
      },
    },
    getConversation: {
      providesTags: (result) => {
        return [{ type: 'ConversationMessage', id: result?.id }];
      },
    },
    addConversationMessage: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'ConversationMessage', id: arg.addConversationMessageModel.conversationId }];
      },
    },
    createEntityAttachment: {
      invalidatesTags: (result, error, args) => {
        //Invalidates all conversations for now
        console.log(result, error, args);
        if (result?.entityType === 'ConversationMessage') {
          return [{ type: 'ConversationMessage' }];
        }
        const type = result?.entityType! as entityAttachmentType;
        return [{ type, id: result?.entityId! }];
      },
    },
    deleteEntityAttachment: {
      invalidatesTags: (result) => {
        const type = result?.entityType! as entityAttachmentType;
        return [{ type }];
      },
    },
    getMasterPart: {
      providesTags: (result) => {
        return [{ type: 'MasterPart', id: result?.id }];
      },
    },
    updateMasterPart: {
      invalidatesTags: (result, errors, args) => {
        if (result === null) {
          // get this with 204's
          return [
            { type: 'MasterPart', id: -1 },
            { type: 'MasterPartHistory', id: 'LIST' },
          ];
        }
        if (result === undefined && errors !== undefined) {
          return [
            { type: 'MasterPart', id: -1 },
            { type: 'MasterPartHistory', id: 'LIST' },
          ];
        }

        const res = result;
        return [
          { type: 'MasterPart', id: res?.id || -1 },
          { type: 'MasterPartHistory', id: 'LIST' },
        ];
      },
    },
    updateTemplate: {
      invalidatesTags: (result) => {
        return [{ type: 'MasterPart', id: result?.id || -1 }];
      },
    },
    updateMasterPartStatus: {
      invalidatesTags: (result) => {
        const res = result;
        return [{ type: 'MasterPart', id: res?.id || -1 }];
      },
    },
    updateStorageContext: {
      invalidatesTags: (result) => {
        return [{ type: 'User' }];
      },
    },
    getBin: {
      providesTags: (result) => {
        return [{ type: 'Bin', id: result?.id }];
      },
    },
    updateBin: {
      invalidatesTags: (result) => {
        return [{ type: 'Bin', id: result?.id || -1 }];
      },
    },
    getCustomer: {
      providesTags: (result) => {
        return [{ type: 'Customer', id: result?.id }];
      },
    },
    updateCustomer: {
      invalidatesTags: (result) => {
        return [{ type: 'Customer', id: result?.id || -1 }];
      },
    },
    reinbound: {
      invalidatesTags: (result) => {
        console.log(result);
        return [{ type: 'Part' }];
      },
    },
    lost: {
      invalidatesTags: (result) => {
        console.log(result);
        return [{ type: 'Part' }];
      },
    },
    getCycleCount: {
      providesTags: (result) => {
        return [{ type: 'CycleCount', id: result?.id }];
      },
    },
    uploadCount: {
      invalidatesTags: (result) => {
        return [{ type: 'CycleCount' }];
      },
    },
    updateCycleCount: {
      invalidatesTags: (result) => {
        return [{ type: 'CycleCount', id: result?.id || -1 }];
      },
    },
    createCycleCount: {
      invalidatesTags: (result) => {
        return [{ type: 'CycleCount' }];
      },
    },
  },
});
