/* eslint-disable no-underscore-dangle */

window.Application = {
  get env() {
    if (window && window._env_) {
      return window._env_;
    }

    return import.meta.env;
  },
};

(function () {
  document.addEventListener('readystatechange', (e) => {
    if (document.readyState !== 'complete') {
      return;
    }

    const links = document.querySelectorAll('link[rel="preload"][as="image"]');
    Array.prototype.slice.call(links).forEach((link) => load(link.href));

    function load(src, retry = 0) {
      if (retry > 3) {
        return;
      }

      const img = document.createElement('img');
      img.src = src;
      img.style.visibility = 'hidden';
      img.style.display = 'none';
      img.onload = function () {
        document.body.removeChild(img);
      };
      img.onerror = function () {
        document.body.removeChild(img);
        setTimeout(load(src, retry + 1), 1000);
      };
      document.body.appendChild(img);
    }
  });
})();
