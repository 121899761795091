/* eslint-disable max-classes-per-file */
import { createElement, ElementType } from 'react';
import { Either, right } from 'fp-ts/Either';
import EventEmitter from 'events';
import type { IModalDispatcher, IModalSource, IModalTask, IServiceLocator, ITask, ShowEventArgs } from 'core/abstractions';

interface DoneCallback<T> {
  (value: Either<Error, T>): void;
}

class Task<T> implements ITask<T> {
  private _done!: DoneCallback<T>;

  private _completed: boolean;

  private _listeners: EventEmitter;

  private _promise: Promise<Either<Error, T>>;

  constructor() {
    this._completed = false;
    this._listeners = new EventEmitter();
    this._promise = new Promise((resolve) => {
      this._done = resolve;
    });
  }

  on(event: 'completed', handler: (task: IModalTask<T>, value: Either<Error, T>) => void): void {
    this._listeners.on(event, handler);
  }

  off(event: 'completed', handler: (task: IModalTask<T>, value: Either<Error, T>) => void): void {
    this._listeners.off(event, handler);
  }

  get result() {
    return this._promise;
  }

  complete = (value: Either<Error, T>) => {
    if (this._completed) {
      return;
    }

    this._completed = true;
    this._done(value);
    this._listeners.emit('completed', this, value);
  };
}

class ModalService extends EventEmitter implements IModalSource, IModalDispatcher {
  show<T>(settings: { name: string; component: ElementType; parameters?: {} }): Promise<Either<Error, T>> {
    const task = new Task<T>();
    const eventArgs: ShowEventArgs<T> = { parameters: {}, ...settings, task };
    this.emit('show', eventArgs);

    return task.result;
  }

  present(settings: { name: string; component: ElementType; parameters?: {} }) {
    const task = new Task<boolean>();
    const eventArgs: ShowEventArgs<boolean> = { parameters: {}, ...settings, task };
    this.emit('show', eventArgs);

    return () => {
      task.complete(right(true));
    };
  }
}

const Modal = new ModalService();
export const ModalSource: IModalSource = Modal;
export const ModalDispatcher: IModalDispatcher = Modal;
export type ModalProps<R, P> = P & { task: IModalTask<R>; visible: boolean; services: IServiceLocator };

export const modal = <R, P>(name: string, component: ElementType<ModalProps<R, P>>) => {
  const WrappedComponent = (props: ModalProps<R, P>) => {
    return createElement(component, props);
  };

  WrappedComponent.show = (props: P): Promise<Either<Error, R>> => {
    return ModalDispatcher.show<R>({ name, component, parameters: props });
  };

  WrappedComponent.present = (props?: P) => {
    return ModalDispatcher.present({ name, component, parameters: props });
  };

  return WrappedComponent;
};
