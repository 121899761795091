import EventEmitter from 'events';
import { noop, throttle } from 'lodash';

const EventName = 'tick';
const DefaultInterval = 60000;

interface Listener {
  (): void;
  ref: () => void;
}

class Ticker {
  constructor() {
    const emitter = new EventEmitter();
    let intervalRef: any | undefined = undefined;

    const tryStart = () => {
      if (typeof intervalRef === 'undefined' && emitter.listenerCount(EventName) > 0) {
        intervalRef = setInterval(() => {
          emitter.emit(EventName);
        }, 1000);
      }
    };

    const tryStop = () => {
      if (typeof intervalRef !== 'undefined' && emitter.listenerCount(EventName) === 0) {
        clearInterval(intervalRef);
        intervalRef = undefined;
      }
    };

    this.subscribe = (sub: () => void, interval) => {
      const throttledSub = throttle(sub, interval ?? DefaultInterval);
      const listener: Listener = () => throttledSub();
      listener.ref = sub;
      emitter.on(EventName, listener);
      tryStart();
      return () => {
        this.unsubscribe(sub);
      };
    };

    this.unsubscribe = (sub: () => void) => {
      const listeners = emitter.listeners(EventName) as Array<Listener>;
      const listener = listeners.find((x) => x.ref === sub);

      if (!listener) {
        return;
      }

      emitter.off(EventName, listener);
      tryStop();
    };

    this.dispose = () => {
      clearInterval(intervalRef);
      emitter.removeAllListeners();
    };
  }

  subscribe: (sub: () => void, interval?: number) => () => void;
  unsubscribe: (sub: () => void) => void;
  dispose: () => void;

  createTimeout = (fn: () => number, timeout: number) => {
    let _clear = noop;
    const id = setTimeout(() => {
      const next = fn();
      if (next) {
        _clear = this.createTimeout(fn, next);
      }
    }, timeout);
    _clear = () => clearTimeout(id);

    return () => {
      _clear();
    };
  };
}

export const Timer = new Ticker();
