// GENERATED CODE
import { emptySplitApi } from './emptyApi';

const baseUrl = '';
export const api = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getAbout: build.query<GetAboutApiResponse, GetAboutApiArg>({
      query: () => ({ url: `${baseUrl}/About`, method: 'GET' }),
    }),
    sendToast: build.mutation<SendToastApiResponse, SendToastApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/SendToast`,
        method: 'POST',
        params: {
          markdownMessage: queryArg.markdownMessage,
          username: queryArg.username,
          persistent: queryArg.persistent,
          severity: queryArg.severity,
        },
      }),
    }),
    changeLoggingLevel: build.mutation<ChangeLoggingLevelApiResponse, ChangeLoggingLevelApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/ChangeLoggingLevel`,
        method: 'POST',
        params: {
          loggerName: queryArg.loggerName,
          logLevel: queryArg.logLevel,
        },
      }),
    }),
    changeMinimumLogLevel: build.mutation<ChangeMinimumLogLevelApiResponse, ChangeMinimumLogLevelApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/ChangeMinimumLogLevel`,
        method: 'POST',
        params: {
          logLevel: queryArg.logLevel,
        },
      }),
    }),
    resetLoggingLevels: build.mutation<ResetLoggingLevelsApiResponse, ResetLoggingLevelsApiArg>({
      query: () => ({ url: `${baseUrl}/Admin/ResetLoggingLevels`, method: 'POST' }),
    }),
    acquireLock: build.query<AcquireLockApiResponse, AcquireLockApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/AcquireLock`,
        method: 'GET',
        params: {
          name: queryArg.name,
          durationSeconds: queryArg.durationSeconds,
        },
      }),
    }),
    releaseLock: build.query<ReleaseLockApiResponse, ReleaseLockApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/ReleaseLock`,
        method: 'GET',
        params: {
          name: queryArg.name,
          lockId: queryArg.lockId,
        },
      }),
    }),
    refreshLock: build.query<RefreshLockApiResponse, RefreshLockApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/RefreshLock`,
        method: 'GET',
        params: {
          name: queryArg.name,
          lockId: queryArg.lockId,
          durationSeconds: queryArg.durationSeconds,
        },
      }),
    }),
    enableBulkUpdate: build.query<EnableBulkUpdateApiResponse, EnableBulkUpdateApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/EnableBulkUpdate`,
        method: 'GET',
        params: {
          enabled: queryArg.enabled,
        },
      }),
    }),
    synchronizeReadCache: build.query<SynchronizeReadCacheApiResponse, SynchronizeReadCacheApiArg>({
      query: () => ({ url: `${baseUrl}/Admin/SynchronizeReadCache`, method: 'GET' }),
    }),
    pauseDatabaseChangeMonitor: build.query<PauseDatabaseChangeMonitorApiResponse, PauseDatabaseChangeMonitorApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/PauseDatabaseChangeMonitor`,
        method: 'GET',
        params: {
          paused: queryArg.paused,
        },
      }),
    }),
    disableDatabaseChangeMonitor: build.query<DisableDatabaseChangeMonitorApiResponse, DisableDatabaseChangeMonitorApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/DisableDatabaseChangeMonitor`,
        method: 'GET',
        params: {
          disabled: queryArg.disabled,
        },
      }),
    }),
    abortChangeProcessing: build.query<AbortChangeProcessingApiResponse, AbortChangeProcessingApiArg>({
      query: () => ({ url: `${baseUrl}/Admin/AbortChangeProcessing`, method: 'GET' }),
    }),
    rebuildReadCacheForEntity: build.query<RebuildReadCacheForEntityApiResponse, RebuildReadCacheForEntityApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/RebuildReadCacheForEntity`,
        method: 'GET',
        params: {
          entityTypeName: queryArg.entityTypeName,
          primaryKeyStart: queryArg.primaryKeyStart,
          syncContext: queryArg.syncContext,
        },
      }),
    }),
    rebuildReadCache: build.query<RebuildReadCacheApiResponse, RebuildReadCacheApiArg>({
      query: () => ({ url: `${baseUrl}/Admin/RebuildReadCache`, method: 'GET' }),
    }),
    markAllSynced: build.query<MarkAllSyncedApiResponse, MarkAllSyncedApiArg>({
      query: () => ({ url: `${baseUrl}/Admin/MarkAllSynced`, method: 'GET' }),
    }),
    processUnsyncedChanges: build.query<ProcessUnsyncedChangesApiResponse, ProcessUnsyncedChangesApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/ProcessUnsyncedChanges`,
        method: 'GET',
        params: {
          syncContext: queryArg.syncContext,
        },
      }),
    }),
    resetLegacySync: build.query<ResetLegacySyncApiResponse, ResetLegacySyncApiArg>({
      query: () => ({ url: `${baseUrl}/Admin/ResetLegacySync`, method: 'GET' }),
    }),
    initiateMigration: build.query<InitiateMigrationApiResponse, InitiateMigrationApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/InitiateMigration`,
        method: 'GET',
        params: {
          includeParts: queryArg.includeParts,
          syncReadCache: queryArg.syncReadCache,
          skipInitial: queryArg.skipInitial,
        },
      }),
    }),
    abortMigration: build.query<AbortMigrationApiResponse, AbortMigrationApiArg>({
      query: () => ({ url: `${baseUrl}/Admin/AbortMigration`, method: 'GET' }),
    }),
    skipLegacyTransaction: build.query<SkipLegacyTransactionApiResponse, SkipLegacyTransactionApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/SkipLegacyTransaction`,
        method: 'GET',
        params: {
          transactionId: queryArg.transactionId,
        },
      }),
    }),
    expireAllTokens: build.mutation<ExpireAllTokensApiResponse, ExpireAllTokensApiArg>({
      query: () => ({ url: `${baseUrl}/Admin/ExpireAllTokens`, method: 'POST' }),
    }),
    resetTokenExpiry: build.query<ResetTokenExpiryApiResponse, ResetTokenExpiryApiArg>({
      query: () => ({ url: `${baseUrl}/Admin/ResetTokenExpiry`, method: 'GET' }),
    }),
    abortAllConnections: build.mutation<AbortAllConnectionsApiResponse, AbortAllConnectionsApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/AbortAllConnections`,
        method: 'POST',
        params: {
          expiredToken: queryArg.expiredToken,
        },
      }),
    }),
    acquireApplicationToken: build.query<AcquireApplicationTokenApiResponse, AcquireApplicationTokenApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/AcquireApplicationToken`,
        method: 'GET',
        params: {
          clientAppId: queryArg.clientAppId,
          clientSecret: queryArg.clientSecret,
          tokenAppId: queryArg.tokenAppId,
          authority: queryArg.authority,
          payloadOnly: queryArg.payloadOnly,
        },
      }),
    }),
    enableBodyLogging: build.query<EnableBodyLoggingApiResponse, EnableBodyLoggingApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/EnableBodyLogging`,
        method: 'GET',
        params: {
          enabled: queryArg.enabled,
        },
      }),
    }),
    enableHeaderLogging: build.query<EnableHeaderLoggingApiResponse, EnableHeaderLoggingApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/EnableHeaderLogging`,
        method: 'GET',
        params: {
          enabled: queryArg.enabled,
        },
      }),
    }),
    enableODataBodyLogging: build.query<EnableODataBodyLoggingApiResponse, EnableODataBodyLoggingApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/EnableODataBodyLogging`,
        method: 'GET',
        params: {
          enabled: queryArg.enabled,
        },
      }),
    }),
    readOnlyMode: build.mutation<ReadOnlyModeApiResponse, ReadOnlyModeApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/ReadOnlyMode`,
        method: 'POST',
        params: {
          enabled: queryArg.enabled,
        },
      }),
    }),
    initializeMaterializedParts: build.mutation<InitializeMaterializedPartsApiResponse, InitializeMaterializedPartsApiArg>({
      query: () => ({ url: `${baseUrl}/Admin/InitializeMaterializedParts`, method: 'POST' }),
    }),
    initializeMaterializedPartTransactions: build.mutation<
      InitializeMaterializedPartTransactionsApiResponse,
      InitializeMaterializedPartTransactionsApiArg
    >({
      query: () => ({ url: `${baseUrl}/Admin/InitializeMaterializedPartTransactions`, method: 'POST' }),
    }),
    useSqlForOdata: build.mutation<UseSqlForOdataApiResponse, UseSqlForOdataApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/UseSqlForOdata`,
        method: 'POST',
        params: {
          useSql: queryArg.useSql,
        },
      }),
    }),
    useAsyncByDefault: build.mutation<UseAsyncByDefaultApiResponse, UseAsyncByDefaultApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Admin/UseAsyncByDefault`,
        method: 'POST',
        params: {
          useAsync: queryArg.useAsync,
        },
      }),
    }),
    unallocatePartsWithNoJobs: build.mutation<UnallocatePartsWithNoJobsApiResponse, UnallocatePartsWithNoJobsApiArg>({
      query: () => ({ url: `${baseUrl}/Admin/UnallocatePartsWithNoJobs`, method: 'POST' }),
    }),
    getAdvancedShipment: build.query<GetAdvancedShipmentApiResponse, GetAdvancedShipmentApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/AdvancedShipments/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createAdvancedShipment: build.mutation<CreateAdvancedShipmentApiResponse, CreateAdvancedShipmentApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/AdvancedShipments`,
        method: 'POST',
        body: queryArg.createAdvancedShipmentModel,
      }),
    }),
    getAnnouncement: build.query<GetAnnouncementApiResponse, GetAnnouncementApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Announcements/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    deleteAnnouncement: build.mutation<DeleteAnnouncementApiResponse, DeleteAnnouncementApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Announcements/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getCurrentAnnouncements: build.query<GetCurrentAnnouncementsApiResponse, GetCurrentAnnouncementsApiArg>({
      query: () => ({ url: `${baseUrl}/Announcements/GetCurrentAnnouncements`, method: 'GET' }),
    }),
    createAnnouncement: build.mutation<CreateAnnouncementApiResponse, CreateAnnouncementApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Announcements`,
        method: 'POST',
        body: queryArg.createAnnouncementModel,
      }),
    }),
    updateAnnouncement: build.mutation<UpdateAnnouncementApiResponse, UpdateAnnouncementApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Announcements`,
        method: 'PUT',
        body: queryArg.updateAnnouncementModel,
      }),
    }),
    getGs1ApplicationIdentifiers: build.query<GetGs1ApplicationIdentifiersApiResponse, GetGs1ApplicationIdentifiersApiArg>({
      query: () => ({ url: `${baseUrl}/Barcodes/GetGs1ApplicationIdentifiers`, method: 'GET' }),
    }),
    getBin: build.query<GetBinApiResponse, GetBinApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Bins/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createBin: build.mutation<CreateBinApiResponse, CreateBinApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Bins`,
        method: 'POST',
        body: queryArg.createBinModel,
      }),
    }),
    updateBin: build.mutation<UpdateBinApiResponse, UpdateBinApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Bins`,
        method: 'PUT',
        body: queryArg.updateBinModel,
      }),
    }),
    updateBulk: build.mutation<UpdateBulkApiResponse, UpdateBulkApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Bins/UpdateBulk`,
        method: 'POST',
        body: queryArg.updateBulkModel,
      }),
    }),
    getCarrier: build.query<GetCarrierApiResponse, GetCarrierApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Carriers/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createCarrier: build.mutation<CreateCarrierApiResponse, CreateCarrierApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Carriers`,
        method: 'POST',
        body: queryArg.createCarrierModel,
      }),
    }),
    updateCarrier: build.mutation<UpdateCarrierApiResponse, UpdateCarrierApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Carriers`,
        method: 'PUT',
        body: queryArg.updateCarrierModel,
      }),
    }),
    getCart: build.query<GetCartApiResponse, GetCartApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Carts/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createCart: build.mutation<CreateCartApiResponse, CreateCartApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Carts`,
        method: 'POST',
        body: queryArg.createCartModel,
      }),
    }),
    getConversation: build.query<GetConversationApiResponse, GetConversationApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Conversations/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    addConversationMessage: build.mutation<AddConversationMessageApiResponse, AddConversationMessageApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Conversations/message`,
        method: 'POST',
        body: queryArg.addConversationMessageModel,
      }),
    }),
    getCustomer: build.query<GetCustomerApiResponse, GetCustomerApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Customers/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createCustomer: build.mutation<CreateCustomerApiResponse, CreateCustomerApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Customers`,
        method: 'POST',
        body: queryArg.createCustomerModel,
      }),
    }),
    updateCustomer: build.mutation<UpdateCustomerApiResponse, UpdateCustomerApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Customers`,
        method: 'PUT',
        body: queryArg.updateCustomerModel,
      }),
    }),
    getCycleCount: build.query<GetCycleCountApiResponse, GetCycleCountApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/CycleCounts/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    deleteCycleCount: build.mutation<DeleteCycleCountApiResponse, DeleteCycleCountApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/CycleCounts/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    createCycleCount: build.mutation<CreateCycleCountApiResponse, CreateCycleCountApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/CycleCounts`,
        method: 'POST',
        body: queryArg.createCycleCountModel,
      }),
    }),
    updateCycleCount: build.mutation<UpdateCycleCountApiResponse, UpdateCycleCountApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/CycleCounts`,
        method: 'PUT',
        body: queryArg.updateCycleCountModel,
      }),
    }),
    uploadCount: build.mutation<UploadCountApiResponse, UploadCountApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/CycleCounts/UploadCount`,
        method: 'POST',
        body: queryArg.uploadCountModel,
      }),
    }),
    getEmbeddedDashboards: build.query<GetEmbeddedDashboardsApiResponse, GetEmbeddedDashboardsApiArg>({
      query: () => ({ url: `${baseUrl}/Dashboard/GetEmbeddedDashboards`, method: 'GET' }),
    }),
    createJob: build.mutation<CreateJobApiResponse, CreateJobApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DataSubscriptionJobs/CreateJob`,
        method: 'POST',
        body: queryArg.createJobModel,
      }),
    }),
    getDataSubscription: build.query<GetDataSubscriptionApiResponse, GetDataSubscriptionApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DataSubscriptions/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    deleteDataSubscription: build.mutation<DeleteDataSubscriptionApiResponse, DeleteDataSubscriptionApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DataSubscriptions/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    createDataSubscription: build.mutation<CreateDataSubscriptionApiResponse, CreateDataSubscriptionApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DataSubscriptions`,
        method: 'POST',
        body: queryArg.createDataSubscriptionModel,
      }),
    }),
    updateDataSubscription: build.mutation<UpdateDataSubscriptionApiResponse, UpdateDataSubscriptionApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DataSubscriptions`,
        method: 'PUT',
        body: queryArg.updateDataSubscriptionModel,
      }),
    }),
    getDataSubscriptionDataSources: build.query<GetDataSubscriptionDataSourcesApiResponse, GetDataSubscriptionDataSourcesApiArg>({
      query: () => ({ url: `${baseUrl}/DataSubscriptions/GetDataSubscriptionDataSources`, method: 'GET' }),
    }),
    getDataSubscriptionObjectTemplate: build.query<GetDataSubscriptionObjectTemplateApiResponse, GetDataSubscriptionObjectTemplateApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DataSubscriptions/GetDataSubscriptionObjectTemplate/${queryArg.dataSubscriptionDataSource}`,
        method: 'GET',
      }),
    }),
    getDataSubscriptionTriggerForDataSource: build.query<
      GetDataSubscriptionTriggerForDataSourceApiResponse,
      GetDataSubscriptionTriggerForDataSourceApiArg
    >({
      query: (queryArg) => ({
        url: `${baseUrl}/DataSubscriptions/GetDataSubscriptionTriggerForDataSource/${queryArg.dataSubscriptionDataSource}`,
        method: 'GET',
      }),
    }),
    sendDataSubscriptionTestPayload: build.mutation<SendDataSubscriptionTestPayloadApiResponse, SendDataSubscriptionTestPayloadApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DataSubscriptions/SendDataSubscriptionTestPayload`,
        method: 'POST',
        body: queryArg.sendDataSubscriptionTestPayloadModel,
      }),
    }),
    getDeliveryReceipt: build.query<GetDeliveryReceiptApiResponse, GetDeliveryReceiptApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DeliveryReceipts/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createDeliveryReceipt: build.mutation<CreateDeliveryReceiptApiResponse, CreateDeliveryReceiptApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DeliveryReceipts`,
        method: 'POST',
        body: queryArg.createDeliveryReceiptModel,
      }),
    }),
    getDeliveryRecipient: build.query<GetDeliveryRecipientApiResponse, GetDeliveryRecipientApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DeliveryRecipients/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createDeliveryRecipient: build.mutation<CreateDeliveryRecipientApiResponse, CreateDeliveryRecipientApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DeliveryRecipients`,
        method: 'POST',
        body: queryArg.createDeliveryRecipientModel,
      }),
    }),
    updateDeliveryRecipient: build.mutation<UpdateDeliveryRecipientApiResponse, UpdateDeliveryRecipientApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DeliveryRecipients`,
        method: 'PUT',
        body: queryArg.updateDeliveryRecipientModel,
      }),
    }),
    sendWebsocketMessage: build.mutation<SendWebsocketMessageApiResponse, SendWebsocketMessageApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Developer/SendWebsocketMessage`,
        method: 'POST',
        body: queryArg.any,
      }),
    }),
    getDockReceipt: build.query<GetDockReceiptApiResponse, GetDockReceiptApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DockReceipts/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    deleteDockReceipt: build.mutation<DeleteDockReceiptApiResponse, DeleteDockReceiptApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DockReceipts/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getDockReceiptHistory: build.query<GetDockReceiptHistoryApiResponse, GetDockReceiptHistoryApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DockReceipts/History/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createDockReceipt: build.mutation<CreateDockReceiptApiResponse, CreateDockReceiptApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DockReceipts`,
        method: 'POST',
        body: queryArg.createDockReceiptModel,
      }),
    }),
    removeDelivery: build.mutation<RemoveDeliveryApiResponse, RemoveDeliveryApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DockReceipts/RemoveDelivery`,
        method: 'POST',
        body: queryArg.removeDeliveryModel,
      }),
    }),
    changeStatus: build.mutation<ChangeStatusApiResponse, ChangeStatusApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/DockReceipts/ChangeStatus`,
        method: 'POST',
        body: queryArg.changeStatusModel,
      }),
    }),
    createEntityAttachment: build.mutation<CreateEntityAttachmentApiResponse, CreateEntityAttachmentApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/EntityAttachments`,
        method: 'POST',
        body: queryArg.createEntityAttachmentModel,
      }),
    }),
    deleteEntityAttachment: build.mutation<DeleteEntityAttachmentApiResponse, DeleteEntityAttachmentApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/EntityAttachments`,
        method: 'DELETE',
        body: queryArg.deleteEntityAttachmentModel,
      }),
    }),
    getExternalApplication: build.query<GetExternalApplicationApiResponse, GetExternalApplicationApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/ExternalApplications/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createExternalApplication: build.mutation<CreateExternalApplicationApiResponse, CreateExternalApplicationApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/ExternalApplications`,
        method: 'POST',
        body: queryArg.createExternalApplicationModel,
      }),
    }),
    updateExternalApplication: build.mutation<UpdateExternalApplicationApiResponse, UpdateExternalApplicationApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/ExternalApplications`,
        method: 'PUT',
        body: queryArg.updateExternalApplicationModel,
      }),
    }),
    createInbound: build.mutation<CreateInboundApiResponse, CreateInboundApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Inbound`,
        method: 'POST',
        body: queryArg.createInboundModel,
      }),
    }),
    reinbound: build.mutation<ReinboundApiResponse, ReinboundApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Inbound/Reinbound`,
        method: 'POST',
        body: queryArg.reinboundModel,
      }),
    }),
    moveToCart: build.mutation<MoveToCartApiResponse, MoveToCartApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Inbound/MoveToCart`,
        method: 'POST',
        body: queryArg.moveToCartModel,
      }),
    }),
    readTransactionInfo: build.query<ReadTransactionInfoApiResponse, ReadTransactionInfoApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Inbound/readTransactionInfo/${queryArg.partId}`,
        method: 'GET',
      }),
    }),
    partsByWarehouse: build.query<PartsByWarehouseApiResponse, PartsByWarehouseApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/PartsByWarehouse`,
        method: 'GET',
        params: {
          customerCode: queryArg.customerCode,
          masterPartCodes: queryArg.masterPartCodes,
          partStatuses: queryArg.partStatuses,
          partStatusesExclusive: queryArg.partStatusesExclusive,
        },
      }),
    }),
    readTransactions: build.query<ReadTransactionsApiResponse, ReadTransactionsApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Legacy/ReadTransactions/${queryArg.partId}`,
        method: 'GET',
      }),
    }),
    getMasterPartAttributeCategory: build.query<GetMasterPartAttributeCategoryApiResponse, GetMasterPartAttributeCategoryApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterPartAttributeCategories/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createMasterPartAttributeCategory: build.mutation<CreateMasterPartAttributeCategoryApiResponse, CreateMasterPartAttributeCategoryApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterPartAttributeCategories`,
        method: 'POST',
        body: queryArg.createMasterPartAttributeCategoryModel,
      }),
    }),
    updateMasterPartAttributeCategory: build.mutation<UpdateMasterPartAttributeCategoryApiResponse, UpdateMasterPartAttributeCategoryApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterPartAttributeCategories`,
        method: 'PUT',
        body: queryArg.updateMasterPartAttributeCategoryModel,
      }),
    }),
    getMasterPart: build.query<GetMasterPartApiResponse, GetMasterPartApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterParts/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    getMasterPartHistory: build.query<GetMasterPartHistoryApiResponse, GetMasterPartHistoryApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterParts/History/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createMasterPart: build.mutation<CreateMasterPartApiResponse, CreateMasterPartApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterParts`,
        method: 'POST',
        body: queryArg.createMasterPartModel,
      }),
    }),
    updateMasterPart: build.mutation<UpdateMasterPartApiResponse, UpdateMasterPartApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterParts`,
        method: 'PUT',
        body: queryArg.updateMasterPartModel,
      }),
    }),
    updateMasterPartStatus: build.mutation<UpdateMasterPartStatusApiResponse, UpdateMasterPartStatusApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterParts/UpdateMasterPartStatus`,
        method: 'PUT',
        body: queryArg.updateMasterPartStatusModel,
      }),
    }),
    merge: build.mutation<MergeApiResponse, MergeApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterParts/Merge`,
        method: 'POST',
        body: queryArg.mergeModel,
      }),
    }),
    legacyCheck: build.query<LegacyCheckApiResponse, LegacyCheckApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterParts/LegacyCheck`,
        method: 'GET',
        params: {
          masterPartCode: queryArg.masterPartCode,
          customerId: queryArg.customerId,
        },
      }),
    }),
    validatePartAttributes: build.mutation<ValidatePartAttributesApiResponse, ValidatePartAttributesApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterParts/ValidatePartAttributes`,
        method: 'POST',
        body: queryArg.validatePartAttributesModel,
      }),
    }),
    updateTemplate: build.mutation<UpdateTemplateApiResponse, UpdateTemplateApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterParts/UpdateTemplate`,
        method: 'POST',
        body: queryArg.updateTemplateModel,
      }),
    }),
    getMasterPartTemplate: build.query<GetMasterPartTemplateApiResponse, GetMasterPartTemplateApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterPartTemplates/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createMasterPartTemplate: build.mutation<CreateMasterPartTemplateApiResponse, CreateMasterPartTemplateApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterPartTemplates`,
        method: 'POST',
        body: queryArg.createMasterPartTemplateModel,
      }),
    }),
    updateMasterPartTemplate: build.mutation<UpdateMasterPartTemplateApiResponse, UpdateMasterPartTemplateApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MasterPartTemplates`,
        method: 'PUT',
        body: queryArg.updateMasterPartTemplateModel,
      }),
    }),
    resolve: build.mutation<ResolveApiResponse, ResolveApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/MIR/Resolve`,
        method: 'POST',
        body: queryArg.resolveModel,
      }),
    }),
    listNotificationChannels: build.query<ListNotificationChannelsApiResponse, ListNotificationChannelsApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/NotificationChannels`,
        method: 'GET',
        params: {
          notificationClientId: queryArg.notificationClientId,
        },
      }),
    }),
    createNotificationChannel: build.mutation<CreateNotificationChannelApiResponse, CreateNotificationChannelApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/NotificationChannels`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    updateNotificationChannel: build.mutation<UpdateNotificationChannelApiResponse, UpdateNotificationChannelApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/NotificationChannels`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getNotificationChannel: build.query<GetNotificationChannelApiResponse, GetNotificationChannelApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/NotificationChannels/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    deleteNotificationChannel: build.mutation<DeleteNotificationChannelApiResponse, DeleteNotificationChannelApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/NotificationChannels/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    updateChannelMappings: build.mutation<UpdateChannelMappingsApiResponse, UpdateChannelMappingsApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/NotificationChannels/UpdateChannelMappings`,
        method: 'PUT',
        body: queryArg.updateChannelMappingsModel,
      }),
    }),
    createNotificationClient: build.mutation<CreateNotificationClientApiResponse, CreateNotificationClientApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/NotificationClients`,
        method: 'POST',
        body: queryArg.createNotificationClientModel,
      }),
    }),
    deleteNotificationClient: build.mutation<DeleteNotificationClientApiResponse, DeleteNotificationClientApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/NotificationClients/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getNotificationMessageTemplate: build.query<GetNotificationMessageTemplateApiResponse, GetNotificationMessageTemplateApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/NotificationMessageTemplates/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    deleteNotificationMessageTemplate: build.mutation<DeleteNotificationMessageTemplateApiResponse, DeleteNotificationMessageTemplateApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/NotificationMessageTemplates/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    createNotificationMessageTemplate: build.mutation<CreateNotificationMessageTemplateApiResponse, CreateNotificationMessageTemplateApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/NotificationMessageTemplates`,
        method: 'POST',
        body: queryArg.createNotificationMessageTemplateModel,
      }),
    }),
    updateNotificationMessageTemplate: build.mutation<UpdateNotificationMessageTemplateApiResponse, UpdateNotificationMessageTemplateApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/NotificationMessageTemplates`,
        method: 'PUT',
        body: queryArg.updateNotificationMessageTemplateModel,
      }),
    }),
    getNotificationMessageTemplateObjectTemplate: build.query<
      GetNotificationMessageTemplateObjectTemplateApiResponse,
      GetNotificationMessageTemplateObjectTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `${baseUrl}/NotificationMessageTemplates/GetNotificationMessageTemplateObjectTemplate/${queryArg.notificationEvent}`,
        method: 'GET',
      }),
    }),
    getOutbound: build.query<GetOutboundApiResponse, GetOutboundApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Outbound/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createOutbound: build.mutation<CreateOutboundApiResponse, CreateOutboundApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Outbound`,
        method: 'POST',
        body: queryArg.createOutboundModel,
      }),
    }),
    updateOutbound: build.mutation<UpdateOutboundApiResponse, UpdateOutboundApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Outbound`,
        method: 'PUT',
        body: queryArg.updateOutboundModel,
      }),
    }),
    updateJobWarehouseOutbound: build.mutation<UpdateJobWarehouseOutboundApiResponse, UpdateJobWarehouseOutboundApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Outbound/UpdateJobWarehouse`,
        method: 'PUT',
        body: queryArg.updateJobWarehouseOutboundModel,
      }),
    }),
    allocateOutbound: build.mutation<AllocateOutboundApiResponse, AllocateOutboundApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Outbound/Allocate`,
        method: 'POST',
        body: queryArg.allocateOutboundModel,
      }),
    }),
    unallocateOutbound: build.mutation<UnallocateOutboundApiResponse, UnallocateOutboundApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Outbound/Unallocate`,
        method: 'POST',
        body: queryArg.unallocateOutboundModel,
      }),
    }),
    updateShippingInformation: build.mutation<UpdateShippingInformationApiResponse, UpdateShippingInformationApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Outbound/UpdateShippingInformation`,
        method: 'POST',
        body: queryArg.updateShippingInformationModel,
      }),
    }),
    updateJobStateOutbound: build.mutation<UpdateJobStateOutboundApiResponse, UpdateJobStateOutboundApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Outbound/UpdateJobState`,
        method: 'POST',
        body: queryArg.updateJobStateOutboundModel,
      }),
    }),
    bulkOutbound: build.mutation<BulkOutboundApiResponse, BulkOutboundApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Outbound/BulkOutbound`,
        method: 'POST',
        body: queryArg.bulkOutboundModel,
      }),
    }),
    renderTemplate: build.query<RenderTemplateApiResponse, RenderTemplateApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Outbound/RenderTemplate/${queryArg.outboundJobId}`,
        method: 'GET',
        params: {
          timezoneOffset: queryArg.timezoneOffset,
        },
      }),
    }),
    completeTransfer: build.mutation<CompleteTransferApiResponse, CompleteTransferApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Outbound/CompleteTransfer`,
        method: 'POST',
        body: queryArg.completeTransferModel,
      }),
    }),
    getPartMirContext: build.query<GetPartMIRContextApiResponse, GetPartMIRContextApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/PartMIRContexts/${queryArg.partId}`,
        method: 'GET',
      }),
    }),
    getPart: build.query<GetPartApiResponse, GetPartApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    scrap: build.mutation<ScrapApiResponse, ScrapApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/Scrap`,
        method: 'POST',
        body: queryArg.scrapModel,
      }),
    }),
    move: build.mutation<MoveApiResponse, MoveApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/Move`,
        method: 'POST',
        body: queryArg.moveModel,
      }),
    }),
    bulkMove: build.mutation<BulkMoveApiResponse, BulkMoveApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/BulkMove`,
        method: 'POST',
        body: queryArg.bulkMoveModel,
      }),
    }),
    bulkJustify: build.mutation<BulkJustifyApiResponse, BulkJustifyApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/BulkJustify`,
        method: 'POST',
        body: queryArg.bulkJustifyModel,
      }),
    }),
    found: build.mutation<FoundApiResponse, FoundApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/Found`,
        method: 'POST',
        body: queryArg.foundModel,
      }),
    }),
    lost: build.mutation<LostApiResponse, LostApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/Lost`,
        method: 'POST',
        body: queryArg.lostModel,
      }),
    }),
    hold: build.mutation<HoldApiResponse, HoldApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/Hold`,
        method: 'POST',
        body: queryArg.holdModel,
      }),
    }),
    release: build.mutation<ReleaseApiResponse, ReleaseApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/Release`,
        method: 'POST',
        body: queryArg.releaseModel,
      }),
    }),
    quarantine: build.mutation<QuarantineApiResponse, QuarantineApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/Quarantine`,
        method: 'POST',
        body: queryArg.quarantineModel,
      }),
    }),
    unquarantine: build.mutation<UnquarantineApiResponse, UnquarantineApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/Unquarantine`,
        method: 'POST',
        body: queryArg.unquarantineModel,
      }),
    }),
    onHoldById: build.query<OnHoldByIdApiResponse, OnHoldByIdApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/OnHoldById`,
        method: 'GET',
        params: {
          holdId: queryArg.holdId,
        },
      }),
    }),
    updatePartStatus: build.mutation<UpdatePartStatusApiResponse, UpdatePartStatusApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/UpdatePartStatus`,
        method: 'POST',
        body: queryArg.updatePartStatusModel,
      }),
    }),
    defective: build.mutation<DefectiveApiResponse, DefectiveApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/Defective`,
        method: 'POST',
        body: queryArg.defectiveModel,
      }),
    }),
    updatePart: build.mutation<UpdatePartApiResponse, UpdatePartApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/Update`,
        method: 'POST',
        body: queryArg.updatePartModel,
      }),
    }),
    printLabel: build.mutation<PrintLabelApiResponse, PrintLabelApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/PrintLabel`,
        method: 'POST',
        body: queryArg.printLabelModel,
      }),
    }),
    putAway: build.mutation<PutAwayApiResponse, PutAwayApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Parts/PutAway`,
        method: 'POST',
        body: queryArg.putAwayModel,
      }),
    }),
    restrictedMetadataKeys: build.query<RestrictedMetadataKeysApiResponse, RestrictedMetadataKeysApiArg>({
      query: () => ({ url: `${baseUrl}/PartTransactionBatches/RestrictedMetadataKeys`, method: 'GET' }),
    }),
    patchMetadata: build.mutation<PatchMetadataApiResponse, PatchMetadataApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/PartTransactionBatches/PatchMetadata`,
        method: 'PATCH',
        body: queryArg.patchMetadataModel,
      }),
    }),
    getUserPermissions: build.query<GetUserPermissionsApiResponse, GetUserPermissionsApiArg>({
      query: () => ({ url: `${baseUrl}/Permissions/GetUserPermissions`, method: 'GET' }),
    }),
    updateRolePermissions: build.mutation<UpdateRolePermissionsApiResponse, UpdateRolePermissionsApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Permissions/UpdateRolePermissions`,
        method: 'PATCH',
        body: queryArg.updateRolePermissionsModel,
      }),
    }),
    syncAdUsers: build.mutation<SyncADUsersApiResponse, SyncADUsersApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Permissions/SyncADUsers`,
        method: 'POST',
        params: {
          dryRun: queryArg.dryRun,
        },
      }),
    }),
    getUserAppRoles: build.query<GetUserAppRolesApiResponse, GetUserAppRolesApiArg>({
      query: () => ({ url: `${baseUrl}/Permissions/GetUserAppRoles`, method: 'GET' }),
    }),
    getAppRolesForUser: build.query<GetAppRolesForUserApiResponse, GetAppRolesForUserApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Permissions/GetAppRolesForUser`,
        method: 'GET',
        params: {
          userId: queryArg.userId,
        },
      }),
    }),
    getAppRoles: build.query<GetAppRolesApiResponse, GetAppRolesApiArg>({
      query: () => ({ url: `${baseUrl}/Permissions/GetAppRoles`, method: 'GET' }),
    }),
    createAppRole: build.mutation<CreateAppRoleApiResponse, CreateAppRoleApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Permissions/CreateAppRole`,
        method: 'POST',
        body: queryArg.createAppRoleModel,
      }),
    }),
    deleteAppRole: build.mutation<DeleteAppRoleApiResponse, DeleteAppRoleApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Permissions/DeleteAppRole`,
        method: 'POST',
        body: queryArg.deleteAppRoleModel,
      }),
    }),
    resetRoleMappings: build.mutation<ResetRoleMappingsApiResponse, ResetRoleMappingsApiArg>({
      query: () => ({ url: `${baseUrl}/Permissions/ResetRoleMappings`, method: 'POST' }),
    }),
    getAllPermissions: build.query<GetAllPermissionsApiResponse, GetAllPermissionsApiArg>({
      query: () => ({ url: `${baseUrl}/Permissions/GetAllPermissions`, method: 'GET' }),
    }),
    getRoleMappings: build.query<GetRoleMappingsApiResponse, GetRoleMappingsApiArg>({
      query: () => ({ url: `${baseUrl}/Permissions/GetRoleMappings`, method: 'GET' }),
    }),
    performRequest: build.mutation<PerformRequestApiResponse, PerformRequestApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Proxy`,
        method: 'POST',
        body: queryArg.performRequestModel,
      }),
    }),
    getPutAwayJob: build.query<GetPutAwayJobApiResponse, GetPutAwayJobApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/PutAwayJobs/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    generateReport: build.mutation<GenerateReportApiResponse, GenerateReportApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Reports/GenerateReport`,
        method: 'POST',
        body: queryArg.generateReportModel,
      }),
    }),
    getResourceGroup: build.query<GetResourceGroupApiResponse, GetResourceGroupApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/ResourceGroups/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    deleteResourceGroup: build.mutation<DeleteResourceGroupApiResponse, DeleteResourceGroupApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/ResourceGroups/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    createResourceGroup: build.mutation<CreateResourceGroupApiResponse, CreateResourceGroupApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/ResourceGroups`,
        method: 'POST',
        body: queryArg.createResourceGroupModel,
      }),
    }),
    updateResourceGroup: build.mutation<UpdateResourceGroupApiResponse, UpdateResourceGroupApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/ResourceGroups`,
        method: 'PUT',
        body: queryArg.updateResourceGroupModel,
      }),
    }),
    getStockLevel: build.query<GetStockLevelApiResponse, GetStockLevelApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/StockLevels/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    deleteStockLevel: build.mutation<DeleteStockLevelApiResponse, DeleteStockLevelApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/StockLevels/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    createStockLevel: build.mutation<CreateStockLevelApiResponse, CreateStockLevelApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/StockLevels`,
        method: 'POST',
        body: queryArg.createStockLevelModel,
      }),
    }),
    updateStockLevel: build.mutation<UpdateStockLevelApiResponse, UpdateStockLevelApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/StockLevels`,
        method: 'PUT',
        body: queryArg.updateStockLevelModel,
      }),
    }),
    requestTokenForSystem: build.query<RequestTokenForSystemApiResponse, RequestTokenForSystemApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Tokens`,
        method: 'GET',
        body: queryArg.requestTokenModel,
      }),
    }),
    createLegacyTransfer: build.mutation<CreateLegacyTransferApiResponse, CreateLegacyTransferApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Transfers/CreateLegacyTransfer`,
        method: 'POST',
        body: queryArg.createLegacyTransferModel,
      }),
    }),
    getTransfer: build.query<GetTransferApiResponse, GetTransferApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Transfers/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createTransfer: build.mutation<CreateTransferApiResponse, CreateTransferApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Transfers`,
        method: 'POST',
        body: queryArg.createTransferModel,
      }),
    }),
    updateTransfer: build.mutation<UpdateTransferApiResponse, UpdateTransferApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Transfers`,
        method: 'PUT',
        body: queryArg.updateTransferModel,
      }),
    }),
    allocateTransfer: build.mutation<AllocateTransferApiResponse, AllocateTransferApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Transfers/Allocate`,
        method: 'POST',
        body: queryArg.allocateTransferModel,
      }),
    }),
    unallocateTransfer: build.mutation<UnallocateTransferApiResponse, UnallocateTransferApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Transfers/Unallocate`,
        method: 'POST',
        body: queryArg.unallocateTransferModel,
      }),
    }),
    transferOut: build.mutation<TransferOutApiResponse, TransferOutApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Transfers/TransferOut`,
        method: 'POST',
        body: queryArg.transferOutModel,
      }),
    }),
    transferIn: build.mutation<TransferInApiResponse, TransferInApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Transfers/TransferIn`,
        method: 'POST',
        body: queryArg.transferInModel,
      }),
    }),
    updateJobStateTransfer: build.mutation<UpdateJobStateTransferApiResponse, UpdateJobStateTransferApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Transfers/UpdateJobState`,
        method: 'POST',
        body: queryArg.updateJobStateTransferModel,
      }),
    }),
    updateJobWarehouseTransfer: build.mutation<UpdateJobWarehouseTransferApiResponse, UpdateJobWarehouseTransferApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Transfers/UpdateJobWarehouse`,
        method: 'PUT',
        body: queryArg.updateJobWarehouseTransferModel,
      }),
    }),
    assignResourceGroups: build.mutation<AssignResourceGroupsApiResponse, AssignResourceGroupsApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Users/AssignResourceGroups`,
        method: 'PUT',
        body: queryArg.assignResourceGroupsModel,
      }),
    }),
    listStorageContexts: build.query<ListStorageContextsApiResponse, ListStorageContextsApiArg>({
      query: () => ({ url: `${baseUrl}/Users/ListStorageContexts`, method: 'GET' }),
    }),
    getDataForStorageContext: build.query<GetDataForStorageContextApiResponse, GetDataForStorageContextApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Users/GetDataForStorageContext/${queryArg.context}`,
        method: 'GET',
      }),
    }),
    updateStorageContext: build.mutation<UpdateStorageContextApiResponse, UpdateStorageContextApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Users/UpdateStorageContext`,
        method: 'PUT',
        body: queryArg.updateStorageContextModel,
      }),
    }),
    deleteStorageContext: build.mutation<DeleteStorageContextApiResponse, DeleteStorageContextApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Users/DeleteStorageContext/${queryArg.context}`,
        method: 'DELETE',
      }),
    }),
    getAllActiveDirectoryUsers: build.query<GetAllActiveDirectoryUsersApiResponse, GetAllActiveDirectoryUsersApiArg>({
      query: () => ({ url: `${baseUrl}/Users/GetAllActiveDirectoryUsers`, method: 'GET' }),
    }),
    renderHandlebarsTemplate: build.mutation<RenderHandlebarsTemplateApiResponse, RenderHandlebarsTemplateApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Utils/RenderHandlebarsTemplate`,
        method: 'POST',
        body: queryArg.renderHandlebarsTemplateModel,
      }),
    }),
    getWarehouse: build.query<GetWarehouseApiResponse, GetWarehouseApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Warehouses/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createWarehouse: build.mutation<CreateWarehouseApiResponse, CreateWarehouseApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Warehouses`,
        method: 'POST',
        body: queryArg.createWarehouseModel,
      }),
    }),
    updateWarehouse: build.mutation<UpdateWarehouseApiResponse, UpdateWarehouseApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Warehouses`,
        method: 'PUT',
        body: queryArg.updateWarehouseModel,
      }),
    }),
    getZone: build.query<GetZoneApiResponse, GetZoneApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Zones/${queryArg.id}`,
        method: 'GET',
      }),
    }),
    createZone: build.mutation<CreateZoneApiResponse, CreateZoneApiArg>({
      query: (queryArg) => ({
        url: `${baseUrl}/Zones`,
        method: 'POST',
        body: queryArg.createZoneModel,
      }),
    }),
    createAdvancedShipmentAsync: build.mutation<AsyncResponse<CreateAdvancedShipmentApiResponse>, CreateAdvancedShipmentApiArg>({
      query: (queryArg) => ({
        url: `/AdvancedShipments`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.createAdvancedShipmentModel,
      }),
    }),
    createBinAsync: build.mutation<AsyncResponse<CreateBinApiResponse>, CreateBinApiArg>({
      query: (queryArg) => ({
        url: `/Bins`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.createBinModel,
      }),
    }),
    updateBinAsync: build.mutation<AsyncResponse<UpdateBinApiResponse>, UpdateBinApiArg>({
      query: (queryArg) => ({
        url: `/Bins`,
        method: 'PUT',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.updateBinModel,
      }),
    }),
    updateBulkAsync: build.mutation<AsyncResponse<UpdateBulkApiResponse>, UpdateBulkApiArg>({
      query: (queryArg) => ({
        url: `/Bins/UpdateBulk`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.updateBulkModel,
      }),
    }),
    createEntityAttachmentAsync: build.mutation<AsyncResponse<CreateEntityAttachmentApiResponse>, CreateEntityAttachmentApiArg>({
      query: (queryArg) => ({
        url: `/EntityAttachments`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.createEntityAttachmentModel,
      }),
    }),
    deleteEntityAttachmentAsync: build.mutation<AsyncResponse<DeleteEntityAttachmentApiResponse>, DeleteEntityAttachmentApiArg>({
      query: (queryArg) => ({
        url: `/EntityAttachments`,
        method: 'DELETE',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.deleteEntityAttachmentModel,
      }),
    }),
    createInboundAsync: build.mutation<AsyncResponse<CreateInboundApiResponse>, CreateInboundApiArg>({
      query: (queryArg) => ({
        url: `/Inbound`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.createInboundModel,
      }),
    }),
    reinboundAsync: build.mutation<AsyncResponse<ReinboundApiResponse>, ReinboundApiArg>({
      query: (queryArg) => ({
        url: `/Inbound/Reinbound`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.reinboundModel,
      }),
    }),
    moveToCartAsync: build.mutation<AsyncResponse<MoveToCartApiResponse>, MoveToCartApiArg>({
      query: (queryArg) => ({
        url: `/Inbound/MoveToCart`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.moveToCartModel,
      }),
    }),
    resolveAsync: build.mutation<AsyncResponse<ResolveApiResponse>, ResolveApiArg>({
      query: (queryArg) => ({
        url: `/MIR/Resolve`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.resolveModel,
      }),
    }),
    createOutboundAsync: build.mutation<AsyncResponse<CreateOutboundApiResponse>, CreateOutboundApiArg>({
      query: (queryArg) => ({
        url: `/Outbound`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.createOutboundModel,
      }),
    }),
    updateOutboundAsync: build.mutation<AsyncResponse<UpdateOutboundApiResponse>, UpdateOutboundApiArg>({
      query: (queryArg) => ({
        url: `/Outbound`,
        method: 'PUT',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.updateOutboundModel,
      }),
    }),
    updateJobWarehouseOutboundAsync: build.mutation<AsyncResponse<UpdateJobWarehouseOutboundApiResponse>, UpdateJobWarehouseOutboundApiArg>({
      query: (queryArg) => ({
        url: `/Outbound/UpdateJobWarehouse`,
        method: 'PUT',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.updateJobWarehouseOutboundModel,
      }),
    }),
    allocateOutboundAsync: build.mutation<AsyncResponse<AllocateOutboundApiResponse>, AllocateOutboundApiArg>({
      query: (queryArg) => ({
        url: `/Outbound/Allocate`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.allocateOutboundModel,
      }),
    }),
    unallocateOutboundAsync: build.mutation<AsyncResponse<UnallocateOutboundApiResponse>, UnallocateOutboundApiArg>({
      query: (queryArg) => ({
        url: `/Outbound/Unallocate`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.unallocateOutboundModel,
      }),
    }),
    updateShippingInformationAsync: build.mutation<AsyncResponse<UpdateShippingInformationApiResponse>, UpdateShippingInformationApiArg>({
      query: (queryArg) => ({
        url: `/Outbound/UpdateShippingInformation`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.updateShippingInformationModel,
      }),
    }),
    updateJobStateOutboundAsync: build.mutation<AsyncResponse<UpdateJobStateOutboundApiResponse>, UpdateJobStateOutboundApiArg>({
      query: (queryArg) => ({
        url: `/Outbound/UpdateJobState`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.updateJobStateOutboundModel,
      }),
    }),
    completeTransferAsync: build.mutation<AsyncResponse<CompleteTransferApiResponse>, CompleteTransferApiArg>({
      query: (queryArg) => ({
        url: `/Outbound/CompleteTransfer`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.completeTransferModel,
      }),
    }),
    scrapAsync: build.mutation<AsyncResponse<ScrapApiResponse>, ScrapApiArg>({
      query: (queryArg) => ({
        url: `/Parts/Scrap`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.scrapModel,
      }),
    }),
    moveAsync: build.mutation<AsyncResponse<MoveApiResponse>, MoveApiArg>({
      query: (queryArg) => ({
        url: `/Parts/Move`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.moveModel,
      }),
    }),
    foundAsync: build.mutation<AsyncResponse<FoundApiResponse>, FoundApiArg>({
      query: (queryArg) => ({
        url: `/Parts/Found`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.foundModel,
      }),
    }),
    lostAsync: build.mutation<AsyncResponse<LostApiResponse>, LostApiArg>({
      query: (queryArg) => ({
        url: `/Parts/Lost`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.lostModel,
      }),
    }),
    holdAsync: build.mutation<AsyncResponse<HoldApiResponse>, HoldApiArg>({
      query: (queryArg) => ({
        url: `/Parts/Hold`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.holdModel,
      }),
    }),
    releaseAsync: build.mutation<AsyncResponse<ReleaseApiResponse>, ReleaseApiArg>({
      query: (queryArg) => ({
        url: `/Parts/Release`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.releaseModel,
      }),
    }),
    quarantineAsync: build.mutation<AsyncResponse<QuarantineApiResponse>, QuarantineApiArg>({
      query: (queryArg) => ({
        url: `/Parts/Quarantine`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.quarantineModel,
      }),
    }),
    unquarantineAsync: build.mutation<AsyncResponse<UnquarantineApiResponse>, UnquarantineApiArg>({
      query: (queryArg) => ({
        url: `/Parts/Unquarantine`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.unquarantineModel,
      }),
    }),
    updatePartStatusAsync: build.mutation<AsyncResponse<UpdatePartStatusApiResponse>, UpdatePartStatusApiArg>({
      query: (queryArg) => ({
        url: `/Parts/UpdatePartStatus`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.updatePartStatusModel,
      }),
    }),
    defectiveAsync: build.mutation<AsyncResponse<DefectiveApiResponse>, DefectiveApiArg>({
      query: (queryArg) => ({
        url: `/Parts/Defective`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.defectiveModel,
      }),
    }),
    updatePartAsync: build.mutation<AsyncResponse<UpdatePartApiResponse>, UpdatePartApiArg>({
      query: (queryArg) => ({
        url: `/Parts/Update`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.updatePartModel,
      }),
    }),
    putAwayAsync: build.mutation<AsyncResponse<PutAwayApiResponse>, PutAwayApiArg>({
      query: (queryArg) => ({
        url: `/Parts/PutAway`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.putAwayModel,
      }),
    }),
    patchMetadataAsync: build.mutation<AsyncResponse<PatchMetadataApiResponse>, PatchMetadataApiArg>({
      query: (queryArg) => ({
        url: `/PartTransactionBatches/PatchMetadata`,
        method: 'PATCH',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.patchMetadataModel,
      }),
    }),
    createLegacyTransferAsync: build.mutation<AsyncResponse<CreateLegacyTransferApiResponse>, CreateLegacyTransferApiArg>({
      query: (queryArg) => ({
        url: `/Transfers/CreateLegacyTransfer`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.createLegacyTransferModel,
      }),
    }),
    createTransferAsync: build.mutation<AsyncResponse<CreateTransferApiResponse>, CreateTransferApiArg>({
      query: (queryArg) => ({
        url: `/Transfers`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.createTransferModel,
      }),
    }),
    updateTransferAsync: build.mutation<AsyncResponse<UpdateTransferApiResponse>, UpdateTransferApiArg>({
      query: (queryArg) => ({
        url: `/Transfers`,
        method: 'PUT',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.updateTransferModel,
      }),
    }),
    allocateTransferAsync: build.mutation<AsyncResponse<AllocateTransferApiResponse>, AllocateTransferApiArg>({
      query: (queryArg) => ({
        url: `/Transfers/Allocate`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.allocateTransferModel,
      }),
    }),
    unallocateTransferAsync: build.mutation<AsyncResponse<UnallocateTransferApiResponse>, UnallocateTransferApiArg>({
      query: (queryArg) => ({
        url: `/Transfers/Unallocate`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.unallocateTransferModel,
      }),
    }),
    transferOutAsync: build.mutation<AsyncResponse<TransferOutApiResponse>, TransferOutApiArg>({
      query: (queryArg) => ({
        url: `/Transfers/TransferOut`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.transferOutModel,
      }),
    }),
    transferInAsync: build.mutation<AsyncResponse<TransferInApiResponse>, TransferInApiArg>({
      query: (queryArg) => ({
        url: `/Transfers/TransferIn`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.transferInModel,
      }),
    }),
    updateJobStateTransferAsync: build.mutation<AsyncResponse<UpdateJobStateTransferApiResponse>, UpdateJobStateTransferApiArg>({
      query: (queryArg) => ({
        url: `/Transfers/UpdateJobState`,
        method: 'POST',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.updateJobStateTransferModel,
      }),
    }),
    updateJobWarehouseTransferAsync: build.mutation<AsyncResponse<UpdateJobWarehouseTransferApiResponse>, UpdateJobWarehouseTransferApiArg>({
      query: (queryArg) => ({
        url: `/Transfers/UpdateJobWarehouse`,
        method: 'PUT',
        headers: { 'X-AEX-Async': 'true' },
        body: queryArg.updateJobWarehouseTransferModel,
      }),
    }),
  }),
  overrideExisting: false,
});

// types

export interface AsyncResponse<T> {
  entityId?: number;
  entityType?: string;
  rowVersion?: number | null;
  resourceUri?: string;
  correlationId?: string;
  resourcePromise: Promise<T>;
}

export type ActiveDirectoryUserModel = {
  email: string;
  name: string;
};
export type AddConversationMessageModel = {
  conversationId: number;
  body: string;
  numberOfAttachments: number;
};
export type AdminAdminControllerDistributedLockResult = {
  name: string;
  lockId?: number | null;
  remainingSeconds: number;
  acquired: boolean;
};
export const AdvancedShipmentJobStateArray = ['Open', 'Cancelled'] as const;
type AdvancedShipmentJobStateArrayType = typeof AdvancedShipmentJobStateArray;
export type AdvancedShipmentJobState = AdvancedShipmentJobStateArrayType[number];
export const AdvancedShipmentJobStatusArray = ['Open', 'InProgress', 'Fulfilled', 'Cancelled'] as const;
type AdvancedShipmentJobStatusArrayType = typeof AdvancedShipmentJobStatusArray;
export type AdvancedShipmentJobStatus = AdvancedShipmentJobStatusArrayType[number];
export type AdvancedShipmentManifestValueModel = {
  expectedMasterParts: Array<ModelsAdvancedShipmentManifestValueModelExpectedMasterPartValueModel>;
};
export type AdvancedShipmentsAdvancedShipmentJobIndexDetailsModel = {
  id: number;
  customerId: number;
  customerCode: string;
  customerName: string;
  customerGroupIds: Array<number>;
  externalReferenceId: string;
  warehouseId?: number | null;
  warehouseCode: string;
  warehouseName: string;
  warehouseGroupIds: Array<number>;
  reference?: string | null;
  waybill?: string | null;
  advancedShipmentManifest?: AdvancedShipmentManifestValueModel;
  advancedShipmentJobState: AdvancedShipmentJobState;
  advancedShipmentJobStatus: AdvancedShipmentJobStatus;
  receivedPartIds: Array<number>;
  rowVersion: number;
  modifiedUser: string;
  modifiedDate: string;
  createdDate: string;
};
export type AdvancedShipmentsCreateCommandAdvancedShipmentManifestModel = {
  expectedMasterParts: Array<AdvancedShipmentsCreateCommandAdvancedShipmentManifestModelExpectedMasterPartModel>;
};
export type AdvancedShipmentsCreateCommandAdvancedShipmentManifestModelExpectedMasterPartModel = {
  masterPartId: number;
  quantity: number;
  defective: boolean;
  attributes?: Array<AdvancedShipmentsCreateCommandAdvancedShipmentManifestModelExpectedPartAttributeModel> | null;
};
export type AdvancedShipmentsCreateCommandAdvancedShipmentManifestModelExpectedPartAttributeModel = {
  masterPartAttributeId: number;
  value?: string | null;
};
export type AllocateOutboundModel = {
  id: number;
  jobId: number;
  partTransactionRequests: Array<PartTransactionRequest>;
  rowVersion: number;
};
export type AllocateTransferModel = {
  id: number;
  partTransactionRequests: Array<PartTransactionRequest>;
  rowVersion: number;
};
export type AnnouncementModel = {
  id: number;
  title: string;
  body?: string | null;
  activationDate?: string | null;
  expirationDate?: string | null;
  dismissable: boolean;
  announcementSeverity: AnnouncementSeverity;
  roles?: Array<string> | null;
  isActive: boolean;
  createdUser: string;
  createdDate: string;
};
export const AnnouncementSeverityArray = ['Information', 'Warning', 'Critical'] as const;
type AnnouncementSeverityArrayType = typeof AnnouncementSeverityArray;
export type AnnouncementSeverity = AnnouncementSeverityArrayType[number];
export type AppRole = {
  allowedMemberTypes?: Array<string> | null;
  description?: string | null;
  displayName?: string | null;
  id?: string | null;
  isEnabled?: boolean | null;
  origin?: string | null;
  value?: string | null;
  additionalData?: object;
  oDataType?: string | null;
};
export type AssignResourceGroupsModel = {
  userId: number;
  resourceGroupsIds: Array<number>;
};
export type AsyncOperationResponseEntityUpdated = {
  $type: string;
  correlationMessageType: string;
  correlationId: string;
  messageType: string;
};
export type EntityUpdatedAsyncResponse = AsyncOperationResponseEntityUpdated & {
  entityId?: number;
  entityType?: string;
  rowVersion?: number | null;
  resourceUri?: string;
  correlationId?: string;
  $type: 'AEX.Inventory.Api.Infrastructure.Web.EntityUpdatedAsyncResponse, AEX.Inventory.Api';
};
export type AsyncOperationResponsePrintLabelResult = {
  $type: string;
  correlationMessageType: string;
  correlationId: string;
  messageType: string;
};
export type PrintLabelAsyncResponse = AsyncOperationResponsePrintLabelResult & {
  printJobId?: string;
  partIds?: Array<number>;
  printerId?: string;
  correlationId?: string;
  $type: 'AEX.Inventory.Api.Infrastructure.Web.PrintLabelAsyncResponse, AEX.Inventory.Api';
};
export const AzureEmbeddedReportViewModelEmbeddedReportTypeArray = ['Report', 'Dashboard', 'Tile', 'Visual', 'Qna'] as const;
type AzureEmbeddedReportViewModelEmbeddedReportTypeArrayType = typeof AzureEmbeddedReportViewModelEmbeddedReportTypeArray;
export type AzureEmbeddedReportViewModelEmbeddedReportType = AzureEmbeddedReportViewModelEmbeddedReportTypeArrayType[number];
export const AzureEmbeddedReportViewModelEmbeddedTokenTypeArray = ['Embed', 'Aad'] as const;
type AzureEmbeddedReportViewModelEmbeddedTokenTypeArrayType = typeof AzureEmbeddedReportViewModelEmbeddedTokenTypeArray;
export type AzureEmbeddedReportViewModelEmbeddedTokenType = AzureEmbeddedReportViewModelEmbeddedTokenTypeArrayType[number];
export type BinAffinityModel = {
  customerId?: number | null;
  selectablePartStatuses?: Array<SelectablePartStatuses> | null;
};
export type BinsBinViewModelIndexDetailsModel = {
  id: number;
  code: string;
  warehouseId: number;
  warehouseCode: string;
  warehouseName: string;
  warehouseGroupIds: Array<number>;
  zoneId?: number | null;
  zoneCode?: string | null;
  affinityCustomerId?: number | null;
  affinityCustomerCode: string;
  affinitySelectablePartStatuses?: Array<SelectablePartStatuses> | null;
  rowVersion: number;
  partCount: number;
  disabled: boolean;
};
export type BulkJustifyModel = {
  masterPartId: number;
  justifications: Array<PartsBulkJustifyCommandJustificationEntry>;
  notes?: string | null;
};
export type BulkMoveModel = {
  reference?: string | null;
  warehouseId: number;
  jobs: Array<PartsBulkMoveCommandJobEntry>;
  notes?: string | null;
};
export type BulkOutboundModel = {
  reference: string;
  jobs: Array<OutboundBulkOutboundCommandJobEntry>;
  notes?: string | null;
};
export type CarrierViewModel = {
  id: number;
  code: string;
  name: string;
  disabled: boolean;
  waybillIsAutogenerated: boolean;
};
export type CartViewModel = {
  id: number;
  code: string;
  warehouseId: number;
  warehouseCode: string;
  warehouseName: string;
  disabled: boolean;
};
export type ChangeStatusModel = {
  id: number;
  closed: boolean;
  rowVersion: number;
};
export type CompleteTransferModel = {
  jobId: number;
  destinationWarehouseId: number;
  carrierId: number;
  waybill: string;
  jobNumber: string;
  notes?: string | null;
  rowVersion: number;
};
export const ConversationContextArray = ['MIR'] as const;
type ConversationContextArrayType = typeof ConversationContextArray;
export type ConversationContext = ConversationContextArrayType[number];
export type ConversationMessageViewModel = {
  id: number;
  author: string;
  body: string;
  timestamp: string;
  attachments: Array<EntityAttachmentModel>;
};
export type ConversationViewModel = {
  id: number;
  title: string;
  conversationContext: ConversationContext;
  messages: Array<ConversationMessageViewModel>;
};
export type CreateAdvancedShipmentModel = {
  externalReferenceId: string;
  customerCode: string;
  warehouseId: number;
  advancedShipmentManifest: AdvancedShipmentsCreateCommandAdvancedShipmentManifestModel;
  reference?: string | null;
  waybill?: string | null;
};
export type CreateAnnouncementModel = {
  title: string;
  body?: string | null;
  activationDate?: string | null;
  expirationDate?: string | null;
  dismissable: boolean;
  announcementSeverity: AnnouncementSeverity;
  roles?: Array<string> | null;
};
export type CreateAppRoleModel = {
  displayName: string;
  description: string;
  value: string;
};
export type CreateBinModel = {
  code: string;
  description?: string | null;
  warehouseId: number;
  zoneId?: number | null;
  affinity: BinAffinityModel;
  disabled: boolean;
};
export type CreateCarrierModel = {
  code: string;
  name: string;
  disabled: boolean;
  waybillIsAutogenerated: boolean;
};
export type CreateCartModel = {
  warehouseId: number;
  numberToCreate: number;
};
export type CreateCustomerModel = {
  code: string;
  name: string;
  referenceLabel: string;
  referenceRequired: boolean;
  disabled: boolean;
  warehouseResourceGroupId?: number | null;
};
export type CreateCycleCountModel = {
  partIds: Array<number>;
  cycleCountJobId?: number | null;
};
export type CreateDataSubscriptionModel = {
  name: string;
  disabled: boolean;
  description?: string | null;
  dataSubscriptionDataSource: DataSubscriptionDataSource;
  dataSubscriptionConfiguration: TemplatedWebHookConfiguration;
  cronExpression?: string | null;
  resourceGroupId: number;
};
export type CreateDeliveryReceiptModel = {
  signatureImage: ImageRequest;
  dockReceiptIds: Array<number>;
  deliveryRecipientId?: number | null;
  deliveryRecipientName?: string | null;
  deliveryRecipientEmail?: string | null;
  deliveryRecipientType: DeliveryRecipientType;
  receivedByName: string;
  receivedByEmail: string;
};
export type CreateDeliveryRecipientModel = {
  name: string;
  emailAddress: string;
};
export type CreateDockReceiptModel = {
  waybillNumber?: string | null;
  carrierId: number;
  warehouseId: number;
  technicianName?: string | null;
  otherCarrierName?: string | null;
  waybillNumberMissing: boolean;
};
export type CreateEntityAttachmentModel = {
  entityId: number;
  entityType: string;
  attachments: Array<EntityAttachmentsCreateCommandAttachment>;
};
export type CreateExternalApplicationModel = {
  displayName?: string | null;
  username?: string | null;
  tenantId: string;
  applicationId: string;
  roles: Array<string>;
  externalApplicationActivationStatus: ExternalApplicationActivationStatus;
};
export type CreateInboundModel = {
  customerId: number;
  dockReceiptId: number;
  customerReference?: string | null;
  jobNumber: string;
  isReturn: boolean;
  advancedShipmentJobId?: number | null;
  printerId?: string | null;
  inboundPartRequests: Array<InboundCreateCommandInboundPartRequest>;
};
export type CreateJobModel = {
  dataSubscriptionId: number;
  entityId: number;
};
export type CreateLegacyTransferModel = {
  sourcePartLocationIds: Array<number>;
  destinationWarehouseId: number;
  carrierId: number;
  waybill?: string | null;
  jobNumber: string;
  notes?: string | null;
};
export type CreateMasterPartAttributeCategoryModel = {
  name: string;
  dataType: MasterPartAttributeDataType;
  displayNameTemplate: string;
  requiredTemplate: boolean;
  gS1BarcodeApplicationIdentifier?: string | null;
};
export type CreateMasterPartModel = {
  customerId: number;
  code: string;
  masterPartTemplateId?: number | null;
  description?: string | null;
  isBulk?: boolean | null;
  reviewStatus: MasterPartReviewStatus;
  aliases?: Array<string> | null;
  masterPartDimensions?: MasterPartDimensions;
  metadata?: string | null;
  disabled: boolean;
};
export type CreateMasterPartTemplateModel = {
  customerId?: number | null;
  name: string;
  description?: string | null;
  masterPartTemplateType: MasterPartTemplateType;
  masterPartAttributeTemplateMappings: Array<MasterPartTemplatesCreateCommandMapping>;
};
export type CreateNotificationClientModel = {
  name: string;
  resourceGroupId: number;
};
export type CreateNotificationMessageTemplateModel = {
  name: string;
  subjectTemplate?: string | null;
  bodyTemplate: string;
  notificationMessagePayloadType: NotificationMessagePayloadType;
  notificationEvent: NotificationEvent;
};
export type CreateOutboundModel = {
  externalReferenceId: string;
  customerCode: string;
  warehouseId: number;
  partTransactionRequests: Array<PartTransactionRequest>;
  createAsOpen: boolean;
  transactionMetadata: Array<OutboundCreateCommandTransactionMetadataEntry>;
  customerReference?: string | null;
  serviceType: string;
  waybillRequired: boolean;
  headerMetadata: object;
  shipByDate: string;
};
export type CreateResourceGroupModel = {
  name: string;
  description?: string | null;
  resourceGroupEntityType: ResourceGroupEntityType;
  entityIds: Array<number>;
};
export type CreateStockLevelModel = {
  warehouseId: number;
  masterPartId: number;
  desiredCount: number;
};
export type CreateTransferModel = {
  externalReferenceId: string;
  customerCode: string;
  warehouseId: number;
  carrierId?: number | null;
  destinationWarehouseId: number;
  partTransactionRequests: Array<PartTransactionRequest>;
  createAsOpen: boolean;
  transactionMetadata: Array<TransfersCreateCommandTransactionMetadataEntry>;
  outReference?: string | null;
  inReference?: string | null;
  serviceType: string;
  shipByDate: string;
};
export type CreateWarehouseModel = {
  code: string;
  name: string;
  warehouseOptions: Array<WarehouseOptions>;
  addresses: Array<WarehousesCreateCommandAddressModel>;
  disabled: boolean;
};
export type CreateZoneModel = {
  warehouseId: number;
  code: string;
  name: string;
};
export type CustomerDetailsModel = {
  id: number;
  code: string;
  name: string;
  referenceLabel: string;
  referenceRequired: boolean;
  rowVersion: number;
  notificationClientId: number;
  disabled: boolean;
  warehouseResourceGroupId?: number | null;
};
export type CycleCountJobDetailsModel = {
  id: number;
  warehouseId: number;
  warehouseCode: string;
  cycleCountPartSnapshots: Array<CycleCountPartSnapshotEntryModel>;
  cycleCountResult?: CycleCountResultModel;
  group?: string | null;
  createdDate: string;
  completedDate?: string | null;
  createdUser: string;
  completedByUser?: string | null;
  cycleCountJobStatus: CycleCountJobStatus;
};
export const CycleCountJobStatusArray = ['Open', 'ReadyForReview', 'Discrepancy', 'Complete'] as const;
type CycleCountJobStatusArrayType = typeof CycleCountJobStatusArray;
export type CycleCountJobStatus = CycleCountJobStatusArrayType[number];
export type CycleCountPartSnapshotEntryModel = {
  partId: number;
  masterPartId: number;
  masterPartCode: string;
  binId: number;
  binCode: string;
  attributes?: object;
};
export type CycleCountResultEntryModel = {
  partId: number;
  masterPartId: number;
  masterPartCode: string;
  expectedBinId?: number | null;
  expectedBinCode?: string | null;
  scannedBinId?: number | null;
  scannedBinCode?: string | null;
  attributes?: object;
  cycleCountResultPartStatus: CycleCountResultPartStatus;
};
export type CycleCountResultModel = {
  cycleCountResultEntries: Array<CycleCountResultEntryModel>;
};
export const CycleCountResultPartStatusArray = ['Good', 'Missing', 'IncorrectLocation'] as const;
type CycleCountResultPartStatusArrayType = typeof CycleCountResultPartStatusArray;
export type CycleCountResultPartStatus = CycleCountResultPartStatusArrayType[number];
export type CycleCountsCreateResponse = {
  jobs: Array<CycleCountJobDetailsModel>;
};
export type CycleCountsUploadCountCommandCycleCountPartEntry = {
  partId: number;
  binId: number;
};
export type DataSubscriptionConfiguration = {
  $type: string;
};
export type TemplatedWebHookConfiguration = DataSubscriptionConfiguration & {
  url?: string;
  headersJson?: string | null;
  globalDataJson?: string | null;
  handlebarsTemplate?: string;
  $type: 'AEX.Inventory.DataSubscriptions.Abstractions.Configurations.TemplatedWebHookConfiguration, AEX.Inventory.DataSubscriptions.Abstractions';
};
export const DataSubscriptionDataSourceArray = [
  'PartTransactionBatch',
  'OutboundJob',
  'CurrentInventory',
  'InventoryAdjustment',
  'MasterPartTemplate',
  'TransferJob',
  'MasterPart',
] as const;
type DataSubscriptionDataSourceArrayType = typeof DataSubscriptionDataSourceArray;
export type DataSubscriptionDataSource = DataSubscriptionDataSourceArrayType[number];
export type DataSubscriptionModel = {
  id: number;
  name: string;
  disabled: boolean;
  description?: string | null;
  dataSubscriptionDataSource: DataSubscriptionDataSource;
  dataSubscriptionTrigger: DataSubscriptionTrigger;
  dataSubscriptionConfiguration: TemplatedWebHookConfiguration;
  cronExpression?: string | null;
  resourceGroupId: number;
  rowVersion: number;
};
export const DataSubscriptionTriggerArray = ['Entity', 'Schedule', 'Index'] as const;
type DataSubscriptionTriggerArrayType = typeof DataSubscriptionTriggerArray;
export type DataSubscriptionTrigger = DataSubscriptionTriggerArrayType[number];
export type DataSubscriptionsGetDataSubscriptionDataSourcesModel = {
  dataSources: Array<DataSubscriptionDataSource>;
};
export type DataSubscriptionsGetDataSubscriptionObjectTemplateQuery = {
  dataSubscriptionDataSource: DataSubscriptionDataSource;
};
export type DataSubscriptionsGetDataSubscriptionTriggerForDataSourceQuery = {
  dataSubscriptionDataSource: DataSubscriptionDataSource;
};
export type DataSubscriptionsGetQuery = {
  id: number;
};
export type DefectiveModel = {
  partTransactionRequests: Array<PartTransactionRequest>;
  defective: boolean;
  notes?: string | null;
};
export type DeleteAnnouncementModel = {
  id: number;
};
export type DeleteAppRoleModel = {
  value: string;
};
export type DeleteCycleCountModel = {
  id: number;
};
export type DeleteDataSubscriptionModel = {
  id: number;
};
export type DeleteDockReceiptModel = {
  id: number;
};
export type DeleteEntityAttachmentModel = {
  entityAttachmentIds: Array<number>;
};
export type DeleteNotificationChannelModel = {
  id: number;
};
export type DeleteNotificationClientModel = {
  id: number;
};
export type DeleteNotificationMessageTemplateModel = {
  id: number;
};
export type DeleteResourceGroupModel = {
  id: number;
};
export type DeleteStockLevelModel = {
  id: number;
};
export type DeleteStorageContextModel = {
  context: string;
};
export type DeliveryReceiptViewModel = {
  id: number;
  recipientName: string;
  recipientEmail: string;
  waybills: Array<string>;
  deliveryDate: string;
  deliveryRecipientType: DeliveryRecipientType;
  signatureImageUrl: string;
  deliveredBy: string;
  receivedByName: string;
  receivedByEmail: string;
};
export const DeliveryRecipientTypeArray = ['ExternalCustomer', 'ActiveDirectoryUser', 'Warehouse'] as const;
type DeliveryRecipientTypeArrayType = typeof DeliveryRecipientTypeArray;
export type DeliveryRecipientType = DeliveryRecipientTypeArrayType[number];
export type DeliveryRecipientViewModel = {
  id: number;
  name: string;
  emailAddress: string;
  deliveryRecipientType: DeliveryRecipientType;
  modifiedDate: string;
  username: string;
};
export const DockReceiptDeliveryTypeArray = ['Undelivered', 'Inbound', 'Internal', 'External'] as const;
type DockReceiptDeliveryTypeArrayType = typeof DockReceiptDeliveryTypeArray;
export type DockReceiptDeliveryType = DockReceiptDeliveryTypeArrayType[number];
export type DockReceiptViewModel = {
  id: number;
  waybillNumber: string;
  carrierId: number;
  warehouseId: number;
  carrierCode: string;
  warehouseCode: string;
  warehouseName: string;
  warehouseGroupIds: Array<number>;
  warehouseOptions: Array<WarehouseOptions>;
  otherCarrierName?: string | null;
  technicianName?: string | null;
  closed: boolean;
  dockReceiptDeliveryType: DockReceiptDeliveryType;
  createdDate: string;
  modifiedDate: string;
  deliveryDate?: string | null;
  deliveryRecipientName?: string | null;
  receivedByEmail?: string | null;
  username: string;
  rowVersion: number;
  deliveryReceiptId?: number | null;
};
export type DockReceiptsHistoryQuery = {
  id: number;
};
export type EmbedToken = {
  token?: string | null;
  tokenId: string;
  expiration: string;
};
export type EmbeddedReportViewModel = {
  id: string;
  name: string;
  embedUrl: string;
  embedToken: EmbedToken;
  reportType: AzureEmbeddedReportViewModelEmbeddedReportType;
  tokenType: AzureEmbeddedReportViewModelEmbeddedTokenType;
};
export type EntityAttachmentModel = {
  id: number;
  url: string;
  entityAttachmentType: EntityAttachmentType;
};
export const EntityAttachmentTypeArray = ['Image'] as const;
type EntityAttachmentTypeArrayType = typeof EntityAttachmentTypeArray;
export type EntityAttachmentType = EntityAttachmentTypeArrayType[number];
export type EntityAttachmentsCreateCommandAttachment = {
  base64Data: string;
  entityAttachmentType: EntityAttachmentType;
  contentType: string;
};
export const ExternalApplicationActivationStatusArray = ['Pending', 'Active', 'Suspended'] as const;
type ExternalApplicationActivationStatusArrayType = typeof ExternalApplicationActivationStatusArray;
export type ExternalApplicationActivationStatus = ExternalApplicationActivationStatusArrayType[number];
export type ExternalApplicationModel = {
  id: number;
  displayName?: string | null;
  username?: string | null;
  tenantId: string;
  applicationId: string;
  modifiedUser: string;
  modifiedDate: string;
  externalApplicationActivationStatus: ExternalApplicationActivationStatus;
  roles: Array<string>;
  rowVersion: number;
};
export type FoundModel = {
  partTransactionRequests: Array<PartTransactionRequest>;
  notes: string;
};
export type GS1ApplicationIdentifier = {
  name: string;
  code: string;
  format: string;
};
export type GenerateReportModel = {
  tables: Array<ReportsGenerateReportCommandTable>;
  email?: string | null;
  download: boolean;
  reportName: string;
  fileName?: string | null;
};
export type HoldModel = {
  holdId?: string | null;
  partTransactionRequests: Array<PartTransactionRequest>;
  notes?: string | null;
  timeout?: string | null;
  transactionMetadata: Array<PartsHoldCommandTransactionMetadataEntry>;
};
export type HttpValidationProblemDetails = ProblemDetails & {
  errors?: object;
  $type: 'Microsoft.AspNetCore.Http.HttpValidationProblemDetails, Microsoft.AspNetCore.Http.Abstractions';
};
export type IApplicationEnvironmentConfiguration = {
  environment: string;
  serverVersion: string;
  serverVersionHash: string;
  serverVersionFull: string;
  latestClientVersion: string;
};
export type ImageRequest = {
  base64Data: string;
  contentType: string;
};
export type InboundCreateCommandInboundPartRequest = {
  masterPartId?: number | null;
  quantity: number;
  workflow: InboundCreateCommandInboundPartWorkflow;
  defective: boolean;
  notes?: string | null;
  inboundPartImageRequests: Array<ImageRequest>;
  partAttributeValues?: Array<InboundCreateCommandPartAttributeModel> | null;
};
export const InboundCreateCommandInboundPartWorkflowArray = [
  'Standard',
  'Unidentifiable',
  'Damaged',
  'InformationMissing',
  'MasterInReview',
  'Quarantined',
] as const;
type InboundCreateCommandInboundPartWorkflowArrayType = typeof InboundCreateCommandInboundPartWorkflowArray;
export type InboundCreateCommandInboundPartWorkflow = InboundCreateCommandInboundPartWorkflowArrayType[number];
export type InboundCreateCommandPartAttributeModel = {
  masterPartAttributeTemplateMappingId: number;
  value: string;
};
export type InboundLoadTransactionInformationModel = {
  partId: number;
  dockReceiptId: number;
  customerReference: string;
  jobNumber: string;
};
export type InventoryReportPartsByWarehouseModel = {
  masterParts?: Array<InventoryReportPartsByWarehouseModelMasterPartEntry> | null;
  customerCode: string;
};
export type InventoryReportPartsByWarehouseModelMasterPartEntry = {
  masterPartCode: string;
  warehouses: Array<InventoryReportPartsByWarehouseModelWarehouseEntry>;
};
export type InventoryReportPartsByWarehouseModelWarehouseEntry = {
  warehouseId: number;
  warehouseCode: string;
  count: number;
  latitude: string;
  longitude: string;
};
export const JobStateArray = ['Draft', 'Open', 'Paused', 'Cancelled', 'Fulfilled', 'Shipped'] as const;
type JobStateArrayType = typeof JobStateArray;
export type JobState = JobStateArrayType[number];
export const JobStatusArray = [
  'Draft',
  'PickReady',
  'PickInProgress',
  'ReadyToShip',
  'Cancelled',
  'Fulfilled',
  'Paused',
  'ShortPick',
  'InTransit',
  'ShortReceived',
  'Incomplete',
] as const;
type JobStatusArrayType = typeof JobStatusArray;
export type JobStatus = JobStatusArrayType[number];
export type LegacyReadTransactionsModel = {
  partId: number;
  transactions: Array<LegacyReadTransactionsModelTransactionModel>;
};
export type LegacyReadTransactionsModelTransactionModel = {
  id: number;
  lpn: number;
  product: string;
  quantity: number;
  warehouseCode: string;
  customerCode: string;
  serialNumber: string;
  mainReference: string;
  customerReference: string;
  jobNumber: string;
  bin: string;
  notes: string;
  transactionType: TransactionType;
  timestamp: string;
};
export type LegacyCheckModel = {
  masterPartCode: string;
  customerId: number;
};
export const LogLevelArray = ['Trace', 'Debug', 'Information', 'Warning', 'Error', 'Critical', 'None'] as const;
type LogLevelArrayType = typeof LogLevelArray;
export type LogLevel = LogLevelArrayType[number];
export type LostModel = {
  partTransactionRequests: Array<PartTransactionRequest>;
  notes: string;
};
export type MIRPartMIRContextsControllerModel = {
  partId: number;
  isActive: boolean;
  conversationId?: number | null;
  resolution: MIRResolution;
  notes?: string | null;
};
export const MIRResolutionArray = ['Unresolved', 'Inbound', 'Scrap', 'PrintLabel', 'Other'] as const;
type MIRResolutionArrayType = typeof MIRResolutionArray;
export type MIRResolution = MIRResolutionArrayType[number];
export type MasterPartAttributeCategoryModel = {
  id: number;
  name: string;
  dataType: MasterPartAttributeDataType;
  displayNameTemplate: string;
  requiredTemplate: boolean;
  gS1BarcodeApplicationIdentifier?: string | null;
};
export const MasterPartAttributeDataTypeArray = ['Text', 'Number', 'Date', 'DateTime', 'List'] as const;
type MasterPartAttributeDataTypeArrayType = typeof MasterPartAttributeDataTypeArray;
export type MasterPartAttributeDataType = MasterPartAttributeDataTypeArrayType[number];
export type MasterPartAttributeTemplateMappingModel = {
  id: number;
  masterPartAttributeCategoryId: number;
  masterPartAttributeCategoryName: string;
  displayName: string;
  dataType: MasterPartAttributeDataType;
  order: number;
  required: boolean;
  validationRegex?: string | null;
  validationExample?: string | null;
  defaultValue?: string | null;
  masterPartAttributeUniquenessScope: MasterPartAttributeUniquenessScope;
  listEntries?: Array<string> | null;
  gS1BarcodeApplicationIdentifier?: string | null;
};
export const MasterPartAttributeUniquenessScopeArray = ['None', 'PartAll', 'PartInStock', 'CustomerAll', 'CustomerInStock'] as const;
type MasterPartAttributeUniquenessScopeArrayType = typeof MasterPartAttributeUniquenessScopeArray;
export type MasterPartAttributeUniquenessScope = MasterPartAttributeUniquenessScopeArrayType[number];
export type MasterPartDetailsModel = {
  id: number;
  customerId: number;
  customerCode: string;
  customerName: string;
  code: string;
  description?: string | null;
  isUnique: boolean;
  isBulk: boolean;
  masterPartTemplateId?: number | null;
  masterPartTemplate?: MasterPartTemplateDetailsModel;
  reviewStatus: MasterPartReviewStatus;
  createdDate: string;
  modifiedDate: string;
  modifiedUser: string;
  disabled: boolean;
  aliases?: Array<string> | null;
  masterPartDimensions?: MasterPartDimensions;
  metadata?: string | null;
  attachments: Array<EntityAttachmentModel>;
  rowVersion: number;
};
export type MasterPartDimensions = {
  weight?: number | null;
  width?: number | null;
  height?: number | null;
  length?: number | null;
};
export type MasterPartListModel = {
  id: number;
  customerId: number;
  customerCode: string;
  customerName: string;
  masterPartTemplateId?: number | null;
  masterPartTemplateName?: string | null;
  code: string;
  description?: string | null;
  isUnique: boolean;
  isBulk: boolean;
  reviewStatus: MasterPartReviewStatus;
  createdDate: string;
  modifiedDate: string;
  modifiedUser: string;
  disabled: boolean;
  aliases?: Array<string> | null;
};
export const MasterPartReviewStatusArray = ['InReview', 'Accepted', 'Escalated', 'Rejected'] as const;
type MasterPartReviewStatusArrayType = typeof MasterPartReviewStatusArray;
export type MasterPartReviewStatus = MasterPartReviewStatusArrayType[number];
export type MasterPartTemplateDetailsModel = {
  id: number;
  name: string;
  customerId?: number | null;
  customerCode?: string | null;
  customerName?: string | null;
  description?: string | null;
  masterPartTemplateType: MasterPartTemplateType;
  masterPartAttributeTemplateMappings: Array<MasterPartAttributeTemplateMappingModel>;
  rowVersion: number;
};
export const MasterPartTemplateTypeArray = ['Standard', 'MIR'] as const;
type MasterPartTemplateTypeArrayType = typeof MasterPartTemplateTypeArray;
export type MasterPartTemplateType = MasterPartTemplateTypeArrayType[number];
export type MasterPartTemplatesCreateCommandMapping = {
  masterPartAttributeCategoryId: number;
  displayName: string;
  order: number;
  required: boolean;
  validationRegex?: string | null;
  validationExample?: string | null;
  defaultValue?: string | null;
  masterPartAttributeUniquenessScope: MasterPartAttributeUniquenessScope;
  listEntries?: Array<string> | null;
  gS1BarcodeApplicationIdentifier?: string | null;
};
export type MasterPartTemplatesUpdateCommandMapping = {
  id: number;
  displayName: string;
  order: number;
  validationRegex?: string | null;
  validationExample?: string | null;
  defaultValue?: string | null;
  masterPartAttributeUniquenessScope: MasterPartAttributeUniquenessScope;
  listEntries?: Array<string> | null;
  gS1BarcodeApplicationIdentifier?: string | null;
};
export type MasterPartsHistoryQuery = {
  id: number;
};
export type MasterPartsLegacyCheckModel = {
  id: number;
  customerId: number;
  customerCode: string;
  customerName: string;
  masterPartTemplateId?: number | null;
  code: string;
  description?: string | null;
  isUnique: boolean;
  isBulk: boolean;
  reviewStatus: MasterPartReviewStatus;
  rowVersion: number;
};
export type MasterPartsValidatePartAttributesCommandPartAttributeModel = {
  masterPartAttributeTemplateMappingId: number;
  value?: string | null;
  skipValidation: boolean;
};
export type MaterializedPartViewModel = {
  id: number;
  partLocationId: number;
  customerId: number;
  customerCode: string;
  masterPartId?: number | null;
  masterPartCode?: string | null;
  warehouseId?: number | null;
  warehouseCode?: string | null;
  warehouseName?: string | null;
  locationType?: string | null;
  locationCode?: string | null;
  locationId?: number | null;
  partStatuses: Array<PartStatuses>;
  partAttributes?: Array<PartAttributeViewModel> | null;
  quantity: number;
  lastActivity: string;
  dateReceived: string;
  transactionRowVersion: number;
  latitude?: number | null;
  longitude?: number | null;
  customerGroupIds: Array<number>;
  warehouseGroupIds: Array<number>;
};
export type MergeModel = {
  sourceMasterPartId: number;
  destinationMasterPartId: number;
};
export type ModelsAdvancedShipmentManifestValueModelExpectedMasterPartValueModel = {
  masterPartId: number;
  masterPartCode: string;
  quantity: number;
  defective: boolean;
  attributes: Array<ModelsAdvancedShipmentManifestValueModelExpectedPartAttributeValueModel>;
  receivedPartCount: number;
};
export type ModelsAdvancedShipmentManifestValueModelExpectedPartAttributeValueModel = {
  masterPartAttributeId: number;
  name: string;
  value: string;
};
export type MoveModel = {
  partMoveRequests: Array<PartsMoveCommandPartMoveRequest>;
};
export type MoveToCartModel = {
  cartId: number;
  inboundBatchId: number;
};
export type NotificationChannelListModel = {
  id: number;
  name: string;
  disabled: boolean;
  numberOfEvents: number;
  notificationMessagePayloadType: NotificationMessagePayloadType;
};
export type NotificationChannelModel = {
  $type: string;
  id: number;
  notificationClientId: number;
  name: string;
  disabled: boolean;
  notificationMessagePayloadType: NotificationMessagePayloadType;
  mappings: Array<NotificationChannelsNotificationChannelModelMappingModel>;
};
export type NotificationChannelsNotificationChannelModelEmailChannelModel = NotificationChannelModel & {
  emailAddresses?: string;
  $type: 'AEX.Inventory.Api.Features.NotificationChannels.NotificationChannelModel+EmailChannelModel, AEX.Inventory.Api';
};
export type NotificationChannelsNotificationChannelModelWebHookChannelModel = NotificationChannelModel & {
  webHookUrl?: string;
  $type: 'AEX.Inventory.Api.Features.NotificationChannels.NotificationChannelModel+WebHookChannelModel, AEX.Inventory.Api';
};
export type NotificationChannelsCreateCommandBase = {
  $type: string;
  notificationClientId: number;
  name: string;
  disabled?: boolean | null;
  notificationMessagePayloadType?: NotificationMessagePayloadType;
};
export type NotificationChannelsCreateEmailCommand = NotificationChannelsCreateCommandBase & {
  emailAddresses?: string;
  $type: 'AEX.Inventory.Api.Features.NotificationChannels.Create+EmailCommand, AEX.Inventory.Api';
};
export type NotificationChannelsCreateWebHookCommand = NotificationChannelsCreateCommandBase & {
  webHookUrl?: string;
  $type: 'AEX.Inventory.Api.Features.NotificationChannels.Create+WebHookCommand, AEX.Inventory.Api';
};
export type NotificationChannelsNotificationChannelModelMappingModel = {
  notificationEvent: NotificationEvent;
  notificationMessageTemplateId: number;
};
export type NotificationChannelsUpdateChannelMappingsCommandChannelMappingEntry = {
  notificationEvent: NotificationEvent;
  notificationMessageTemplateId: number;
};
export type NotificationChannelsUpdateCommandBase = {
  $type: string;
  id: number;
  name: string;
  disabled?: boolean | null;
};
export type NotificationChannelsUpdateEmailCommand = NotificationChannelsUpdateCommandBase & {
  emailAddresses?: string;
  $type: 'AEX.Inventory.Api.Features.NotificationChannels.Update+EmailCommand, AEX.Inventory.Api';
};
export type NotificationChannelsUpdateWebHookCommand = NotificationChannelsUpdateCommandBase & {
  webHookUrl?: string;
  $type: 'AEX.Inventory.Api.Features.NotificationChannels.Update+WebHookCommand, AEX.Inventory.Api';
};
export type NotificationClientModel = {
  id: number;
  name: string;
  notificationClientType: NotificationClientType;
  resourceGroupId: number;
  resourceGroupName: string;
  numberOfChannels: number;
  canDelete: boolean;
};
export const NotificationClientTypeArray = ['Customer', 'Admin'] as const;
type NotificationClientTypeArrayType = typeof NotificationClientTypeArray;
export type NotificationClientType = NotificationClientTypeArrayType[number];
export const NotificationEventArray = [
  'MIRPartReceived',
  'MasterPartInReviewCreated',
  'MasterPartInReviewAccepted',
  'MasterPartInReviewEscalated',
  'MIRConversationUpdated',
  'PartNotFound',
  'InternalDelivery',
] as const;
type NotificationEventArrayType = typeof NotificationEventArray;
export type NotificationEvent = NotificationEventArrayType[number];
export const NotificationMessagePayloadTypeArray = ['Email', 'Slack', 'Teams'] as const;
type NotificationMessagePayloadTypeArrayType = typeof NotificationMessagePayloadTypeArray;
export type NotificationMessagePayloadType = NotificationMessagePayloadTypeArrayType[number];
export type NotificationMessageTemplateModel = {
  id: number;
  name: string;
  subjectTemplate?: string | null;
  bodyTemplate: string;
  notificationMessagePayloadType: NotificationMessagePayloadType;
  notificationEvent: NotificationEvent;
};
export type NotificationMessageTemplatesGetNotificationMessageTemplateObjectTemplateQuery = {
  notificationEvent: NotificationEvent;
};
export type OutboundBulkOutboundCommandJobEntry = {
  jobNumber: string;
  partIds: Array<number>;
};
export type OutboundBulkOutboundModel = {
  success: boolean;
  problems: Array<OutboundBulkOutboundModelProblem>;
};
export type OutboundBulkOutboundModelProblem = {
  partId: number;
  description: string;
};
export type OutboundCreateCommandTransactionMetadataEntry = {
  key: string;
  value: string;
};
export type OutboundRenderTemplateModel = {
  outboundJobId: number;
  headerTemplate: string;
  headerTemplateData: any;
};
export type OutboundJobDetailsModel = {
  id: number;
  customerId: number;
  customerCode: string;
  customerName: string;
  customerGroupIds: Array<number>;
  externalReferenceId: string;
  warehouseId?: number | null;
  warehouseCode: string;
  warehouseName: string;
  warehouseGroupIds: Array<number>;
  customerReference: string;
  serviceType: string;
  waybillRequired: boolean;
  resolutionDate: string;
  outboundJobShippingInformation?: OutboundJobShippingInformation;
  shipByDate: string;
  partIds: Array<number>;
  jobState: JobState;
  jobStatus: JobStatus;
  lastPartTransactionBatchId?: number | null;
  rowVersion: number;
  modifiedUser: string;
  modifiedDate: string;
  createdDate: string;
};
export type OutboundJobShippingInformation = {
  waybills: Array<string>;
  packageQuantity: number;
  notes?: string | null;
  packageType: OutboundJobShippingPackageType;
  rowVersion: number;
};
export const OutboundJobShippingPackageTypeArray = [
  'Barrel',
  'Carton',
  'Envelope',
  'Crate',
  'Pallet',
  'Cylinders',
  'EmptyDrum',
  'EmptyPallet',
  'Other',
  'Rolls',
  'Stinger',
] as const;
type OutboundJobShippingPackageTypeArrayType = typeof OutboundJobShippingPackageTypeArray;
export type OutboundJobShippingPackageType = OutboundJobShippingPackageTypeArrayType[number];
export const PartAttributeValidationFailureReasonArray = ['RequiredFieldMissing', 'NotUnique', 'InvalidFormat'] as const;
type PartAttributeValidationFailureReasonArrayType = typeof PartAttributeValidationFailureReasonArray;
export type PartAttributeValidationFailureReason = PartAttributeValidationFailureReasonArrayType[number];
export type PartAttributeValidationResult = {
  masterPartAttributeTemplateMappingId: number;
  masterPartTemplateId: number;
  masterPartAttributeCategoryId: number;
  displayName: string;
  value?: string | null;
  reason?: PartAttributeValidationFailureReason;
  isRequired: boolean;
  succeeded: boolean;
  skipped: boolean;
};
export type PartAttributeViewModel = {
  masterPartAttributeCategoryId: number;
  masterPartAttributeTemplateMappingId: number;
  value: string;
  displayName: string;
};
export type PartLocationChangeRequest = {
  partLocationId: number;
  rowVersion: number;
};
export const PartStatusesArray = [
  'Good',
  'Defective',
  'Damaged',
  'InformationMissing',
  'Unidentifiable',
  'Allocated',
  'MasterInReview',
  'Resolved',
  'Received',
  'NotFound',
  'InTransit',
  'OnHold',
  'Quarantined',
] as const;
type PartStatusesArrayType = typeof PartStatusesArray;
export type PartStatuses = PartStatusesArrayType[number];
export type PartTransactionBatchesPatchMetadataCommandMetadataModel = {
  name: string;
  value?: string | null;
};
export type PartTransactionRequest = {
  partId: number;
  rowVersion: number;
};
export type PartsBulkJustifyCommandAttributeEntry = {
  masterPartAttributeTemplateMappingId: number;
  value: string;
};
export type PartsBulkJustifyCommandJustificationEntry = {
  partId: number;
  attributes: Array<PartsBulkJustifyCommandAttributeEntry>;
};
export type PartsBulkJustifyModel = {
  success: boolean;
  problems: Array<PartsBulkJustifyModelProblem>;
};
export type PartsBulkJustifyModelProblem = {
  partId: number;
  description: string;
};
export type PartsBulkMoveCommandJobEntry = {
  jobNumber?: string | null;
  partEntries: Array<PartsBulkMoveCommandPartEntry>;
};
export type PartsBulkMoveCommandPartEntry = {
  partId: number;
  destinationBin: string;
};
export type PartsBulkMoveModel = {
  success: boolean;
  problems: Array<PartsBulkMoveModelProblem>;
};
export type PartsBulkMoveModelProblem = {
  partId: number;
  description: string;
};
export type PartsHoldCommandTransactionMetadataEntry = {
  key: string;
  value: string;
};
export const PartsMoveCommandLocationTypeArray = ['Dock', 'Cart', 'Bin'] as const;
type PartsMoveCommandLocationTypeArrayType = typeof PartsMoveCommandLocationTypeArray;
export type PartsMoveCommandLocationType = PartsMoveCommandLocationTypeArrayType[number];
export type PartsMoveCommandPartMoveRequest = {
  fromLocationId: number;
  destinationLocationType: PartsMoveCommandLocationType;
  destinationLocationId: number;
  rowVersion: number;
};
export type PartsOnHoldByIdModel = {
  partIds: Array<number>;
};
export type PartsOnHoldByIdQuery = {
  holdId: string;
};
export const PartsPutAwayCommandLocationTypeArray = ['Dock', 'Cart', 'Bin'] as const;
type PartsPutAwayCommandLocationTypeArrayType = typeof PartsPutAwayCommandLocationTypeArray;
export type PartsPutAwayCommandLocationType = PartsPutAwayCommandLocationTypeArrayType[number];
export type PartsPutAwayCommandPutAwayRequest = {
  jobId: number;
  fromLocationId: number;
  destinationLocationType: PartsPutAwayCommandLocationType;
  destinationLocationId: number;
  rowVersion: number;
};
export type PartsReleaseCommandTransactionMetadataEntry = {
  key: string;
  value: string;
};
export type PartsUpdateCommandPartAttributeValueModel = {
  masterPartAttributeTemplateMappingId: number;
  value: string;
};
export type PartsByWarehouseModel = {
  customerCode: string;
  masterPartCodes: string;
  partStatuses?: Array<PartStatuses> | null;
  partStatusesExclusive?: boolean | null;
};
export type PatchMetadataModel = {
  partTransactionBatchId: number;
  metadata: Array<PartTransactionBatchesPatchMetadataCommandMetadataModel>;
};
export type PerformRequestModel = {
  url: string;
  payload?: string | null;
  contentType?: string | null;
};
export type PermissionsSyncADUsersModel = {
  groupName: string;
  directoryUserCount: number;
  inventoryUserCount: number;
  addedUsers: Array<string>;
  deletedUsers: Array<string>;
  renamedUsers: object;
  dryRun: boolean;
};
export type PermissionsUpdateRolePermissionsCommandRoleEntry = {
  role: string;
  permissions: Array<string>;
};
export type PrintLabelModel = {
  partIds: Array<number>;
  printerId: string;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  extensions: object;
  $type: 'Microsoft.AspNetCore.Mvc.ProblemDetails, Microsoft.AspNetCore.Http.Abstractions';
};
export type ProxyPerformRequestModel = {
  body?: string | null;
  statusCode: number;
};
export type PutAwayPutAwayJobIndexModel = {
  id: number;
  partId: number;
  jobId: number;
  partLocationId: number;
  customerId: number;
  customerCode: string;
  customerGroupIds: Array<number>;
  masterPartId?: number | null;
  masterPartCode: string;
  isUnique: boolean;
  warehouseId?: number | null;
  warehouseCode: string;
  warehouseName: string;
  warehouseCartsEnabled: boolean;
  warehouseGroupIds: Array<number>;
  locationType?: string | null;
  locationCode?: string | null;
  locationId?: number | null;
  partStatuses: Array<PartStatuses>;
  suggestedBinId?: number | null;
  suggestedBinCode?: string | null;
  suggestedBins?: Array<PutAwayPutAwayJobIndexSuggestedBin> | null;
  complete: boolean;
  createdDate: string;
  lastPartTransactionBatchId?: number | null;
  rowVersion: number;
  transactionRowVersion: number;
};
export type PutAwayPutAwayJobIndexSuggestedBin = {
  id: number;
  code: string;
  reason: string;
  partCount: number;
  priority: number;
};
export type PutAwayModel = {
  putAwayRequests: Array<PartsPutAwayCommandPutAwayRequest>;
};
export type QuarantineModel = {
  partTransactionRequests: Array<PartTransactionRequest>;
  notes: string;
};
export type ReadCacheEntityResponse = {
  entityId: number;
  entityType: string;
  rowVersion?: number | null;
};
export type ReinboundModel = {
  partId: number;
  printerId?: string | null;
};
export type ReleaseModel = {
  partIds: Array<number>;
  transactionMetadata: Array<PartsReleaseCommandTransactionMetadataEntry>;
};
export type RemoveDeliveryModel = {
  id: number;
  rowVersion: number;
};
export type RenderHandlebarsTemplateModel = {
  handlebarsTemplate: string;
  dataObjectJson: string;
};
export type ReportsGenerateReportCommandColumnEntry = {
  name: string;
  alias?: string | null;
};
export type ReportsGenerateReportCommandTable = {
  name: string;
  oDataQueryExpression: string;
  columnEntries?: Array<ReportsGenerateReportCommandColumnEntry> | null;
};
export type RequestTokenModel = {
  system?: string | null;
};
export type ResolveModel = {
  partId: number;
  resolution: MIRResolution;
  notes?: string | null;
  transactionRowVersion: number;
};
export type ResourceGroupDetailViewModel = {
  id: number;
  name: string;
  description?: string | null;
  resourceGroupEntityType: ResourceGroupEntityType;
  resourceGroupType: ResourceGroupType;
  canDelete: boolean;
  entityIds: Array<number>;
};
export const ResourceGroupEntityTypeArray = ['Customer', 'Warehouse'] as const;
type ResourceGroupEntityTypeArrayType = typeof ResourceGroupEntityTypeArray;
export type ResourceGroupEntityType = ResourceGroupEntityTypeArrayType[number];
export const ResourceGroupTypeArray = ['Auto', 'Administration', 'UserDefined'] as const;
type ResourceGroupTypeArrayType = typeof ResourceGroupTypeArray;
export type ResourceGroupType = ResourceGroupTypeArrayType[number];
export type RolePermissionsModel = {
  role: string;
  permissions: Array<string>;
};
export type ScrapModel = {
  partLocationChangeRequests: Array<PartLocationChangeRequest>;
  notes: string;
};
export const SelectablePartStatusesArray = [
  'Any',
  'Good',
  'Defective',
  'Damaged',
  'InformationMissing',
  'Unidentifiable',
  'Allocated',
  'MasterInReview',
  'Resolved',
  'Received',
  'NotFound',
  'InTransit',
  'OnHold',
  'Quarantined',
] as const;
type SelectablePartStatusesArrayType = typeof SelectablePartStatusesArray;
export type SelectablePartStatuses = SelectablePartStatusesArrayType[number];
export type SendDataSubscriptionTestPayloadModel = {
  dataObjectJson: string;
  dataSubscriptionConfiguration: TemplatedWebHookConfiguration;
};
export type StockLevelViewModel = {
  id: number;
  customerId: number;
  customerCode: string;
  customerName: string;
  warehouseId: number;
  warehouseCode: string;
  masterPartId: number;
  masterPartCode: string;
  desiredCount: number;
  onHand: number;
};
export type SyncADUsersModel = {
  dryRun: boolean;
};
export type TokensRequestTokenModel = {
  token: string;
  expiryDate: string;
};
export const TransactionTypeArray = ['Inbound', 'Move', 'Allocate', 'Unallocate', 'Outbound', 'Transfer', 'Lost', 'Scrap', 'Justify'] as const;
type TransactionTypeArrayType = typeof TransactionTypeArray;
export type TransactionType = TransactionTypeArrayType[number];
export type TransferInModel = {
  jobId: number;
  cartId?: number | null;
  partIds: Array<number>;
  dockReceiptId: number;
  reference?: string | null;
  rowVersion: number;
};
export type TransferJobDetailsModel = {
  id: number;
  customerId: number;
  customerCode: string;
  customerName: string;
  customerGroupIds: Array<number>;
  externalReferenceId: string;
  warehouseId: number;
  warehouseCode: string;
  warehouseName: string;
  destinationWarehouseId: number;
  destinationWarehouseCode: string;
  destinationWarehouseName: string;
  warehouseGroupIds: Array<number>;
  carrierId?: number | null;
  carrierCode?: string | null;
  outReference?: string | null;
  inReference?: string | null;
  serviceType: string;
  resolutionDate: string;
  shipByDate: string;
  jobState: JobState;
  jobStatus: JobStatus;
  partIds: Array<number>;
  lastPartTransactionBatchId?: number | null;
  rowVersion: number;
  modifiedUser: string;
  modifiedDate: string;
  createdDate: string;
  transferJobShippingInformation: TransferJobShippingInformation;
};
export type TransferJobShippingInformation = {
  waybills: Array<string>;
  packageQuantity: number;
  notes?: string | null;
  packageType: OutboundJobShippingPackageType;
  rowVersion: number;
};
export type TransferOutModel = {
  jobId: number;
  waybills: Array<string>;
  packageQuantity: number;
  notes?: string | null;
  packageType: OutboundJobShippingPackageType;
  rowVersion: number;
};
export type TransfersCreateCommandTransactionMetadataEntry = {
  key: string;
  value: string;
};
export type UnallocateOutboundModel = {
  id: number;
  jobId: number;
  partTransactionRequests: Array<PartTransactionRequest>;
  markPartsAsNotFound: boolean;
  rowVersion: number;
};
export type UnallocateTransferModel = {
  id: number;
  partTransactionRequests: Array<PartTransactionRequest>;
  markPartsAsNotFound: boolean;
  rowVersion: number;
};
export type UnquarantineModel = {
  partTransactionRequests: Array<PartTransactionRequest>;
  notes: string;
};
export type UpdateAnnouncementModel = {
  id: number;
  title: string;
  body?: string | null;
  activationDate?: string | null;
  expirationDate?: string | null;
  dismissable: boolean;
  announcementSeverity: AnnouncementSeverity;
  roles?: Array<string> | null;
};
export type UpdateBinModel = {
  id: number;
  description?: string | null;
  zoneId?: number | null;
  affinity: BinAffinityModel;
  disabled: boolean;
  rowVersion: number;
};
export type UpdateBulkModel = {
  binIds: Array<number>;
  setDescription: boolean;
  description?: string | null;
  setAffinity: boolean;
  affinity?: BinAffinityModel;
  setZone: boolean;
  zoneId?: number | null;
};
export type UpdateCarrierModel = {
  id: number;
  name: string;
  waybillIsAutogenerated: boolean;
  disabled: boolean;
};
export type UpdateChannelMappingsModel = {
  notificationChannelId: number;
  mappings: Array<NotificationChannelsUpdateChannelMappingsCommandChannelMappingEntry>;
};
export type UpdateCustomerModel = {
  id: number;
  name: string;
  referenceLabel?: string | null;
  referenceRequired: boolean;
  disabled: boolean;
  rowVersion: number;
  warehouseResourceGroupId?: number | null;
};
export type UpdateCycleCountModel = {
  id: number;
  cycleCountJobStatus: CycleCountJobStatus;
};
export type UpdateDataSubscriptionModel = {
  id: number;
  name: string;
  disabled: boolean;
  description?: string | null;
  dataSubscriptionConfiguration: TemplatedWebHookConfiguration;
  cronExpression?: string | null;
  resourceGroupId: number;
  rowVersion: number;
};
export type UpdateDeliveryRecipientModel = {
  id: number;
  name: string;
  emailAddress: string;
};
export type UpdateExternalApplicationModel = {
  id: number;
  displayName: string;
  username: string;
  roles: Array<string>;
  externalApplicationActivationStatus: ExternalApplicationActivationStatus;
  rowVersion: number;
};
export type UpdateJobStateOutboundModel = {
  id: number;
  jobState: JobState;
  rowVersion: number;
};
export type UpdateJobStateTransferModel = {
  id: number;
  jobState: JobState;
  rowVersion: number;
};
export type UpdateJobWarehouseOutboundModel = {
  id: number;
  outboundJobId: number;
  warehouseId: number;
  rowVersion: number;
};
export type UpdateJobWarehouseTransferModel = {
  id: number;
  warehouseId: number;
  rowVersion: number;
};
export type UpdateMasterPartAttributeCategoryModel = {
  id: number;
  displayNameTemplate: string;
  requiredTemplate: boolean;
  gS1BarcodeApplicationIdentifier?: string | null;
};
export type UpdateMasterPartModel = {
  id: number;
  code: string;
  description?: string | null;
  isBulk: boolean;
  disabled: boolean;
  aliases?: Array<string> | null;
  masterPartDimensions?: MasterPartDimensions;
  metadata?: string | null;
  rowVersion: number;
};
export type UpdateMasterPartStatusModel = {
  id: number;
  reviewStatus: MasterPartReviewStatus;
  notes?: string | null;
  rowVersion: number;
};
export type UpdateMasterPartTemplateModel = {
  id: number;
  name: string;
  description?: string | null;
  masterPartAttributeTemplateMappings: Array<MasterPartTemplatesUpdateCommandMapping>;
  rowVersion: number;
};
export type UpdateNotificationMessageTemplateModel = {
  id: number;
  name: string;
  subjectTemplate?: string | null;
  bodyTemplate: string;
};
export type UpdateOutboundModel = {
  id: number;
  outboundJobId: number;
  customerReference?: string | null;
  serviceType: string;
  waybillRequired: boolean;
  headerMetadata: object;
  shipByDate: string;
  rowVersion: number;
};
export type UpdatePartModel = {
  partId: number;
  transactionRowVersion: number;
  partAttributes?: Array<PartsUpdateCommandPartAttributeValueModel> | null;
  masterPartId?: number | null;
  notes?: string | null;
};
export type UpdatePartStatusModel = {
  partId: number;
  transactionRowVersion: number;
  partStatuses: Array<PartStatuses>;
  notes?: string | null;
};
export type UpdateResourceGroupModel = {
  id: number;
  name: string;
  description?: string | null;
  entityIds: Array<number>;
};
export type UpdateRolePermissionsModel = {
  entries: Array<PermissionsUpdateRolePermissionsCommandRoleEntry>;
};
export type UpdateShippingInformationModel = {
  id: number;
  outboundJobId: number;
  waybills: Array<string>;
  packageQuantity: number;
  notes?: string | null;
  packageType: OutboundJobShippingPackageType;
  rowVersion: number;
};
export type UpdateStockLevelModel = {
  id: number;
  warehouseId: number;
  masterPartId: number;
  desiredCount: number;
};
export type UpdateStorageContextModel = {
  context: string;
  data?: string | null;
};
export type UpdateTemplateModel = {
  id: number;
  masterPartTemplateId?: number | null;
  rowVersion: number;
};
export type UpdateTransferModel = {
  id: number;
  outReference?: string | null;
  inReference?: string | null;
  serviceType: string;
  carrierId?: number | null;
  shipByDate: string;
  rowVersion: number;
};
export type UpdateWarehouseModel = {
  id: number;
  name: string;
  warehouseOptions: Array<WarehouseOptions>;
  addresses: Array<WarehousesCreateCommandAddressModel>;
  disabled: boolean;
  rowVersion: number;
};
export type UploadCountModel = {
  cycleCountJobId: number;
  partEntries: Array<CycleCountsUploadCountCommandCycleCountPartEntry>;
  completedBy: string;
};
export type UserAppRole = {
  userName: string;
  appRoleName: string;
  via: string;
};
export type UserAppRoleModel = {
  roles: Array<string>;
};
export type UserAppRolesModel = {
  userAppRoles: Array<UserAppRole>;
};
export type UtilsRenderHandlebarsTemplateModel = {
  renderedTemplate: string;
};
export type ValidatePartAttributesModel = {
  masterPartId: number;
  partAttributeValues: Array<MasterPartsValidatePartAttributesCommandPartAttributeModel>;
};
export type WarehouseDetailsModel = {
  id: number;
  code: string;
  name: string;
  streetNumber?: string | null;
  streetName?: string | null;
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  province?: string | null;
  postalCode?: string | null;
  country?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  warehouseOptions: Array<WarehouseOptions>;
  warehouseOptionsEnum?: WarehouseOptions;
  disabled: boolean;
  timezoneId?: string | null;
  rowVersion: number;
  resourceGroupIds: Array<number>;
};
export const WarehouseOptionsArray = ['None', 'Carts', 'AllowZoneConfiguration', 'RequireDeliveryReceipt'] as const;
type WarehouseOptionsArrayType = typeof WarehouseOptionsArray;
export type WarehouseOptions = WarehouseOptionsArrayType[number];
export type WarehousesCreateCommandAddressModel = {
  name: string;
  description?: string | null;
  streetNumber: string;
  streetName: string;
  address2?: string | null;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  latitude: number;
  longitude: number;
  timezoneId?: string | null;
};
export type ZoneViewModel = {
  id: number;
  code: string;
  warehouseId: number;
  warehouseCode: string;
  warehouseName: string;
  binCount: number;
};

// endpoint req/resp
export type GetAboutApiArg = void;
export type GetAboutApiResponse = IApplicationEnvironmentConfiguration;
export type SendToastApiArg = {
  markdownMessage?: string;
  username?: string;
  persistent?: boolean;
  severity?: AnnouncementSeverity;
};
export type SendToastApiResponse = any;
export type ChangeLoggingLevelApiArg = {
  loggerName?: string;
  logLevel?: LogLevel;
};
export type ChangeLoggingLevelApiResponse = any;
export type ChangeMinimumLogLevelApiArg = {
  logLevel?: LogLevel;
};
export type ChangeMinimumLogLevelApiResponse = any;
export type ResetLoggingLevelsApiArg = void;
export type ResetLoggingLevelsApiResponse = any;
export type AcquireLockApiArg = {
  name?: string;
  durationSeconds?: number;
};
export type AcquireLockApiResponse = AdminAdminControllerDistributedLockResult;
export type ReleaseLockApiArg = {
  name?: string;
  lockId?: number;
};
export type ReleaseLockApiResponse = any;
export type RefreshLockApiArg = {
  name?: string;
  lockId?: number;
  durationSeconds?: number;
};
export type RefreshLockApiResponse = AdminAdminControllerDistributedLockResult;
export type EnableBulkUpdateApiArg = {
  enabled?: boolean;
};
export type EnableBulkUpdateApiResponse = any;
export type SynchronizeReadCacheApiArg = void;
export type SynchronizeReadCacheApiResponse = any;
export type PauseDatabaseChangeMonitorApiArg = {
  paused?: boolean;
};
export type PauseDatabaseChangeMonitorApiResponse = any;
export type DisableDatabaseChangeMonitorApiArg = {
  disabled?: boolean;
};
export type DisableDatabaseChangeMonitorApiResponse = any;
export type AbortChangeProcessingApiArg = void;
export type AbortChangeProcessingApiResponse = any;
export type RebuildReadCacheForEntityApiArg = {
  entityTypeName?: string;
  primaryKeyStart?: string;
  syncContext?: string;
};
export type RebuildReadCacheForEntityApiResponse = any;
export type RebuildReadCacheApiArg = void;
export type RebuildReadCacheApiResponse = any;
export type MarkAllSyncedApiArg = void;
export type MarkAllSyncedApiResponse = any;
export type ProcessUnsyncedChangesApiArg = {
  syncContext?: string;
};
export type ProcessUnsyncedChangesApiResponse = any;
export type ResetLegacySyncApiArg = void;
export type ResetLegacySyncApiResponse = any;
export type InitiateMigrationApiArg = {
  includeParts?: boolean;
  syncReadCache?: boolean;
  skipInitial?: boolean;
};
export type InitiateMigrationApiResponse = any;
export type AbortMigrationApiArg = void;
export type AbortMigrationApiResponse = any;
export type SkipLegacyTransactionApiArg = {
  transactionId?: number;
};
export type SkipLegacyTransactionApiResponse = any;
export type ExpireAllTokensApiArg = void;
export type ExpireAllTokensApiResponse = any;
export type ResetTokenExpiryApiArg = void;
export type ResetTokenExpiryApiResponse = any;
export type AbortAllConnectionsApiArg = {
  expiredToken?: boolean;
};
export type AbortAllConnectionsApiResponse = any;
export type AcquireApplicationTokenApiArg = {
  clientAppId?: string;
  clientSecret?: string;
  tokenAppId?: string;
  authority?: string;
  payloadOnly?: boolean;
};
export type AcquireApplicationTokenApiResponse = any;
export type EnableBodyLoggingApiArg = {
  enabled?: boolean;
};
export type EnableBodyLoggingApiResponse = any;
export type EnableHeaderLoggingApiArg = {
  enabled?: boolean;
};
export type EnableHeaderLoggingApiResponse = any;
export type EnableODataBodyLoggingApiArg = {
  enabled?: boolean;
};
export type EnableODataBodyLoggingApiResponse = any;
export type ReadOnlyModeApiArg = {
  enabled?: boolean;
};
export type ReadOnlyModeApiResponse = any;
export type InitializeMaterializedPartsApiArg = void;
export type InitializeMaterializedPartsApiResponse = any;
export type InitializeMaterializedPartTransactionsApiArg = void;
export type InitializeMaterializedPartTransactionsApiResponse = any;
export type UseSqlForOdataApiArg = {
  useSql?: boolean;
};
export type UseSqlForOdataApiResponse = any;
export type UseAsyncByDefaultApiArg = {
  useAsync?: boolean;
};
export type UseAsyncByDefaultApiResponse = any;
export type UnallocatePartsWithNoJobsApiArg = void;
export type UnallocatePartsWithNoJobsApiResponse = any;
export type GetAdvancedShipmentApiArg = {
  id?: number;
};
export type GetAdvancedShipmentApiResponse = AdvancedShipmentsAdvancedShipmentJobIndexDetailsModel;
export type CreateAdvancedShipmentApiArg = {
  createAdvancedShipmentModel: CreateAdvancedShipmentModel;
};
export type CreateAdvancedShipmentApiResponse = AdvancedShipmentsAdvancedShipmentJobIndexDetailsModel;
export type GetAnnouncementApiArg = {
  id?: number;
};
export type GetAnnouncementApiResponse = AnnouncementModel;
export type DeleteAnnouncementApiArg = {
  id?: number;
};
export type DeleteAnnouncementApiResponse = any;
export type GetCurrentAnnouncementsApiArg = void;
export type GetCurrentAnnouncementsApiResponse = Array<AnnouncementModel>;
export type CreateAnnouncementApiArg = {
  createAnnouncementModel: CreateAnnouncementModel;
};
export type CreateAnnouncementApiResponse = AnnouncementModel;
export type UpdateAnnouncementApiArg = {
  updateAnnouncementModel: UpdateAnnouncementModel;
};
export type UpdateAnnouncementApiResponse = AnnouncementModel;
export type GetGs1ApplicationIdentifiersApiArg = void;
export type GetGs1ApplicationIdentifiersApiResponse = Array<GS1ApplicationIdentifier>;
export type GetBinApiArg = {
  id?: number;
};
export type GetBinApiResponse = BinsBinViewModelIndexDetailsModel;
export type CreateBinApiArg = {
  createBinModel: CreateBinModel;
};
export type CreateBinApiResponse = BinsBinViewModelIndexDetailsModel;
export type UpdateBinApiArg = {
  updateBinModel: UpdateBinModel;
};
export type UpdateBinApiResponse = BinsBinViewModelIndexDetailsModel;
export type UpdateBulkApiArg = {
  updateBulkModel: UpdateBulkModel;
};
export type UpdateBulkApiResponse = ReadCacheEntityResponse;
export type GetCarrierApiArg = {
  id?: number;
};
export type GetCarrierApiResponse = CarrierViewModel;
export type CreateCarrierApiArg = {
  createCarrierModel: CreateCarrierModel;
};
export type CreateCarrierApiResponse = CarrierViewModel;
export type UpdateCarrierApiArg = {
  updateCarrierModel: UpdateCarrierModel;
};
export type UpdateCarrierApiResponse = CarrierViewModel;
export type GetCartApiArg = {
  id?: number;
};
export type GetCartApiResponse = CartViewModel;
export type CreateCartApiArg = {
  createCartModel: CreateCartModel;
};
export type CreateCartApiResponse = CartViewModel;
export type GetConversationApiArg = {
  id?: number;
};
export type GetConversationApiResponse = ConversationViewModel;
export type AddConversationMessageApiArg = {
  addConversationMessageModel: AddConversationMessageModel;
};
export type AddConversationMessageApiResponse = ConversationMessageViewModel;
export type GetCustomerApiArg = {
  id?: number;
};
export type GetCustomerApiResponse = CustomerDetailsModel;
export type CreateCustomerApiArg = {
  createCustomerModel: CreateCustomerModel;
};
export type CreateCustomerApiResponse = CustomerDetailsModel;
export type UpdateCustomerApiArg = {
  updateCustomerModel: UpdateCustomerModel;
};
export type UpdateCustomerApiResponse = CustomerDetailsModel;
export type GetCycleCountApiArg = {
  id?: number;
};
export type GetCycleCountApiResponse = CycleCountJobDetailsModel;
export type DeleteCycleCountApiArg = {
  id?: number;
};
export type DeleteCycleCountApiResponse = any;
export type CreateCycleCountApiArg = {
  createCycleCountModel: CreateCycleCountModel;
};
export type CreateCycleCountApiResponse = CycleCountsCreateResponse;
export type UpdateCycleCountApiArg = {
  updateCycleCountModel: UpdateCycleCountModel;
};
export type UpdateCycleCountApiResponse = CycleCountJobDetailsModel;
export type UploadCountApiArg = {
  uploadCountModel: UploadCountModel;
};
export type UploadCountApiResponse = CycleCountJobDetailsModel;
export type GetEmbeddedDashboardsApiArg = void;
export type GetEmbeddedDashboardsApiResponse = Array<EmbeddedReportViewModel>;
export type CreateJobApiArg = {
  createJobModel: CreateJobModel;
};
export type CreateJobApiResponse = any;
export type GetDataSubscriptionApiArg = {
  id?: number;
};
export type GetDataSubscriptionApiResponse = DataSubscriptionModel;
export type DeleteDataSubscriptionApiArg = {
  id?: number;
};
export type DeleteDataSubscriptionApiResponse = any;
export type CreateDataSubscriptionApiArg = {
  createDataSubscriptionModel: CreateDataSubscriptionModel;
};
export type CreateDataSubscriptionApiResponse = DataSubscriptionModel;
export type UpdateDataSubscriptionApiArg = {
  updateDataSubscriptionModel: UpdateDataSubscriptionModel;
};
export type UpdateDataSubscriptionApiResponse = DataSubscriptionModel;
export type GetDataSubscriptionDataSourcesApiArg = void;
export type GetDataSubscriptionDataSourcesApiResponse = DataSubscriptionsGetDataSubscriptionDataSourcesModel;
export type GetDataSubscriptionObjectTemplateApiArg = {
  dataSubscriptionDataSource?: DataSubscriptionDataSource;
};
export type GetDataSubscriptionObjectTemplateApiResponse = string;
export type GetDataSubscriptionTriggerForDataSourceApiArg = {
  dataSubscriptionDataSource?: DataSubscriptionDataSource;
};
export type GetDataSubscriptionTriggerForDataSourceApiResponse = DataSubscriptionTrigger;
export type SendDataSubscriptionTestPayloadApiArg = {
  sendDataSubscriptionTestPayloadModel: SendDataSubscriptionTestPayloadModel;
};
export type SendDataSubscriptionTestPayloadApiResponse = any;
export type GetDeliveryReceiptApiArg = {
  id?: number;
};
export type GetDeliveryReceiptApiResponse = DeliveryReceiptViewModel;
export type CreateDeliveryReceiptApiArg = {
  createDeliveryReceiptModel: CreateDeliveryReceiptModel;
};
export type CreateDeliveryReceiptApiResponse = DeliveryReceiptViewModel;
export type GetDeliveryRecipientApiArg = {
  id?: number;
};
export type GetDeliveryRecipientApiResponse = DeliveryRecipientViewModel;
export type CreateDeliveryRecipientApiArg = {
  createDeliveryRecipientModel: CreateDeliveryRecipientModel;
};
export type CreateDeliveryRecipientApiResponse = DeliveryRecipientViewModel;
export type UpdateDeliveryRecipientApiArg = {
  updateDeliveryRecipientModel: UpdateDeliveryRecipientModel;
};
export type UpdateDeliveryRecipientApiResponse = DeliveryRecipientViewModel;
export type SendWebsocketMessageApiArg = {
  any: any;
};
export type SendWebsocketMessageApiResponse = any;
export type GetDockReceiptApiArg = {
  id?: number;
};
export type GetDockReceiptApiResponse = DockReceiptViewModel;
export type DeleteDockReceiptApiArg = {
  id?: number;
};
export type DeleteDockReceiptApiResponse = any;
export type GetDockReceiptHistoryApiArg = {
  id?: number;
};
export type GetDockReceiptHistoryApiResponse = Array<DockReceiptViewModel>;
export type CreateDockReceiptApiArg = {
  createDockReceiptModel: CreateDockReceiptModel;
};
export type CreateDockReceiptApiResponse = DockReceiptViewModel;
export type RemoveDeliveryApiArg = {
  removeDeliveryModel: RemoveDeliveryModel;
};
export type RemoveDeliveryApiResponse = DockReceiptViewModel;
export type ChangeStatusApiArg = {
  changeStatusModel: ChangeStatusModel;
};
export type ChangeStatusApiResponse = DockReceiptViewModel;
export type CreateEntityAttachmentApiArg = {
  createEntityAttachmentModel: CreateEntityAttachmentModel;
};
export type CreateEntityAttachmentApiResponse = ReadCacheEntityResponse;
export type DeleteEntityAttachmentApiArg = {
  deleteEntityAttachmentModel: DeleteEntityAttachmentModel;
};
export type DeleteEntityAttachmentApiResponse = ReadCacheEntityResponse;
export type GetExternalApplicationApiArg = {
  id?: number;
};
export type GetExternalApplicationApiResponse = ExternalApplicationModel;
export type CreateExternalApplicationApiArg = {
  createExternalApplicationModel: CreateExternalApplicationModel;
};
export type CreateExternalApplicationApiResponse = ExternalApplicationModel;
export type UpdateExternalApplicationApiArg = {
  updateExternalApplicationModel: UpdateExternalApplicationModel;
};
export type UpdateExternalApplicationApiResponse = ExternalApplicationModel;
export type CreateInboundApiArg = {
  createInboundModel: CreateInboundModel;
};
export type CreateInboundApiResponse = ReadCacheEntityResponse;
export type ReinboundApiArg = {
  reinboundModel: ReinboundModel;
};
export type ReinboundApiResponse = ReadCacheEntityResponse;
export type MoveToCartApiArg = {
  moveToCartModel: MoveToCartModel;
};
export type MoveToCartApiResponse = ReadCacheEntityResponse;
export type ReadTransactionInfoApiArg = {
  partId?: number;
};
export type ReadTransactionInfoApiResponse = InboundLoadTransactionInformationModel;
export type PartsByWarehouseApiArg = {
  customerCode?: string;
  masterPartCodes?: string;
  partStatuses?: Array<PartStatuses> | null;
  partStatusesExclusive?: boolean | null;
};
export type PartsByWarehouseApiResponse = InventoryReportPartsByWarehouseModel;
export type ReadTransactionsApiArg = {
  partId?: number;
};
export type ReadTransactionsApiResponse = LegacyReadTransactionsModel;
export type GetMasterPartAttributeCategoryApiArg = {
  id?: number;
};
export type GetMasterPartAttributeCategoryApiResponse = MasterPartAttributeCategoryModel;
export type CreateMasterPartAttributeCategoryApiArg = {
  createMasterPartAttributeCategoryModel: CreateMasterPartAttributeCategoryModel;
};
export type CreateMasterPartAttributeCategoryApiResponse = MasterPartAttributeCategoryModel;
export type UpdateMasterPartAttributeCategoryApiArg = {
  updateMasterPartAttributeCategoryModel: UpdateMasterPartAttributeCategoryModel;
};
export type UpdateMasterPartAttributeCategoryApiResponse = MasterPartAttributeCategoryModel;
export type GetMasterPartApiArg = {
  id?: number;
};
export type GetMasterPartApiResponse = MasterPartDetailsModel;
export type GetMasterPartHistoryApiArg = {
  id?: number;
};
export type GetMasterPartHistoryApiResponse = Array<MasterPartListModel>;
export type CreateMasterPartApiArg = {
  createMasterPartModel: CreateMasterPartModel;
};
export type CreateMasterPartApiResponse = MasterPartDetailsModel;
export type UpdateMasterPartApiArg = {
  updateMasterPartModel: UpdateMasterPartModel;
};
export type UpdateMasterPartApiResponse = MasterPartDetailsModel;
export type UpdateMasterPartStatusApiArg = {
  updateMasterPartStatusModel: UpdateMasterPartStatusModel;
};
export type UpdateMasterPartStatusApiResponse = MasterPartDetailsModel;
export type MergeApiArg = {
  mergeModel: MergeModel;
};
export type MergeApiResponse = any;
export type LegacyCheckApiArg = {
  masterPartCode?: string;
  customerId?: number;
};
export type LegacyCheckApiResponse = MasterPartsLegacyCheckModel;
export type ValidatePartAttributesApiArg = {
  validatePartAttributesModel: ValidatePartAttributesModel;
};
export type ValidatePartAttributesApiResponse = Array<PartAttributeValidationResult>;
export type UpdateTemplateApiArg = {
  updateTemplateModel: UpdateTemplateModel;
};
export type UpdateTemplateApiResponse = MasterPartDetailsModel;
export type GetMasterPartTemplateApiArg = {
  id?: number;
};
export type GetMasterPartTemplateApiResponse = MasterPartTemplateDetailsModel;
export type CreateMasterPartTemplateApiArg = {
  createMasterPartTemplateModel: CreateMasterPartTemplateModel;
};
export type CreateMasterPartTemplateApiResponse = MasterPartTemplateDetailsModel;
export type UpdateMasterPartTemplateApiArg = {
  updateMasterPartTemplateModel: UpdateMasterPartTemplateModel;
};
export type UpdateMasterPartTemplateApiResponse = MasterPartTemplateDetailsModel;
export type ResolveApiArg = {
  resolveModel: ResolveModel;
};
export type ResolveApiResponse = ReadCacheEntityResponse;
export type ListNotificationChannelsApiArg = {
  notificationClientId?: number;
};
export type ListNotificationChannelsApiResponse = Array<NotificationChannelListModel>;
export type CreateNotificationChannelApiArg = {
  body: NotificationChannelsCreateEmailCommand | NotificationChannelsCreateWebHookCommand;
};
export type CreateNotificationChannelApiResponse =
  | NotificationChannelsNotificationChannelModelEmailChannelModel
  | NotificationChannelsNotificationChannelModelWebHookChannelModel;
export type UpdateNotificationChannelApiArg = {
  body: NotificationChannelsUpdateEmailCommand | NotificationChannelsUpdateWebHookCommand;
};
export type UpdateNotificationChannelApiResponse =
  | NotificationChannelsNotificationChannelModelEmailChannelModel
  | NotificationChannelsNotificationChannelModelWebHookChannelModel;
export type GetNotificationChannelApiArg = {
  id?: number;
};
export type GetNotificationChannelApiResponse =
  | NotificationChannelsNotificationChannelModelEmailChannelModel
  | NotificationChannelsNotificationChannelModelWebHookChannelModel;
export type DeleteNotificationChannelApiArg = {
  id?: number;
};
export type DeleteNotificationChannelApiResponse = any;
export type UpdateChannelMappingsApiArg = {
  updateChannelMappingsModel: UpdateChannelMappingsModel;
};
export type UpdateChannelMappingsApiResponse =
  | NotificationChannelsNotificationChannelModelEmailChannelModel
  | NotificationChannelsNotificationChannelModelWebHookChannelModel;
export type CreateNotificationClientApiArg = {
  createNotificationClientModel: CreateNotificationClientModel;
};
export type CreateNotificationClientApiResponse = NotificationClientModel;
export type DeleteNotificationClientApiArg = {
  id?: number;
};
export type DeleteNotificationClientApiResponse = any;
export type GetNotificationMessageTemplateApiArg = {
  id?: number;
};
export type GetNotificationMessageTemplateApiResponse = NotificationMessageTemplateModel;
export type DeleteNotificationMessageTemplateApiArg = {
  id?: number;
};
export type DeleteNotificationMessageTemplateApiResponse = any;
export type CreateNotificationMessageTemplateApiArg = {
  createNotificationMessageTemplateModel: CreateNotificationMessageTemplateModel;
};
export type CreateNotificationMessageTemplateApiResponse = NotificationMessageTemplateModel;
export type UpdateNotificationMessageTemplateApiArg = {
  updateNotificationMessageTemplateModel: UpdateNotificationMessageTemplateModel;
};
export type UpdateNotificationMessageTemplateApiResponse = NotificationMessageTemplateModel;
export type GetNotificationMessageTemplateObjectTemplateApiArg = {
  notificationEvent?: NotificationEvent;
};
export type GetNotificationMessageTemplateObjectTemplateApiResponse = string;
export type GetOutboundApiArg = {
  id?: number;
};
export type GetOutboundApiResponse = OutboundJobDetailsModel;
export type CreateOutboundApiArg = {
  createOutboundModel: CreateOutboundModel;
};
export type CreateOutboundApiResponse = OutboundJobDetailsModel;
export type UpdateOutboundApiArg = {
  updateOutboundModel: UpdateOutboundModel;
};
export type UpdateOutboundApiResponse = OutboundJobDetailsModel;
export type UpdateJobWarehouseOutboundApiArg = {
  updateJobWarehouseOutboundModel: UpdateJobWarehouseOutboundModel;
};
export type UpdateJobWarehouseOutboundApiResponse = OutboundJobDetailsModel;
export type AllocateOutboundApiArg = {
  allocateOutboundModel: AllocateOutboundModel;
};
export type AllocateOutboundApiResponse = OutboundJobDetailsModel;
export type UnallocateOutboundApiArg = {
  unallocateOutboundModel: UnallocateOutboundModel;
};
export type UnallocateOutboundApiResponse = OutboundJobDetailsModel;
export type UpdateShippingInformationApiArg = {
  updateShippingInformationModel: UpdateShippingInformationModel;
};
export type UpdateShippingInformationApiResponse = OutboundJobDetailsModel;
export type UpdateJobStateOutboundApiArg = {
  updateJobStateOutboundModel: UpdateJobStateOutboundModel;
};
export type UpdateJobStateOutboundApiResponse = OutboundJobDetailsModel;
export type BulkOutboundApiArg = {
  bulkOutboundModel: BulkOutboundModel;
};
export type BulkOutboundApiResponse = OutboundBulkOutboundModel;
export type RenderTemplateApiArg = {
  timezoneOffset?: string;
  outboundJobId?: number;
};
export type RenderTemplateApiResponse = OutboundRenderTemplateModel;
export type CompleteTransferApiArg = {
  completeTransferModel: CompleteTransferModel;
};
export type CompleteTransferApiResponse = ReadCacheEntityResponse;
export type GetPartMIRContextApiArg = {
  partId?: number;
};
export type GetPartMIRContextApiResponse = MIRPartMIRContextsControllerModel;
export type GetPartApiArg = {
  id?: number;
};
export type GetPartApiResponse = MaterializedPartViewModel;
export type ScrapApiArg = {
  scrapModel: ScrapModel;
};
export type ScrapApiResponse = ReadCacheEntityResponse;
export type MoveApiArg = {
  moveModel: MoveModel;
};
export type MoveApiResponse = ReadCacheEntityResponse;
export type BulkMoveApiArg = {
  bulkMoveModel: BulkMoveModel;
};
export type BulkMoveApiResponse = PartsBulkMoveModel;
export type BulkJustifyApiArg = {
  bulkJustifyModel: BulkJustifyModel;
};
export type BulkJustifyApiResponse = PartsBulkJustifyModel;
export type FoundApiArg = {
  foundModel: FoundModel;
};
export type FoundApiResponse = ReadCacheEntityResponse;
export type LostApiArg = {
  lostModel: LostModel;
};
export type LostApiResponse = ReadCacheEntityResponse;
export type HoldApiArg = {
  holdModel: HoldModel;
};
export type HoldApiResponse = ReadCacheEntityResponse;
export type ReleaseApiArg = {
  releaseModel: ReleaseModel;
};
export type ReleaseApiResponse = ReadCacheEntityResponse;
export type QuarantineApiArg = {
  quarantineModel: QuarantineModel;
};
export type QuarantineApiResponse = ReadCacheEntityResponse;
export type UnquarantineApiArg = {
  unquarantineModel: UnquarantineModel;
};
export type UnquarantineApiResponse = ReadCacheEntityResponse;
export type OnHoldByIdApiArg = {
  holdId?: string;
};
export type OnHoldByIdApiResponse = PartsOnHoldByIdModel;
export type UpdatePartStatusApiArg = {
  updatePartStatusModel: UpdatePartStatusModel;
};
export type UpdatePartStatusApiResponse = ReadCacheEntityResponse;
export type DefectiveApiArg = {
  defectiveModel: DefectiveModel;
};
export type DefectiveApiResponse = ReadCacheEntityResponse;
export type UpdatePartApiArg = {
  updatePartModel: UpdatePartModel;
};
export type UpdatePartApiResponse = ReadCacheEntityResponse;
export type PrintLabelApiArg = {
  printLabelModel: PrintLabelModel;
};
export type PrintLabelApiResponse = PrintLabelAsyncResponse;
export type PutAwayApiArg = {
  putAwayModel: PutAwayModel;
};
export type PutAwayApiResponse = ReadCacheEntityResponse;
export type RestrictedMetadataKeysApiArg = void;
export type RestrictedMetadataKeysApiResponse = Array<string>;
export type PatchMetadataApiArg = {
  patchMetadataModel: PatchMetadataModel;
};
export type PatchMetadataApiResponse = ReadCacheEntityResponse;
export type GetUserPermissionsApiArg = void;
export type GetUserPermissionsApiResponse = Array<string>;
export type UpdateRolePermissionsApiArg = {
  updateRolePermissionsModel: UpdateRolePermissionsModel;
};
export type UpdateRolePermissionsApiResponse = Array<RolePermissionsModel>;
export type SyncADUsersApiArg = {
  dryRun?: boolean;
};
export type SyncADUsersApiResponse = PermissionsSyncADUsersModel;
export type GetUserAppRolesApiArg = void;
export type GetUserAppRolesApiResponse = UserAppRolesModel;
export type GetAppRolesForUserApiArg = {
  userId?: string;
};
export type GetAppRolesForUserApiResponse = UserAppRoleModel;
export type GetAppRolesApiArg = void;
export type GetAppRolesApiResponse = Array<AppRole>;
export type CreateAppRoleApiArg = {
  createAppRoleModel: CreateAppRoleModel;
};
export type CreateAppRoleApiResponse = any;
export type DeleteAppRoleApiArg = {
  deleteAppRoleModel: DeleteAppRoleModel;
};
export type DeleteAppRoleApiResponse = any;
export type ResetRoleMappingsApiArg = void;
export type ResetRoleMappingsApiResponse = any;
export type GetAllPermissionsApiArg = void;
export type GetAllPermissionsApiResponse = Array<string>;
export type GetRoleMappingsApiArg = void;
export type GetRoleMappingsApiResponse = Array<RolePermissionsModel>;
export type PerformRequestApiArg = {
  performRequestModel: PerformRequestModel;
};
export type PerformRequestApiResponse = ProxyPerformRequestModel;
export type GetPutAwayJobApiArg = {
  id?: number;
};
export type GetPutAwayJobApiResponse = PutAwayPutAwayJobIndexModel;
export type GenerateReportApiArg = {
  generateReportModel: GenerateReportModel;
};
export type GenerateReportApiResponse = any;
export type GetResourceGroupApiArg = {
  id?: number;
};
export type GetResourceGroupApiResponse = ResourceGroupDetailViewModel;
export type DeleteResourceGroupApiArg = {
  id?: number;
};
export type DeleteResourceGroupApiResponse = any;
export type CreateResourceGroupApiArg = {
  createResourceGroupModel: CreateResourceGroupModel;
};
export type CreateResourceGroupApiResponse = ResourceGroupDetailViewModel;
export type UpdateResourceGroupApiArg = {
  updateResourceGroupModel: UpdateResourceGroupModel;
};
export type UpdateResourceGroupApiResponse = ResourceGroupDetailViewModel;
export type GetStockLevelApiArg = {
  id?: number;
};
export type GetStockLevelApiResponse = StockLevelViewModel;
export type DeleteStockLevelApiArg = {
  id?: number;
};
export type DeleteStockLevelApiResponse = any;
export type CreateStockLevelApiArg = {
  createStockLevelModel: CreateStockLevelModel;
};
export type CreateStockLevelApiResponse = StockLevelViewModel;
export type UpdateStockLevelApiArg = {
  updateStockLevelModel: UpdateStockLevelModel;
};
export type UpdateStockLevelApiResponse = StockLevelViewModel;
export type RequestTokenForSystemApiArg = {
  requestTokenModel: RequestTokenModel;
};
export type RequestTokenForSystemApiResponse = TokensRequestTokenModel;
export type CreateLegacyTransferApiArg = {
  createLegacyTransferModel: CreateLegacyTransferModel;
};
export type CreateLegacyTransferApiResponse = ReadCacheEntityResponse;
export type GetTransferApiArg = {
  id?: number;
};
export type GetTransferApiResponse = TransferJobDetailsModel;
export type CreateTransferApiArg = {
  createTransferModel: CreateTransferModel;
};
export type CreateTransferApiResponse = TransferJobDetailsModel;
export type UpdateTransferApiArg = {
  updateTransferModel: UpdateTransferModel;
};
export type UpdateTransferApiResponse = TransferJobDetailsModel;
export type AllocateTransferApiArg = {
  allocateTransferModel: AllocateTransferModel;
};
export type AllocateTransferApiResponse = TransferJobDetailsModel;
export type UnallocateTransferApiArg = {
  unallocateTransferModel: UnallocateTransferModel;
};
export type UnallocateTransferApiResponse = TransferJobDetailsModel;
export type TransferOutApiArg = {
  transferOutModel: TransferOutModel;
};
export type TransferOutApiResponse = TransferJobDetailsModel;
export type TransferInApiArg = {
  transferInModel: TransferInModel;
};
export type TransferInApiResponse = TransferJobDetailsModel;
export type UpdateJobStateTransferApiArg = {
  updateJobStateTransferModel: UpdateJobStateTransferModel;
};
export type UpdateJobStateTransferApiResponse = TransferJobDetailsModel;
export type UpdateJobWarehouseTransferApiArg = {
  updateJobWarehouseTransferModel: UpdateJobWarehouseTransferModel;
};
export type UpdateJobWarehouseTransferApiResponse = TransferJobDetailsModel;
export type AssignResourceGroupsApiArg = {
  assignResourceGroupsModel: AssignResourceGroupsModel;
};
export type AssignResourceGroupsApiResponse = any;
export type ListStorageContextsApiArg = void;
export type ListStorageContextsApiResponse = Array<string>;
export type GetDataForStorageContextApiArg = {
  context?: string;
};
export type GetDataForStorageContextApiResponse = string;
export type UpdateStorageContextApiArg = {
  updateStorageContextModel: UpdateStorageContextModel;
};
export type UpdateStorageContextApiResponse = any;
export type DeleteStorageContextApiArg = {
  context?: string;
};
export type DeleteStorageContextApiResponse = any;
export type GetAllActiveDirectoryUsersApiArg = void;
export type GetAllActiveDirectoryUsersApiResponse = Array<ActiveDirectoryUserModel>;
export type RenderHandlebarsTemplateApiArg = {
  renderHandlebarsTemplateModel: RenderHandlebarsTemplateModel;
};
export type RenderHandlebarsTemplateApiResponse = UtilsRenderHandlebarsTemplateModel;
export type GetWarehouseApiArg = {
  id?: number;
};
export type GetWarehouseApiResponse = WarehouseDetailsModel;
export type CreateWarehouseApiArg = {
  createWarehouseModel: CreateWarehouseModel;
};
export type CreateWarehouseApiResponse = WarehouseDetailsModel;
export type UpdateWarehouseApiArg = {
  updateWarehouseModel: UpdateWarehouseModel;
};
export type UpdateWarehouseApiResponse = WarehouseDetailsModel;
export type GetZoneApiArg = {
  id?: number;
};
export type GetZoneApiResponse = ZoneViewModel;
export type CreateZoneApiArg = {
  createZoneModel: CreateZoneModel;
};
export type CreateZoneApiResponse = ZoneViewModel;

export const {
  useGetAboutQuery,
  useLazyGetAboutQuery,
  useSendToastMutation,
  useChangeLoggingLevelMutation,
  useChangeMinimumLogLevelMutation,
  useResetLoggingLevelsMutation,
  useAcquireLockQuery,
  useLazyAcquireLockQuery,
  useReleaseLockQuery,
  useLazyReleaseLockQuery,
  useRefreshLockQuery,
  useLazyRefreshLockQuery,
  useEnableBulkUpdateQuery,
  useLazyEnableBulkUpdateQuery,
  useSynchronizeReadCacheQuery,
  useLazySynchronizeReadCacheQuery,
  usePauseDatabaseChangeMonitorQuery,
  useLazyPauseDatabaseChangeMonitorQuery,
  useDisableDatabaseChangeMonitorQuery,
  useLazyDisableDatabaseChangeMonitorQuery,
  useAbortChangeProcessingQuery,
  useLazyAbortChangeProcessingQuery,
  useRebuildReadCacheForEntityQuery,
  useLazyRebuildReadCacheForEntityQuery,
  useRebuildReadCacheQuery,
  useLazyRebuildReadCacheQuery,
  useMarkAllSyncedQuery,
  useLazyMarkAllSyncedQuery,
  useProcessUnsyncedChangesQuery,
  useLazyProcessUnsyncedChangesQuery,
  useResetLegacySyncQuery,
  useLazyResetLegacySyncQuery,
  useInitiateMigrationQuery,
  useLazyInitiateMigrationQuery,
  useAbortMigrationQuery,
  useLazyAbortMigrationQuery,
  useSkipLegacyTransactionQuery,
  useLazySkipLegacyTransactionQuery,
  useExpireAllTokensMutation,
  useResetTokenExpiryQuery,
  useLazyResetTokenExpiryQuery,
  useAbortAllConnectionsMutation,
  useAcquireApplicationTokenQuery,
  useLazyAcquireApplicationTokenQuery,
  useEnableBodyLoggingQuery,
  useLazyEnableBodyLoggingQuery,
  useEnableHeaderLoggingQuery,
  useLazyEnableHeaderLoggingQuery,
  useEnableODataBodyLoggingQuery,
  useLazyEnableODataBodyLoggingQuery,
  useReadOnlyModeMutation,
  useInitializeMaterializedPartsMutation,
  useInitializeMaterializedPartTransactionsMutation,
  useUseSqlForOdataMutation,
  useUseAsyncByDefaultMutation,
  useUnallocatePartsWithNoJobsMutation,
  useGetAdvancedShipmentQuery,
  useLazyGetAdvancedShipmentQuery,
  useCreateAdvancedShipmentMutation,
  useGetAnnouncementQuery,
  useLazyGetAnnouncementQuery,
  useDeleteAnnouncementMutation,
  useGetCurrentAnnouncementsQuery,
  useLazyGetCurrentAnnouncementsQuery,
  useCreateAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useGetGs1ApplicationIdentifiersQuery,
  useLazyGetGs1ApplicationIdentifiersQuery,
  useGetBinQuery,
  useLazyGetBinQuery,
  useCreateBinMutation,
  useUpdateBinMutation,
  useUpdateBulkMutation,
  useGetCarrierQuery,
  useLazyGetCarrierQuery,
  useCreateCarrierMutation,
  useUpdateCarrierMutation,
  useGetCartQuery,
  useLazyGetCartQuery,
  useCreateCartMutation,
  useGetConversationQuery,
  useLazyGetConversationQuery,
  useAddConversationMessageMutation,
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useGetCycleCountQuery,
  useLazyGetCycleCountQuery,
  useDeleteCycleCountMutation,
  useCreateCycleCountMutation,
  useUpdateCycleCountMutation,
  useUploadCountMutation,
  useGetEmbeddedDashboardsQuery,
  useLazyGetEmbeddedDashboardsQuery,
  useCreateJobMutation,
  useGetDataSubscriptionQuery,
  useLazyGetDataSubscriptionQuery,
  useDeleteDataSubscriptionMutation,
  useCreateDataSubscriptionMutation,
  useUpdateDataSubscriptionMutation,
  useGetDataSubscriptionDataSourcesQuery,
  useLazyGetDataSubscriptionDataSourcesQuery,
  useGetDataSubscriptionObjectTemplateQuery,
  useLazyGetDataSubscriptionObjectTemplateQuery,
  useGetDataSubscriptionTriggerForDataSourceQuery,
  useLazyGetDataSubscriptionTriggerForDataSourceQuery,
  useSendDataSubscriptionTestPayloadMutation,
  useGetDeliveryReceiptQuery,
  useLazyGetDeliveryReceiptQuery,
  useCreateDeliveryReceiptMutation,
  useGetDeliveryRecipientQuery,
  useLazyGetDeliveryRecipientQuery,
  useCreateDeliveryRecipientMutation,
  useUpdateDeliveryRecipientMutation,
  useSendWebsocketMessageMutation,
  useGetDockReceiptQuery,
  useLazyGetDockReceiptQuery,
  useDeleteDockReceiptMutation,
  useGetDockReceiptHistoryQuery,
  useLazyGetDockReceiptHistoryQuery,
  useCreateDockReceiptMutation,
  useRemoveDeliveryMutation,
  useChangeStatusMutation,
  useCreateEntityAttachmentMutation,
  useDeleteEntityAttachmentMutation,
  useGetExternalApplicationQuery,
  useLazyGetExternalApplicationQuery,
  useCreateExternalApplicationMutation,
  useUpdateExternalApplicationMutation,
  useCreateInboundMutation,
  useReinboundMutation,
  useMoveToCartMutation,
  useReadTransactionInfoQuery,
  useLazyReadTransactionInfoQuery,
  usePartsByWarehouseQuery,
  useLazyPartsByWarehouseQuery,
  useReadTransactionsQuery,
  useLazyReadTransactionsQuery,
  useGetMasterPartAttributeCategoryQuery,
  useLazyGetMasterPartAttributeCategoryQuery,
  useCreateMasterPartAttributeCategoryMutation,
  useUpdateMasterPartAttributeCategoryMutation,
  useGetMasterPartQuery,
  useLazyGetMasterPartQuery,
  useGetMasterPartHistoryQuery,
  useLazyGetMasterPartHistoryQuery,
  useCreateMasterPartMutation,
  useUpdateMasterPartMutation,
  useUpdateMasterPartStatusMutation,
  useMergeMutation,
  useLegacyCheckQuery,
  useLazyLegacyCheckQuery,
  useValidatePartAttributesMutation,
  useUpdateTemplateMutation,
  useGetMasterPartTemplateQuery,
  useLazyGetMasterPartTemplateQuery,
  useCreateMasterPartTemplateMutation,
  useUpdateMasterPartTemplateMutation,
  useResolveMutation,
  useListNotificationChannelsQuery,
  useLazyListNotificationChannelsQuery,
  useCreateNotificationChannelMutation,
  useUpdateNotificationChannelMutation,
  useGetNotificationChannelQuery,
  useLazyGetNotificationChannelQuery,
  useDeleteNotificationChannelMutation,
  useUpdateChannelMappingsMutation,
  useCreateNotificationClientMutation,
  useDeleteNotificationClientMutation,
  useGetNotificationMessageTemplateQuery,
  useLazyGetNotificationMessageTemplateQuery,
  useDeleteNotificationMessageTemplateMutation,
  useCreateNotificationMessageTemplateMutation,
  useUpdateNotificationMessageTemplateMutation,
  useGetNotificationMessageTemplateObjectTemplateQuery,
  useLazyGetNotificationMessageTemplateObjectTemplateQuery,
  useGetOutboundQuery,
  useLazyGetOutboundQuery,
  useCreateOutboundMutation,
  useUpdateOutboundMutation,
  useUpdateJobWarehouseOutboundMutation,
  useAllocateOutboundMutation,
  useUnallocateOutboundMutation,
  useUpdateShippingInformationMutation,
  useUpdateJobStateOutboundMutation,
  useBulkOutboundMutation,
  useRenderTemplateQuery,
  useLazyRenderTemplateQuery,
  useCompleteTransferMutation,
  useGetPartMirContextQuery,
  useLazyGetPartMirContextQuery,
  useGetPartQuery,
  useLazyGetPartQuery,
  useScrapMutation,
  useMoveMutation,
  useBulkMoveMutation,
  useBulkJustifyMutation,
  useFoundMutation,
  useLostMutation,
  useHoldMutation,
  useReleaseMutation,
  useQuarantineMutation,
  useUnquarantineMutation,
  useOnHoldByIdQuery,
  useLazyOnHoldByIdQuery,
  useUpdatePartStatusMutation,
  useDefectiveMutation,
  useUpdatePartMutation,
  usePrintLabelMutation,
  usePutAwayMutation,
  useRestrictedMetadataKeysQuery,
  useLazyRestrictedMetadataKeysQuery,
  usePatchMetadataMutation,
  useGetUserPermissionsQuery,
  useLazyGetUserPermissionsQuery,
  useUpdateRolePermissionsMutation,
  useSyncAdUsersMutation,
  useGetUserAppRolesQuery,
  useLazyGetUserAppRolesQuery,
  useGetAppRolesForUserQuery,
  useLazyGetAppRolesForUserQuery,
  useGetAppRolesQuery,
  useLazyGetAppRolesQuery,
  useCreateAppRoleMutation,
  useDeleteAppRoleMutation,
  useResetRoleMappingsMutation,
  useGetAllPermissionsQuery,
  useLazyGetAllPermissionsQuery,
  useGetRoleMappingsQuery,
  useLazyGetRoleMappingsQuery,
  usePerformRequestMutation,
  useGetPutAwayJobQuery,
  useLazyGetPutAwayJobQuery,
  useGenerateReportMutation,
  useGetResourceGroupQuery,
  useLazyGetResourceGroupQuery,
  useDeleteResourceGroupMutation,
  useCreateResourceGroupMutation,
  useUpdateResourceGroupMutation,
  useGetStockLevelQuery,
  useLazyGetStockLevelQuery,
  useDeleteStockLevelMutation,
  useCreateStockLevelMutation,
  useUpdateStockLevelMutation,
  useRequestTokenForSystemQuery,
  useLazyRequestTokenForSystemQuery,
  useCreateLegacyTransferMutation,
  useGetTransferQuery,
  useLazyGetTransferQuery,
  useCreateTransferMutation,
  useUpdateTransferMutation,
  useAllocateTransferMutation,
  useUnallocateTransferMutation,
  useTransferOutMutation,
  useTransferInMutation,
  useUpdateJobStateTransferMutation,
  useUpdateJobWarehouseTransferMutation,
  useAssignResourceGroupsMutation,
  useListStorageContextsQuery,
  useLazyListStorageContextsQuery,
  useGetDataForStorageContextQuery,
  useLazyGetDataForStorageContextQuery,
  useUpdateStorageContextMutation,
  useDeleteStorageContextMutation,
  useGetAllActiveDirectoryUsersQuery,
  useLazyGetAllActiveDirectoryUsersQuery,
  useRenderHandlebarsTemplateMutation,
  useGetWarehouseQuery,
  useLazyGetWarehouseQuery,
  useCreateWarehouseMutation,
  useUpdateWarehouseMutation,
  useGetZoneQuery,
  useLazyGetZoneQuery,
  useCreateZoneMutation,
  useCreateAdvancedShipmentAsyncMutation: useCreateAdvancedShipmentMutationAsync,
  useCreateBinAsyncMutation: useCreateBinMutationAsync,
  useUpdateBinAsyncMutation: useUpdateBinMutationAsync,
  useUpdateBulkAsyncMutation: useUpdateBulkMutationAsync,
  useCreateEntityAttachmentAsyncMutation: useCreateEntityAttachmentMutationAsync,
  useDeleteEntityAttachmentAsyncMutation: useDeleteEntityAttachmentMutationAsync,
  useCreateInboundAsyncMutation: useCreateInboundMutationAsync,
  useReinboundAsyncMutation: useReinboundMutationAsync,
  useMoveToCartAsyncMutation: useMoveToCartMutationAsync,
  useResolveAsyncMutation: useResolveMutationAsync,
  useCreateOutboundAsyncMutation: useCreateOutboundMutationAsync,
  useUpdateOutboundAsyncMutation: useUpdateOutboundMutationAsync,
  useUpdateJobWarehouseOutboundAsyncMutation: useUpdateJobWarehouseOutboundMutationAsync,
  useAllocateOutboundAsyncMutation: useAllocateOutboundMutationAsync,
  useUnallocateOutboundAsyncMutation: useUnallocateOutboundMutationAsync,
  useUpdateShippingInformationAsyncMutation: useUpdateShippingInformationMutationAsync,
  useUpdateJobStateOutboundAsyncMutation: useUpdateJobStateOutboundMutationAsync,
  useCompleteTransferAsyncMutation: useCompleteTransferMutationAsync,
  useScrapAsyncMutation: useScrapMutationAsync,
  useMoveAsyncMutation: useMoveMutationAsync,
  useFoundAsyncMutation: useFoundMutationAsync,
  useLostAsyncMutation: useLostMutationAsync,
  useHoldAsyncMutation: useHoldMutationAsync,
  useReleaseAsyncMutation: useReleaseMutationAsync,
  useQuarantineAsyncMutation: useQuarantineMutationAsync,
  useUnquarantineAsyncMutation: useUnquarantineMutationAsync,
  useUpdatePartStatusAsyncMutation: useUpdatePartStatusMutationAsync,
  useDefectiveAsyncMutation: useDefectiveMutationAsync,
  useUpdatePartAsyncMutation: useUpdatePartMutationAsync,
  usePutAwayAsyncMutation: usePutAwayMutationAsync,
  usePatchMetadataAsyncMutation: usePatchMetadataMutationAsync,
  useCreateLegacyTransferAsyncMutation: useCreateLegacyTransferMutationAsync,
  useCreateTransferAsyncMutation: useCreateTransferMutationAsync,
  useUpdateTransferAsyncMutation: useUpdateTransferMutationAsync,
  useAllocateTransferAsyncMutation: useAllocateTransferMutationAsync,
  useUnallocateTransferAsyncMutation: useUnallocateTransferMutationAsync,
  useTransferOutAsyncMutation: useTransferOutMutationAsync,
  useTransferInAsyncMutation: useTransferInMutationAsync,
  useUpdateJobStateTransferAsyncMutation: useUpdateJobStateTransferMutationAsync,
  useUpdateJobWarehouseTransferAsyncMutation: useUpdateJobWarehouseTransferMutationAsync,
} = api;
