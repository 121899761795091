import { AccountInfo, AuthenticationResult, PublicClientApplication, AuthError, BrowserAuthError, BrowserAuthErrorCodes } from '@azure/msal-browser';
import { right, left, Either, isLeft } from 'fp-ts/Either';
import type { Identity, LogoutSettings, LoginSettings, RefreshSettings } from 'viewmodels';
import { merge } from 'lodash';
import { parseAccessToken } from '../utils';

export type MSALConfig = {
  clientId: string;
  authority: string;
  scopes: Array<string>;
};

export const AzureAuth = {
  create: (msalConfig: MSALConfig) => {
    return new AuzureAuthProvider(msalConfig);
  },
};

const defaultOptions = {
  redirectUri: window.location.origin,
};

export const IDLE_TIMEOUT: number = Application.env.VITE_IDLE_TIMEOUT_SECONDS * 1000;
export const PROMOT_BEFORE_IDLE: number = Application.env.VITE_IDLE_TIMEOUT_PROMPT_REMAINING_SECONDS * 1000;
export const LOCAL_STORAGE_KEY = 'lastActivity';

class AuzureAuthProvider {
  private msalApplication: PublicClientApplication;
  private loginResponse: AuthenticationResult | null;
  private msalConfig: MSALConfig;
  private readLoginResponse: boolean;

  constructor(msalConfig: MSALConfig) {
    this.readLoginResponse = false;
    this.loginResponse = null;
    this.msalConfig = msalConfig;
    this.msalApplication = new PublicClientApplication({
      auth: {
        clientId: msalConfig.clientId,
        authority: msalConfig.authority,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    });
  }

  initialize = async () => {
    await this.msalApplication.initialize();
  };

  login = async (options?: LoginSettings): Promise<Either<AuthError, Identity>> => {
    await this.initialize();

    const settings = { ...defaultOptions, ...options };
    const { forceRefresh } = settings;

    if (!this.loginResponse || forceRefresh) {
      const result = await this.signin(settings);
      if (isLeft(result)) {
        return result;
      }

      this.loginResponse = result.right;
    }

    const res = mapAuthResultToUserInfo(this.loginResponse);
    return right(res);
  };

  logout = async (options?: LogoutSettings): Promise<Either<AuthError, boolean>> => {
    await this.initialize();

    const { redirectUri } = { ...defaultOptions, ...options };
    const account = this.getAccount();
    if (!account) {
      return left(new BrowserAuthError(BrowserAuthErrorCodes.noAccountError));
    }

    try {
      await this.msalApplication.logout({ account, postLogoutRedirectUri: redirectUri });

      this.loginResponse = null;
      return right(true);
    } catch (e: any) {
      return left(e);
    }
  };

  refreshToken = async (options?: RefreshSettings): Promise<Either<AuthError, Identity>> => {
    await this.initialize();

    const account = this.getAccount();
    if (!account) {
      return left(new BrowserAuthError(BrowserAuthErrorCodes.noAccountError));
    }

    const { scopes } = this.msalConfig;
    const request = merge({ scopes, account, forceRefresh: true }, options);

    try {
      this.loginResponse = await this.msalApplication.acquireTokenSilent(request);

      const res = mapAuthResultToUserInfo(this.loginResponse);

      return right(res);
    } catch (e: any) {
      return left(e);
    }
  };

  passwordRefresh = async (options: any): Promise<Either<AuthError, AuthenticationResult>> => {
    try {
      const {
        msalConfig: { scopes },
      } = this;
      const { redirectUri } = options;

      const extraQueryParameters = {
        max_age: '0',
      };

      await this.msalApplication.acquireTokenRedirect({ scopes, redirectUri, extraQueryParameters });
      return left(new AuthError('login_redirect'));
    } catch (e: any) {
      return left(e);
    }
  };

  // TODO: need to update the variable names and reduce this to make it more readable
  private async signin(options: LoginSettings): Promise<Either<AuthError, AuthenticationResult>> {
    try {
      const {
        msalConfig: { scopes },
      } = this;
      const { redirectUri, popup, forceRefresh } = options;

      // are we here from a redirect?
      let result: AuthenticationResult | null = null;
      if (!this.readLoginResponse) {
        this.readLoginResponse = true;
        result = await this.msalApplication.handleRedirectPromise();
      }

      const account = this.getAccount();
      if (!result && account && !forceRefresh) {
        result = await this.msalApplication.acquireTokenSilent({ scopes, account, redirectUri });
      }

      if (!result && popup) {
        result = await this.msalApplication.acquireTokenPopup({ redirectUri, scopes });
      }

      if (result) {
        // temporary disable the password refresh flow
        if (1 + 1 === 2) {
          return right(result);
        }

        const lastActivity = parseInt(localStorage.getItem(LOCAL_STORAGE_KEY) || '0');
        if (lastActivity === 0) {
          return right(result);
        }
        // idle redirect flows
        // 1. periodically log the LAST_ACTIVITY timestamp
        // 2. on idle, redirect to the signin page with a max_age=0
        // 3. on sign in, check the LAST_ACTIVITY timestamp.
        //     If the LAST_ACTIVITY is LESS than the IDLE_TIMEOUT, then the user is good to go.
        //     if the LAST_ACTIVITY is GREATER than the IDLE_TIMEOUT, and the token's auth_time value is less than the IDLE_TIMEOUT, then the user is good to go.
        //     if the LAST_ACTIVITY is GREATER than the IDLE_TIMEOUT, and the token's auth_time value is GREATER than the IDLE_TIMEOUT, then redirect to the signin page with max_page=0
        const idleTimeoutSeconds = IDLE_TIMEOUT / 1000;
        const userInfo = mapAuthResultToUserInfo(result);
        const currentTimestamp = Math.ceil(Date.now() / 1000);
        const secondsSinceLastActivity = currentTimestamp - lastActivity;
        const authTimestamp = userInfo.auth_time || 0;
        if (authTimestamp === 0) {
          console.warn('auth_time is 0');
        }
        const secondsSinceAuth = currentTimestamp - authTimestamp;

        const isUserActive = secondsSinceLastActivity <= idleTimeoutSeconds;
        if (isUserActive) {
          return right(result);
        }

        const passwordRecentlyRefreshed = secondsSinceAuth < idleTimeoutSeconds;
        if (passwordRecentlyRefreshed) {
          return right(result);
        }

        // user must revalidate
        return await this.passwordRefresh(options);
      }

      // the next line causes a redirect but we return a left to make js happy! :)
      await this.msalApplication.acquireTokenRedirect({ scopes, redirectUri });
      return left(new AuthError('login_redirect'));
    } catch (e: any) {
      return left(e);
    }
  }

  private getAccount(): AccountInfo | null {
    if (this.loginResponse && this.loginResponse.account) {
      return this.loginResponse.account;
    }

    const currentAccounts = this.msalApplication.getAllAccounts();
    if (currentAccounts.length === 0) {
      return null;
    }

    return currentAccounts[0];
  }
}

function mapAuthResultToUserInfo(authResult: AuthenticationResult): Identity {
  const { accessToken: token } = authResult;

  const { exp, name: fullname, given_name: givenName, family_name: familyName, email, roles, auth_time } = parseAccessToken(token);

  return {
    fullname,
    givenName,
    familyName,
    email,
    pic: '',
    roles,
    auth_time,
    token: {
      value: token,
      exp,
    },
  };
}
