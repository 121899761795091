import React, { useContext, useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { PromptDialog } from '../PromptDialog';
import { noop } from 'lodash';

export const NavBlockerContext = React.createContext<{
  isDirty: boolean;
  setIsDirty: (dirty: boolean) => void;
  stopBlocking: () => void;
  startBlocking: () => void;
}>({
  isDirty: false,
  setIsDirty: () => {},
  stopBlocking: () => {},
  startBlocking: () => {},
});

export const NavBlockerProvider = ({ children }: React.PropsWithChildren<any>) => {
  /* const navigator = useContext(UNSAFE_NavigationContext).navigator as History;
  const navigate = useNavigate(); */
  const [showPrompt, setShowPrompt] = useState(false);
  const continueRef = useRef<() => void>();
  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    continueRef.current && continueRef.current();
  }, []);

  const contextValue = useMemo(() => {
    let isDirty = false;
    let isBlocking = true;
    let unblock: (() => void) | null = null;

    return {
      setIsDirty(value: boolean) {
        if (!isBlocking) {
          return;
        }

        if (value === isDirty) {
          return;
        }

        isDirty = value;

        if (!isDirty) {
          unblock && unblock();
          unblock = null;
          return;
        }

        try {
          // DISABLED UNTIL FIXED
          /* unblock = navigator.block((tx: Transition) => {
            continueRef.current = () => {
              unblock && unblock();
              isDirty = false;
              navigate(tx.location.pathname);
            };
            setShowPrompt(true);
          }); */
          noop();
        } catch (e) {
          console.error(e);
        }
      },
      get isDirty() {
        return isDirty;
      },
      stopBlocking() {
        this.setIsDirty(false);
        isBlocking = false;
      },
      startBlocking() {
        isBlocking = true;
      },
    };
  }, []);

  return (
    <>
      <NavBlockerContext.Provider value={contextValue}>{children}</NavBlockerContext.Provider>
      <PromptDialog showDialog={showPrompt} confirmNavigation={confirmNavigation} cancelNavigation={cancelNavigation} />
    </>
  );
};

export function useNavBlocker() {
  const navBlocker = useContext(NavBlockerContext);

  useEffect(() => {
    return () => {
      navBlocker.setIsDirty(false);
      navBlocker.startBlocking();
    };
  }, [navBlocker]);

  return navBlocker;
}
