import { createSelector } from '@reduxjs/toolkit';
import type { ApplicationState } from 'viewmodels/application';
import { Policies } from 'viewmodels/Policies';
import type { UserState } from 'viewmodels/UserState';
import { Permissions } from 'viewmodels/Permissions';

export { selectClientEnv, selectServerEnv, selectApplicationStatus } from '../reducers/applicationReducer';

export const selectApplicationUser = createSelector(
  (state: { user: UserState; application: ApplicationState }) => state.user.profile,
  (state: { user: UserState; application: ApplicationState }) => state.application.rolesOverride,
  (profile, rolesOverride) => {
    if (!profile) {
      return;
    }

    if (!Policies.RoleImpersonationPolicy.evaluate(profile)) {
      return profile;
    }

    const roles = rolesOverride?.map((x) => x.role) ?? profile.roles;
    const permissions =
      rolesOverride?.reduce((permissions, role) => [...permissions, ...role.permissions], [Permissions.SystemRoleImpersonation]) ??
      profile.permissions;

    return {
      ...profile,
      roles,
      permissions,
    };
  },
);
