import { Policy } from './Policy';
import { Permissions } from './Permissions';

const ExpireAllTokens = Policy.all('ExpireAllTokens', Permissions.AdminExpireAllTokens);

const DevTeamPolicy = Policy.some('DevTeamPolicy', Permissions.DeveloperConsole);
const RoleImpersonationPolicy = Policy.some('RoleImpersonationPolicy', Permissions.SystemRoleImpersonation);

const DockReceiptsList = Policy.all('DockReceiptsList', Permissions.DockReceiptsList);
const DockReceiptsCreate = Policy.all('DockReceiptsCreate', Permissions.DockReceiptsCreate, DockReceiptsList);
const DockReceiptsView = Policy.all('DockReceiptsView', Permissions.DockReceiptsRead);
const DockReceiptsDelete = Policy.all('DockReceiptsDelete', Permissions.DockReceiptsDelete);
const DockReceiptsChangeStatus = Policy.all('DockReceiptsChangeStatus', Permissions.DockReceiptsChangeStatus);
const DeliveryReceiptsCreate = Policy.all('DeliveryReceiptsCreate', Permissions.DeliveryReceiptsCreate);
const DeliveryReceiptsRead = Policy.all('DeliveryReceiptsRead', Permissions.DeliveryReceiptsRead);
const DockReceiptsRemoveDelivery = Policy.all('DockReceiptsRemoveDelivery', Permissions.DockReceiptsRemoveDelivery);

const InboundMasterPartsCreate = Policy.all('InboundMasterPartsCreate', Permissions.MasterPartsCreateInbound);
const Inbound = Policy.all('Inbound', Permissions.InboundCreate, Permissions.MasterPartsCreateInbound);
const ReInboundLPN = Policy.all('ReInboundLPN', Permissions.InboundReinbound);
const CustomerList = Policy.all('CustomerList', Permissions.CustomersList);

const MasterPartsList = Policy.all('MasterPartsList', Permissions.MasterPartsList);
const MasterPartsCreate = Policy.all('MasterPartsCreate', Permissions.MasterPartsCreate);
const MasterPartsView = Policy.all('MasterPartsView', MasterPartsList, Permissions.MasterPartsRead);
const MasterPartsEdit = Policy.all('MasterPartsEdit', MasterPartsView, Permissions.MasterPartsUpdate);
const MasterpartsAccept = Policy.all('MasterpartsAccept', Permissions.MasterPartsUpdateMasterPartStatus);
const MasterpartsEscalate = Policy.all('MasterpartsEscalate', Permissions.MasterPartsUpdateMasterPartStatus);
const MasterPartsMerge = Policy.all('MasterPartsMerge', Permissions.MasterPartsMerge, Permissions.MasterPartsUpdateMasterPartStatus);
const MasterPartsNotAcceptedEdit = Policy.all('MasterPartsNotAcceptedEdit', Permissions.MasterPartsUpdatePartNotInMaster);
const MasterPartsNotAcceptedMerge = Policy.all(
  'MasterPartsNotAcceptedMerge',
  Permissions.MasterPartsMergePartNotInMaster,
  Permissions.MasterPartsUpdateMasterPartStatus,
);

const MasterPartUpdateTemplate = Policy.all('MasterPartUpdateTemplate', Permissions.MasterPartsUpdateTemplate);
const MasterPartsInitializeUpdateTemplate = Policy.all('MasterPartsInitializeUpdateTemplate', Permissions.MasterPartsInitializeUpdateTemplate);
const PartNotInMasterUpdateTemplate = Policy.some('PartNotInMasterUpdateTemplate', MasterPartUpdateTemplate, MasterPartsInitializeUpdateTemplate);
const MasterPartsHistory = Policy.all('MasterPartsHistory', Permissions.MasterPartsHistory);
const MasterPartTemplatesCreate = Policy.all('MasterPartTemplatesCreate', Permissions.MasterPartTemplatesCreate);
const MasterPartTemplatesList = Policy.all('MasterPartTemplatesList', Permissions.MasterPartTemplatesList);
const MasterPartTemplateUpdate = Policy.all('MasterPartTemplateEdit', Permissions.MasterPartTemplatesUpdate);
// const MasterPartTemplateDelete = Policy.all('MasterPartTemplateDelete', Permissions.MasterPartTemplatesDelete);
const MasterPartManagement = Policy.all('MasterPartManagement', Permissions.ManagementMasterParts, MasterPartsList);
const PartNotInMasterManagement = Policy.all('PartNotInMasterManagement', Permissions.ManagementMasterPartNotInMaster);
const MIRList = Policy.all('MIRList', Permissions.MIRList);
const MIRView = Policy.all('MIRView', Permissions.MIRList);
const MIREdit = Policy.all('MIREdit', Permissions.MIRPartsUpdate);
const MIRScrap = Policy.all('MIRScrap', Permissions.PartsScrap);
const MIRResolve = Policy.all('MIRResolve', Permissions.MIRResolve);
const MIRManagement = Policy.all('MIRManagement', Permissions.MIRList, Permissions.InventoryReportList);
const MIRPartRecon = Policy.all('MIRPartRecon', Permissions.TemplateMismatchedPartsList);

const ExternalApplicationsCreate = Policy.all('ExternalApplicationsCreate', Permissions.ExternalApplicationsCreate);
const ExternalApplicationsList = Policy.all('ExternalApplicationsList', Permissions.ExternalApplicationsList);
const ExternalApplicationsUpdate = Policy.all('ExternalApplicationsUpdate', Permissions.ExternalApplicationsUpdate);
const ExternalApplicationsView = Policy.all('ExternalApplicationsView', Permissions.ExternalApplicationsRead);

const DeliveryRecipientsCreate = Policy.all('DeliveryRecipientsCreate', Permissions.DeliveryRecipientsCreate);
const DeliveryRecipientsList = Policy.all('DeliveryRecipientsList', Permissions.DeliveryRecipientsList);
const DeliveryRecipientsUpdate = Policy.all('DeliveryRecipientsUpdate', Permissions.DeliveryRecipientsUpdate);
const DeliveryRecipientsView = Policy.all('DeliveryRecipientsView', Permissions.DeliveryRecipientsRead);
const DeliveryRecipientsManagement = Policy.all('DeliveryReceipipientsManagement', Permissions.ManagementRecipients);

const ApiAccessManagement = Policy.all('ApiAccessManagement', Permissions.ManagementApiAccess, ExternalApplicationsList);

const IncomingJobsList = Policy.all('IncomingJobsList', Permissions.IncomingJobsList);
const OutgoingJobsList = Policy.all('OutgoingJobsList', Permissions.OutgoingJobsList);

const TransferIn = Policy.all('TransferIn', Permissions.TransfersTransferIn);
const TransferOut = Policy.all('TransferOut', Permissions.TransfersTransferOut);
const TransfersRead = Policy.all('TransfersRead', Permissions.TransfersRead);

const ConversationsList = Policy.all('ConversationsList', Permissions.ConversationsRead);
const ConversationsCreate = Policy.all('ConversationsCreate', Permissions.ConversationsAddConversationMessage);
const ConversationsView = Policy.all('ConversationsView', Permissions.ConversationsAddConversationMessage);
const Conversations = Policy.some('Conversations', ConversationsList, ConversationsCreate, ConversationsView);

const LPNList = Policy.all('LPNList', Permissions.PartsList);
const LPNView = Policy.all('LPNView', Permissions.PartsRead);
const LPNEdit = Policy.all('LPNEdit', Permissions.PartsUpdate);
const LPNScrap = Policy.all('LPNScrap', Permissions.PartsScrap);
const LPNDefective = Policy.all('LPNDefective', Permissions.PartsDefective);
const LPNHistory = Policy.all('LPNHistory', Permissions.PartsRead, Permissions.PartTransactionsList);
const LPNPrintLabel = Policy.all('LPNPrintLabel', Permissions.PartsPrintLabel);
const LPNMove = Policy.all('LPNMove', Permissions.PartsMove, Permissions.CartsList, Permissions.BinsList);
const LPNLost = Policy.all('LPNLost', Permissions.PartsLost);
const LPNLostOutbound = Policy.all('LPNLostOutbound', Permissions.PartsLostOutbound);
const LPNFound = Policy.all('LPNFound', Permissions.PartsFound);
const LPNRelease = Policy.all('LPNRelease', Permissions.PartsRelease);
const LPNHold = Policy.all('LPNHold', Permissions.PartsHold);
const LPNUpdateStatus = Policy.all('LPNUpdateStatus', Permissions.PartsUpdatePartStatus);
const LPNNotFoundManagement = Policy.all('LPNNotFoundManagement', Permissions.ManagementNotFound);
const LPNTransfer = Policy.all('LPNLost', Permissions.TransfersCreateLegacyTransfer);

const InventoryReportView = Policy.all('InventoryReportView', Permissions.InventoryReportList);
const InventoryReportViewItem = Policy.all('InventoryReportViewItem', LPNView, LPNHistory);
const InventoryReport = Policy.all('InventoryReport', InventoryReportView);
const InventoryReportActions = Policy.some(
  'InventoryReportActions',
  LPNHold,
  LPNRelease,
  LPNLost,
  LPNFound,
  LPNMove,
  LPNEdit,
  LPNPrintLabel,
  LPNScrap,
);

const BinsList = Policy.all('BinsList', Permissions.BinsList);
const BinsCreate = Policy.all('BinsCreate', Permissions.BinsCreate);
const BinsView = Policy.all('BinsView', Permissions.BinsRead);
const BinsEdit = Policy.all('BinsEdit', Permissions.BinsUpdate);
const BinManagement = Policy.all('BinManagement', Permissions.ManagementBins, BinsList, BinsCreate, BinsView, BinsEdit);

const CustomersList = Policy.all('CustomersList', Permissions.CustomersList);
const CustomersCreate = Policy.all('CustomersCreate', Permissions.CustomersCreate);
const CustomersView = Policy.all('CustomersView', Permissions.CustomersRead);
const CustomersEdit = Policy.all('CustomersEdit', Permissions.CustomersUpdate);
const CustomerManagement = Policy.all('CustomerManagement', Permissions.ManagementCustomers, CustomersList);

const StockLevelsList = Policy.all('StockLevelsList', Permissions.StockLevelsList);
const StockLevelsCreate = Policy.all('StockLevelsCreate', Permissions.StockLevelsCreate);
const StockLevelsUpdate = Policy.all('StockLevelsUpdate', Permissions.StockLevelsUpdate);
const StockLevelsDelete = Policy.all('StockLevelsDelete', Permissions.StockLevelsDelete);
const StockLevelsManagement = Policy.all('StockLevelsManagement', Permissions.ManagementStockLevels);

const OutboundList = Policy.all('OutboundList', Permissions.OutboundList);
const BulkOutbound = Policy.all('BulkOutbound', Permissions.OutboundBulkOutbound);
const OutboundExecute = Policy.all('OutboundExecute', Permissions.OutboundUpdateShippingInformation);
const OutboundCompleteTransfer = Policy.all('OutboundCompleteTransfer', Permissions.OutboundCompleteTransfer);
const OutboundUpdateJobState = Policy.all('OutboundUpdateJobState', Permissions.OutboundUpdateJobState);

const WarehousesList = Policy.all('WarehousesList', Permissions.WarehousesList);
const WarehousesCreate = Policy.all('WarehousesCreate', Permissions.WarehousesCreate);
const WarehousesView = Policy.all('WarehousesView', Permissions.WarehousesRead);
const WarehousesEdit = Policy.all('WarehousesEdit', Permissions.WarehousesUpdate);
const WarehouseManagement = Policy.all('WarehouseManagement', Permissions.ManagementWarehouses, WarehousesList);

const CartsList = Policy.all('CartsList', Permissions.CartsList);
const CartsCreate = Policy.all('CartsCreate', Permissions.CartsCreate);
const CartsView = Policy.all('CartsView', Permissions.CartsRead);
const CartsEdit = Policy.all('CartsEdit', Permissions.CartsCreate);
const CartManagement = Policy.all('CartManagement', Permissions.ManagementCarts, CartsList, CartsCreate, CartsView, CartsEdit);

const CarriersList = Policy.all('CarriersList', Permissions.CarriersList);
const CarriersCreate = Policy.all('CarriersCreate', Permissions.CarriersCreate);
const CarriersView = Policy.all('CarrierssView', Permissions.CarriersRead);
const CarriersEdit = Policy.all('CarriersEdit', Permissions.CarriersUpdate);
const CarrierManagement = Policy.all('CarrierManagement', Permissions.ManagementCarriers);

const NotificationChannelsList = Policy.all('NotificationChannelsList', Permissions.NotificationChannelsList);
const NotificationChannelsCreate = Policy.all(
  'NotificationChannelsCreate',
  Permissions.NotificationChannelsCreate,
  Permissions.NotificationMessageTemplatesList,
);
const NotificationChannelsDelete = Policy.all('NotificationChannelsDelete', Permissions.NotificationChannelsDelete);
const NotificationChannelsEdit = Policy.all(
  'NotificationChannelsEdit',
  Permissions.NotificationChannelsUpdate,
  Permissions.NotificationChannelsUpdateChannelMappings,
  Permissions.NotificationMessageTemplatesList,
);

const NotificationClientsList = Policy.all('NotificationClientsList', Permissions.NotificationClientsList);
const NotificationClientsCreate = Policy.all('NotificationClientsCreate', Permissions.NotificationClientsCreate);
const NotificationClientsDelete = Policy.all('NotificationClientsDelete', Permissions.NotificationClientsDelete);

const NotificationsManagement = Policy.all('NotificationsManagement', Permissions.ManagementNotifications, NotificationClientsList);

const PrintersManagement = Policy.all('PrintersList', Permissions.ManagementPrinters);

const PutAway = Policy.all('PutAway', Permissions.WarehousesList, Permissions.PutAwayJobsList, Permissions.PartsMove);

const UsersAssignResourceGroups = Policy.all('UsersAssignResourceGroups', Permissions.UsersAssignResourceGroups);
const UsersManagement = Policy.all('UsersManagement', Permissions.ManagementUsers, Permissions.UsersList, UsersAssignResourceGroups);
const UserExemptFromIdleTimeout = Policy.all('UserExemptFromIdleTimeout', Permissions.SecurityIdleTimeoutExempt);

const RoleManagementReader = Policy.all('RoleManagementReader', Permissions.PermissionsReadAllPermissions, Permissions.PermissionsReadRoleMappings);

const RoleManagementWriter = Policy.all('RoleManagementWriter', Permissions.PermissionsUpdateRolePermissions);

const RoleManagement = Policy.all('RoleManagement', Permissions.ManagementPermissions, RoleManagementReader, RoleManagementWriter);

const PermissionsReset = Policy.all('Reset Permissions', Permissions.PermissionsResetRoleMappings);

const PermissionsCreateAppRole = Policy.all('Create App Role', Permissions.PermissionsCreateAppRole);

const PermissionsSyncAdUsers = Policy.all('Reset Permissions', Permissions.PermissionsSyncADUsers);

const Management = Policy.some('Management', CartManagement, WarehouseManagement, CustomerManagement, BinManagement, MasterPartManagement);

const Maintenance = Policy.some('Maintenance', BulkOutbound);

const ExceptionMagement = Policy.some('ExceptionManagement', LPNNotFoundManagement, MIRManagement, PartNotInMasterManagement);

const TransactionsReport = Policy.all('TransactionsReport', Permissions.PartTransactionsList);

const DashboardReports = Policy.all('DashboardReports', Permissions.DashboardReadEmbeddedDashboards);

const PatchMetadata = Policy.all('PatchMetadata', Permissions.PartTransactionBatchesPatchMetadata);

const QuarantineParts = Policy.all('QuarantineParts', Permissions.PartsQuarantine);

const UnquarantineParts = Policy.all('UnquarantineParts', Permissions.PartsUnquarantine);

const HangfireDashboard = Policy.all('HangfireDashboard', Permissions.HangfireDashboard);

const BulkMoveParts = Policy.all('QuarantineParts', Permissions.PartsBulkMove);

const BulkJustifyParts = Policy.all('BulkJustifyParts', Permissions.PartsBulkJustify);

const CreateDataSubscription = Policy.all('DataSubscriptionCreate', Permissions.DataSubscriptionsCreate);
const ReadDataSubscription = Policy.all('DataSubscriptionRead', Permissions.DataSubscriptionsRead);
const UpdateDataSubscription = Policy.all('DataSubscriptionUpdate', Permissions.DataSubscriptionsUpdate);
const DeleteDataSubscription = Policy.all('DataSubscriptionDelete', Permissions.DataSubscriptionsDelete);
const DataSubscriptionList = Policy.all('DataSubscriptionDelete', Permissions.DataSubscriptionsList);
const DataSubscriptionJobsList = Policy.all('DataSubscriptionJobsList', Permissions.DataSubscriptionJobsList);
const DataSubscriptionsManagement = Policy.all('DataSubscriptionManagement', Permissions.ManagementDataSubscriptions, DataSubscriptionList);
const DataSubscriptionJobsCreateJob = Policy.all('DataSubscriptionJobRetry', Permissions.DataSubscriptionJobsCreateJob);

const AnnouncementsCreate = Policy.all('AnnouncementsCreate', Permissions.AnnouncementsCreate);
const AnnouncementsRead = Policy.all('AnnouncementsRead', Permissions.AnnouncementsRead);
const AnnouncementsList = Policy.all('AnnouncementsList', Permissions.AnnouncementsList);
const AnnouncementsDelete = Policy.all('AnnouncementsDelete', Permissions.AnnouncementsDelete);
const AnnouncementsManagement = Policy.all('AnnouncementsManagement', Permissions.ManagementAnnouncements, AnnouncementsList);

const ZonesRead = Policy.all('ZonesRead', Permissions.ZonesRead);
const ZonesCreate = Policy.all('ZonesCreate', Permissions.ZonesCreate);
const ZonesList = Policy.all('ZonesList', Permissions.ZonesList);

const ApplicationLogs = Policy.all('ApplicationLogs', Permissions.ManagementApplicationLogs);

const GenerateReport = Policy.all('Generate Report', Permissions.ReportsGenerateReport);

const ResourceGroupsList = Policy.all('ResourceGroupsList', Permissions.ResourceGroupsList);
const ResourceGroupsCreate = Policy.all('ResourceGroupsCreate', Permissions.ResourceGroupsCreate);
const ResourceGroupsDelete = Policy.all('ResourceGroupsDelete', Permissions.ResourceGroupsDelete);
const ResourceGroupsRead = Policy.all('ResourceGroupsRead', Permissions.ResourceGroupsRead);
const ResourceGroupsUpdate = Policy.all('ResourceGroupsUpdate', Permissions.ResourceGroupsUpdate);
const ResourceGroupManagement = Policy.all('ResourceGroupManagement', Permissions.ManagementResourceGroups, ResourceGroupsList);

const CycleCountList = Policy.all('CycleCountList', Permissions.BinsList);
const CycleCountManagement = Policy.all('CycleCountManagement', Permissions.ManagementCycleCounts);

const DeveloperSkipJobNumberCheck = Policy.all('DeveloperSkipJobNumberCheck', Permissions.DeveloperSkipJobNumberCheck);

const BulkActions = Policy.some('BulkActions', LPNPrintLabel, BulkOutbound, BulkMoveParts, LPNScrap);

const SystemManagement = Policy.some(
  'SystemManagement',
  AnnouncementsManagement,
  NotificationsManagement,
  PrintersManagement,
  RoleManagement,
  UsersManagement,
  ApplicationLogs,
  ApiAccessManagement,
  DataSubscriptionsManagement,
  ResourceGroupManagement,
);

export const Policies = {
  DevTeamPolicy,
  RoleImpersonationPolicy,

  // Dockreceipts
  DockReceiptsList,
  DockReceiptsCreate,
  DockReceiptsView,
  DockReceiptsDelete,
  DockReceiptsRemoveDelivery,
  DockReceiptsChangeStatus,
  DeliveryReceiptsCreate,
  DeliveryReceiptsRead,

  //ExternalApplications
  ExternalApplicationsCreate,
  ExternalApplicationsList,
  ExternalApplicationsUpdate,
  ExternalApplicationsView,

  //ResourceGroups
  ResourceGroupsList,
  ResourceGroupsCreate,
  ResourceGroupsUpdate,
  ResourceGroupsRead,
  ResourceGroupsDelete,
  ResourceGroupManagement,

  //Jobs
  IncomingJobsList,
  OutgoingJobsList,

  //ApiAccess
  ApiAccessManagement,

  //Transfers
  TransferIn,
  TransferOut,
  TransfersRead,

  // customers
  CustomerList,

  // Inbound
  ReInboundLPN,
  InboundMasterPartsCreate,
  Inbound,

  // Masterparts
  MasterPartsList,
  MasterPartsCreate,
  MasterPartsView,
  MasterPartsEdit,
  MasterpartsAccept,
  MasterpartsEscalate,
  MasterPartsMerge,
  MasterPartManagement,
  MasterPartsNotAcceptedEdit,
  MasterPartsNotAcceptedMerge,
  MasterPartTemplatesCreate,
  MasterPartTemplatesList,
  MasterPartTemplateUpdate,
  MasterPartsHistory,
  MasterPartUpdateTemplate,
  MasterPartsInitializeUpdateTemplate,
  PartNotInMasterUpdateTemplate,

  // Notifications
  NotificationChannelsCreate,
  NotificationChannelsEdit,
  NotificationChannelsList,
  NotificationChannelsDelete,
  NotificationsManagement,
  NotificationClientsCreate,
  NotificationClientsDelete,
  NotificationClientsList,

  // MIR
  MIRList,
  MIRView,
  MIREdit,
  MIRScrap,
  MIRResolve,
  MIRManagement,
  PartNotInMasterManagement,
  MIRPartRecon,

  // Conversations
  Conversations,
  ConversationsList,
  ConversationsView,
  ConversationsCreate,

  // Inventory report
  InventoryReport,
  InventoryReportView,
  InventoryReportViewItem,
  InventoryReportActions,

  // Parts
  LPNList,
  LPNView,
  LPNEdit,
  LPNHistory,
  LPNScrap,
  LPNPrintLabel,
  LPNMove,
  LPNLost,
  LPNLostOutbound,
  LPNFound,
  LPNRelease,
  LPNHold,
  LPNUpdateStatus,
  LPNNotFoundManagement,
  LPNDefective,
  LPNTransfer,
  QuarantineParts,
  UnquarantineParts,
  BulkMoveParts,
  BulkJustifyParts,

  // Printers
  PrintersManagement,

  // Bins
  BinsList,
  BinsCreate,
  BinsView,
  BinsEdit,
  BinManagement,

  // Customers
  CustomersList,
  CustomersCreate,
  CustomersView,
  CustomersEdit,
  CustomerManagement,

  // Warehouses
  WarehousesList,
  WarehousesCreate,
  WarehousesView,
  WarehousesEdit,
  WarehouseManagement,

  // Carts
  CartsList,
  CartsCreate,
  CartsView,
  CartsEdit,
  CartManagement,

  // Carriers
  CarriersList,
  CarriersCreate,
  CarriersView,
  CarriersEdit,
  CarrierManagement,

  // Put Away
  PutAway,

  // Management
  Management,
  SystemManagement,

  // Maintenance,
  Maintenance,

  // Exception Management
  ExceptionMagement,

  // TransactionReport
  TransactionsReport,
  PatchMetadata,

  // Users
  UsersManagement,
  UsersAssignResourceGroups,
  UserExemptFromIdleTimeout,

  //Roles
  RoleManagement,
  RoleManagementReader,
  RoleManagementWriter,

  PermissionsReset,
  PermissionsCreateAppRole,
  PermissionsSyncAdUsers,

  //Outbound
  OutboundList,
  BulkOutbound,
  OutboundExecute,
  OutboundCompleteTransfer,
  OutboundUpdateJobState,

  // Dashboards
  DashboardReports,

  // Hangfire
  HangfireDashboard,

  //Data Subscriptions
  CreateDataSubscription,
  ReadDataSubscription,
  UpdateDataSubscription,
  DeleteDataSubscription,
  DataSubscriptionList,
  DataSubscriptionsManagement,
  DataSubscriptionJobsCreateJob,
  DataSubscriptionJobsList,

  //Announcements
  AnnouncementsCreate,
  AnnouncementsDelete,
  AnnouncementsList,
  AnnouncementsManagement,
  AnnouncementsRead,

  //Zones
  ZonesRead,
  ZonesCreate,
  ZonesList,

  //Logs
  ApplicationLogs,

  ExpireAllTokens,

  GenerateReport,

  //Developers
  DeveloperSkipJobNumberCheck,

  //StockLevels
  StockLevelsDelete,
  StockLevelsCreate,
  StockLevelsUpdate,
  StockLevelsList,
  StockLevelsManagement,

  //DeliveryRecipients
  DeliveryRecipientsCreate,
  DeliveryRecipientsView,
  DeliveryRecipientsUpdate,
  DeliveryRecipientsList,
  DeliveryRecipientsManagement,

  //Cycle Count
  CycleCountList,
  CycleCountManagement,

  //Bulk Actions
  BulkActions,
};

export const PoliciesList = Object.values(Policies);
