import { ServiceLocator } from 'core/services';
import type { WebSocket, MessageEnvelope } from 'core/sockets';
import { noop } from 'lodash';

export interface WaitPredicate<T> {
  (e: Array<MessageEnvelope<T>>): boolean;
}

export function waitForSocketMessage<T = any>(predicate: WaitPredicate<T>, timeout = 10000) {
  const ref = {
    unsub: noop,
  };

  const waitTask = new Promise<Array<MessageEnvelope<T>>>((resolve, reject) => {
    const hub = ServiceLocator.get<WebSocket>('NotificationHub');

    const received: Array<MessageEnvelope<T>> = [];
    ref.unsub = hub.on('*', (e) => {
      try {
        received.push(e);
        if (!predicate(received)) {
          return;
        }

        resolve(received);
      } catch (e) {
        reject(e);
      }
    });

    setTimeout(() => {
      reject(`Timed out after ${timeout}`);
    }, timeout);
  });

  waitTask.finally(() => {
    ref.unsub();
  });

  return waitTask;
}
