/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ApplicationState } from 'viewmodels/application/ApplicationState';
import type { ApplicationStatus } from 'viewmodels/application/ApplicationStatus';
import type { ServerEnvironment } from 'viewmodels/application/ServerEnvironment';

const initialState: ApplicationState = {
  clientEnvironment: {
    environment: Application.env.NODE_ENV,
    clientVersion: Application.env.VITE_IMAGE_VERSION,
  },
  status: 'offline',
};

const slice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setRoleOverrides: (
      state: ApplicationState,
      action: PayloadAction<
        | Array<{
            role: string;
            permissions: Array<string>;
          }>
        | undefined
      >,
    ) => {
      state.rolesOverride = action.payload;
    },
    resetConsole: (state: ApplicationState) => {
      state.rolesOverride = undefined;
    },
    setServerEnv: (state: ApplicationState, action: PayloadAction<ServerEnvironment>) => {
      state.serverEnvironment = action.payload;
    },
    setApplicationStatus: (state: ApplicationState, action: PayloadAction<ApplicationStatus>) => {
      state.status = action.payload;
    },
  },
});

export const selectServerEnv = (state: { application: ApplicationState }) => state.application.serverEnvironment;
export const selectClientEnv = (state: { application: ApplicationState }) => state.application.clientEnvironment;
export const selectApplicationStatus = (state: { application: ApplicationState }) => state.application.status;

export const {
  reducer: applicationReducer,
  actions: { setRoleOverrides, resetConsole, setServerEnv, setApplicationStatus },
} = slice;
