// GENERATED CODE
import { api } from './restApi.generated';
import {
  QueryBuilder,
  ODataQuery,
  Exp,
  BinaryExp,
  GroupExp,
  createODataQuery,
  ScalarComparable,
  NoSkipAndTakeBuilder,
  Entity,
  ColumnsMetadata,
  RecursivePartial,
  extendEntity,
  ColumnMetadata,
} from 'core/OData/QueryBuilder';
import { useMemo, useCallback, DependencyList } from 'react';
import jQuery from 'jquery';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { isArray, isUndefined } from 'lodash';
import type { LoadOptions } from 'devextreme/data';

const PageSize = 100;

// Do not replace with the lodash equivalent.
// The lodash version returns false when given a value of 0
// and that is not correct in the context this function is used.
function isEmpty(val: any) {
  if (isArray(val) && val.length === 0) {
    return true;
  }

  if (val === null || typeof val === 'undefined') {
    return true;
  }

  return false;
}

function isString(val: any): val is string {
  return typeof val === 'string';
}

function optionsToQueryState<Resource>(options: LoadOptions, entity: Entity<Resource>) {
  const state: ODataQuery = { $count: true };
  if (options.filter instanceof Array && !isEmpty(options.filter)) {
    let { filter } = options;
    let filterExp: Exp | undefined = Exp.parse(filter, entity);

    if (filterExp) {
      state.$filter = filterExp;
    }
  } else if (options.searchValue && options.searchExpr) {
    const searchExpr = options.searchExpr instanceof Array ? options.searchExpr : [options.searchExpr];
    state.$filter = searchExpr
      .filter(isString)
      .map((field) => {
        const fieldMetadata: ColumnMetadata = entity.Columns[field];
        if (fieldMetadata.isArray) {
          return new BinaryExp(field, 'any', new BinaryExp('t', options.searchOperation || 'startswith', options.searchValue));
        }

        return new BinaryExp(field, options.searchOperation ?? 'startswith', options.searchValue);
      })
      .reduce((prev: Exp | undefined, exp: Exp) => {
        if (!prev) {
          return new GroupExp([exp]);
        }

        return prev.or.push(exp);
      }, undefined);
  }

  if (typeof options.skip === 'number') {
    state.$skip = options.skip;
  }

  if (typeof options.take === 'number') {
    state.$top = options.take;
  }

  if (options.sort) {
    state.$orderBy = resolveOrderby(options.sort as Array<DataGridSortDescriptor>);
  }

  return state;
}

function mergeQueries(a: ODataQuery, b: ODataQuery) {
  let combinedFilter: Exp | undefined = a.$filter;
  let combinedOrder: Array<{ field: string; desc: boolean }> = [];

  if (b.$filter) {
    combinedFilter = combinedFilter ? new GroupExp([combinedFilter, b.$filter], ['and']) : b.$filter;
  }

  if (a.$orderBy) {
    combinedOrder = [...a.$orderBy];
  }

  if (b.$orderBy) {
    combinedOrder = [...b.$orderBy.filter((x) => !combinedOrder.find((y) => y.field === x.field))];
  }

  return {
    ...a,
    $filter: combinedFilter,
    $orderBy: combinedOrder,
    $raw: a.$raw ?? b.$raw,
  };
}

function resolveOrderby(value?: Array<DataGridSortDescriptor>) {
  if (!value) {
    return [];
  }

  return value.map(({ selector, desc }) => ({ field: selector, desc: desc ?? false }));
}

type DataGridSortDescriptor = {
  selector: string;
  desc?: boolean;
};

type RawODataResponse<T> = {
  value: Array<T>;
  '@odata.count': number;
};

type ODataResponse<T> = {
  value: Array<T>;
  totalCount: number;
};

type ODataStoreSettings<Resource> = {
  key?: string;
  endpointPath: string;
  endpoint: (options: LoadOptions, endpointPath?: string) => Promise<ODataResponse<any>>;
  createOdataQuery: (options: LoadOptions, endpointPath?: string) => string;
  transformOptions?: (options: LoadOptions) => LoadOptions;
  transformResults?: (items: Array<Resource>) => Array<any>;
};

type DataSourceResult<T> = {
  data: Array<T>;
  totalCount: number;
  summary: Array<number>;
};

function createFilterFromListOptions(propertyName: string, operator: keyof ScalarComparable<any, any>, options: LoadOptions) {
  const { filter, searchOperation, searchValue } = options;
  let result = [];

  if (filter && filter.length > 0) {
    result.push(filter);
  }

  if (searchOperation && searchValue) {
    if (result.length > 0) {
      result.push('and');
    }

    result.push([propertyName, searchOperation, searchValue]);
  }

  return result;
}

function filterProperty(exp: Array<any>, propertyName: string): Array<any> | undefined {
  if (exp.length === 0) {
    return undefined;
  }

  if (exp.length === 1) {
    return filterProperty(exp[0], propertyName);
  }

  if (exp.length === 2) {
    const [logicalOp, expression] = exp;
    const e = filterProperty(expression, propertyName);
    if (!e) {
      return;
    }

    return [logicalOp, expression];
  }

  let [left, op, right, ...others] = exp;
  if (left instanceof Array && Exp.isExpression(left)) {
    left = filterProperty(left, propertyName);
  }

  if (right instanceof Array && Exp.isExpression(right)) {
    right = filterProperty(right, propertyName);
  }

  if (left === propertyName || (isUndefined(left) && isUndefined(right))) {
    return filterProperty(others, propertyName);
  }

  if (isUndefined(left) && !isUndefined(right)) {
    return filterProperty([right, ...others], propertyName);
  }

  if (isUndefined(right) && !isUndefined(left)) {
    return filterProperty([left, ...others], propertyName);
  }

  const result = [left, op, right];
  if (others.length > 0) {
    return filterProperty([result, ...others], propertyName);
  }

  return result;
}

function loadData<Result>(
  baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<Result>, FetchBaseQueryError, FetchBaseQueryMeta>>,
  args: {
    query: ODataQuery;
    endpointPath: string;
  },
  aggregatedResult: ODataResponse<Result> = { totalCount: 0, value: [] },
): PromiseLike<QueryReturnValue<ODataResponse<Result>, FetchBaseQueryError, FetchBaseQueryMeta>> {
  const { endpointPath, query } = args;
  const params = { ...query, $top: query.$top ?? PageSize, $skip: query.$skip ?? aggregatedResult.value.length };
  return baseQuery(`${endpointPath}?${createODataQuery(params)}`).then((response) => {
    if (response.error) {
      return {
        error: response.error,
      };
    }

    if (!response.data) {
      return {
        data: aggregatedResult,
      };
    }

    const {
      data: { value, '@odata.count': count },
    } = response;

    const result = {
      totalCount: typeof count === 'undefined' ? aggregatedResult.totalCount : count,
      value: [...aggregatedResult.value, ...value],
    };

    if (typeof query.$top === 'undefined' && result.totalCount > result.value.length) {
      return loadData(baseQuery, args, result);
    }

    return {
      data: result,
    };
  });
}

function useDataStore<Resource>(settings: ODataStoreSettings<Resource>) {
  const ds = useMemo(() => {
    const { key, endpoint, transformOptions, transformResults } = settings;
    const sendRequest = (options: LoadOptions) => {
      const d = jQuery.Deferred();
      endpoint(options)
        .then((data) => {
          if (!data) {
            return d.reject('no data');
          }

          const { value, totalCount } = data;
          let results = value;
          if (transformResults && results instanceof Array) {
            results = transformResults(results);
          }

          d.resolve(results, { totalCount });
        })
        .catch((error: unknown) => {
          if (isApiError(error)) {
            d.reject(error.data.detail);
          } else {
            d.reject(error);
          }
        });
      return d.promise();
    };

    const store = new CustomStore({
      key: key,
      byKey: (value: any) => {
        if (!value) {
          return jQuery.Deferred().resolve(undefined);
        }

        return sendRequest({ filter: [key, '=', value], skip: 0, take: 1 });
      },
      load: (options: LoadOptions) => {
        const transformedOptions = transformOptions ? transformOptions(options) : options;

        return sendRequest(transformedOptions);
      },
    });

    return store;
  }, [settings]);

  return ds;
}

function useDataSource<Resource>(settings: ODataStoreSettings<Resource>): XDataSource {
  const store = useDataStore(settings);
  return useMemo(() => {
    let _options: { filter?: [] } = { filter: [] };
    const dataSource = new DataSource({
      key: settings.key,
      reshapeOnPush: true,
      byKey: (value: any) => {
        return store.byKey(value);
      },
      load(options: LoadOptions) {
        _options = options;
        return store.load(options).then((...args: Array<any>) => {
          const [data, { totalCount }] = args;
          let result: DataSourceResult<any> = {
            data,
            totalCount,
            summary: [totalCount],
          };

          return result;
        });
      },
    });

    Object.defineProperty(dataSource, 'lastFilter', {
      get() {
        return _options.filter ?? [];
      },
    });

    Object.defineProperty(dataSource, 'settings', {
      get() {
        return settings;
      },
    });

    return dataSource as XDataSource;
  }, [settings, store]);
}

export type XDataSource = DataSource & {
  settings: ODataStoreSettings<any>;
  lastFilter: Array<string>;
};

export type XPropertyListSettings<T> = {
  dataSource: XDataSource;
  property: string;
  operator?: keyof ScalarComparable<any, any>;
  transformResult?: (item: T) => { text: string; value: any | Array<any> };
};

export function isXDataSource(ds: XDataSource | DataSource): ds is XDataSource {
  return (ds as XDataSource).settings !== undefined && (ds as XDataSource).lastFilter !== undefined;
}

export function isApiError(error: any): error is { data: { status: number; title: string; type: string; detail: string } } {
  return 'data' in error && 'type' in error.data;
}

type Query<Kind> = ODataQuery & { kind: Kind };

// enums

export const OdataPartStatusesArray = [
  'Good',
  'Defective',
  'Damaged',
  'InformationMissing',
  'Unidentifiable',
  'Allocated',
  'MasterInReview',
  'Resolved',
  'Received',
  'NotFound',
  'InTransit',
  'OnHold',
  'Quarantined',
] as const;
type OdataPartStatusesArrayType = typeof OdataPartStatusesArray;
export type OdataPartStatuses = OdataPartStatusesArrayType[number];

export const OdataWarehouseOptionsArray = ['None', 'Carts', 'AllowZoneConfiguration', 'RequireDeliveryReceipt'] as const;
type OdataWarehouseOptionsArrayType = typeof OdataWarehouseOptionsArray;
export type OdataWarehouseOptions = OdataWarehouseOptionsArrayType[number];

export const OdataDockReceiptDeliveryTypeArray = ['Undelivered', 'Inbound', 'Internal', 'External'] as const;
type OdataDockReceiptDeliveryTypeArrayType = typeof OdataDockReceiptDeliveryTypeArray;
export type OdataDockReceiptDeliveryType = OdataDockReceiptDeliveryTypeArrayType[number];

export const OdataDeliveryRecipientTypeArray = ['ExternalCustomer', 'ActiveDirectoryUser', 'Warehouse'] as const;
type OdataDeliveryRecipientTypeArrayType = typeof OdataDeliveryRecipientTypeArray;
export type OdataDeliveryRecipientType = OdataDeliveryRecipientTypeArrayType[number];

export const OdataMasterPartReviewStatusArray = ['InReview', 'Accepted', 'Escalated', 'Rejected'] as const;
type OdataMasterPartReviewStatusArrayType = typeof OdataMasterPartReviewStatusArray;
export type OdataMasterPartReviewStatus = OdataMasterPartReviewStatusArrayType[number];

export const OdataMasterPartTemplateTypeArray = ['Standard', 'MIR'] as const;
type OdataMasterPartTemplateTypeArrayType = typeof OdataMasterPartTemplateTypeArray;
export type OdataMasterPartTemplateType = OdataMasterPartTemplateTypeArrayType[number];

export const OdataSelectablePartStatusesArray = [
  'Any',
  'Good',
  'Defective',
  'Damaged',
  'InformationMissing',
  'Unidentifiable',
  'Allocated',
  'MasterInReview',
  'Resolved',
  'Received',
  'NotFound',
  'InTransit',
  'OnHold',
  'Quarantined',
] as const;
type OdataSelectablePartStatusesArrayType = typeof OdataSelectablePartStatusesArray;
export type OdataSelectablePartStatuses = OdataSelectablePartStatusesArrayType[number];

export const OdataMIRResolutionArray = ['Unresolved', 'Inbound', 'Scrap', 'PrintLabel', 'Other'] as const;
type OdataMIRResolutionArrayType = typeof OdataMIRResolutionArray;
export type OdataMIRResolution = OdataMIRResolutionArrayType[number];

export const OdataResourceGroupEntityTypeArray = ['Customer', 'Warehouse'] as const;
type OdataResourceGroupEntityTypeArrayType = typeof OdataResourceGroupEntityTypeArray;
export type OdataResourceGroupEntityType = OdataResourceGroupEntityTypeArrayType[number];

export const OdataResourceGroupTypeArray = ['Auto', 'Administration', 'UserDefined'] as const;
type OdataResourceGroupTypeArrayType = typeof OdataResourceGroupTypeArray;
export type OdataResourceGroupType = OdataResourceGroupTypeArrayType[number];

export const OdataAnnouncementSeverityArray = ['Information', 'Warning', 'Critical'] as const;
type OdataAnnouncementSeverityArrayType = typeof OdataAnnouncementSeverityArray;
export type OdataAnnouncementSeverity = OdataAnnouncementSeverityArrayType[number];

export const OdataNotificationMessagePayloadTypeArray = ['Email', 'Slack', 'Teams'] as const;
type OdataNotificationMessagePayloadTypeArrayType = typeof OdataNotificationMessagePayloadTypeArray;
export type OdataNotificationMessagePayloadType = OdataNotificationMessagePayloadTypeArrayType[number];

export const OdataNotificationEventArray = [
  'MIRPartReceived',
  'MasterPartInReviewCreated',
  'MasterPartInReviewAccepted',
  'MasterPartInReviewEscalated',
  'MIRConversationUpdated',
  'PartNotFound',
  'InternalDelivery',
] as const;
type OdataNotificationEventArrayType = typeof OdataNotificationEventArray;
export type OdataNotificationEvent = OdataNotificationEventArrayType[number];

export const OdataNotificationClientTypeArray = ['Customer', 'Admin'] as const;
type OdataNotificationClientTypeArrayType = typeof OdataNotificationClientTypeArray;
export type OdataNotificationClientType = OdataNotificationClientTypeArrayType[number];

export const OdataMasterPartAttributeDataTypeArray = ['Text', 'Number', 'Date', 'DateTime', 'List'] as const;
type OdataMasterPartAttributeDataTypeArrayType = typeof OdataMasterPartAttributeDataTypeArray;
export type OdataMasterPartAttributeDataType = OdataMasterPartAttributeDataTypeArrayType[number];

export const OdataEntityAttachmentTypeArray = ['Image'] as const;
type OdataEntityAttachmentTypeArrayType = typeof OdataEntityAttachmentTypeArray;
export type OdataEntityAttachmentType = OdataEntityAttachmentTypeArrayType[number];

export const OdataPartTransactionTypeArray = [
  'Information',
  'Receive',
  'Inbound',
  'PutAway',
  'Move',
  'Allocate',
  'Unallocate',
  'Resolve',
  'TransferOut',
  'TransferIn',
  'Outbound',
  'Scrap',
  'Merge',
  'Status',
  'Lost',
  'Found',
  'Hold',
  'Release',
  'Pick',
  'Quarantine',
  'Unquarantine',
] as const;
type OdataPartTransactionTypeArrayType = typeof OdataPartTransactionTypeArray;
export type OdataPartTransactionType = OdataPartTransactionTypeArrayType[number];

export const OdataPartInformationTransactionTypeArray = ['Information', 'MasterPart'] as const;
type OdataPartInformationTransactionTypeArrayType = typeof OdataPartInformationTransactionTypeArray;
export type OdataPartInformationTransactionType = OdataPartInformationTransactionTypeArrayType[number];

export const OdataJobStatusArray = [
  'Draft',
  'PickReady',
  'PickInProgress',
  'ReadyToShip',
  'Cancelled',
  'Fulfilled',
  'Paused',
  'ShortPick',
  'InTransit',
  'ShortReceived',
  'Incomplete',
] as const;
type OdataJobStatusArrayType = typeof OdataJobStatusArray;
export type OdataJobStatus = OdataJobStatusArrayType[number];

export const OdataAdvancedShipmentJobStateArray = ['Open', 'Cancelled'] as const;
type OdataAdvancedShipmentJobStateArrayType = typeof OdataAdvancedShipmentJobStateArray;
export type OdataAdvancedShipmentJobState = OdataAdvancedShipmentJobStateArrayType[number];

export const OdataAdvancedShipmentJobStatusArray = ['Open', 'InProgress', 'Fulfilled', 'Cancelled'] as const;
type OdataAdvancedShipmentJobStatusArrayType = typeof OdataAdvancedShipmentJobStatusArray;
export type OdataAdvancedShipmentJobStatus = OdataAdvancedShipmentJobStatusArrayType[number];

export const OdataExternalApplicationActivationStatusArray = ['Pending', 'Active', 'Suspended'] as const;
type OdataExternalApplicationActivationStatusArrayType = typeof OdataExternalApplicationActivationStatusArray;
export type OdataExternalApplicationActivationStatus = OdataExternalApplicationActivationStatusArrayType[number];

export const OdataJobTypeArray = ['PutAway', 'Outbound', 'Transfer'] as const;
type OdataJobTypeArrayType = typeof OdataJobTypeArray;
export type OdataJobType = OdataJobTypeArrayType[number];

export const OdataOutgoingJobTypeArray = ['Outbound', 'Transfer'] as const;
type OdataOutgoingJobTypeArrayType = typeof OdataOutgoingJobTypeArray;
export type OdataOutgoingJobType = OdataOutgoingJobTypeArrayType[number];

export const OdataIncomingJobTypeArray = ['Transfer'] as const;
type OdataIncomingJobTypeArrayType = typeof OdataIncomingJobTypeArray;
export type OdataIncomingJobType = OdataIncomingJobTypeArrayType[number];

export const OdataCycleCountJobStatusArray = ['Open', 'ReadyForReview', 'Discrepancy', 'Complete'] as const;
type OdataCycleCountJobStatusArrayType = typeof OdataCycleCountJobStatusArray;
export type OdataCycleCountJobStatus = OdataCycleCountJobStatusArrayType[number];

export const OdataDataSubscriptionJobResultArray = ['Success', 'Cancelled', 'Failure'] as const;
type OdataDataSubscriptionJobResultArrayType = typeof OdataDataSubscriptionJobResultArray;
export type OdataDataSubscriptionJobResult = OdataDataSubscriptionJobResultArrayType[number];

export const OdataDataSubscriptionDataSourceArray = [
  'PartTransactionBatch',
  'OutboundJob',
  'CurrentInventory',
  'InventoryAdjustment',
  'MasterPartTemplate',
  'TransferJob',
  'MasterPart',
] as const;
type OdataDataSubscriptionDataSourceArrayType = typeof OdataDataSubscriptionDataSourceArray;
export type OdataDataSubscriptionDataSource = OdataDataSubscriptionDataSourceArrayType[number];

export const OdataDataSubscriptionTriggerArray = ['Entity', 'Schedule', 'Index'] as const;
type OdataDataSubscriptionTriggerArrayType = typeof OdataDataSubscriptionTriggerArray;
export type OdataDataSubscriptionTrigger = OdataDataSubscriptionTriggerArrayType[number];

// types
export class OdataPartAttributeViewModel {
  constructor(
    public masterPartAttributeCategoryId: number,
    public masterPartAttributeTemplateMappingId: number,
    public value: string,
    public displayName: string,
  ) {}

  static Name = 'OdataPartAttributeViewModel';
  static Columns: ColumnsMetadata<OdataPartAttributeViewModel> = {
    masterPartAttributeCategoryId: { name: 'masterPartAttributeCategoryId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    masterPartAttributeTemplateMappingId: {
      name: 'masterPartAttributeTemplateMappingId',
      type: 'number',
      odataType: 'Edm.Int32',
      nullable: false,
      isArray: false,
    },
    value: { name: 'value', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    displayName: { name: 'displayName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
  };
}

export class OdataMetadataViewModel {
  constructor(public name: string, public value: string) {}

  static Name = 'OdataMetadataViewModel';
  static Columns: ColumnsMetadata<OdataMetadataViewModel> = {
    name: { name: 'name', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    value: { name: 'value', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
  };
}

export class OdataUserResourceGroupDetailViewModel {
  constructor(
    public resourceGroupId: number,
    public resourceGroupName: string | null,
    public resourceGroupEntityType: OdataResourceGroupEntityType,
    public resourceGroupType: OdataResourceGroupType,
  ) {}

  static Name = 'OdataUserResourceGroupDetailViewModel';
  static Columns: ColumnsMetadata<OdataUserResourceGroupDetailViewModel> = {
    resourceGroupId: { name: 'resourceGroupId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    resourceGroupName: { name: 'resourceGroupName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    resourceGroupEntityType: {
      name: 'resourceGroupEntityType',
      type: 'OdataResourceGroupEntityType',
      odataType: 'AEX.Inventory.Data.ResourceGroupEntityType',
      nullable: false,
      isArray: false,
    },
    resourceGroupType: {
      name: 'resourceGroupType',
      type: 'OdataResourceGroupType',
      odataType: 'AEX.Inventory.Data.ResourceGroupType',
      nullable: false,
      isArray: false,
    },
  };
}

export class OdataSuggestedBin {
  constructor(public id: number, public code: string | null, public reason: string | null, public partCount: number, public priority: number) {}

  static Name = 'OdataSuggestedBin';
  static Columns: ColumnsMetadata<OdataSuggestedBin> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    code: { name: 'code', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    reason: { name: 'reason', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    partCount: { name: 'partCount', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    priority: { name: 'priority', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
  };
}

export class OdataEntityAttachmentReadModel {
  constructor(public id: number, public url: string, public entityAttachmentType: OdataEntityAttachmentType) {}

  static Name = 'OdataEntityAttachmentReadModel';
  static Columns: ColumnsMetadata<OdataEntityAttachmentReadModel> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    url: { name: 'url', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    entityAttachmentType: {
      name: 'entityAttachmentType',
      type: 'OdataEntityAttachmentType',
      odataType: 'AEX.Inventory.Data.EntityAttachmentType',
      nullable: false,
      isArray: false,
    },
  };
}

export class OdataPartAttributeReadModel {
  constructor(
    public masterPartAttributeTemplateMappingId: number,
    public masterPartAttributeCategoryId: number,
    public masterPartTemplateId: number,
    public value: string | null,
    public displayName: string | null,
  ) {}

  static Name = 'OdataPartAttributeReadModel';
  static Columns: ColumnsMetadata<OdataPartAttributeReadModel> = {
    masterPartAttributeTemplateMappingId: {
      name: 'masterPartAttributeTemplateMappingId',
      type: 'number',
      odataType: 'Edm.Int32',
      nullable: false,
      isArray: false,
    },
    masterPartAttributeCategoryId: { name: 'masterPartAttributeCategoryId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    masterPartTemplateId: { name: 'masterPartTemplateId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    value: { name: 'value', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    displayName: { name: 'displayName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
  };
}

// entities
export class OdataInventoryReport {
  constructor(
    public id: number,
    public partLocationId: number,
    public customerId: number,
    public customerCode: string,
    public masterPartId: number | null,
    public masterPartCode: string | null,
    public warehouseId: number | null,
    public warehouseCode: string | null,
    public warehouseName: string | null,
    public locationType: string | null,
    public locationCode: string | null,
    public locationId: number | null,
    public partStatuses: Array<OdataPartStatuses>,
    public partAttributes: Array<OdataPartAttributeViewModel> | null,
    public quantity: number,
    public lastActivity: number,
    public dateReceived: number,
    public transactionRowVersion: number,
    public latitude: number | null,
    public longitude: number | null,
    public customerGroupIds: Array<number>,
    public warehouseGroupIds: Array<number>,
  ) {}

  static Name = 'OdataInventoryReport';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataInventoryReport> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    partLocationId: { name: 'partLocationId', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    masterPartId: { name: 'masterPartId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    masterPartCode: { name: 'masterPartCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationType: { name: 'locationType', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationCode: { name: 'locationCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationId: { name: 'locationId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    partStatuses: { name: 'partStatuses', type: 'Array<OdataPartStatuses>', odataType: '', nullable: false, isArray: true, elementKind: 'enum' },
    partAttributes: {
      name: 'partAttributes',
      type: 'Array<OdataPartAttributeViewModel>',
      odataType: '',
      nullable: true,
      isArray: true,
      elementKind: 'type',
      elementType: OdataPartAttributeViewModel,
    },
    quantity: { name: 'quantity', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    lastActivity: { name: 'lastActivity', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    dateReceived: { name: 'dateReceived', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    transactionRowVersion: { name: 'transactionRowVersion', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    latitude: { name: 'latitude', type: 'number', odataType: 'Edm.Double', nullable: true, isArray: false },
    longitude: { name: 'longitude', type: 'number', odataType: 'Edm.Double', nullable: true, isArray: false },
    customerGroupIds: { name: 'customerGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
  };
}

export class OdataDockReceipts {
  constructor(
    public id: number,
    public waybillNumber: string,
    public carrierId: number,
    public warehouseId: number,
    public carrierCode: string,
    public warehouseCode: string,
    public warehouseName: string,
    public warehouseGroupIds: Array<number>,
    public warehouseOptions: Array<OdataWarehouseOptions>,
    public otherCarrierName: string | null,
    public technicianName: string | null,
    public closed: boolean,
    public dockReceiptDeliveryType: OdataDockReceiptDeliveryType,
    public createdDate: number,
    public modifiedDate: number,
    public deliveryDate: number | null,
    public deliveryRecipientName: string | null,
    public receivedByEmail: string | null,
    public username: string,
    public rowVersion: number,
    public deliveryReceiptId: number | null,
  ) {}

  static Name = 'OdataDockReceipts';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataDockReceipts> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    waybillNumber: { name: 'waybillNumber', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    carrierId: { name: 'carrierId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    carrierCode: { name: 'carrierCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    warehouseOptions: {
      name: 'warehouseOptions',
      type: 'Array<OdataWarehouseOptions>',
      odataType: '',
      nullable: false,
      isArray: true,
      elementKind: 'enum',
    },
    otherCarrierName: { name: 'otherCarrierName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    technicianName: { name: 'technicianName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    closed: { name: 'closed', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    dockReceiptDeliveryType: {
      name: 'dockReceiptDeliveryType',
      type: 'OdataDockReceiptDeliveryType',
      odataType: 'AEX.Inventory.Data.DockReceiptDeliveryType',
      nullable: false,
      isArray: false,
    },
    createdDate: { name: 'createdDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    modifiedDate: { name: 'modifiedDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    deliveryDate: { name: 'deliveryDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: true, isArray: false },
    deliveryRecipientName: { name: 'deliveryRecipientName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    receivedByEmail: { name: 'receivedByEmail', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    username: { name: 'username', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    rowVersion: { name: 'rowVersion', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    deliveryReceiptId: { name: 'deliveryReceiptId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
  };
}

export class OdataDeliveryReceipts {
  constructor(
    public id: number,
    public recipient: string,
    public numberOfWaybills: number,
    public deliveryDate: number,
    public deliveredBy: string,
    public receivedByName: string,
    public receivedByEmail: string,
  ) {}

  static Name = 'OdataDeliveryReceipts';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataDeliveryReceipts> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    recipient: { name: 'recipient', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    numberOfWaybills: { name: 'numberOfWaybills', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    deliveryDate: { name: 'deliveryDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    deliveredBy: { name: 'deliveredBy', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    receivedByName: { name: 'receivedByName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    receivedByEmail: { name: 'receivedByEmail', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
  };
}

export class OdataDeliveryRecipients {
  constructor(
    public id: number,
    public name: string,
    public emailAddress: string,
    public deliveryRecipientType: OdataDeliveryRecipientType,
    public modifiedDate: number,
    public username: string,
  ) {}

  static Name = 'OdataDeliveryRecipients';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataDeliveryRecipients> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    name: { name: 'name', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    emailAddress: { name: 'emailAddress', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    deliveryRecipientType: {
      name: 'deliveryRecipientType',
      type: 'OdataDeliveryRecipientType',
      odataType: 'AEX.Inventory.Data.DeliveryRecipientType',
      nullable: false,
      isArray: false,
    },
    modifiedDate: { name: 'modifiedDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    username: { name: 'username', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
  };
}

export class OdataMasterParts {
  constructor(
    public id: number,
    public customerId: number,
    public customerCode: string,
    public customerName: string,
    public masterPartTemplateId: number | null,
    public masterPartTemplateName: string | null,
    public code: string,
    public description: string | null,
    public isUnique: boolean,
    public isBulk: boolean,
    public reviewStatus: OdataMasterPartReviewStatus,
    public createdDate: number,
    public modifiedDate: number,
    public modifiedUser: string,
    public disabled: boolean,
    public aliases: Array<string> | null,
  ) {}

  static Name = 'OdataMasterParts';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataMasterParts> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    customerName: { name: 'customerName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    masterPartTemplateId: { name: 'masterPartTemplateId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    masterPartTemplateName: { name: 'masterPartTemplateName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    code: { name: 'code', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    description: { name: 'description', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    isUnique: { name: 'isUnique', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    isBulk: { name: 'isBulk', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    reviewStatus: {
      name: 'reviewStatus',
      type: 'OdataMasterPartReviewStatus',
      odataType: 'AEX.Inventory.Data.MasterPartReviewStatus',
      nullable: false,
      isArray: false,
    },
    createdDate: { name: 'createdDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    modifiedDate: { name: 'modifiedDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    modifiedUser: { name: 'modifiedUser', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    disabled: { name: 'disabled', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    aliases: { name: 'aliases', type: 'Array<string>', odataType: '', nullable: true, isArray: true, elementKind: 'primitive' },
  };
}

export class OdataMasterPartTemplates {
  constructor(
    public id: number,
    public name: string,
    public customerId: number | null,
    public customerCode: string | null,
    public customerName: string | null,
    public description: string | null,
    public masterPartTemplateType: OdataMasterPartTemplateType,
    public rowVersion: number,
  ) {}

  static Name = 'OdataMasterPartTemplates';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataMasterPartTemplates> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    name: { name: 'name', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    customerName: { name: 'customerName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    description: { name: 'description', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    masterPartTemplateType: {
      name: 'masterPartTemplateType',
      type: 'OdataMasterPartTemplateType',
      odataType: 'AEX.Inventory.Data.MasterPartTemplateType',
      nullable: false,
      isArray: false,
    },
    rowVersion: { name: 'rowVersion', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
  };
}

export class OdataCustomers {
  constructor(
    public id: number,
    public code: string,
    public name: string,
    public referenceLabel: string,
    public referenceRequired: boolean,
    public rowVersion: number,
    public notificationClientId: number,
    public disabled: boolean,
    public warehouseResourceGroupId: number | null,
  ) {}

  static Name = 'OdataCustomers';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataCustomers> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    code: { name: 'code', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    name: { name: 'name', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    referenceLabel: { name: 'referenceLabel', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    referenceRequired: { name: 'referenceRequired', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    rowVersion: { name: 'rowVersion', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    notificationClientId: { name: 'notificationClientId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    disabled: { name: 'disabled', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    warehouseResourceGroupId: { name: 'warehouseResourceGroupId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
  };
}

export class OdataWarehouses {
  constructor(
    public id: number,
    public code: string,
    public name: string,
    public streetNumber: string | null,
    public streetName: string | null,
    public address: string | null,
    public address2: string | null,
    public city: string | null,
    public province: string | null,
    public postalCode: string | null,
    public country: string | null,
    public latitude: number | null,
    public longitude: number | null,
    public warehouseOptions: Array<OdataWarehouseOptions>,
    public disabled: boolean,
    public timezoneId: string | null,
    public rowVersion: number,
    public resourceGroupIds: Array<number>,
  ) {}

  static Name = 'OdataWarehouses';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataWarehouses> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    code: { name: 'code', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    name: { name: 'name', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    streetNumber: { name: 'streetNumber', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    streetName: { name: 'streetName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    address: { name: 'address', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    address2: { name: 'address2', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    city: { name: 'city', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    province: { name: 'province', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    postalCode: { name: 'postalCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    country: { name: 'country', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    latitude: { name: 'latitude', type: 'number', odataType: 'Edm.Double', nullable: true, isArray: false },
    longitude: { name: 'longitude', type: 'number', odataType: 'Edm.Double', nullable: true, isArray: false },
    warehouseOptions: {
      name: 'warehouseOptions',
      type: 'Array<OdataWarehouseOptions>',
      odataType: '',
      nullable: false,
      isArray: true,
      elementKind: 'enum',
    },
    disabled: { name: 'disabled', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    timezoneId: { name: 'timezoneId', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    rowVersion: { name: 'rowVersion', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    resourceGroupIds: { name: 'resourceGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
  };
}

export class OdataBins {
  constructor(
    public id: number,
    public code: string | null,
    public warehouseId: number,
    public warehouseCode: string | null,
    public warehouseName: string | null,
    public warehouseGroupIds: Array<number>,
    public zoneId: number | null,
    public zoneCode: string | null,
    public affinityCustomerId: number | null,
    public affinityCustomerCode: string | null,
    public affinitySelectablePartStatuses: Array<OdataSelectablePartStatuses>,
    public rowVersion: number,
    public partCount: number,
    public disabled: boolean,
  ) {}

  static Name = 'OdataBins';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataBins> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    code: { name: 'code', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    zoneId: { name: 'zoneId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    zoneCode: { name: 'zoneCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    affinityCustomerId: { name: 'affinityCustomerId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    affinityCustomerCode: { name: 'affinityCustomerCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    affinitySelectablePartStatuses: {
      name: 'affinitySelectablePartStatuses',
      type: 'Array<OdataSelectablePartStatuses>',
      odataType: '',
      nullable: false,
      isArray: true,
      elementKind: 'enum',
    },
    rowVersion: { name: 'rowVersion', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    partCount: { name: 'partCount', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    disabled: { name: 'disabled', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
  };
}

export class OdataZones {
  constructor(
    public id: number,
    public code: string,
    public warehouseId: number,
    public warehouseCode: string,
    public warehouseName: string,
    public binCount: number,
  ) {}

  static Name = 'OdataZones';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataZones> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    code: { name: 'code', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    binCount: { name: 'binCount', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
  };
}

export class OdataCarriers {
  constructor(public id: number, public code: string, public name: string, public disabled: boolean, public waybillIsAutogenerated: boolean) {}

  static Name = 'OdataCarriers';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataCarriers> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    code: { name: 'code', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    name: { name: 'name', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    disabled: { name: 'disabled', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    waybillIsAutogenerated: { name: 'waybillIsAutogenerated', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
  };
}

export class OdataParts {
  constructor(
    public id: number,
    public partLocationId: number,
    public customerId: number,
    public customerCode: string,
    public masterPartId: number | null,
    public masterPartCode: string | null,
    public warehouseId: number | null,
    public warehouseCode: string | null,
    public warehouseName: string | null,
    public locationType: string | null,
    public locationCode: string | null,
    public locationId: number | null,
    public partStatuses: Array<OdataPartStatuses>,
    public partAttributes: Array<OdataPartAttributeViewModel> | null,
    public quantity: number,
    public lastActivity: number,
    public dateReceived: number,
    public transactionRowVersion: number,
    public latitude: number | null,
    public longitude: number | null,
    public customerGroupIds: Array<number>,
    public warehouseGroupIds: Array<number>,
  ) {}

  static Name = 'OdataParts';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataParts> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    partLocationId: { name: 'partLocationId', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    masterPartId: { name: 'masterPartId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    masterPartCode: { name: 'masterPartCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationType: { name: 'locationType', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationCode: { name: 'locationCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationId: { name: 'locationId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    partStatuses: { name: 'partStatuses', type: 'Array<OdataPartStatuses>', odataType: '', nullable: false, isArray: true, elementKind: 'enum' },
    partAttributes: {
      name: 'partAttributes',
      type: 'Array<OdataPartAttributeViewModel>',
      odataType: '',
      nullable: true,
      isArray: true,
      elementKind: 'type',
      elementType: OdataPartAttributeViewModel,
    },
    quantity: { name: 'quantity', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    lastActivity: { name: 'lastActivity', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    dateReceived: { name: 'dateReceived', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    transactionRowVersion: { name: 'transactionRowVersion', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    latitude: { name: 'latitude', type: 'number', odataType: 'Edm.Double', nullable: true, isArray: false },
    longitude: { name: 'longitude', type: 'number', odataType: 'Edm.Double', nullable: true, isArray: false },
    customerGroupIds: { name: 'customerGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
  };
}

export class OdataTemplateMismatchedParts {
  constructor(
    public id: number,
    public attributesTemplateId: number | null,
    public attributesTemplateName: string | null,
    public masterPartTemplateId: number | null,
    public masterPartTemplateName: string | null,
    public customerId: number,
    public customerCode: string,
    public masterPartId: number | null,
    public masterPartCode: string | null,
    public warehouseId: number | null,
    public warehouseCode: string | null,
    public warehouseName: string | null,
    public locationType: string | null,
    public locationCode: string | null,
    public locationId: number | null,
    public partStatuses: Array<OdataPartStatuses>,
    public partAttributes: Array<OdataPartAttributeViewModel> | null,
    public lastActivity: number,
    public transactionRowVersion: number,
    public customerGroupIds: Array<number>,
    public warehouseGroupIds: Array<number>,
  ) {}

  static Name = 'OdataTemplateMismatchedParts';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataTemplateMismatchedParts> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    attributesTemplateId: { name: 'attributesTemplateId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    attributesTemplateName: { name: 'attributesTemplateName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    masterPartTemplateId: { name: 'masterPartTemplateId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    masterPartTemplateName: { name: 'masterPartTemplateName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    masterPartId: { name: 'masterPartId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    masterPartCode: { name: 'masterPartCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationType: { name: 'locationType', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationCode: { name: 'locationCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationId: { name: 'locationId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    partStatuses: { name: 'partStatuses', type: 'Array<OdataPartStatuses>', odataType: '', nullable: false, isArray: true, elementKind: 'enum' },
    partAttributes: {
      name: 'partAttributes',
      type: 'Array<OdataPartAttributeViewModel>',
      odataType: '',
      nullable: true,
      isArray: true,
      elementKind: 'type',
      elementType: OdataPartAttributeViewModel,
    },
    lastActivity: { name: 'lastActivity', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    transactionRowVersion: { name: 'transactionRowVersion', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    customerGroupIds: { name: 'customerGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
  };
}

export class OdataPartTransactions {
  constructor(
    public id: number,
    public transactionMetadata: Array<OdataMetadataViewModel> | null,
    public partTransactionType: OdataPartTransactionType,
    public partId: number,
    public customerId: number,
    public customerCode: string,
    public masterPartId: number | null,
    public masterPartCode: string | null,
    public warehouseId: number | null,
    public warehouseCode: string | null,
    public warehouseName: string | null,
    public locationType: string | null,
    public locationCode: string | null,
    public locationId: number | null,
    public partStatuses: Array<OdataPartStatuses>,
    public partAttributes: Array<OdataPartAttributeViewModel> | null,
    public quantity: number,
    public timestamp: number,
    public isInStock: boolean,
    public partTransactionBatchId: number,
    public notes: string | null,
    public user: string,
    public customerGroupIds: Array<number>,
    public warehouseGroupIds: Array<number>,
  ) {}

  static Name = 'OdataPartTransactions';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataPartTransactions> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    transactionMetadata: {
      name: 'transactionMetadata',
      type: 'Array<OdataMetadataViewModel>',
      odataType: '',
      nullable: true,
      isArray: true,
      elementKind: 'type',
      elementType: OdataMetadataViewModel,
    },
    partTransactionType: {
      name: 'partTransactionType',
      type: 'OdataPartTransactionType',
      odataType: 'AEX.Inventory.Data.Abstractions.PartTransactionType',
      nullable: false,
      isArray: false,
    },
    partId: { name: 'partId', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    masterPartId: { name: 'masterPartId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    masterPartCode: { name: 'masterPartCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationType: { name: 'locationType', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationCode: { name: 'locationCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationId: { name: 'locationId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    partStatuses: { name: 'partStatuses', type: 'Array<OdataPartStatuses>', odataType: '', nullable: false, isArray: true, elementKind: 'enum' },
    partAttributes: {
      name: 'partAttributes',
      type: 'Array<OdataPartAttributeViewModel>',
      odataType: '',
      nullable: true,
      isArray: true,
      elementKind: 'type',
      elementType: OdataPartAttributeViewModel,
    },
    quantity: { name: 'quantity', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    timestamp: { name: 'timestamp', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    isInStock: { name: 'isInStock', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    partTransactionBatchId: { name: 'partTransactionBatchId', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    notes: { name: 'notes', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    user: { name: 'user', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    customerGroupIds: { name: 'customerGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
  };
}

export class OdataPartInformationTransactions {
  constructor(
    public id: number,
    public partInformationTransactionType: OdataPartInformationTransactionType,
    public partId: number,
    public customerId: number,
    public customerCode: string,
    public masterPartId: number | null,
    public masterPartCode: string | null,
    public partAttributes: Array<OdataPartAttributeViewModel> | null,
    public timestamp: number,
    public partTransactionBatchId: number,
    public user: string,
  ) {}

  static Name = 'OdataPartInformationTransactions';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataPartInformationTransactions> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    partInformationTransactionType: {
      name: 'partInformationTransactionType',
      type: 'OdataPartInformationTransactionType',
      odataType: 'AEX.Inventory.Data.Abstractions.PartInformationTransactionType',
      nullable: false,
      isArray: false,
    },
    partId: { name: 'partId', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    masterPartId: { name: 'masterPartId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    masterPartCode: { name: 'masterPartCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    partAttributes: {
      name: 'partAttributes',
      type: 'Array<OdataPartAttributeViewModel>',
      odataType: '',
      nullable: true,
      isArray: true,
      elementKind: 'type',
      elementType: OdataPartAttributeViewModel,
    },
    timestamp: { name: 'timestamp', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    partTransactionBatchId: { name: 'partTransactionBatchId', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    user: { name: 'user', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
  };
}

export class OdataMIR {
  constructor(
    public id: number,
    public masterPartCode: string,
    public masterPartId: number | null,
    public customerCode: string,
    public customerId: number,
    public partStatuses: Array<OdataPartStatuses>,
    public warehouseId: number,
    public warehouseCode: string,
    public warehouseName: string,
    public locationType: string,
    public locationCode: string,
    public quantity: number,
    public inboundTimestamp: number,
    public lastActivity: number,
    public resolution: OdataMIRResolution | null,
    public notes: string | null,
  ) {}

  static Name = 'OdataMIR';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataMIR> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    masterPartCode: { name: 'masterPartCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    masterPartId: { name: 'masterPartId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    partStatuses: { name: 'partStatuses', type: 'Array<OdataPartStatuses>', odataType: '', nullable: false, isArray: true, elementKind: 'enum' },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    locationType: { name: 'locationType', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    locationCode: { name: 'locationCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    quantity: { name: 'quantity', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    inboundTimestamp: { name: 'inboundTimestamp', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    lastActivity: { name: 'lastActivity', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    resolution: { name: 'resolution', type: 'OdataMIRResolution', odataType: 'AEX.Inventory.Data.MIRResolution', nullable: true, isArray: false },
    notes: { name: 'notes', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
  };
}

export class OdataCarts {
  constructor(
    public id: number,
    public code: string,
    public warehouseId: number,
    public warehouseCode: string,
    public warehouseName: string,
    public disabled: boolean,
  ) {}

  static Name = 'OdataCarts';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataCarts> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    code: { name: 'code', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    disabled: { name: 'disabled', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
  };
}

export class OdataJobParts {
  constructor(
    public id: number,
    public jobId: number,
    public partLocationId: number,
    public customerId: number,
    public customerCode: string | null,
    public customerGroupIds: Array<number>,
    public masterPartId: number | null,
    public masterPartCode: string | null,
    public warehouseId: number | null,
    public warehouseCode: string | null,
    public warehouseName: string | null,
    public warehouseGroupIds: Array<number>,
    public locationType: string | null,
    public locationCode: string | null,
    public locationId: number | null,
    public jobType: OdataJobType,
    public partStatuses: Array<OdataPartStatuses>,
    public partAttributes: Array<OdataPartAttributeReadModel> | null,
    public jobModifiedDate: number,
    public lastActivity: number,
    public transactionRowVersion: number,
  ) {}

  static Name = 'OdataJobParts';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataJobParts> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    jobId: { name: 'jobId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    partLocationId: { name: 'partLocationId', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    customerGroupIds: { name: 'customerGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    masterPartId: { name: 'masterPartId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    masterPartCode: { name: 'masterPartCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    locationType: { name: 'locationType', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationCode: { name: 'locationCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationId: { name: 'locationId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    jobType: { name: 'jobType', type: 'OdataJobType', odataType: 'AEX.Inventory.RavenDb.Indexes.Common.JobType', nullable: false, isArray: false },
    partStatuses: { name: 'partStatuses', type: 'Array<OdataPartStatuses>', odataType: '', nullable: false, isArray: true, elementKind: 'enum' },
    partAttributes: {
      name: 'partAttributes',
      type: 'Array<OdataPartAttributeReadModel>',
      odataType: '',
      nullable: true,
      isArray: true,
      elementKind: 'type',
      elementType: OdataPartAttributeReadModel,
    },
    jobModifiedDate: { name: 'jobModifiedDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    lastActivity: { name: 'lastActivity', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    transactionRowVersion: { name: 'transactionRowVersion', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
  };
}

export class OdataJobs {
  constructor(
    public id: number,
    public reference: string | null,
    public customerId: number,
    public customerCode: string | null,
    public customerGroupIds: Array<number>,
    public warehouseId: number | null,
    public warehouseCode: string | null,
    public warehouseName: string | null,
    public warehouseGroupIds: Array<number>,
    public jobType: OdataJobType,
    public modifiedDate: number,
  ) {}

  static Name = 'OdataJobs';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataJobs> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    reference: { name: 'reference', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    customerGroupIds: { name: 'customerGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    jobType: { name: 'jobType', type: 'OdataJobType', odataType: 'AEX.Inventory.RavenDb.Indexes.Common.JobType', nullable: false, isArray: false },
    modifiedDate: { name: 'modifiedDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
  };
}

export class OdataPutAwayJobs {
  constructor(
    public partId: number,
    public suggestedBins: Array<OdataSuggestedBin>,
    public id: number,
    public jobId: number,
    public partLocationId: number,
    public customerId: number,
    public customerCode: string | null,
    public customerGroupIds: Array<number>,
    public masterPartId: number | null,
    public masterPartCode: string | null,
    public isUnique: boolean,
    public warehouseId: number | null,
    public warehouseCode: string | null,
    public warehouseName: string | null,
    public warehouseCartsEnabled: boolean,
    public warehouseGroupIds: Array<number>,
    public locationType: string | null,
    public locationCode: string | null,
    public locationId: number | null,
    public partStatuses: Array<OdataPartStatuses>,
    public suggestedBinId: number | null,
    public suggestedBinCode: string | null,
    public complete: boolean,
    public createdDate: number,
    public lastPartTransactionBatchId: number | null,
    public rowVersion: number,
    public transactionRowVersion: number,
  ) {}

  static Name = 'OdataPutAwayJobs';
  static key = 'partId';
  static Columns: ColumnsMetadata<OdataPutAwayJobs> = {
    partId: { name: 'partId', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    suggestedBins: {
      name: 'suggestedBins',
      type: 'Array<OdataSuggestedBin>',
      odataType: '',
      nullable: false,
      isArray: true,
      elementKind: 'type',
      elementType: OdataSuggestedBin,
    },
    id: { name: 'id', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    jobId: { name: 'jobId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    partLocationId: { name: 'partLocationId', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    customerGroupIds: { name: 'customerGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    masterPartId: { name: 'masterPartId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    masterPartCode: { name: 'masterPartCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    isUnique: { name: 'isUnique', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseCartsEnabled: { name: 'warehouseCartsEnabled', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    locationType: { name: 'locationType', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationCode: { name: 'locationCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    locationId: { name: 'locationId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    partStatuses: { name: 'partStatuses', type: 'Array<OdataPartStatuses>', odataType: '', nullable: false, isArray: true, elementKind: 'enum' },
    suggestedBinId: { name: 'suggestedBinId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    suggestedBinCode: { name: 'suggestedBinCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    complete: { name: 'complete', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    createdDate: { name: 'createdDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    lastPartTransactionBatchId: { name: 'lastPartTransactionBatchId', type: 'number', odataType: 'Edm.Int64', nullable: true, isArray: false },
    rowVersion: { name: 'rowVersion', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    transactionRowVersion: { name: 'transactionRowVersion', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
  };
}

export class OdataOutgoingJobs {
  constructor(
    public id: number,
    public customerId: number,
    public customerCode: string | null,
    public customerName: string | null,
    public customerGroupIds: Array<number>,
    public externalReferenceId: string | null,
    public warehouseId: number | null,
    public warehouseCode: string | null,
    public warehouseName: string | null,
    public warehouseGroupIds: Array<number>,
    public reference: string | null,
    public serviceType: string | null,
    public destination: string | null,
    public resolutionDate: number,
    public shipByDate: number,
    public partCount: number,
    public jobStatus: OdataJobStatus,
    public createdDate: number,
    public modifiedUser: string | null,
    public modifiedDate: number,
    public jobType: OdataOutgoingJobType,
  ) {}

  static Name = 'OdataOutgoingJobs';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataOutgoingJobs> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    customerName: { name: 'customerName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    customerGroupIds: { name: 'customerGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    externalReferenceId: { name: 'externalReferenceId', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    reference: { name: 'reference', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    serviceType: { name: 'serviceType', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    destination: { name: 'destination', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    resolutionDate: { name: 'resolutionDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    shipByDate: { name: 'shipByDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    partCount: { name: 'partCount', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    jobStatus: { name: 'jobStatus', type: 'OdataJobStatus', odataType: 'AEX.Inventory.Data.Abstractions.JobStatus', nullable: false, isArray: false },
    createdDate: { name: 'createdDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    modifiedUser: { name: 'modifiedUser', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    modifiedDate: { name: 'modifiedDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    jobType: {
      name: 'jobType',
      type: 'OdataOutgoingJobType',
      odataType: 'AEX.Inventory.RavenDb.Indexes.Common.OutgoingJobType',
      nullable: false,
      isArray: false,
    },
  };
}

export class OdataIncomingJobs {
  constructor(
    public id: number,
    public customerId: number,
    public customerCode: string | null,
    public customerName: string | null,
    public customerGroupIds: Array<number>,
    public externalReferenceId: string | null,
    public warehouseId: number | null,
    public warehouseCode: string | null,
    public warehouseName: string | null,
    public warehouseGroupIds: Array<number>,
    public reference: string | null,
    public serviceType: string | null,
    public resolutionDate: number,
    public shipByDate: number,
    public partCount: number,
    public jobStatus: OdataJobStatus,
    public createdDate: number,
    public modifiedUser: string | null,
    public modifiedDate: number,
    public jobType: OdataIncomingJobType,
  ) {}

  static Name = 'OdataIncomingJobs';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataIncomingJobs> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    customerName: { name: 'customerName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    customerGroupIds: { name: 'customerGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    externalReferenceId: { name: 'externalReferenceId', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    reference: { name: 'reference', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    serviceType: { name: 'serviceType', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    resolutionDate: { name: 'resolutionDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    shipByDate: { name: 'shipByDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    partCount: { name: 'partCount', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    jobStatus: { name: 'jobStatus', type: 'OdataJobStatus', odataType: 'AEX.Inventory.Data.Abstractions.JobStatus', nullable: false, isArray: false },
    createdDate: { name: 'createdDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    modifiedUser: { name: 'modifiedUser', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    modifiedDate: { name: 'modifiedDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    jobType: {
      name: 'jobType',
      type: 'OdataIncomingJobType',
      odataType: 'AEX.Inventory.RavenDb.Indexes.Common.IncomingJobType',
      nullable: false,
      isArray: false,
    },
  };
}

export class OdataOutbound {
  constructor(
    public id: number,
    public customerId: number,
    public customerCode: string,
    public customerName: string,
    public customerGroupIds: Array<number>,
    public externalReferenceId: string,
    public warehouseId: number | null,
    public warehouseCode: string,
    public warehouseName: string,
    public warehouseGroupIds: Array<number>,
    public customerReference: string,
    public serviceType: string,
    public resolutionDate: number,
    public shipByDate: number,
    public partCount: number,
    public jobStatus: OdataJobStatus,
    public modifiedUser: string,
    public modifiedDate: number,
    public createdDate: number,
  ) {}

  static Name = 'OdataOutbound';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataOutbound> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    customerName: { name: 'customerName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    customerGroupIds: { name: 'customerGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    externalReferenceId: { name: 'externalReferenceId', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    customerReference: { name: 'customerReference', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    serviceType: { name: 'serviceType', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    resolutionDate: { name: 'resolutionDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    shipByDate: { name: 'shipByDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    partCount: { name: 'partCount', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    jobStatus: { name: 'jobStatus', type: 'OdataJobStatus', odataType: 'AEX.Inventory.Data.Abstractions.JobStatus', nullable: false, isArray: false },
    modifiedUser: { name: 'modifiedUser', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    modifiedDate: { name: 'modifiedDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    createdDate: { name: 'createdDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
  };
}

export class OdataTransfers {
  constructor(
    public id: number,
    public customerId: number,
    public customerCode: string,
    public customerName: string,
    public customerGroupIds: Array<number>,
    public externalReferenceId: string,
    public warehouseId: number,
    public warehouseCode: string,
    public warehouseName: string,
    public destinationWarehouseId: number,
    public destinationWarehouseCode: string,
    public destinationWarehouseName: string,
    public warehouseGroupIds: Array<number>,
    public carrierId: number | null,
    public carrierCode: string | null,
    public outReference: string | null,
    public inReference: string | null,
    public serviceType: string,
    public resolutionDate: number,
    public shipByDate: number,
    public jobStatus: OdataJobStatus,
    public partCount: number,
    public modifiedUser: string,
    public modifiedDate: number,
    public createdDate: number,
  ) {}

  static Name = 'OdataTransfers';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataTransfers> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    customerName: { name: 'customerName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    customerGroupIds: { name: 'customerGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    externalReferenceId: { name: 'externalReferenceId', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    destinationWarehouseId: { name: 'destinationWarehouseId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    destinationWarehouseCode: { name: 'destinationWarehouseCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    destinationWarehouseName: { name: 'destinationWarehouseName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    carrierId: { name: 'carrierId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    carrierCode: { name: 'carrierCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    outReference: { name: 'outReference', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    inReference: { name: 'inReference', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    serviceType: { name: 'serviceType', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    resolutionDate: { name: 'resolutionDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    shipByDate: { name: 'shipByDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    jobStatus: { name: 'jobStatus', type: 'OdataJobStatus', odataType: 'AEX.Inventory.Data.Abstractions.JobStatus', nullable: false, isArray: false },
    partCount: { name: 'partCount', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    modifiedUser: { name: 'modifiedUser', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    modifiedDate: { name: 'modifiedDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    createdDate: { name: 'createdDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
  };
}

export class OdataAdvancedShipments {
  constructor(
    public id: number,
    public customerId: number,
    public customerCode: string | null,
    public customerName: string | null,
    public customerGroupIds: Array<number>,
    public externalReferenceId: string | null,
    public warehouseId: number | null,
    public warehouseCode: string | null,
    public warehouseName: string | null,
    public warehouseGroupIds: Array<number>,
    public reference: string | null,
    public waybill: string | null,
    public expectedPartCount: number,
    public receivedPartCount: number,
    public modifiedUser: string | null,
    public modifiedDate: number,
    public createdDate: number,
  ) {}

  static Name = 'OdataAdvancedShipments';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataAdvancedShipments> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    customerName: { name: 'customerName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    customerGroupIds: { name: 'customerGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    externalReferenceId: { name: 'externalReferenceId', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseName: { name: 'warehouseName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    reference: { name: 'reference', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    waybill: { name: 'waybill', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    expectedPartCount: { name: 'expectedPartCount', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    receivedPartCount: { name: 'receivedPartCount', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    modifiedUser: { name: 'modifiedUser', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    modifiedDate: { name: 'modifiedDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    createdDate: { name: 'createdDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
  };
}

export class OdataAdvancedShipmentExpectedParts {
  constructor(
    public jobId: number,
    public customerId: number,
    public customerGroupIds: Array<number>,
    public externalReferenceId: string | null,
    public warehouseId: number | null,
    public warehouseGroupIds: Array<number>,
    public masterPartId: number,
    public masterPartAttributeId: number,
    public masterPartAttributeValue: string | null,
    public advancedShipmentJobState: OdataAdvancedShipmentJobState,
    public advancedShipmentJobStatus: OdataAdvancedShipmentJobStatus,
  ) {}

  static Name = 'OdataAdvancedShipmentExpectedParts';
  static key = 'jobId';
  static Columns: ColumnsMetadata<OdataAdvancedShipmentExpectedParts> = {
    jobId: { name: 'jobId', type: 'number', odataType: 'Edm.Int64', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerGroupIds: { name: 'customerGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    externalReferenceId: { name: 'externalReferenceId', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: true, isArray: false },
    warehouseGroupIds: { name: 'warehouseGroupIds', type: 'Array<number>', odataType: '', nullable: false, isArray: true, elementKind: 'primitive' },
    masterPartId: { name: 'masterPartId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    masterPartAttributeId: { name: 'masterPartAttributeId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    masterPartAttributeValue: { name: 'masterPartAttributeValue', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    advancedShipmentJobState: {
      name: 'advancedShipmentJobState',
      type: 'OdataAdvancedShipmentJobState',
      odataType: 'AEX.Inventory.Data.Abstractions.AdvancedShipmentJobState',
      nullable: false,
      isArray: false,
    },
    advancedShipmentJobStatus: {
      name: 'advancedShipmentJobStatus',
      type: 'OdataAdvancedShipmentJobStatus',
      odataType: 'AEX.Inventory.Data.Abstractions.AdvancedShipmentJobStatus',
      nullable: false,
      isArray: false,
    },
  };
}

export class OdataUsers {
  constructor(
    public id: number,
    public username: string,
    public isApplication: boolean,
    public externalApplicationName: string | null,
    public resourceGroups: Array<OdataUserResourceGroupDetailViewModel> | null,
  ) {}

  static Name = 'OdataUsers';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataUsers> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    username: { name: 'username', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    isApplication: { name: 'isApplication', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    externalApplicationName: { name: 'externalApplicationName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    resourceGroups: {
      name: 'resourceGroups',
      type: 'Array<OdataUserResourceGroupDetailViewModel>',
      odataType: '',
      nullable: true,
      isArray: true,
      elementKind: 'type',
      elementType: OdataUserResourceGroupDetailViewModel,
    },
  };
}

export class OdataResourceGroups {
  constructor(
    public id: number,
    public name: string,
    public description: string | null,
    public resourceGroupEntityType: OdataResourceGroupEntityType,
    public resourceGroupType: OdataResourceGroupType,
    public canDelete: boolean,
    public entityCount: number,
  ) {}

  static Name = 'OdataResourceGroups';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataResourceGroups> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    name: { name: 'name', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    description: { name: 'description', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    resourceGroupEntityType: {
      name: 'resourceGroupEntityType',
      type: 'OdataResourceGroupEntityType',
      odataType: 'AEX.Inventory.Data.ResourceGroupEntityType',
      nullable: false,
      isArray: false,
    },
    resourceGroupType: {
      name: 'resourceGroupType',
      type: 'OdataResourceGroupType',
      odataType: 'AEX.Inventory.Data.ResourceGroupType',
      nullable: false,
      isArray: false,
    },
    canDelete: { name: 'canDelete', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    entityCount: { name: 'entityCount', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
  };
}

export class OdataCycleCounts {
  constructor(
    public id: number,
    public warehouseId: number,
    public warehouseCode: string,
    public group: string | null,
    public createdDate: number,
    public completedDate: number | null,
    public createdUser: string,
    public completedByUser: string | null,
    public cycleCountJobStatus: OdataCycleCountJobStatus,
  ) {}

  static Name = 'OdataCycleCounts';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataCycleCounts> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    group: { name: 'group', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    createdDate: { name: 'createdDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    completedDate: { name: 'completedDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: true, isArray: false },
    createdUser: { name: 'createdUser', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    completedByUser: { name: 'completedByUser', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    cycleCountJobStatus: {
      name: 'cycleCountJobStatus',
      type: 'OdataCycleCountJobStatus',
      odataType: 'AEX.Inventory.Data.Models.CycleCountJobStatus',
      nullable: false,
      isArray: false,
    },
  };
}

export class OdataDataSubscriptions {
  constructor(
    public id: number,
    public name: string,
    public disabled: boolean,
    public description: string | null,
    public dataSubscriptionConfigurationType: string,
    public resourceGroup: string,
    public dataSubscriptionDataSource: OdataDataSubscriptionDataSource,
    public dataSubscriptionTrigger: OdataDataSubscriptionTrigger,
  ) {}

  static Name = 'OdataDataSubscriptions';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataDataSubscriptions> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    name: { name: 'name', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    disabled: { name: 'disabled', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    description: { name: 'description', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    dataSubscriptionConfigurationType: {
      name: 'dataSubscriptionConfigurationType',
      type: 'string',
      odataType: 'Edm.String',
      nullable: false,
      isArray: false,
    },
    resourceGroup: { name: 'resourceGroup', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    dataSubscriptionDataSource: {
      name: 'dataSubscriptionDataSource',
      type: 'OdataDataSubscriptionDataSource',
      odataType: 'AEX.Inventory.DataSubscriptions.Abstractions.DataSubscriptionDataSource',
      nullable: false,
      isArray: false,
    },
    dataSubscriptionTrigger: {
      name: 'dataSubscriptionTrigger',
      type: 'OdataDataSubscriptionTrigger',
      odataType: 'AEX.Inventory.DataSubscriptions.Abstractions.DataSubscriptionTrigger',
      nullable: false,
      isArray: false,
    },
  };
}

export class OdataDataSubscriptionJobs {
  constructor(
    public id: number,
    public dataSubscriptionId: number,
    public dataSubscriptionJobResult: OdataDataSubscriptionJobResult,
    public hangfireJobId: string,
    public entityId: number | null,
    public timestamp: number,
  ) {}

  static Name = 'OdataDataSubscriptionJobs';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataDataSubscriptionJobs> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    dataSubscriptionId: { name: 'dataSubscriptionId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    dataSubscriptionJobResult: {
      name: 'dataSubscriptionJobResult',
      type: 'OdataDataSubscriptionJobResult',
      odataType: 'AEX.Inventory.Data.Models.DataSubscriptionJobResult',
      nullable: false,
      isArray: false,
    },
    hangfireJobId: { name: 'hangfireJobId', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    entityId: { name: 'entityId', type: 'number', odataType: 'Edm.Int64', nullable: true, isArray: false },
    timestamp: { name: 'timestamp', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
  };
}

export class OdataAnnouncements {
  constructor(
    public id: number,
    public title: string,
    public body: string | null,
    public activationDate: number | null,
    public expirationDate: number | null,
    public dismissable: boolean,
    public announcementSeverity: OdataAnnouncementSeverity,
    public roles: Array<string> | null,
    public isActive: boolean,
    public createdUser: string,
    public createdDate: number,
  ) {}

  static Name = 'OdataAnnouncements';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataAnnouncements> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    title: { name: 'title', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    body: { name: 'body', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    activationDate: { name: 'activationDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: true, isArray: false },
    expirationDate: { name: 'expirationDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: true, isArray: false },
    dismissable: { name: 'dismissable', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    announcementSeverity: {
      name: 'announcementSeverity',
      type: 'OdataAnnouncementSeverity',
      odataType: 'AEX.Inventory.Data.AnnouncementSeverity',
      nullable: false,
      isArray: false,
    },
    roles: { name: 'roles', type: 'Array<string>', odataType: '', nullable: true, isArray: true, elementKind: 'primitive' },
    isActive: { name: 'isActive', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    createdUser: { name: 'createdUser', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    createdDate: { name: 'createdDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
  };
}

export class OdataExternalApplications {
  constructor(
    public id: number,
    public displayName: string | null,
    public numberOfRoles: number,
    public modifiedUser: string | null,
    public modifiedDate: number,
    public externalApplicationActivationStatus: OdataExternalApplicationActivationStatus,
  ) {}

  static Name = 'OdataExternalApplications';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataExternalApplications> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    displayName: { name: 'displayName', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    numberOfRoles: { name: 'numberOfRoles', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    modifiedUser: { name: 'modifiedUser', type: 'string', odataType: 'Edm.String', nullable: true, isArray: false },
    modifiedDate: { name: 'modifiedDate', type: 'number', odataType: 'Edm.DateTimeOffset', nullable: false, isArray: false },
    externalApplicationActivationStatus: {
      name: 'externalApplicationActivationStatus',
      type: 'OdataExternalApplicationActivationStatus',
      odataType: 'AEX.Inventory.Data.Abstractions.ExternalApplicationActivationStatus',
      nullable: false,
      isArray: false,
    },
  };
}

export class OdataNotificationMessageTemplates {
  constructor(
    public id: number,
    public name: string,
    public numberOfChannels: number,
    public notificationMessagePayloadType: OdataNotificationMessagePayloadType,
    public notificationEvent: OdataNotificationEvent,
  ) {}

  static Name = 'OdataNotificationMessageTemplates';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataNotificationMessageTemplates> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    name: { name: 'name', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    numberOfChannels: { name: 'numberOfChannels', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    notificationMessagePayloadType: {
      name: 'notificationMessagePayloadType',
      type: 'OdataNotificationMessagePayloadType',
      odataType: 'AEX.Inventory.Data.NotificationMessagePayloadType',
      nullable: false,
      isArray: false,
    },
    notificationEvent: {
      name: 'notificationEvent',
      type: 'OdataNotificationEvent',
      odataType: 'AEX.Inventory.Data.NotificationEvent',
      nullable: false,
      isArray: false,
    },
  };
}

export class OdataNotificationClients {
  constructor(
    public id: number,
    public name: string,
    public notificationClientType: OdataNotificationClientType,
    public resourceGroupId: number,
    public resourceGroupName: string,
    public numberOfChannels: number,
    public canDelete: boolean,
  ) {}

  static Name = 'OdataNotificationClients';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataNotificationClients> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    name: { name: 'name', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    notificationClientType: {
      name: 'notificationClientType',
      type: 'OdataNotificationClientType',
      odataType: 'AEX.Inventory.Data.NotificationClientType',
      nullable: false,
      isArray: false,
    },
    resourceGroupId: { name: 'resourceGroupId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    resourceGroupName: { name: 'resourceGroupName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    numberOfChannels: { name: 'numberOfChannels', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    canDelete: { name: 'canDelete', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
  };
}

export class OdataMasterPartAttributeCategories {
  constructor(
    public id: number,
    public name: string,
    public dataType: OdataMasterPartAttributeDataType,
    public displayNameTemplate: string,
    public requiredTemplate: boolean,
    public gS1BarcodeApplicationIdentifier: string | null,
  ) {}

  static Name = 'OdataMasterPartAttributeCategories';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataMasterPartAttributeCategories> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    name: { name: 'name', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    dataType: {
      name: 'dataType',
      type: 'OdataMasterPartAttributeDataType',
      odataType: 'AEX.Inventory.Data.MasterPartAttributeDataType',
      nullable: false,
      isArray: false,
    },
    displayNameTemplate: { name: 'displayNameTemplate', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    requiredTemplate: { name: 'requiredTemplate', type: 'boolean', odataType: 'Edm.Boolean', nullable: false, isArray: false },
    gS1BarcodeApplicationIdentifier: {
      name: 'gS1BarcodeApplicationIdentifier',
      type: 'string',
      odataType: 'Edm.String',
      nullable: true,
      isArray: false,
    },
  };
}

export class OdataStockLevels {
  constructor(
    public id: number,
    public customerId: number,
    public customerCode: string,
    public customerName: string,
    public warehouseId: number,
    public warehouseCode: string,
    public masterPartId: number,
    public masterPartCode: string,
    public desiredCount: number,
    public onHand: number,
  ) {}

  static Name = 'OdataStockLevels';
  static key = 'id';
  static Columns: ColumnsMetadata<OdataStockLevels> = {
    id: { name: 'id', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerId: { name: 'customerId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    customerCode: { name: 'customerCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    customerName: { name: 'customerName', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    warehouseId: { name: 'warehouseId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    warehouseCode: { name: 'warehouseCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    masterPartId: { name: 'masterPartId', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    masterPartCode: { name: 'masterPartCode', type: 'string', odataType: 'Edm.String', nullable: false, isArray: false },
    desiredCount: { name: 'desiredCount', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
    onHand: { name: 'onHand', type: 'number', odataType: 'Edm.Int32', nullable: false, isArray: false },
  };
}

export type QueryArgs<Kind> = {
  query: Query<Kind>;
  endpointPath: string;
};

export const odataApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOdataInventoryReport: build.query<ODataResponse<OdataInventoryReport>, QueryArgs<'OdataInventoryReport'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataInventoryReport>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataDockReceipts: build.query<ODataResponse<OdataDockReceipts>, QueryArgs<'OdataDockReceipts'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataDockReceipts>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataDeliveryReceipts: build.query<ODataResponse<OdataDeliveryReceipts>, QueryArgs<'OdataDeliveryReceipts'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataDeliveryReceipts>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataDeliveryRecipients: build.query<ODataResponse<OdataDeliveryRecipients>, QueryArgs<'OdataDeliveryRecipients'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataDeliveryRecipients>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataMasterParts: build.query<ODataResponse<OdataMasterParts>, QueryArgs<'OdataMasterParts'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataMasterParts>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataMasterPartTemplates: build.query<ODataResponse<OdataMasterPartTemplates>, QueryArgs<'OdataMasterPartTemplates'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (
          arg: string,
        ) => PromiseLike<QueryReturnValue<RawODataResponse<OdataMasterPartTemplates>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataCustomers: build.query<ODataResponse<OdataCustomers>, QueryArgs<'OdataCustomers'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataCustomers>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataWarehouses: build.query<ODataResponse<OdataWarehouses>, QueryArgs<'OdataWarehouses'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataWarehouses>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataBins: build.query<ODataResponse<OdataBins>, QueryArgs<'OdataBins'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataBins>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataZones: build.query<ODataResponse<OdataZones>, QueryArgs<'OdataZones'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataZones>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataCarriers: build.query<ODataResponse<OdataCarriers>, QueryArgs<'OdataCarriers'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataCarriers>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataParts: build.query<ODataResponse<OdataParts>, QueryArgs<'OdataParts'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataParts>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataTemplateMismatchedParts: build.query<ODataResponse<OdataTemplateMismatchedParts>, QueryArgs<'OdataTemplateMismatchedParts'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (
          arg: string,
        ) => PromiseLike<QueryReturnValue<RawODataResponse<OdataTemplateMismatchedParts>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataPartTransactions: build.query<ODataResponse<OdataPartTransactions>, QueryArgs<'OdataPartTransactions'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataPartTransactions>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataPartInformationTransactions: build.query<ODataResponse<OdataPartInformationTransactions>, QueryArgs<'OdataPartInformationTransactions'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (
          arg: string,
        ) => PromiseLike<QueryReturnValue<RawODataResponse<OdataPartInformationTransactions>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataMIR: build.query<ODataResponse<OdataMIR>, QueryArgs<'OdataMIR'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataMIR>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataCarts: build.query<ODataResponse<OdataCarts>, QueryArgs<'OdataCarts'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataCarts>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataJobParts: build.query<ODataResponse<OdataJobParts>, QueryArgs<'OdataJobParts'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataJobParts>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataJobs: build.query<ODataResponse<OdataJobs>, QueryArgs<'OdataJobs'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataJobs>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataPutAwayJobs: build.query<ODataResponse<OdataPutAwayJobs>, QueryArgs<'OdataPutAwayJobs'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataPutAwayJobs>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataOutgoingJobs: build.query<ODataResponse<OdataOutgoingJobs>, QueryArgs<'OdataOutgoingJobs'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataOutgoingJobs>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataIncomingJobs: build.query<ODataResponse<OdataIncomingJobs>, QueryArgs<'OdataIncomingJobs'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataIncomingJobs>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataOutbound: build.query<ODataResponse<OdataOutbound>, QueryArgs<'OdataOutbound'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataOutbound>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataTransfers: build.query<ODataResponse<OdataTransfers>, QueryArgs<'OdataTransfers'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataTransfers>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataAdvancedShipments: build.query<ODataResponse<OdataAdvancedShipments>, QueryArgs<'OdataAdvancedShipments'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataAdvancedShipments>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataAdvancedShipmentExpectedParts: build.query<
      ODataResponse<OdataAdvancedShipmentExpectedParts>,
      QueryArgs<'OdataAdvancedShipmentExpectedParts'>
    >({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (
          arg: string,
        ) => PromiseLike<QueryReturnValue<RawODataResponse<OdataAdvancedShipmentExpectedParts>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataUsers: build.query<ODataResponse<OdataUsers>, QueryArgs<'OdataUsers'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataUsers>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataResourceGroups: build.query<ODataResponse<OdataResourceGroups>, QueryArgs<'OdataResourceGroups'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataResourceGroups>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataCycleCounts: build.query<ODataResponse<OdataCycleCounts>, QueryArgs<'OdataCycleCounts'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataCycleCounts>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataDataSubscriptions: build.query<ODataResponse<OdataDataSubscriptions>, QueryArgs<'OdataDataSubscriptions'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataDataSubscriptions>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataDataSubscriptionJobs: build.query<ODataResponse<OdataDataSubscriptionJobs>, QueryArgs<'OdataDataSubscriptionJobs'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (
          arg: string,
        ) => PromiseLike<QueryReturnValue<RawODataResponse<OdataDataSubscriptionJobs>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataAnnouncements: build.query<ODataResponse<OdataAnnouncements>, QueryArgs<'OdataAnnouncements'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataAnnouncements>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataExternalApplications: build.query<ODataResponse<OdataExternalApplications>, QueryArgs<'OdataExternalApplications'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (
          arg: string,
        ) => PromiseLike<QueryReturnValue<RawODataResponse<OdataExternalApplications>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataNotificationMessageTemplates: build.query<
      ODataResponse<OdataNotificationMessageTemplates>,
      QueryArgs<'OdataNotificationMessageTemplates'>
    >({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (
          arg: string,
        ) => PromiseLike<QueryReturnValue<RawODataResponse<OdataNotificationMessageTemplates>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataNotificationClients: build.query<ODataResponse<OdataNotificationClients>, QueryArgs<'OdataNotificationClients'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (
          arg: string,
        ) => PromiseLike<QueryReturnValue<RawODataResponse<OdataNotificationClients>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataMasterPartAttributeCategories: build.query<
      ODataResponse<OdataMasterPartAttributeCategories>,
      QueryArgs<'OdataMasterPartAttributeCategories'>
    >({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (
          arg: string,
        ) => PromiseLike<QueryReturnValue<RawODataResponse<OdataMasterPartAttributeCategories>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
    getOdataStockLevels: build.query<ODataResponse<OdataStockLevels>, QueryArgs<'OdataStockLevels'>>({
      queryFn: (
        args,
        queryApi,
        extraOptions,
        baseQuery: (arg: string) => PromiseLike<QueryReturnValue<RawODataResponse<OdataStockLevels>, FetchBaseQueryError, FetchBaseQueryMeta>>,
      ) => {
        return loadData(baseQuery, args);
      },
      keepUnusedDataFor: 15,
    }),
  }),
});

export type ODataQuerySettings<Resource> = {
  query?: (
    builder: Omit<QueryBuilder<Resource>, 'and' | 'or'>,
  ) =>
    | Pick<QueryBuilder<Resource>, 'skip'>
    | Pick<QueryBuilder<Resource>, 'take'>
    | Omit<QueryBuilder<Resource>, 'filter' | 'orderBy' | 'and' | 'or'>
    | boolean
    | undefined;
};

export type ODataSourceSettings<Resource> = {
  key?: any;
  metadata?: { columns: RecursivePartial<ColumnsMetadata<Resource>> };
  query?: (
    builder: Omit<NoSkipAndTakeBuilder<Resource>, 'and' | 'or'>,
    options: LoadOptions,
  ) => Pick<NoSkipAndTakeBuilder<Resource>, 'filter'> | Omit<NoSkipAndTakeBuilder<Resource>, 'filter'> | boolean | undefined;
  transformResults?: (items: Array<Resource>) => Array<any>;
  transformOptions?: (options: LoadOptions) => LoadOptions;
};

export function useGetOdataInventoryReportQuery(configuration: () => ODataQuerySettings<OdataInventoryReport>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataInventoryReport'> = { $count: true, kind: 'OdataInventoryReport' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataInventoryReport, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/InventoryReport' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataInventoryReportQuery(settings, options);
  return query;
}

export const useOdataInventoryReportDataSource = (
  configuration: () => ODataSourceSettings<OdataInventoryReport>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataInventoryReportQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/InventoryReport';
    const kind = 'OdataInventoryReport';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataInventoryReport, config.metadata) : OdataInventoryReport;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataInventoryReport, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataInventoryReport> = {
      key: OdataInventoryReport.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataInventoryReportQuery() {
  const [trigger] = odataApi.useLazyGetOdataInventoryReportQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataInventoryReport>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataInventoryReport'> = { $count: true, kind: 'OdataInventoryReport' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataInventoryReport, query));
      }
      return trigger({ query: query, endpointPath: '/odata/InventoryReport' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataDockReceiptsQuery(configuration: () => ODataQuerySettings<OdataDockReceipts>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataDockReceipts'> = { $count: true, kind: 'OdataDockReceipts' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataDockReceipts, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/DockReceipts' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataDockReceiptsQuery(settings, options);
  return query;
}

export const useOdataDockReceiptsDataSource = (configuration: () => ODataSourceSettings<OdataDockReceipts>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataDockReceiptsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/DockReceipts';
    const kind = 'OdataDockReceipts';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataDockReceipts, config.metadata) : OdataDockReceipts;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataDockReceipts, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataDockReceipts> = {
      key: OdataDockReceipts.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataDockReceiptsQuery() {
  const [trigger] = odataApi.useLazyGetOdataDockReceiptsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataDockReceipts>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataDockReceipts'> = { $count: true, kind: 'OdataDockReceipts' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataDockReceipts, query));
      }
      return trigger({ query: query, endpointPath: '/odata/DockReceipts' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataDeliveryReceiptsQuery(configuration: () => ODataQuerySettings<OdataDeliveryReceipts>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataDeliveryReceipts'> = { $count: true, kind: 'OdataDeliveryReceipts' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataDeliveryReceipts, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/DeliveryReceipts' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataDeliveryReceiptsQuery(settings, options);
  return query;
}

export const useOdataDeliveryReceiptsDataSource = (
  configuration: () => ODataSourceSettings<OdataDeliveryReceipts>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataDeliveryReceiptsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/DeliveryReceipts';
    const kind = 'OdataDeliveryReceipts';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataDeliveryReceipts, config.metadata) : OdataDeliveryReceipts;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataDeliveryReceipts, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataDeliveryReceipts> = {
      key: OdataDeliveryReceipts.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataDeliveryReceiptsQuery() {
  const [trigger] = odataApi.useLazyGetOdataDeliveryReceiptsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataDeliveryReceipts>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataDeliveryReceipts'> = { $count: true, kind: 'OdataDeliveryReceipts' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataDeliveryReceipts, query));
      }
      return trigger({ query: query, endpointPath: '/odata/DeliveryReceipts' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataDeliveryRecipientsQuery(configuration: () => ODataQuerySettings<OdataDeliveryRecipients>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataDeliveryRecipients'> = { $count: true, kind: 'OdataDeliveryRecipients' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataDeliveryRecipients, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/DeliveryRecipients' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataDeliveryRecipientsQuery(settings, options);
  return query;
}

export const useOdataDeliveryRecipientsDataSource = (
  configuration: () => ODataSourceSettings<OdataDeliveryRecipients>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataDeliveryRecipientsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/DeliveryRecipients';
    const kind = 'OdataDeliveryRecipients';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataDeliveryRecipients, config.metadata) : OdataDeliveryRecipients;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataDeliveryRecipients, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataDeliveryRecipients> = {
      key: OdataDeliveryRecipients.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataDeliveryRecipientsQuery() {
  const [trigger] = odataApi.useLazyGetOdataDeliveryRecipientsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataDeliveryRecipients>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataDeliveryRecipients'> = { $count: true, kind: 'OdataDeliveryRecipients' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataDeliveryRecipients, query));
      }
      return trigger({ query: query, endpointPath: '/odata/DeliveryRecipients' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataMasterPartsQuery(configuration: () => ODataQuerySettings<OdataMasterParts>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataMasterParts'> = { $count: true, kind: 'OdataMasterParts' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataMasterParts, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/MasterParts' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataMasterPartsQuery(settings, options);
  return query;
}

export const useOdataMasterPartsDataSource = (configuration: () => ODataSourceSettings<OdataMasterParts>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataMasterPartsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/MasterParts';
    const kind = 'OdataMasterParts';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataMasterParts, config.metadata) : OdataMasterParts;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataMasterParts, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataMasterParts> = {
      key: OdataMasterParts.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataMasterPartsQuery() {
  const [trigger] = odataApi.useLazyGetOdataMasterPartsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataMasterParts>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataMasterParts'> = { $count: true, kind: 'OdataMasterParts' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataMasterParts, query));
      }
      return trigger({ query: query, endpointPath: '/odata/MasterParts' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataMasterPartTemplatesQuery(configuration: () => ODataQuerySettings<OdataMasterPartTemplates>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataMasterPartTemplates'> = { $count: true, kind: 'OdataMasterPartTemplates' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataMasterPartTemplates, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/MasterPartTemplates' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataMasterPartTemplatesQuery(settings, options);
  return query;
}

export const useOdataMasterPartTemplatesDataSource = (
  configuration: () => ODataSourceSettings<OdataMasterPartTemplates>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataMasterPartTemplatesQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/MasterPartTemplates';
    const kind = 'OdataMasterPartTemplates';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataMasterPartTemplates, config.metadata) : OdataMasterPartTemplates;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataMasterPartTemplates, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataMasterPartTemplates> = {
      key: OdataMasterPartTemplates.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataMasterPartTemplatesQuery() {
  const [trigger] = odataApi.useLazyGetOdataMasterPartTemplatesQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataMasterPartTemplates>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataMasterPartTemplates'> = { $count: true, kind: 'OdataMasterPartTemplates' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataMasterPartTemplates, query));
      }
      return trigger({ query: query, endpointPath: '/odata/MasterPartTemplates' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataCustomersQuery(configuration: () => ODataQuerySettings<OdataCustomers>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataCustomers'> = { $count: true, kind: 'OdataCustomers' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataCustomers, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/Customers' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataCustomersQuery(settings, options);
  return query;
}

export const useOdataCustomersDataSource = (configuration: () => ODataSourceSettings<OdataCustomers>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataCustomersQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/Customers';
    const kind = 'OdataCustomers';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataCustomers, config.metadata) : OdataCustomers;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataCustomers, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataCustomers> = {
      key: OdataCustomers.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataCustomersQuery() {
  const [trigger] = odataApi.useLazyGetOdataCustomersQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataCustomers>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataCustomers'> = { $count: true, kind: 'OdataCustomers' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataCustomers, query));
      }
      return trigger({ query: query, endpointPath: '/odata/Customers' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataWarehousesQuery(configuration: () => ODataQuerySettings<OdataWarehouses>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataWarehouses'> = { $count: true, kind: 'OdataWarehouses' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataWarehouses, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/Warehouses' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataWarehousesQuery(settings, options);
  return query;
}

export const useOdataWarehousesDataSource = (configuration: () => ODataSourceSettings<OdataWarehouses>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataWarehousesQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/Warehouses';
    const kind = 'OdataWarehouses';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataWarehouses, config.metadata) : OdataWarehouses;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataWarehouses, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataWarehouses> = {
      key: OdataWarehouses.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataWarehousesQuery() {
  const [trigger] = odataApi.useLazyGetOdataWarehousesQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataWarehouses>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataWarehouses'> = { $count: true, kind: 'OdataWarehouses' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataWarehouses, query));
      }
      return trigger({ query: query, endpointPath: '/odata/Warehouses' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataBinsQuery(configuration: () => ODataQuerySettings<OdataBins>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataBins'> = { $count: true, kind: 'OdataBins' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataBins, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/Bins' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataBinsQuery(settings, options);
  return query;
}

export const useOdataBinsDataSource = (configuration: () => ODataSourceSettings<OdataBins>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataBinsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/Bins';
    const kind = 'OdataBins';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataBins, config.metadata) : OdataBins;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataBins, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataBins> = {
      key: OdataBins.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataBinsQuery() {
  const [trigger] = odataApi.useLazyGetOdataBinsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataBins>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataBins'> = { $count: true, kind: 'OdataBins' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataBins, query));
      }
      return trigger({ query: query, endpointPath: '/odata/Bins' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataZonesQuery(configuration: () => ODataQuerySettings<OdataZones>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataZones'> = { $count: true, kind: 'OdataZones' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataZones, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/Zones' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataZonesQuery(settings, options);
  return query;
}

export const useOdataZonesDataSource = (configuration: () => ODataSourceSettings<OdataZones>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataZonesQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/Zones';
    const kind = 'OdataZones';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataZones, config.metadata) : OdataZones;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataZones, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataZones> = {
      key: OdataZones.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataZonesQuery() {
  const [trigger] = odataApi.useLazyGetOdataZonesQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataZones>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataZones'> = { $count: true, kind: 'OdataZones' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataZones, query));
      }
      return trigger({ query: query, endpointPath: '/odata/Zones' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataCarriersQuery(configuration: () => ODataQuerySettings<OdataCarriers>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataCarriers'> = { $count: true, kind: 'OdataCarriers' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataCarriers, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/Carriers' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataCarriersQuery(settings, options);
  return query;
}

export const useOdataCarriersDataSource = (configuration: () => ODataSourceSettings<OdataCarriers>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataCarriersQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/Carriers';
    const kind = 'OdataCarriers';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataCarriers, config.metadata) : OdataCarriers;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataCarriers, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataCarriers> = {
      key: OdataCarriers.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataCarriersQuery() {
  const [trigger] = odataApi.useLazyGetOdataCarriersQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataCarriers>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataCarriers'> = { $count: true, kind: 'OdataCarriers' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataCarriers, query));
      }
      return trigger({ query: query, endpointPath: '/odata/Carriers' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataPartsQuery(configuration: () => ODataQuerySettings<OdataParts>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataParts'> = { $count: true, kind: 'OdataParts' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataParts, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/Parts' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataPartsQuery(settings, options);
  return query;
}

export const useOdataPartsDataSource = (configuration: () => ODataSourceSettings<OdataParts>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataPartsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/Parts';
    const kind = 'OdataParts';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataParts, config.metadata) : OdataParts;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataParts, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataParts> = {
      key: OdataParts.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataPartsQuery() {
  const [trigger] = odataApi.useLazyGetOdataPartsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataParts>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataParts'> = { $count: true, kind: 'OdataParts' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataParts, query));
      }
      return trigger({ query: query, endpointPath: '/odata/Parts' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataTemplateMismatchedPartsQuery(configuration: () => ODataQuerySettings<OdataTemplateMismatchedParts>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataTemplateMismatchedParts'> = { $count: true, kind: 'OdataTemplateMismatchedParts' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataTemplateMismatchedParts, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/TemplateMismatchedParts' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataTemplateMismatchedPartsQuery(settings, options);
  return query;
}

export const useOdataTemplateMismatchedPartsDataSource = (
  configuration: () => ODataSourceSettings<OdataTemplateMismatchedParts>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataTemplateMismatchedPartsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/TemplateMismatchedParts';
    const kind = 'OdataTemplateMismatchedParts';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataTemplateMismatchedParts, config.metadata) : OdataTemplateMismatchedParts;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataTemplateMismatchedParts, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataTemplateMismatchedParts> = {
      key: OdataTemplateMismatchedParts.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataTemplateMismatchedPartsQuery() {
  const [trigger] = odataApi.useLazyGetOdataTemplateMismatchedPartsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataTemplateMismatchedParts>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataTemplateMismatchedParts'> = { $count: true, kind: 'OdataTemplateMismatchedParts' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataTemplateMismatchedParts, query));
      }
      return trigger({ query: query, endpointPath: '/odata/TemplateMismatchedParts' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataPartTransactionsQuery(configuration: () => ODataQuerySettings<OdataPartTransactions>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataPartTransactions'> = { $count: true, kind: 'OdataPartTransactions' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataPartTransactions, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/PartTransactions' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataPartTransactionsQuery(settings, options);
  return query;
}

export const useOdataPartTransactionsDataSource = (
  configuration: () => ODataSourceSettings<OdataPartTransactions>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataPartTransactionsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/PartTransactions';
    const kind = 'OdataPartTransactions';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataPartTransactions, config.metadata) : OdataPartTransactions;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataPartTransactions, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataPartTransactions> = {
      key: OdataPartTransactions.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataPartTransactionsQuery() {
  const [trigger] = odataApi.useLazyGetOdataPartTransactionsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataPartTransactions>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataPartTransactions'> = { $count: true, kind: 'OdataPartTransactions' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataPartTransactions, query));
      }
      return trigger({ query: query, endpointPath: '/odata/PartTransactions' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataPartInformationTransactionsQuery(
  configuration: () => ODataQuerySettings<OdataPartInformationTransactions>,
  deps: DependencyList,
) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataPartInformationTransactions'> = { $count: true, kind: 'OdataPartInformationTransactions' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataPartInformationTransactions, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/PartInformationTransactions' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataPartInformationTransactionsQuery(settings, options);
  return query;
}

export const useOdataPartInformationTransactionsDataSource = (
  configuration: () => ODataSourceSettings<OdataPartInformationTransactions>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataPartInformationTransactionsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/PartInformationTransactions';
    const kind = 'OdataPartInformationTransactions';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataPartInformationTransactions, config.metadata) : OdataPartInformationTransactions;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataPartInformationTransactions, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataPartInformationTransactions> = {
      key: OdataPartInformationTransactions.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataPartInformationTransactionsQuery() {
  const [trigger] = odataApi.useLazyGetOdataPartInformationTransactionsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataPartInformationTransactions>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataPartInformationTransactions'> = { $count: true, kind: 'OdataPartInformationTransactions' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataPartInformationTransactions, query));
      }
      return trigger({ query: query, endpointPath: '/odata/PartInformationTransactions' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataMIRQuery(configuration: () => ODataQuerySettings<OdataMIR>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataMIR'> = { $count: true, kind: 'OdataMIR' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataMIR, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/MIR' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataMIRQuery(settings, options);
  return query;
}

export const useOdataMIRDataSource = (configuration: () => ODataSourceSettings<OdataMIR>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataMIRQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/MIR';
    const kind = 'OdataMIR';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataMIR, config.metadata) : OdataMIR;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataMIR, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataMIR> = {
      key: OdataMIR.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataMIRQuery() {
  const [trigger] = odataApi.useLazyGetOdataMIRQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataMIR>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataMIR'> = { $count: true, kind: 'OdataMIR' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataMIR, query));
      }
      return trigger({ query: query, endpointPath: '/odata/MIR' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataCartsQuery(configuration: () => ODataQuerySettings<OdataCarts>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataCarts'> = { $count: true, kind: 'OdataCarts' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataCarts, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/Carts' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataCartsQuery(settings, options);
  return query;
}

export const useOdataCartsDataSource = (configuration: () => ODataSourceSettings<OdataCarts>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataCartsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/Carts';
    const kind = 'OdataCarts';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataCarts, config.metadata) : OdataCarts;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataCarts, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataCarts> = {
      key: OdataCarts.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataCartsQuery() {
  const [trigger] = odataApi.useLazyGetOdataCartsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataCarts>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataCarts'> = { $count: true, kind: 'OdataCarts' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataCarts, query));
      }
      return trigger({ query: query, endpointPath: '/odata/Carts' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataJobPartsQuery(configuration: () => ODataQuerySettings<OdataJobParts>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataJobParts'> = { $count: true, kind: 'OdataJobParts' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataJobParts, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/JobParts' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataJobPartsQuery(settings, options);
  return query;
}

export const useOdataJobPartsDataSource = (configuration: () => ODataSourceSettings<OdataJobParts>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataJobPartsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/JobParts';
    const kind = 'OdataJobParts';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataJobParts, config.metadata) : OdataJobParts;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataJobParts, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataJobParts> = {
      key: OdataJobParts.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataJobPartsQuery() {
  const [trigger] = odataApi.useLazyGetOdataJobPartsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataJobParts>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataJobParts'> = { $count: true, kind: 'OdataJobParts' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataJobParts, query));
      }
      return trigger({ query: query, endpointPath: '/odata/JobParts' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataJobsQuery(configuration: () => ODataQuerySettings<OdataJobs>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataJobs'> = { $count: true, kind: 'OdataJobs' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataJobs, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/Jobs' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataJobsQuery(settings, options);
  return query;
}

export const useOdataJobsDataSource = (configuration: () => ODataSourceSettings<OdataJobs>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataJobsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/Jobs';
    const kind = 'OdataJobs';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataJobs, config.metadata) : OdataJobs;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataJobs, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataJobs> = {
      key: OdataJobs.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataJobsQuery() {
  const [trigger] = odataApi.useLazyGetOdataJobsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataJobs>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataJobs'> = { $count: true, kind: 'OdataJobs' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataJobs, query));
      }
      return trigger({ query: query, endpointPath: '/odata/Jobs' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataPutAwayJobsQuery(configuration: () => ODataQuerySettings<OdataPutAwayJobs>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataPutAwayJobs'> = { $count: true, kind: 'OdataPutAwayJobs' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataPutAwayJobs, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/PutAwayJobs' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataPutAwayJobsQuery(settings, options);
  return query;
}

export const useOdataPutAwayJobsDataSource = (configuration: () => ODataSourceSettings<OdataPutAwayJobs>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataPutAwayJobsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/PutAwayJobs';
    const kind = 'OdataPutAwayJobs';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataPutAwayJobs, config.metadata) : OdataPutAwayJobs;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataPutAwayJobs, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataPutAwayJobs> = {
      key: OdataPutAwayJobs.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataPutAwayJobsQuery() {
  const [trigger] = odataApi.useLazyGetOdataPutAwayJobsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataPutAwayJobs>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataPutAwayJobs'> = { $count: true, kind: 'OdataPutAwayJobs' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataPutAwayJobs, query));
      }
      return trigger({ query: query, endpointPath: '/odata/PutAwayJobs' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataOutgoingJobsQuery(configuration: () => ODataQuerySettings<OdataOutgoingJobs>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataOutgoingJobs'> = { $count: true, kind: 'OdataOutgoingJobs' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataOutgoingJobs, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/OutgoingJobs' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataOutgoingJobsQuery(settings, options);
  return query;
}

export const useOdataOutgoingJobsDataSource = (configuration: () => ODataSourceSettings<OdataOutgoingJobs>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataOutgoingJobsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/OutgoingJobs';
    const kind = 'OdataOutgoingJobs';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataOutgoingJobs, config.metadata) : OdataOutgoingJobs;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataOutgoingJobs, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataOutgoingJobs> = {
      key: OdataOutgoingJobs.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataOutgoingJobsQuery() {
  const [trigger] = odataApi.useLazyGetOdataOutgoingJobsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataOutgoingJobs>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataOutgoingJobs'> = { $count: true, kind: 'OdataOutgoingJobs' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataOutgoingJobs, query));
      }
      return trigger({ query: query, endpointPath: '/odata/OutgoingJobs' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataIncomingJobsQuery(configuration: () => ODataQuerySettings<OdataIncomingJobs>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataIncomingJobs'> = { $count: true, kind: 'OdataIncomingJobs' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataIncomingJobs, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/IncomingJobs' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataIncomingJobsQuery(settings, options);
  return query;
}

export const useOdataIncomingJobsDataSource = (configuration: () => ODataSourceSettings<OdataIncomingJobs>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataIncomingJobsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/IncomingJobs';
    const kind = 'OdataIncomingJobs';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataIncomingJobs, config.metadata) : OdataIncomingJobs;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataIncomingJobs, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataIncomingJobs> = {
      key: OdataIncomingJobs.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataIncomingJobsQuery() {
  const [trigger] = odataApi.useLazyGetOdataIncomingJobsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataIncomingJobs>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataIncomingJobs'> = { $count: true, kind: 'OdataIncomingJobs' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataIncomingJobs, query));
      }
      return trigger({ query: query, endpointPath: '/odata/IncomingJobs' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataOutboundQuery(configuration: () => ODataQuerySettings<OdataOutbound>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataOutbound'> = { $count: true, kind: 'OdataOutbound' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataOutbound, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/Outbound' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataOutboundQuery(settings, options);
  return query;
}

export const useOdataOutboundDataSource = (configuration: () => ODataSourceSettings<OdataOutbound>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataOutboundQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/Outbound';
    const kind = 'OdataOutbound';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataOutbound, config.metadata) : OdataOutbound;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataOutbound, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataOutbound> = {
      key: OdataOutbound.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataOutboundQuery() {
  const [trigger] = odataApi.useLazyGetOdataOutboundQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataOutbound>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataOutbound'> = { $count: true, kind: 'OdataOutbound' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataOutbound, query));
      }
      return trigger({ query: query, endpointPath: '/odata/Outbound' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataTransfersQuery(configuration: () => ODataQuerySettings<OdataTransfers>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataTransfers'> = { $count: true, kind: 'OdataTransfers' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataTransfers, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/Transfers' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataTransfersQuery(settings, options);
  return query;
}

export const useOdataTransfersDataSource = (configuration: () => ODataSourceSettings<OdataTransfers>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataTransfersQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/Transfers';
    const kind = 'OdataTransfers';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataTransfers, config.metadata) : OdataTransfers;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataTransfers, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataTransfers> = {
      key: OdataTransfers.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataTransfersQuery() {
  const [trigger] = odataApi.useLazyGetOdataTransfersQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataTransfers>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataTransfers'> = { $count: true, kind: 'OdataTransfers' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataTransfers, query));
      }
      return trigger({ query: query, endpointPath: '/odata/Transfers' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataAdvancedShipmentsQuery(configuration: () => ODataQuerySettings<OdataAdvancedShipments>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataAdvancedShipments'> = { $count: true, kind: 'OdataAdvancedShipments' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataAdvancedShipments, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/AdvancedShipments' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataAdvancedShipmentsQuery(settings, options);
  return query;
}

export const useOdataAdvancedShipmentsDataSource = (
  configuration: () => ODataSourceSettings<OdataAdvancedShipments>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataAdvancedShipmentsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/AdvancedShipments';
    const kind = 'OdataAdvancedShipments';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataAdvancedShipments, config.metadata) : OdataAdvancedShipments;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataAdvancedShipments, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataAdvancedShipments> = {
      key: OdataAdvancedShipments.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataAdvancedShipmentsQuery() {
  const [trigger] = odataApi.useLazyGetOdataAdvancedShipmentsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataAdvancedShipments>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataAdvancedShipments'> = { $count: true, kind: 'OdataAdvancedShipments' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataAdvancedShipments, query));
      }
      return trigger({ query: query, endpointPath: '/odata/AdvancedShipments' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataAdvancedShipmentExpectedPartsQuery(
  configuration: () => ODataQuerySettings<OdataAdvancedShipmentExpectedParts>,
  deps: DependencyList,
) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataAdvancedShipmentExpectedParts'> = { $count: true, kind: 'OdataAdvancedShipmentExpectedParts' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataAdvancedShipmentExpectedParts, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/AdvancedShipmentExpectedParts' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataAdvancedShipmentExpectedPartsQuery(settings, options);
  return query;
}

export const useOdataAdvancedShipmentExpectedPartsDataSource = (
  configuration: () => ODataSourceSettings<OdataAdvancedShipmentExpectedParts>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataAdvancedShipmentExpectedPartsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/AdvancedShipmentExpectedParts';
    const kind = 'OdataAdvancedShipmentExpectedParts';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataAdvancedShipmentExpectedParts, config.metadata) : OdataAdvancedShipmentExpectedParts;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataAdvancedShipmentExpectedParts, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataAdvancedShipmentExpectedParts> = {
      key: OdataAdvancedShipmentExpectedParts.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataAdvancedShipmentExpectedPartsQuery() {
  const [trigger] = odataApi.useLazyGetOdataAdvancedShipmentExpectedPartsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataAdvancedShipmentExpectedParts>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataAdvancedShipmentExpectedParts'> = { $count: true, kind: 'OdataAdvancedShipmentExpectedParts' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataAdvancedShipmentExpectedParts, query));
      }
      return trigger({ query: query, endpointPath: '/odata/AdvancedShipmentExpectedParts' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataUsersQuery(configuration: () => ODataQuerySettings<OdataUsers>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataUsers'> = { $count: true, kind: 'OdataUsers' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataUsers, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/Users' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataUsersQuery(settings, options);
  return query;
}

export const useOdataUsersDataSource = (configuration: () => ODataSourceSettings<OdataUsers>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataUsersQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/Users';
    const kind = 'OdataUsers';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataUsers, config.metadata) : OdataUsers;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataUsers, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataUsers> = {
      key: OdataUsers.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataUsersQuery() {
  const [trigger] = odataApi.useLazyGetOdataUsersQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataUsers>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataUsers'> = { $count: true, kind: 'OdataUsers' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataUsers, query));
      }
      return trigger({ query: query, endpointPath: '/odata/Users' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataResourceGroupsQuery(configuration: () => ODataQuerySettings<OdataResourceGroups>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataResourceGroups'> = { $count: true, kind: 'OdataResourceGroups' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataResourceGroups, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/ResourceGroups' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataResourceGroupsQuery(settings, options);
  return query;
}

export const useOdataResourceGroupsDataSource = (
  configuration: () => ODataSourceSettings<OdataResourceGroups>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataResourceGroupsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/ResourceGroups';
    const kind = 'OdataResourceGroups';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataResourceGroups, config.metadata) : OdataResourceGroups;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataResourceGroups, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataResourceGroups> = {
      key: OdataResourceGroups.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataResourceGroupsQuery() {
  const [trigger] = odataApi.useLazyGetOdataResourceGroupsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataResourceGroups>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataResourceGroups'> = { $count: true, kind: 'OdataResourceGroups' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataResourceGroups, query));
      }
      return trigger({ query: query, endpointPath: '/odata/ResourceGroups' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataCycleCountsQuery(configuration: () => ODataQuerySettings<OdataCycleCounts>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataCycleCounts'> = { $count: true, kind: 'OdataCycleCounts' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataCycleCounts, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/CycleCounts' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataCycleCountsQuery(settings, options);
  return query;
}

export const useOdataCycleCountsDataSource = (configuration: () => ODataSourceSettings<OdataCycleCounts>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataCycleCountsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/CycleCounts';
    const kind = 'OdataCycleCounts';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataCycleCounts, config.metadata) : OdataCycleCounts;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataCycleCounts, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataCycleCounts> = {
      key: OdataCycleCounts.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataCycleCountsQuery() {
  const [trigger] = odataApi.useLazyGetOdataCycleCountsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataCycleCounts>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataCycleCounts'> = { $count: true, kind: 'OdataCycleCounts' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataCycleCounts, query));
      }
      return trigger({ query: query, endpointPath: '/odata/CycleCounts' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataDataSubscriptionsQuery(configuration: () => ODataQuerySettings<OdataDataSubscriptions>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataDataSubscriptions'> = { $count: true, kind: 'OdataDataSubscriptions' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataDataSubscriptions, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/DataSubscriptions' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataDataSubscriptionsQuery(settings, options);
  return query;
}

export const useOdataDataSubscriptionsDataSource = (
  configuration: () => ODataSourceSettings<OdataDataSubscriptions>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataDataSubscriptionsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/DataSubscriptions';
    const kind = 'OdataDataSubscriptions';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataDataSubscriptions, config.metadata) : OdataDataSubscriptions;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataDataSubscriptions, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataDataSubscriptions> = {
      key: OdataDataSubscriptions.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataDataSubscriptionsQuery() {
  const [trigger] = odataApi.useLazyGetOdataDataSubscriptionsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataDataSubscriptions>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataDataSubscriptions'> = { $count: true, kind: 'OdataDataSubscriptions' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataDataSubscriptions, query));
      }
      return trigger({ query: query, endpointPath: '/odata/DataSubscriptions' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataDataSubscriptionJobsQuery(configuration: () => ODataQuerySettings<OdataDataSubscriptionJobs>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataDataSubscriptionJobs'> = { $count: true, kind: 'OdataDataSubscriptionJobs' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataDataSubscriptionJobs, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/DataSubscriptionJobs' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataDataSubscriptionJobsQuery(settings, options);
  return query;
}

export const useOdataDataSubscriptionJobsDataSource = (
  configuration: () => ODataSourceSettings<OdataDataSubscriptionJobs>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataDataSubscriptionJobsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/DataSubscriptionJobs';
    const kind = 'OdataDataSubscriptionJobs';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataDataSubscriptionJobs, config.metadata) : OdataDataSubscriptionJobs;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataDataSubscriptionJobs, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataDataSubscriptionJobs> = {
      key: OdataDataSubscriptionJobs.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataDataSubscriptionJobsQuery() {
  const [trigger] = odataApi.useLazyGetOdataDataSubscriptionJobsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataDataSubscriptionJobs>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataDataSubscriptionJobs'> = { $count: true, kind: 'OdataDataSubscriptionJobs' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataDataSubscriptionJobs, query));
      }
      return trigger({ query: query, endpointPath: '/odata/DataSubscriptionJobs' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataAnnouncementsQuery(configuration: () => ODataQuerySettings<OdataAnnouncements>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataAnnouncements'> = { $count: true, kind: 'OdataAnnouncements' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataAnnouncements, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/Announcements' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataAnnouncementsQuery(settings, options);
  return query;
}

export const useOdataAnnouncementsDataSource = (configuration: () => ODataSourceSettings<OdataAnnouncements>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataAnnouncementsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/Announcements';
    const kind = 'OdataAnnouncements';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataAnnouncements, config.metadata) : OdataAnnouncements;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataAnnouncements, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataAnnouncements> = {
      key: OdataAnnouncements.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataAnnouncementsQuery() {
  const [trigger] = odataApi.useLazyGetOdataAnnouncementsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataAnnouncements>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataAnnouncements'> = { $count: true, kind: 'OdataAnnouncements' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataAnnouncements, query));
      }
      return trigger({ query: query, endpointPath: '/odata/Announcements' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataExternalApplicationsQuery(configuration: () => ODataQuerySettings<OdataExternalApplications>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataExternalApplications'> = { $count: true, kind: 'OdataExternalApplications' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataExternalApplications, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/ExternalApplications' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataExternalApplicationsQuery(settings, options);
  return query;
}

export const useOdataExternalApplicationsDataSource = (
  configuration: () => ODataSourceSettings<OdataExternalApplications>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataExternalApplicationsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/ExternalApplications';
    const kind = 'OdataExternalApplications';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataExternalApplications, config.metadata) : OdataExternalApplications;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataExternalApplications, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataExternalApplications> = {
      key: OdataExternalApplications.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataExternalApplicationsQuery() {
  const [trigger] = odataApi.useLazyGetOdataExternalApplicationsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataExternalApplications>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataExternalApplications'> = { $count: true, kind: 'OdataExternalApplications' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataExternalApplications, query));
      }
      return trigger({ query: query, endpointPath: '/odata/ExternalApplications' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataNotificationMessageTemplatesQuery(
  configuration: () => ODataQuerySettings<OdataNotificationMessageTemplates>,
  deps: DependencyList,
) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataNotificationMessageTemplates'> = { $count: true, kind: 'OdataNotificationMessageTemplates' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataNotificationMessageTemplates, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/NotificationMessageTemplates' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataNotificationMessageTemplatesQuery(settings, options);
  return query;
}

export const useOdataNotificationMessageTemplatesDataSource = (
  configuration: () => ODataSourceSettings<OdataNotificationMessageTemplates>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataNotificationMessageTemplatesQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/NotificationMessageTemplates';
    const kind = 'OdataNotificationMessageTemplates';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataNotificationMessageTemplates, config.metadata) : OdataNotificationMessageTemplates;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataNotificationMessageTemplates, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataNotificationMessageTemplates> = {
      key: OdataNotificationMessageTemplates.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataNotificationMessageTemplatesQuery() {
  const [trigger] = odataApi.useLazyGetOdataNotificationMessageTemplatesQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataNotificationMessageTemplates>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataNotificationMessageTemplates'> = { $count: true, kind: 'OdataNotificationMessageTemplates' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataNotificationMessageTemplates, query));
      }
      return trigger({ query: query, endpointPath: '/odata/NotificationMessageTemplates' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataNotificationClientsQuery(configuration: () => ODataQuerySettings<OdataNotificationClients>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataNotificationClients'> = { $count: true, kind: 'OdataNotificationClients' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataNotificationClients, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/NotificationClients' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataNotificationClientsQuery(settings, options);
  return query;
}

export const useOdataNotificationClientsDataSource = (
  configuration: () => ODataSourceSettings<OdataNotificationClients>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataNotificationClientsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/NotificationClients';
    const kind = 'OdataNotificationClients';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataNotificationClients, config.metadata) : OdataNotificationClients;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataNotificationClients, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataNotificationClients> = {
      key: OdataNotificationClients.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataNotificationClientsQuery() {
  const [trigger] = odataApi.useLazyGetOdataNotificationClientsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataNotificationClients>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataNotificationClients'> = { $count: true, kind: 'OdataNotificationClients' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataNotificationClients, query));
      }
      return trigger({ query: query, endpointPath: '/odata/NotificationClients' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataMasterPartAttributeCategoriesQuery(
  configuration: () => ODataQuerySettings<OdataMasterPartAttributeCategories>,
  deps: DependencyList,
) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataMasterPartAttributeCategories'> = { $count: true, kind: 'OdataMasterPartAttributeCategories' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataMasterPartAttributeCategories, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/MasterPartAttributeCategories' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataMasterPartAttributeCategoriesQuery(settings, options);
  return query;
}

export const useOdataMasterPartAttributeCategoriesDataSource = (
  configuration: () => ODataSourceSettings<OdataMasterPartAttributeCategories>,
  deps: DependencyList,
): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataMasterPartAttributeCategoriesQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/MasterPartAttributeCategories';
    const kind = 'OdataMasterPartAttributeCategories';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataMasterPartAttributeCategories, config.metadata) : OdataMasterPartAttributeCategories;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataMasterPartAttributeCategories, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataMasterPartAttributeCategories> = {
      key: OdataMasterPartAttributeCategories.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataMasterPartAttributeCategoriesQuery() {
  const [trigger] = odataApi.useLazyGetOdataMasterPartAttributeCategoriesQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataMasterPartAttributeCategories>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataMasterPartAttributeCategories'> = { $count: true, kind: 'OdataMasterPartAttributeCategories' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataMasterPartAttributeCategories, query));
      }
      return trigger({ query: query, endpointPath: '/odata/MasterPartAttributeCategories' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export function useGetOdataStockLevelsQuery(configuration: () => ODataQuerySettings<OdataStockLevels>, deps: DependencyList) {
  const [settings, options] = useMemo(() => {
    const query: Query<'OdataStockLevels'> = { $count: true, kind: 'OdataStockLevels' };
    let skip = false;
    const config = typeof configuration === 'function' ? configuration() : {};
    if (config.query) {
      const builder = new QueryBuilder(OdataStockLevels, query);
      skip = !config.query(builder);
    }
    return [{ query, endpointPath: '/odata/StockLevels' }, { skip }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const query = odataApi.useGetOdataStockLevelsQuery(settings, options);
  return query;
}

export const useOdataStockLevelsDataSource = (configuration: () => ODataSourceSettings<OdataStockLevels>, deps: DependencyList): XDataSource => {
  const [trigger] = odataApi.useLazyGetOdataStockLevelsQuery();
  const dsConfig = useMemo(() => {
    const defaultEndPointPath = '/odata/StockLevels';
    const kind = 'OdataStockLevels';
    const config = configuration ? configuration() : {};
    const metadata = config.metadata ? extendEntity(OdataStockLevels, config.metadata) : OdataStockLevels;
    const transformOptions = config.transformOptions ? config.transformOptions : (o: LoadOptions) => o;
    const createCombinedQuery = (options: LoadOptions) => {
      const query = { $count: true };
      const builder: any = new QueryBuilder(OdataStockLevels, query);
      if (config.query && !config.query(builder, options)) {
        return null;
      }
      const queryFromOptions = optionsToQueryState(options, metadata);
      return mergeQueries(queryFromOptions, query);
    };

    const combinedConfig: ODataStoreSettings<OdataStockLevels> = {
      key: OdataStockLevels.key,
      ...config,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return Promise.resolve({ value: [], totalCount: 0 });
        }
        return trigger({ query: { ...combinedQuery, kind }, endpointPath }, false).unwrap();
      },
      createOdataQuery: (options: LoadOptions, endpointPath = defaultEndPointPath) => {
        const combinedQuery = createCombinedQuery(transformOptions(options));
        if (!combinedQuery) {
          return endpointPath;
        }

        return `${endpointPath}?${createODataQuery(combinedQuery)}`;
      },
    };

    return combinedConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const ds = useDataSource(dsConfig);
  return ds;
};

export function useLazyGetOdataStockLevelsQuery() {
  const [trigger] = odataApi.useLazyGetOdataStockLevelsQuery();

  const query = useCallback(
    (arg: ODataQuerySettings<OdataStockLevels>, preferCacheValue?: boolean | undefined) => {
      const query: Query<'OdataStockLevels'> = { $count: true, kind: 'OdataStockLevels' };
      if (typeof arg.query === 'function') {
        arg.query(new QueryBuilder(OdataStockLevels, query));
      }
      return trigger({ query: query, endpointPath: '/odata/StockLevels' }, preferCacheValue);
    },
    [trigger],
  );

  const result = useMemo(() => [query], [query]);

  return result;
}

export const useOdataPropertyListDataStore = <T = any>(config: () => XPropertyListSettings<T>, deps: DependencyList) => {
  const combinedSettings: ODataStoreSettings<any> = useMemo(() => {
    const { dataSource, property, operator, transformResult } = config();
    const {
      settings: { endpoint, endpointPath, createOdataQuery },
    } = dataSource;
    const transformResultFn: (x: any) => any = transformResult
      ? (x) => ({ ...transformResult(x), [property]: x })
      : (x) => ({ text: x, value: x, [property]: x });
    const defaultEndPointPath = `${endpointPath}/GetHeaderFilterList(propertyName='${property}')`;
    const value: ODataStoreSettings<any> = {
      key: property,
      get endpointPath() {
        return defaultEndPointPath;
      },
      endpoint: (options: LoadOptions) => {
        return endpoint(options, defaultEndPointPath);
      },
      createOdataQuery(options, endpointPath = defaultEndPointPath) {
        return createOdataQuery(options, endpointPath);
      },
      transformOptions: (options) => {
        const filter = filterProperty(dataSource.lastFilter, property) ?? [];
        const listFilter = createFilterFromListOptions(property, operator ?? 'startsWith', options);
        if (listFilter.length > 0) {
          if (filter.length > 0) {
            filter.push('and');
          }
          filter.push(listFilter);
        }

        return {
          ...options,
          filter,
        };
      },
      transformResults: (items) => {
        return items.map(transformResultFn);
      },
    };

    return value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return useDataStore(combinedSettings);
};
