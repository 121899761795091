import type { IServiceLocator } from '../abstractions';

class Service implements IServiceLocator {
  private registrations: { [name: string]: () => any } = {};

  register = <T>(name: string, deps: Array<string>, factory: (...args: Array<any>) => T) => {
    const factoryWithDeps = () => {
      const resolvedDependencies = deps.map((depName) => this.get(depName));
      return factory(...resolvedDependencies);
    };

    this.registrations[name] = factoryWithDeps;
  };

  registerSingleton = <T>(name: string, deps: Array<string>, factory: (...args: Array<any>) => T) => {
    let instance: T;
    const factoryWithDeps = () => {
      if (!instance) {
        const resolvedDependencies = deps.map((depName) => this.get(depName));
        instance = factory(...resolvedDependencies);
      }
      return instance;
    };

    this.registrations[name] = factoryWithDeps;
  };

  get = <T>(name: string, or?: () => T): T => {
    const registration = this.registrations[name];
    if (!registration) {
      if (or) {
        return or();
      }

      throw new Error(`Could not find a service named "${name}"`);
    }

    return registration();
  };
}

export const ServiceLocator = new Service();
