// THIS IS GENERATED FILE.  YOUR CHANGES WILL BE LOST.

export const Permissions = {
  ReadHeaderFilterList: '.ReadHeaderFilterList',
  ReadListCount: '.ReadListCount',
  AboutRead: 'About.Read',
  AdminAbortAllConnections: 'Admin.AbortAllConnections',
  AdminAbortChangeProcessing: 'Admin.AbortChangeProcessing',
  AdminAbortMigration: 'Admin.AbortMigration',
  AdminAcquireApplicationToken: 'Admin.AcquireApplicationToken',
  AdminAcquireLock: 'Admin.AcquireLock',
  AdminChangeLoggingLevel: 'Admin.ChangeLoggingLevel',
  AdminChangeMinimumLogLevel: 'Admin.ChangeMinimumLogLevel',
  AdminDisableDatabaseChangeMonitor: 'Admin.DisableDatabaseChangeMonitor',
  AdminEnableBodyLogging: 'Admin.EnableBodyLogging',
  AdminEnableBulkUpdate: 'Admin.EnableBulkUpdate',
  AdminEnableHeaderLogging: 'Admin.EnableHeaderLogging',
  AdminEnableODataBodyLogging: 'Admin.EnableODataBodyLogging',
  AdminExpireAllTokens: 'Admin.ExpireAllTokens',
  AdminInitializeMaterializedParts: 'Admin.InitializeMaterializedParts',
  AdminInitializeMaterializedPartTransactions: 'Admin.InitializeMaterializedPartTransactions',
  AdminInitiateMigration: 'Admin.InitiateMigration',
  AdminMarkAllSynced: 'Admin.MarkAllSynced',
  AdminPauseDatabaseChangeMonitor: 'Admin.PauseDatabaseChangeMonitor',
  AdminProcessUnsyncedChanges: 'Admin.ProcessUnsyncedChanges',
  AdminReadOnlyMode: 'Admin.ReadOnlyMode',
  AdminRebuildReadCache: 'Admin.RebuildReadCache',
  AdminRebuildReadCacheForEntity: 'Admin.RebuildReadCacheForEntity',
  AdminRefreshLock: 'Admin.RefreshLock',
  AdminReleaseLock: 'Admin.ReleaseLock',
  AdminResetLegacySync: 'Admin.ResetLegacySync',
  AdminResetLoggingLevels: 'Admin.ResetLoggingLevels',
  AdminResetTokenExpiry: 'Admin.ResetTokenExpiry',
  AdminSendToast: 'Admin.SendToast',
  AdminSkipLegacyTransaction: 'Admin.SkipLegacyTransaction',
  AdminSynchronizeReadCache: 'Admin.SynchronizeReadCache',
  AdminUnallocatePartsWithNoJobs: 'Admin.UnallocatePartsWithNoJobs',
  AdminUseAsyncByDefault: 'Admin.UseAsyncByDefault',
  AdminUseSqlForOdata: 'Admin.UseSqlForOdata',
  AdvancedShipmentsCreate: 'AdvancedShipments.Create',
  AdvancedShipmentsList: 'AdvancedShipments.List',
  AdvancedShipmentsRead: 'AdvancedShipments.Read',
  AnnouncementsCreate: 'Announcements.Create',
  AnnouncementsDelete: 'Announcements.Delete',
  AnnouncementsList: 'Announcements.List',
  AnnouncementsRead: 'Announcements.Read',
  AnnouncementsReadCurrentAnnouncements: 'Announcements.ReadCurrentAnnouncements',
  AnnouncementsUpdate: 'Announcements.Update',
  BarcodesReadGs1ApplicationIdentifiers: 'Barcodes.ReadGs1ApplicationIdentifiers',
  BinsCreate: 'Bins.Create',
  BinsList: 'Bins.List',
  BinsRead: 'Bins.Read',
  BinsUpdate: 'Bins.Update',
  BinsUpdateBulk: 'Bins.UpdateBulk',
  CarriersCreate: 'Carriers.Create',
  CarriersList: 'Carriers.List',
  CarriersRead: 'Carriers.Read',
  CarriersUpdate: 'Carriers.Update',
  CartsCreate: 'Carts.Create',
  CartsList: 'Carts.List',
  CartsRead: 'Carts.Read',
  ConversationsAddConversationMessage: 'Conversations.AddConversationMessage',
  ConversationsRead: 'Conversations.Read',
  CustomersCreate: 'Customers.Create',
  CustomersList: 'Customers.List',
  CustomersRead: 'Customers.Read',
  CustomersUpdate: 'Customers.Update',
  CycleCountsCreate: 'CycleCounts.Create',
  CycleCountsDelete: 'CycleCounts.Delete',
  CycleCountsList: 'CycleCounts.List',
  CycleCountsRead: 'CycleCounts.Read',
  CycleCountsUpdate: 'CycleCounts.Update',
  CycleCountsUploadCount: 'CycleCounts.UploadCount',
  DashboardReadEmbeddedDashboards: 'Dashboard.ReadEmbeddedDashboards',
  DataSubscriptionJobsCreateJob: 'DataSubscriptionJobs.CreateJob',
  DataSubscriptionJobsList: 'DataSubscriptionJobs.List',
  DataSubscriptionsCreate: 'DataSubscriptions.Create',
  DataSubscriptionsDelete: 'DataSubscriptions.Delete',
  DataSubscriptionsList: 'DataSubscriptions.List',
  DataSubscriptionsRead: 'DataSubscriptions.Read',
  DataSubscriptionsReadDataSubscriptionDataSources: 'DataSubscriptions.ReadDataSubscriptionDataSources',
  DataSubscriptionsReadDataSubscriptionObjectTemplate: 'DataSubscriptions.ReadDataSubscriptionObjectTemplate',
  DataSubscriptionsReadDataSubscriptionTriggerForDataSource: 'DataSubscriptions.ReadDataSubscriptionTriggerForDataSource',
  DataSubscriptionsSendDataSubscriptionTestPayload: 'DataSubscriptions.SendDataSubscriptionTestPayload',
  DataSubscriptionsUpdate: 'DataSubscriptions.Update',
  DeliveryReceiptsCreate: 'DeliveryReceipts.Create',
  DeliveryReceiptsList: 'DeliveryReceipts.List',
  DeliveryReceiptsRead: 'DeliveryReceipts.Read',
  DeliveryRecipientsCreate: 'DeliveryRecipients.Create',
  DeliveryRecipientsList: 'DeliveryRecipients.List',
  DeliveryRecipientsRead: 'DeliveryRecipients.Read',
  DeliveryRecipientsUpdate: 'DeliveryRecipients.Update',
  DeveloperConsole: 'Developer.Console',
  DeveloperSendWebsocketMessage: 'Developer.SendWebsocketMessage',
  DeveloperSkipJobNumberCheck: 'Developer.SkipJobNumberCheck',
  DockReceiptsChangeStatus: 'DockReceipts.ChangeStatus',
  DockReceiptsCreate: 'DockReceipts.Create',
  DockReceiptsDelete: 'DockReceipts.Delete',
  DockReceiptsHistory: 'DockReceipts.History',
  DockReceiptsList: 'DockReceipts.List',
  DockReceiptsRead: 'DockReceipts.Read',
  DockReceiptsRemoveDelivery: 'DockReceipts.RemoveDelivery',
  EntityAttachmentsCreate: 'EntityAttachments.Create',
  EntityAttachmentsDelete: 'EntityAttachments.Delete',
  ExternalApplicationsCreate: 'ExternalApplications.Create',
  ExternalApplicationsList: 'ExternalApplications.List',
  ExternalApplicationsRead: 'ExternalApplications.Read',
  ExternalApplicationsUpdate: 'ExternalApplications.Update',
  HangfireAdmin: 'Hangfire.Admin',
  HangfireDashboard: 'Hangfire.Dashboard',
  InboundCreate: 'Inbound.Create',
  InboundMoveToCart: 'Inbound.MoveToCart',
  InboundReadTransactionInfo: 'Inbound.ReadTransactionInfo',
  InboundReinbound: 'Inbound.Reinbound',
  IncomingJobsList: 'IncomingJobs.List',
  InventoryReportAsOfBatch: 'InventoryReport.AsOfBatch',
  InventoryReportList: 'InventoryReport.List',
  InventoryReportPartsByWarehouse: 'InventoryReport.PartsByWarehouse',
  JobPartsList: 'JobParts.List',
  JobsList: 'Jobs.List',
  LegacyReadTransactions: 'Legacy.ReadTransactions',
  ManagementAnnouncements: 'Management.Announcements',
  ManagementApiAccess: 'Management.ApiAccess',
  ManagementApplicationLogs: 'Management.ApplicationLogs',
  ManagementBins: 'Management.Bins',
  ManagementCarriers: 'Management.Carriers',
  ManagementCarts: 'Management.Carts',
  ManagementCustomers: 'Management.Customers',
  ManagementCycleCounts: 'Management.CycleCounts',
  ManagementDataSubscriptions: 'Management.DataSubscriptions',
  ManagementMasterPartNotInMaster: 'Management.MasterPartNotInMaster',
  ManagementMasterParts: 'Management.MasterParts',
  ManagementNotFound: 'Management.NotFound',
  ManagementNotifications: 'Management.Notifications',
  ManagementNotificationsClients: 'Management.Notifications.Clients',
  ManagementNotificationsMessageTemplates: 'Management.Notifications.MessageTemplates',
  ManagementPermissions: 'Management.Permissions',
  ManagementPrinters: 'Management.Printers',
  ManagementRecipients: 'Management.Recipients',
  ManagementResourceGroups: 'Management.ResourceGroups',
  ManagementRoles: 'Management.Roles',
  ManagementStockLevels: 'Management.StockLevels',
  ManagementUsers: 'Management.Users',
  ManagementWarehouses: 'Management.Warehouses',
  MasterPartAttributeCategoriesCreate: 'MasterPartAttributeCategories.Create',
  MasterPartAttributeCategoriesList: 'MasterPartAttributeCategories.List',
  MasterPartAttributeCategoriesRead: 'MasterPartAttributeCategories.Read',
  MasterPartAttributeCategoriesUpdate: 'MasterPartAttributeCategories.Update',
  MasterPartsCreate: 'MasterParts.Create',
  MasterPartsCreateInbound: 'MasterParts.Create.Inbound',
  MasterPartsHistory: 'MasterParts.History',
  MasterPartsInitializeUpdateTemplate: 'MasterParts.Initialize.UpdateTemplate',
  MasterPartsLegacyCheck: 'MasterParts.LegacyCheck',
  MasterPartsList: 'MasterParts.List',
  MasterPartsMerge: 'MasterParts.Merge',
  MasterPartsMergePartNotInMaster: 'MasterParts.Merge.PartNotInMaster',
  MasterPartsRead: 'MasterParts.Read',
  MasterPartsUpdate: 'MasterParts.Update',
  MasterPartsUpdatePartNotInMaster: 'MasterParts.Update.PartNotInMaster',
  MasterPartsUpdateMasterPartStatus: 'MasterParts.UpdateMasterPartStatus',
  MasterPartsUpdateTemplate: 'MasterParts.UpdateTemplate',
  MasterPartsValidatePartAttributes: 'MasterParts.ValidatePartAttributes',
  MasterPartTemplatesCreate: 'MasterPartTemplates.Create',
  MasterPartTemplatesList: 'MasterPartTemplates.List',
  MasterPartTemplatesRead: 'MasterPartTemplates.Read',
  MasterPartTemplatesUpdate: 'MasterPartTemplates.Update',
  MIRList: 'MIR.List',
  MIRPartsUpdate: 'MIR.Parts.Update',
  MIRResolve: 'MIR.Resolve',
  NotificationChannelsCreate: 'NotificationChannels.Create',
  NotificationChannelsDelete: 'NotificationChannels.Delete',
  NotificationChannelsList: 'NotificationChannels.List',
  NotificationChannelsRead: 'NotificationChannels.Read',
  NotificationChannelsUpdate: 'NotificationChannels.Update',
  NotificationChannelsUpdateChannelMappings: 'NotificationChannels.UpdateChannelMappings',
  NotificationClientsCreate: 'NotificationClients.Create',
  NotificationClientsDelete: 'NotificationClients.Delete',
  NotificationClientsList: 'NotificationClients.List',
  NotificationMessageTemplatesCreate: 'NotificationMessageTemplates.Create',
  NotificationMessageTemplatesDelete: 'NotificationMessageTemplates.Delete',
  NotificationMessageTemplatesList: 'NotificationMessageTemplates.List',
  NotificationMessageTemplatesRead: 'NotificationMessageTemplates.Read',
  NotificationMessageTemplatesReadNotificationMessageTemplateObjectTemplate:
    'NotificationMessageTemplates.ReadNotificationMessageTemplateObjectTemplate',
  NotificationMessageTemplatesUpdate: 'NotificationMessageTemplates.Update',
  OutboundAllocate: 'Outbound.Allocate',
  OutboundBulkOutbound: 'Outbound.BulkOutbound',
  OutboundCompleteTransfer: 'Outbound.CompleteTransfer',
  OutboundCreate: 'Outbound.Create',
  OutboundList: 'Outbound.List',
  OutboundRead: 'Outbound.Read',
  OutboundRenderTemplate: 'Outbound.RenderTemplate',
  OutboundUnallocate: 'Outbound.Unallocate',
  OutboundUpdate: 'Outbound.Update',
  OutboundUpdateJobState: 'Outbound.UpdateJobState',
  OutboundUpdateJobWarehouse: 'Outbound.UpdateJobWarehouse',
  OutboundUpdateShippingInformation: 'Outbound.UpdateShippingInformation',
  OutgoingJobsList: 'OutgoingJobs.List',
  PartInformationTransactionsList: 'PartInformationTransactions.List',
  PartMIRContextsRead: 'PartMIRContexts.Read',
  PartsBulkJustify: 'Parts.BulkJustify',
  PartsBulkMove: 'Parts.BulkMove',
  PartsDefective: 'Parts.Defective',
  PartsFound: 'Parts.Found',
  PartsHold: 'Parts.Hold',
  PartsList: 'Parts.List',
  PartsLost: 'Parts.Lost',
  PartsLostOutbound: 'Parts.Lost.Outbound',
  PartsMove: 'Parts.Move',
  PartsOnHoldById: 'Parts.OnHoldById',
  PartsPrintLabel: 'Parts.PrintLabel',
  PartsPutAway: 'Parts.PutAway',
  PartsQuarantine: 'Parts.Quarantine',
  PartsRead: 'Parts.Read',
  PartsRelease: 'Parts.Release',
  PartsScrap: 'Parts.Scrap',
  PartsUnquarantine: 'Parts.Unquarantine',
  PartsUpdate: 'Parts.Update',
  PartsUpdatePartStatus: 'Parts.UpdatePartStatus',
  PartTransactionBatchesPatchMetadata: 'PartTransactionBatches.PatchMetadata',
  PartTransactionBatchesRestrictedMetadataKeys: 'PartTransactionBatches.RestrictedMetadataKeys',
  PartTransactionsList: 'PartTransactions.List',
  PermissionsCreateAppRole: 'Permissions.CreateAppRole',
  PermissionsDeleteAppRole: 'Permissions.DeleteAppRole',
  PermissionsReadAllPermissions: 'Permissions.ReadAllPermissions',
  PermissionsReadAppRoles: 'Permissions.ReadAppRoles',
  PermissionsReadAppRolesForUser: 'Permissions.ReadAppRolesForUser',
  PermissionsReadRoleMappings: 'Permissions.ReadRoleMappings',
  PermissionsReadUserAppRoles: 'Permissions.ReadUserAppRoles',
  PermissionsReadUserPermissions: 'Permissions.ReadUserPermissions',
  PermissionsResetRoleMappings: 'Permissions.ResetRoleMappings',
  PermissionsSyncADUsers: 'Permissions.SyncADUsers',
  PermissionsUpdateRolePermissions: 'Permissions.UpdateRolePermissions',
  ProxyPerformRequest: 'Proxy.PerformRequest',
  PutAwayJobsList: 'PutAwayJobs.List',
  PutAwayJobsRead: 'PutAwayJobs.Read',
  ReportsGenerateReport: 'Reports.GenerateReport',
  ResourceGroupsCreate: 'ResourceGroups.Create',
  ResourceGroupsDelete: 'ResourceGroups.Delete',
  ResourceGroupsList: 'ResourceGroups.List',
  ResourceGroupsRead: 'ResourceGroups.Read',
  ResourceGroupsUpdate: 'ResourceGroups.Update',
  RoutesReadAllRoutes: 'Routes.ReadAllRoutes',
  SecurityIdleTimeoutExempt: 'Security.IdleTimeoutExempt',
  StockLevelsCreate: 'StockLevels.Create',
  StockLevelsDelete: 'StockLevels.Delete',
  StockLevelsList: 'StockLevels.List',
  StockLevelsRead: 'StockLevels.Read',
  StockLevelsUpdate: 'StockLevels.Update',
  SystemRoleImpersonation: 'System.RoleImpersonation',
  SystemUserImpersonation: 'System.UserImpersonation',
  TemplateMismatchedPartsList: 'TemplateMismatchedParts.List',
  TokensRequestTokenForSystem: 'Tokens.RequestTokenForSystem',
  TransfersAllocate: 'Transfers.Allocate',
  TransfersCreate: 'Transfers.Create',
  TransfersCreateLegacyTransfer: 'Transfers.CreateLegacyTransfer',
  TransfersList: 'Transfers.List',
  TransfersRead: 'Transfers.Read',
  TransfersTransferIn: 'Transfers.TransferIn',
  TransfersTransferOut: 'Transfers.TransferOut',
  TransfersUnallocate: 'Transfers.Unallocate',
  TransfersUpdate: 'Transfers.Update',
  TransfersUpdateJobState: 'Transfers.UpdateJobState',
  TransfersUpdateJobWarehouse: 'Transfers.UpdateJobWarehouse',
  UsersAssignResourceGroups: 'Users.AssignResourceGroups',
  UsersDeleteStorageContext: 'Users.DeleteStorageContext',
  UsersList: 'Users.List',
  UsersListStorageContexts: 'Users.ListStorageContexts',
  UsersReadAllActiveDirectoryUsers: 'Users.ReadAllActiveDirectoryUsers',
  UsersReadDataForStorageContext: 'Users.ReadDataForStorageContext',
  UsersUpdateStorageContext: 'Users.UpdateStorageContext',
  UtilsRenderHandlebarsTemplate: 'Utils.RenderHandlebarsTemplate',
  WarehousesCreate: 'Warehouses.Create',
  WarehousesList: 'Warehouses.List',
  WarehousesRead: 'Warehouses.Read',
  WarehousesUpdate: 'Warehouses.Update',
  WebsocketConnect: 'Websocket.Connect',
  ZonesCreate: 'Zones.Create',
  ZonesList: 'Zones.List',
  ZonesRead: 'Zones.Read',
};

export const PermissionList = Object.values(Permissions);
