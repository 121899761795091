import type { ExportingEvent } from 'devextreme/ui/data_grid';
import { useMemo } from 'react';
import { Either, getOrElse, left, right } from 'fp-ts/lib/Either';
import { last } from 'lodash';
// import { isXDataSource } from 'redux-store/services';
import type { Column as DataColumn } from 'devextreme/ui/data_grid';
import { GenerateReportDialog } from 'shared/components/GenerateReportDialog';
import { isXDataSource } from 'redux-store/services/odataApi.generated';

const ExpandSymbolMap = {
  A: 'columns',
  B: 'visible',
  C: 'visibleIndex',
  D: 'width',
  E: 'filterType',
  F: 'filterValues',
  G: 'sortIndex',
  H: 'sortOrder',
  I: 'groupIndex',
  J: 'activityMetadata',
  K: 'activityType',
  L: 'batchId',
  M: 'customerCode',
  N: 'id',
  O: 'location',
  P: 'locationCode',
  Q: 'locationType',
  R: 'masterPartCode',
  S: 'notes',
  T: 'partAttributes',
  U: 'partId',
  V: 'partStatuses',
  W: 'quantity',
  X: 'timestamp',
  Y: 'user',
  Z: 'warehouseCode',
  '[': 'warehouseName',
  '\\': 'currentInventory',
  ']': 'allowedPageSizes',
  '^': 'dataField',
  '`': 'name',
  a: 'filterPanel',
  b: 'filterEnabled',
  c: 'filterValue',
  d: 'pageIndex',
  e: 'pageSize',
  f: 'searchText',
  _: 'dataType',
  g: 'selectedFilterOperation',
  h: 'selectedRowKeys',
  i: 'lastSortOrder',
};

const CompressSymbolMap = Object.keys(ExpandSymbolMap).reduce((all, key) => ({ ...all, [ExpandSymbolMap[key]]: key }), {});
const nextSymbol = last(Object.keys(ExpandSymbolMap).sort((a, b) => a.charCodeAt(0) - b.charCodeAt(0)))!.charCodeAt(0) + 1;

export const compress = <T extends ReportState>(state: T): string => {
  let newSymbols = false;
  const shrink = {};
  const symbols = {};
  let count = nextSymbol;

  const compressSymbol = (key: string) => {
    if (CompressSymbolMap[key]) {
      return CompressSymbolMap[key];
    }

    newSymbols = true;
    if (!shrink[key]) {
      shrink[key] = String.fromCharCode(count);
      symbols[shrink[key]] = key;
      count++;
    }
    return shrink[key];
  };

  const zip = (value: any): any => {
    if (typeof value === 'undefined' || value === null) {
      return value;
    }

    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' || value instanceof Date) {
      return value;
    }

    if (value instanceof Array) {
      return value.map((value) => {
        return zip(value);
      });
    }

    const obj = value;
    return Object.keys(obj)
      .sort()
      .reduce((target: any, key: string) => {
        const cname = compressSymbol(key);
        let value = obj[key];

        value = zip(value);

        if (typeof value === 'undefined' || value === null) {
          return target;
        }

        return {
          ...target,
          [cname]: value,
        };
      }, undefined);
  };

  const compressedState = zip(state);
  if (newSymbols) {
    console.warn('Detected new symbols', symbols);
  }
  const value = JSON.stringify([symbols, compressedState]);
  return btoa(value);
};

export const decompress = <T extends ReportState>(state: string): T => {
  const decodedValue = atob(state);
  const [symbols, compressedState] = JSON.parse(decodedValue);

  const decompressSymbol = (key: string) => {
    return ExpandSymbolMap[key] ?? symbols[key] ?? key;
  };

  const unzip = (value: any): any => {
    if (typeof value === 'undefined' || value === null) {
      return value;
    }

    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      return value;
    }

    if (value instanceof Array) {
      return value.map((value) => {
        return unzip(value);
      });
    }

    const obj = value;
    return Object.keys(obj)
      .sort()
      .reduce((target: any, key: string) => {
        const cname = decompressSymbol(key);
        let value = obj[key];

        value = unzip(value);

        if (typeof value === 'undefined' || value === null) {
          return target;
        }

        return {
          ...target,
          [cname]: value,
        };
      }, undefined);
  };

  const decompressedState = unzip(compressedState);
  return decompressedState;
};

export type ReportViewItem = {
  text: string;
  value: string;
};

export type ColumnState = {
  dataField?: string;
  name?: string;
  dataType?: string;
  groupIndex?: number;
  filterValues?: Array<any>;
  filterValue?: any;
  filterType?: string;
  sortOrder?: string;
  visible?: boolean;
  visibleIndex?: number;
  width?: number;
  sortIndex?: number;
  selectedFilterOperation?: string;
};

export type ReportState = {
  columns: Array<ColumnState>;
  filterValue: Array<any> | null;
  searchText: string;
};

export function useSavedView(defaultStateString?: string): string | undefined {
  const savedView = useMemo(() => {
    let state: string | undefined;
    try {
      const result = loadGridState();
      state = getOrElse(() => defaultStateString)(result);
    } finally {
      return state;
    }
  }, [defaultStateString]);

  return savedView;
}

export const saveGridState = (compressedState: string) => {
  window.location.replace(`${window.location.pathname}#${compressedState}`);
};

export async function exportDataGrid(e: ExportingEvent<any, any>, reportName: string = 'report') {
  e.cancel = true;
  const dataGrid = e.component!;
  const dataSource = dataGrid.getDataSource();
  if (!isXDataSource(dataSource)) {
    throw new Error('Datasource must be of type XDataSource');
  }

  const query = { filter: dataGrid.getCombinedFilter(), sort: dataSource.sort() };
  const oDataQueryExpression = dataSource.settings.createOdataQuery(query);
  const columns = dataGrid
    .getVisibleColumns()
    .filter((x: DataColumn) => x.allowExporting)
    .map((x: DataColumn) => {
      const dataField = x.dataField!;
      let caption = x.caption!;
      if (x.ownerBand) {
        const parentColumn = dataGrid.columnOption(x.ownerBand) as DataColumn<any, any>;
        caption = `${parentColumn.caption} ${caption}`;
      }
      return { name: dataField, alias: caption };
    });
  await GenerateReportDialog.show({
    config: {
      reportName: reportName,
      tables: [
        {
          oDataQueryExpression: oDataQueryExpression,
          columnEntries: columns,
          name: 'Report',
        },
      ],
    },
  });
}

export const FilterOperations = {
  number: ['=', '<>', '>', '>=', '<', '<='],
  string: ['startswith', 'endswith', '=', '<>', 'anyof', 'noneof', 'allof', 'someof'],
  stringWithContains: ['startswith', 'endswith', 'contains', '=', '<>', 'anyof', 'noneof', 'allof', 'someof'],
  fulltext: ['contains', 'startswith', 'endswith', '=', '<>'],
  enum: ['anyof', 'noneof'],
  boolean: ['anyof', 'noneof'],
  date: undefined,
};

export const RemoteOperations = {
  paging: true,
  sorting: true,
  filtering: true,
  summary: true,
  groupPaging: false,
  grouping: false,
};

function loadGridState(): Either<unknown, string> {
  let compressedState = '';
  if (window.location.hash) {
    compressedState = window.location.hash.slice(1);
  }

  try {
    if (!compressedState) {
      return left(new Error('No State'));
    }

    return right(compressedState);
  } catch (e) {
    return left(e);
  }
}
