export const I18NKeys = {
  ADUsersSynchConfirm: {
    Message: 'ADUsersSynchConfirm.Message',
  },
  ADUsersSynchReport: {
    Labels: {
      DeletedUsers: 'ADUsersSynchReport.Labels.DeletedUsers',
      ImportedUsers: 'ADUsersSynchReport.Labels.ImportedUsers',
      RenamedUsers: 'ADUsersSynchReport.Labels.RenamedUsers',
      TotalUsers: 'ADUsersSynchReport.Labels.TotalUsers',
    },
    Messages: {
      Success: 'ADUsersSynchReport.Messages.Success',
    },
  },
  AcceptDialog: {
    Content: 'AcceptDialog.Content',
    Title: 'AcceptDialog.Title',
  },
  Actions: {
    Search: 'Actions.Search',
    Searching: 'Actions.Searching',
    Update: 'Actions.Update',
    Updating: 'Actions.Updating',
  },
  Activities: {
    Confirmation: {
      Dialog: {
        Message: 'Activities.Confirmation.Dialog.Message',
        Title: 'Activities.Confirmation.Dialog.Title',
      },
    },
    LastActivity: 'Activities.LastActivity',
  },
  ActivityType: {
    Allocate: 'ActivityType.Allocate',
    Found: 'ActivityType.Found',
    Hold: 'ActivityType.Hold',
    Inbound: 'ActivityType.Inbound',
    Information: 'ActivityType.Information',
    Lost: 'ActivityType.Lost',
    Merge: 'ActivityType.Merge',
    Move: 'ActivityType.Move',
    Outbound: 'ActivityType.Outbound',
    Pick: 'ActivityType.Pick',
    PutAway: 'ActivityType.PutAway',
    Quarantine: 'ActivityType.Quarantine',
    Receive: 'ActivityType.Receive',
    Release: 'ActivityType.Release',
    Resolve: 'ActivityType.Resolve',
    Scrap: 'ActivityType.Scrap',
    Status: 'ActivityType.Status',
    TransferIn: 'ActivityType.TransferIn',
    TransferOut: 'ActivityType.TransferOut',
    Unallocate: 'ActivityType.Unallocate',
    Unquarantine: 'ActivityType.Unquarantine',
  },
  Address: {
    City: 'Address.City',
    Country: 'Address.Country',
    Enter: 'Address.Enter',
    Info: 'Address.Info',
    Label: 'Address.Label',
    Optional: 'Address.Optional',
    PostalCode: 'Address.PostalCode',
    Province: 'Address.Province',
    Street: 'Address.Street',
    StreetNumber: 'Address.StreetNumber',
    SuiteNumber: 'Address.SuiteNumber',
  },
  AnnouncementSeverity: {
    Critical: 'AnnouncementSeverity.Critical',
    Information: 'AnnouncementSeverity.Information',
    Warning: 'AnnouncementSeverity.Warning',
  },
  Bin: {
    Label: 'Bin.Label',
    Name: 'Bin.Name',
  },
  BinManagement: {
    Dialog: {
      Create: {
        Title: 'BinManagement.Dialog.Create.Title',
      },
      Edit: {
        Title: 'BinManagement.Dialog.Edit.Title',
      },
    },
    Messages: {
      PrintAll: 'BinManagement.Messages.PrintAll',
    },
  },
  BinMoveDialog: {
    Destination: {
      Placeholder: 'BinMoveDialog.Destination.Placeholder',
    },
    Label: 'BinMoveDialog.Label',
    Title: 'BinMoveDialog.Title',
    Type: {
      Placeholder: 'BinMoveDialog.Type.Placeholder',
    },
  },
  Bins: {
    Label: 'Bins.Label',
  },
  BulkDialog: {
    Body: 'BulkDialog.Body',
    Title: 'BulkDialog.Title',
  },
  Buttons: {
    Accept: 'Buttons.Accept',
    Cancel: 'Buttons.Cancel',
    Cart: 'Buttons.Cart',
    Close: 'Buttons.Close',
    Confirm: 'Buttons.Confirm',
    Create: 'Buttons.Create',
    Delete: 'Buttons.Delete',
    DryRun: 'Buttons.DryRun',
    Escalate: 'Buttons.Escalate',
    Inbound: 'Buttons.Inbound',
    Next: 'Buttons.Next',
    No: 'Buttons.No',
    Ok: 'Buttons.Ok',
    Print: 'Buttons.Print',
    Reject: 'Buttons.Reject',
    Reload: 'Buttons.Reload',
    Reset: 'Buttons.Reset',
    Save: 'Buttons.Save',
    Scrap: 'Buttons.Scrap',
    Search: 'Buttons.Search',
    SendMessage: 'Buttons.SendMessage',
    Synch: 'Buttons.Synch',
    Update: 'Buttons.Update',
    Yes: 'Buttons.Yes',
  },
  CaptureCartDialog: {
    SelectCart: 'CaptureCartDialog.SelectCart',
  },
  Carrier: {
    Code: 'Carrier.Code',
    Label: 'Carrier.Label',
    Name: 'Carrier.Name',
  },
  Carriers: {
    Dialog: {
      Create: {
        Title: 'Carriers.Dialog.Create.Title',
      },
      Edit: {
        Title: 'Carriers.Dialog.Edit.Title',
      },
    },
  },
  CartManagement: {
    Dialog: {
      Text: 'CartManagement.Dialog.Text',
      Title: 'CartManagement.Dialog.Title',
    },
    Messages: {
      PrintAll: 'CartManagement.Messages.PrintAll',
    },
  },
  Carts: {
    Info: 'Carts.Info',
  },
  Complete: {
    Transfer: 'Complete.Transfer',
  },
  Contact: {
    Info: 'Contact.Info',
  },
  Conversation: {
    Message: 'Conversation.Message',
    Title: 'Conversation.Title',
  },
  CreateCarts: {
    NumCarts: 'CreateCarts.NumCarts',
  },
  CreateCustomerDialog: {
    MissingFields: 'CreateCustomerDialog.MissingFields',
  },
  CreateNotificationClients: {
    Dialog: {
      Title: 'CreateNotificationClients.Dialog.Title',
    },
  },
  CreateNotificationDialog: {
    CardHeader: 'CreateNotificationDialog.CardHeader',
    Destination: {
      Label: {
        Email: 'CreateNotificationDialog.Destination.Label.Email',
        Slack: 'CreateNotificationDialog.Destination.Label.Slack',
        Teams: 'CreateNotificationDialog.Destination.Label.Teams',
      },
      Note: {
        Email: 'CreateNotificationDialog.Destination.Note.Email',
        Slack: 'CreateNotificationDialog.Destination.Note.Slack',
        Teams: 'CreateNotificationDialog.Destination.Note.Teams',
      },
      Placeholder: {
        Email: 'CreateNotificationDialog.Destination.Placeholder.Email',
        Slack: 'CreateNotificationDialog.Destination.Placeholder.Slack',
        Teams: 'CreateNotificationDialog.Destination.Placeholder.Teams',
      },
    },
    Events: {
      InternalDelivery: 'CreateNotificationDialog.Events.InternalDelivery',
      MIRConversationUpdated: 'CreateNotificationDialog.Events.MIRConversationUpdated',
      MIRPartReceived: 'CreateNotificationDialog.Events.MIRPartReceived',
      MasterPartInReviewAccepted: 'CreateNotificationDialog.Events.MasterPartInReviewAccepted',
      MasterPartInReviewCreated: 'CreateNotificationDialog.Events.MasterPartInReviewCreated',
      MasterPartInReviewEscalated: 'CreateNotificationDialog.Events.MasterPartInReviewEscalated',
      PartNotFound: 'CreateNotificationDialog.Events.PartNotFound',
    },
    Title: 'CreateNotificationDialog.Title',
  },
  CreatePutAway: {
    Messages: {
      SelectAll: 'CreatePutAway.Messages.SelectAll',
    },
  },
  CreatePutAwayDialog: {
    ScanParts: 'CreatePutAwayDialog.ScanParts',
    Title: 'CreatePutAwayDialog.Title',
  },
  CreateRoleDialog: {
    Title: 'CreateRoleDialog.Title',
  },
  Customer: {
    Code: 'Customer.Code',
    Info: 'Customer.Info',
    Name: 'Customer.Name',
    Reference: 'Customer.Reference',
  },
  CustomerManagement: {
    Dialog: {
      Create: {
        Title: 'CustomerManagement.Dialog.Create.Title',
      },
      Edit: {
        Title: 'CustomerManagement.Dialog.Edit.Title',
      },
    },
    Email: {
      Apple: {
        Label: 'CustomerManagement.Email.Apple.Label',
        Placeholder: 'CustomerManagement.Email.Apple.Placeholder',
      },
      Inventory: {
        Label: 'CustomerManagement.Email.Inventory.Label',
        Placeholder: 'CustomerManagement.Email.Inventory.Placeholder',
      },
      Reply: {
        Label: 'CustomerManagement.Email.Reply.Label',
        Placeholder: 'CustomerManagement.Email.Reply.Placeholder',
      },
    },
    RefLabel: 'CustomerManagement.RefLabel',
    Required: 'CustomerManagement.Required',
  },
  CycleCountStatus: {
    Complete: 'CycleCountStatus.Complete',
    Discrepancy: 'CycleCountStatus.Discrepancy',
    Open: 'CycleCountStatus.Open',
    ReadyForReview: 'CycleCountStatus.ReadyForReview',
  },
  DataExport: {
    LargeDataSet: {
      Message: 'DataExport.LargeDataSet.Message',
      Title: 'DataExport.LargeDataSet.Title',
    },
    MissingSort: {
      Message: 'DataExport.MissingSort.Message',
      Title: 'DataExport.MissingSort.Title',
    },
    Spinner: {
      Message: 'DataExport.Spinner.Message',
    },
  },
  DataGrid: {
    Filters: {
      CustomFilter: {
        Message: 'DataGrid.Filters.CustomFilter.Message',
      },
    },
  },
  DataSubscriptionDataSource: {
    CurrentInventory: 'DataSubscriptionDataSource.CurrentInventory',
    InventoryAdjustment: 'DataSubscriptionDataSource.InventoryAdjustment',
    MasterPart: 'DataSubscriptionDataSource.MasterPart',
    MasterPartTemplate: 'DataSubscriptionDataSource.MasterPartTemplate',
    OutboundJob: 'DataSubscriptionDataSource.OutboundJob',
    PartTransactionBatch: 'DataSubscriptionDataSource.PartTransactionBatch',
    TransferJob: 'DataSubscriptionDataSource.TransferJob',
  },
  DataSubscriptionTrigger: {
    Entity: 'DataSubscriptionTrigger.Entity',
    Index: 'DataSubscriptionTrigger.Index',
    Schedule: 'DataSubscriptionTrigger.Schedule',
  },
  DefectiveDialog: {
    Confirmation: 'DefectiveDialog.Confirmation',
    Errors: {
      Failed: 'DefectiveDialog.Errors.Failed',
      FailedGood: 'DefectiveDialog.Errors.FailedGood',
    },
    GoodConfirmation: 'DefectiveDialog.GoodConfirmation',
  },
  DeletAnnouncementDialog: {
    Confirmation: 'DeletAnnouncementDialog.Confirmation',
    Title: 'DeletAnnouncementDialog.Title',
  },
  DeleteCycleCountsDialog: {
    Confirmation: 'DeleteCycleCountsDialog.Confirmation',
    Title: 'DeleteCycleCountsDialog.Title',
  },
  DeleteDataSubscription: {
    Dialog: {
      Message: 'DeleteDataSubscription.Dialog.Message',
      Title: 'DeleteDataSubscription.Dialog.Title',
    },
  },
  DeleteNotificationChannelDialog: {
    Confirmation: 'DeleteNotificationChannelDialog.Confirmation',
    Title: 'DeleteNotificationChannelDialog.Title',
  },
  DeleteNotificationClients: {
    Dialog: {
      Message: 'DeleteNotificationClients.Dialog.Message',
      Title: 'DeleteNotificationClients.Dialog.Title',
    },
  },
  DeleteNotificationTemplates: {
    Dialog: {
      Title: 'DeleteNotificationTemplates.Dialog.Title',
    },
  },
  DeleteResourceGroupDialog: {
    Confirmation: 'DeleteResourceGroupDialog.Confirmation',
    Title: 'DeleteResourceGroupDialog.Title',
  },
  DeleteStockLevel: {
    Dialog: {
      Message: 'DeleteStockLevel.Dialog.Message',
      Title: 'DeleteStockLevel.Dialog.Title',
    },
  },
  DeleteZone: {
    Dialog: {
      Message: 'DeleteZone.Dialog.Message',
      Title: 'DeleteZone.Dialog.Title',
    },
  },
  DeliveryRecipientType: {
    ActiveDirectoryUser: 'DeliveryRecipientType.ActiveDirectoryUser',
    ExternalCustomer: 'DeliveryRecipientType.ExternalCustomer',
  },
  DockReceiptNotFound: {
    Body: 'DockReceiptNotFound.Body',
    Title: 'DockReceiptNotFound.Title',
  },
  DockReceiptOpenCloseDialog: {
    Message: {
      Close: {
        Confirm: 'DockReceiptOpenCloseDialog.Message.Close.Confirm',
        Note: 'DockReceiptOpenCloseDialog.Message.Close.Note',
      },
      Open: {
        Confirm: 'DockReceiptOpenCloseDialog.Message.Open.Confirm',
        Note: 'DockReceiptOpenCloseDialog.Message.Open.Note',
      },
    },
    Title: {
      Close: 'DockReceiptOpenCloseDialog.Title.Close',
      Open: 'DockReceiptOpenCloseDialog.Title.Open',
    },
  },
  DockReceipts: {
    Closed: 'DockReceipts.Closed',
    DeliveryDate: 'DockReceipts.DeliveryDate',
    DeliveryType: {
      External: 'DockReceipts.DeliveryType.External',
      Inbound: 'DockReceipts.DeliveryType.Inbound',
      Internal: 'DockReceipts.DeliveryType.Internal',
      Undelivered: 'DockReceipts.DeliveryType.Undelivered',
    },
    NumItems: 'DockReceipts.NumItems',
    NumWaybills: 'DockReceipts.NumWaybills',
    Open: 'DockReceipts.Open',
    Other: 'DockReceipts.Other',
    Statuses: {
      NotStarted: 'DockReceipts.Statuses.NotStarted',
      Received: 'DockReceipts.Statuses.Received',
    },
    Technician: 'DockReceipts.Technician',
    WaybillNumber: 'DockReceipts.WaybillNumber',
  },
  DockReceiptsCreateDialog: {
    Autogenerated: 'DockReceiptsCreateDialog.Autogenerated',
    NoWaybillNumber: 'DockReceiptsCreateDialog.NoWaybillNumber',
    Title: 'DockReceiptsCreateDialog.Title',
  },
  DockReceiptsDeleteDialog: {
    Confirmation: 'DockReceiptsDeleteDialog.Confirmation',
    Title: 'DockReceiptsDeleteDialog.Title',
  },
  Dropdown: {
    PlaceHolder: {
      SelectOne: 'Dropdown.PlaceHolder.SelectOne',
    },
  },
  ERRORS: {
    E0000: 'ERRORS.E0000',
    E0001: 'ERRORS.E0001',
    E0002: 'ERRORS.E0002',
    E0003: 'ERRORS.E0003',
    E0004: 'ERRORS.E0004',
    E0005: 'ERRORS.E0005',
    E0006: 'ERRORS.E0006',
    E0007: 'ERRORS.E0007',
    E0008: 'ERRORS.E0008',
    E0009: 'ERRORS.E0009',
  },
  Edit: {
    PartStatuses: 'Edit.PartStatuses',
  },
  EditActivitiesDialog: {
    NoActivity: 'EditActivitiesDialog.NoActivity',
    Title: 'EditActivitiesDialog.Title',
    WaybillNumber: 'EditActivitiesDialog.WaybillNumber',
  },
  EditCustomerDialog: {
    CardHeader: 'EditCustomerDialog.CardHeader',
  },
  EditNotificationDialog: {
    CardHeader: 'EditNotificationDialog.CardHeader',
    Title: 'EditNotificationDialog.Title',
  },
  EditRoleDialog: {
    Headings: {
      DisabledPermissions: 'EditRoleDialog.Headings.DisabledPermissions',
      DisabledPolicies: 'EditRoleDialog.Headings.DisabledPolicies',
      EnabledPermissions: 'EditRoleDialog.Headings.EnabledPermissions',
      EnabledPolicies: 'EditRoleDialog.Headings.EnabledPolicies',
    },
    Messages: {
      ConfirmPolicyRemoval: {
        Message: 'EditRoleDialog.Messages.ConfirmPolicyRemoval.Message',
        Title: 'EditRoleDialog.Messages.ConfirmPolicyRemoval.Title',
      },
      ConfirmResetAllRoles: {
        Message: 'EditRoleDialog.Messages.ConfirmResetAllRoles.Message',
        Title: 'EditRoleDialog.Messages.ConfirmResetAllRoles.Title',
      },
      ConfirmRestoreRole: {
        Message: 'EditRoleDialog.Messages.ConfirmRestoreRole.Message',
        Title: 'EditRoleDialog.Messages.ConfirmRestoreRole.Title',
      },
    },
    Tabs: {
      Permissions: 'EditRoleDialog.Tabs.Permissions',
      Policies: 'EditRoleDialog.Tabs.Policies',
    },
    Title: 'EditRoleDialog.Title',
  },
  ErrorAlert: {
    Message: 'ErrorAlert.Message',
    Title: 'ErrorAlert.Title',
  },
  EscalateDialog: {
    MoreInfo: 'EscalateDialog.MoreInfo',
    Title: 'EscalateDialog.Title',
  },
  FoundDialog: {
    Buttons: {
      Found: 'FoundDialog.Buttons.Found',
    },
    Confirmation: 'FoundDialog.Confirmation',
    Errors: {
      Failed: 'FoundDialog.Errors.Failed',
    },
    Title: 'FoundDialog.Title',
  },
  GS1Lookup: {
    Labels: {
      PartNumber: 'GS1Lookup.Labels.PartNumber',
      ScannedData: 'GS1Lookup.Labels.ScannedData',
    },
    Reasons: {
      NoGTIN: 'GS1Lookup.Reasons.NoGTIN',
      NoMatches: 'GS1Lookup.Reasons.NoMatches',
    },
    Title: 'GS1Lookup.Title',
  },
  HoldDialog: {
    Buttons: {
      Hold: 'HoldDialog.Buttons.Hold',
    },
    Confirmation: 'HoldDialog.Confirmation',
    Errors: {
      Failed: 'HoldDialog.Errors.Failed',
    },
    HoldFor: 'HoldDialog.HoldFor',
    Title: 'HoldDialog.Title',
  },
  HubArea: {
    Tooltip: {
      Title: 'HubArea.Tooltip.Title',
    },
  },
  InboundJobOrderStatus: {
    Fulfilled: 'InboundJobOrderStatus.Fulfilled',
    InTransit: 'InboundJobOrderStatus.InTransit',
    ShortReceived: 'InboundJobOrderStatus.ShortReceived',
  },
  InboundPartWorkflows: {
    Damaged: 'InboundPartWorkflows.Damaged',
    InformationMissing: 'InboundPartWorkflows.InformationMissing',
    MasterInReview: 'InboundPartWorkflows.MasterInReview',
    Quarantined: 'InboundPartWorkflows.Quarantined',
    ReInbound: 'InboundPartWorkflows.ReInbound',
    Standard: 'InboundPartWorkflows.Standard',
    Unidentifiable: 'InboundPartWorkflows.Unidentifiable',
  },
  InboundSuccess: {
    Batch: 'InboundSuccess.Batch',
    Printing: 'InboundSuccess.Printing',
  },
  InventoryReport: {
    ReportViews: {
      All: 'InventoryReport.ReportViews.All',
      AvailableDefectiveInventory: 'InventoryReport.ReportViews.AvailableDefectiveInventory',
      AvailableInventory: 'InventoryReport.ReportViews.AvailableInventory',
      AvailableInventoryByWarehouse: 'InventoryReport.ReportViews.AvailableInventoryByWarehouse',
      Custom: 'InventoryReport.ReportViews.Custom',
      MIRParts: 'InventoryReport.ReportViews.MIRParts',
    },
  },
  Job: {
    Number: 'Job.Number',
  },
  JobType: {
    Outbound: 'JobType.Outbound',
    PutAway: 'JobType.PutAway',
    Transfer: 'JobType.Transfer',
  },
  LPNScan: {
    Title: 'LPNScan.Title',
  },
  Label: {
    Action: 'Label.Action',
    Actions: 'Label.Actions',
    ActivationDate: 'Label.ActivationDate',
    AddImage: 'Label.AddImage',
    Alias: 'Label.Alias',
    Announcements: 'Label.Announcements',
    AppleOrderNumber: 'Label.AppleOrderNumber',
    AutoGenerated: 'Label.AutoGenerated',
    BatchId: 'Label.BatchId',
    BinCount: 'Label.BinCount',
    BulkActions: 'Label.BulkActions',
    Code: 'Label.Code',
    Count: 'Label.Count',
    CreatedDate: 'Label.CreatedDate',
    DateCreated: 'Label.DateCreated',
    DateReceived: 'Label.DateReceived',
    DeliveryDate: 'Label.DeliveryDate',
    DeliveryType: 'Label.DeliveryType',
    Description: 'Label.Description',
    Destination: 'Label.Destination',
    DestinationWarehouse: 'Label.DestinationWarehouse',
    Details: 'Label.Details',
    Disabled: 'Label.Disabled',
    Dismissable: 'Label.Dismissable',
    DownloadCountSheet: 'Label.DownloadCountSheet',
    Edit: 'Label.Edit',
    EditStatus: 'Label.EditStatus',
    Email: 'Label.Email',
    Enabled: 'Label.Enabled',
    Events: 'Label.Events',
    ExpirationDate: 'Label.ExpirationDate',
    Failed: 'Label.Failed',
    False: 'Label.False',
    Good: 'Label.Good',
    Id: 'Label.Id',
    IsInStock: 'Label.IsInStock',
    LastActivity: 'Label.LastActivity',
    LastSeen: 'Label.LastSeen',
    LikeParts: 'Label.LikeParts',
    MarkDefective: 'Label.MarkDefective',
    MarkGood: 'Label.MarkGood',
    ModifiedDate: 'Label.ModifiedDate',
    Name: 'Label.Name',
    No: 'Label.No',
    Notes: 'Label.Notes',
    Notifications: 'Label.Notifications',
    NumParts: 'Label.NumParts',
    PartCount: 'Label.PartCount',
    PartStatus: 'Label.PartStatus',
    Parts: 'Label.Parts',
    PreviousName: 'Label.PreviousName',
    Qty: 'Label.Qty',
    Quantity: 'Label.Quantity',
    Quarantine: 'Label.Quarantine',
    Ready: 'Label.Ready',
    Reason: 'Label.Reason',
    ReferenceIn: 'Label.ReferenceIn',
    ReferenceOut: 'Label.ReferenceOut',
    ResourceGroup: 'Label.ResourceGroup',
    Result: 'Label.Result',
    Return: 'Label.Return',
    SelectType: 'Label.SelectType',
    Severity: 'Label.Severity',
    ShipByDate: 'Label.ShipByDate',
    ShowAll: 'Label.ShowAll',
    Source: 'Label.Source',
    Status: 'Label.Status',
    TechnicianName: 'Label.TechnicianName',
    TimezoneId: 'Label.TimezoneId',
    Title: 'Label.Title',
    TransactionInformation: 'Label.TransactionInformation',
    Transfer: 'Label.Transfer',
    True: 'Label.True',
    Type: 'Label.Type',
    Unquarantine: 'Label.Unquarantine',
    User: 'Label.User',
    Username: 'Label.Username',
    Yes: 'Label.Yes',
  },
  LegacyTransaction: {
    CustomerReference: 'LegacyTransaction.CustomerReference',
    Date: 'LegacyTransaction.Date',
    JobNumber: 'LegacyTransaction.JobNumber',
    MainReference: 'LegacyTransaction.MainReference',
    PartNumber: 'LegacyTransaction.PartNumber',
    Quantity: 'LegacyTransaction.Quantity',
    Serial: 'LegacyTransaction.Serial',
  },
  LegacyTransactionsDialog: {
    Title: 'LegacyTransactionsDialog.Title',
  },
  LoggoutPage: {
    Buttons: {
      SignIn: 'LoggoutPage.Buttons.SignIn',
    },
    Messages: {
      Goodbye: 'LoggoutPage.Messages.Goodbye',
      MoreToDo: 'LoggoutPage.Messages.MoreToDo',
    },
  },
  LostDialog: {
    Buttons: {
      Lost: 'LostDialog.Buttons.Lost',
    },
    Confirmation: 'LostDialog.Confirmation',
    Errors: {
      Failed: 'LostDialog.Errors.Failed',
    },
    Title: 'LostDialog.Title',
  },
  LpnInfo: {
    EditLpn: 'LpnInfo.EditLpn',
  },
  MIRDetail: {
    Title: 'MIRDetail.Title',
  },
  MIREditDialog: {
    Title: 'MIREditDialog.Title',
  },
  MIRPart: {
    Information: 'MIRPart.Information',
  },
  MIRResolved: {
    InboundDate: 'MIRResolved.InboundDate',
    NextAction: 'MIRResolved.NextAction',
    NextActions: {
      Inbound: 'MIRResolved.NextActions.Inbound',
      None: 'MIRResolved.NextActions.None',
      Other: 'MIRResolved.NextActions.Other',
      PrintLabel: 'MIRResolved.NextActions.PrintLabel',
      Scrap: 'MIRResolved.NextActions.Scrap',
      Unresolved: 'MIRResolved.NextActions.Unresolved',
    },
    ResolutionDate: 'MIRResolved.ResolutionDate',
  },
  MasterPart: {
    Bulk: 'MasterPart.Bulk',
    PartCode: 'MasterPart.PartCode',
    ReviewStatus: 'MasterPart.ReviewStatus',
    ReviewStatuses: {
      Accepted: 'MasterPart.ReviewStatuses.Accepted',
      Escalated: 'MasterPart.ReviewStatuses.Escalated',
      InReview: 'MasterPart.ReviewStatuses.InReview',
      Rejected: 'MasterPart.ReviewStatuses.Rejected',
    },
    UnidentifiableCode: 'MasterPart.UnidentifiableCode',
    Unique: 'MasterPart.Unique',
    Length: 'MasterPart.Length',
    Width: 'MasterPart.Width',
    Height: 'MasterPart.Height',
    Weight: 'MasterPart.Weight',
  },
  Dimensions: {
    Label: 'Dimensions.Label',
    Imperial: 'Dimensions.Imperial',
    Metric: 'Dimensions.Metric',
  },
  MasterPartHistoryDialog: {
    Title: 'MasterPartHistoryDialog.Title',
  },
  MasterPartTemplates: {
    Template: 'MasterPartTemplates.Template',
  },
  MasterParts: {
    CreateDialog: {
      Errors: {
        CreateFailed: {
          Message: 'MasterParts.CreateDialog.Errors.CreateFailed.Message',
        },
      },
      Title: 'MasterParts.CreateDialog.Title',
    },
    Edit: {
      Notifications: {
        Failed: 'MasterParts.Edit.Notifications.Failed',
        Merged: 'MasterParts.Edit.Notifications.Merged',
        Updated: 'MasterParts.Edit.Notifications.Updated',
      },
    },
    EditDialog: {
      Notifications: {
        Cancelled: 'MasterParts.EditDialog.Notifications.Cancelled',
        MergeRequired: 'MasterParts.EditDialog.Notifications.MergeRequired',
      },
      Title: 'MasterParts.EditDialog.Title',
    },
    MergeDialog: {
      Buttons: {
        YesAndReject: 'MasterParts.MergeDialog.Buttons.YesAndReject',
      },
      Error: 'MasterParts.MergeDialog.Error',
      Message: 'MasterParts.MergeDialog.Message',
      Title: 'MasterParts.MergeDialog.Title',
    },
  },
  MergingNotAllowedDialog: {
    Body: 'MergingNotAllowedDialog.Body',
    CannotMerge: 'MergingNotAllowedDialog.CannotMerge',
    Title: 'MergingNotAllowedDialog.Title',
    View: 'MergingNotAllowedDialog.View',
  },
  MirResolutionDescription: {
    Inbound: 'MirResolutionDescription.Inbound',
    None: 'MirResolutionDescription.None',
    Other: 'MirResolutionDescription.Other',
    PrintLabel: 'MirResolutionDescription.PrintLabel',
    Repackage: 'MirResolutionDescription.Repackage',
    Scrap: 'MirResolutionDescription.Scrap',
  },
  Notes: {
    Quarantine: 'Notes.Quarantine',
    Unquarantine: 'Notes.Unquarantine',
  },
  Notification: {
    Offline: {
      Message: 'Notification.Offline.Message',
      Title: 'Notification.Offline.Title',
    },
  },
  Notifications: {
    List: {
      Dialog: {
        Title: 'Notifications.List.Dialog.Title',
      },
    },
  },
  Options: {
    No: 'Options.No',
    Yes: 'Options.Yes',
  },
  Outbound: {
    CancelDate: 'Outbound.CancelDate',
    OrderNumber: 'Outbound.OrderNumber',
    OrderStatus: 'Outbound.OrderStatus',
    Pieces: 'Outbound.Pieces',
    ServiceType: 'Outbound.ServiceType',
    ShipDate: 'Outbound.ShipDate',
    TimeToShip: 'Outbound.TimeToShip',
  },
  OutboundJobStatus: {
    Cancelled: 'OutboundJobStatus.Cancelled',
    Draft: 'OutboundJobStatus.Draft',
    Fulfilled: 'OutboundJobStatus.Fulfilled',
    Incomplete: 'OutboundJobStatus.Incomplete',
    Paused: 'OutboundJobStatus.Paused',
    PickInProgress: 'OutboundJobStatus.PickInProgress',
    PickReady: 'OutboundJobStatus.PickReady',
    ReadyToShip: 'OutboundJobStatus.ReadyToShip',
    ShortPick: 'OutboundJobStatus.ShortPick',
  },
  OutboundOrder: {
    BOLNumber: 'OutboundOrder.BOLNumber',
    Dialog: {
      OrderDetails: 'OutboundOrder.Dialog.OrderDetails',
      Title: 'OutboundOrder.Dialog.Title',
    },
    NoPartsAllocated: 'OutboundOrder.NoPartsAllocated',
    OrderInfo: {
      Title: 'OutboundOrder.OrderInfo.Title',
    },
    PackageQuantity: 'OutboundOrder.PackageQuantity',
    PackageType: 'OutboundOrder.PackageType',
    ShippingNotes: 'OutboundOrder.ShippingNotes',
    StaleData: 'OutboundOrder.StaleData',
  },
  OutboundTransferDialog: {
    Errors: {
      Failed: 'OutboundTransferDialog.Errors.Failed',
    },
  },
  Part: {
    AssetTag: 'Part.AssetTag',
    Bin: 'Part.Bin',
    Information: 'Part.Information',
    LPNNumber: 'Part.LPNNumber',
    Location: 'Part.Location',
    LocationCode: 'Part.LocationCode',
    LocationType: 'Part.LocationType',
    PartNumber: 'Part.PartNumber',
    SelectablePartStatuses: {
      Allocated: 'Part.SelectablePartStatuses.Allocated',
      Any: 'Part.SelectablePartStatuses.Any',
      Damaged: 'Part.SelectablePartStatuses.Damaged',
      Defective: 'Part.SelectablePartStatuses.Defective',
      Good: 'Part.SelectablePartStatuses.Good',
      InTransit: 'Part.SelectablePartStatuses.InTransit',
      InformationMissing: 'Part.SelectablePartStatuses.InformationMissing',
      MasterInReview: 'Part.SelectablePartStatuses.MasterInReview',
      None: 'Part.SelectablePartStatuses.None',
      NotFound: 'Part.SelectablePartStatuses.NotFound',
      OnHold: 'Part.SelectablePartStatuses.OnHold',
      Quarantined: 'Part.SelectablePartStatuses.Quarantined',
      Received: 'Part.SelectablePartStatuses.Received',
      Resolved: 'Part.SelectablePartStatuses.Resolved',
      Unidentifiable: 'Part.SelectablePartStatuses.Unidentifiable',
    },
    SerialNumber: 'Part.SerialNumber',
    Statuses: {
      Allocated: 'Part.Statuses.Allocated',
      Damaged: 'Part.Statuses.Damaged',
      Defective: 'Part.Statuses.Defective',
      Good: 'Part.Statuses.Good',
      InTransit: 'Part.Statuses.InTransit',
      InformationMissing: 'Part.Statuses.InformationMissing',
      Label: 'Part.Statuses.Label',
      MasterInReview: 'Part.Statuses.MasterInReview',
      NotFound: 'Part.Statuses.NotFound',
      OnHold: 'Part.Statuses.OnHold',
      Quarantined: 'Part.Statuses.Quarantined',
      Received: 'Part.Statuses.Received',
      Resolved: 'Part.Statuses.Resolved',
      Unidentifiable: 'Part.Statuses.Unidentifiable',
      Unknown: 'Part.Statuses.Unknown',
    },
  },
  PartAttribute: {
    Validation: {
      InvalidFormat: 'PartAttribute.Validation.InvalidFormat',
      NotUnique: 'PartAttribute.Validation.NotUnique',
      RequiredFieldMissing: 'PartAttribute.Validation.RequiredFieldMissing',
    },
  },
  PartDisabledAlert: {
    Body: 'PartDisabledAlert.Body',
    Title: 'PartDisabledAlert.Title',
  },
  PartList: {
    Title: 'PartList.Title',
  },
  PartNotFoundAlert: {
    Body: 'PartNotFoundAlert.Body',
    Title: 'PartNotFoundAlert.Title',
  },
  PartNotFoundResolveDialog: {
    Buttons: {
      KeepCurrentLocation: 'PartNotFoundResolveDialog.Buttons.KeepCurrentLocation',
    },
    Title: 'PartNotFoundResolveDialog.Title',
  },
  PartRejectedAlert: {
    Body: 'PartRejectedAlert.Body',
    Title: 'PartRejectedAlert.Title',
  },
  PartValidationDialog: {
    Body: 'PartValidationDialog.Body',
    Title: 'PartValidationDialog.Title',
  },
  Print: {
    Reprint: {
      Title: 'Print.Reprint.Title',
    },
  },
  Printer: {
    Client: 'Printer.Client',
    Configure: 'Printer.Configure',
    ConfigureHeader: 'Printer.ConfigureHeader',
    Create: 'Printer.Create',
    Delete: 'Printer.Delete',
    DeleteMessage: {
      Body: 'Printer.DeleteMessage.Body',
    },
    Description: 'Printer.Description',
    Edit: 'Printer.Edit',
    Enabled: 'Printer.Enabled',
    IsConnected: 'Printer.IsConnected',
    Name: 'Printer.Name',
    NamedConfigTitle: 'Printer.NamedConfigTitle',
    Network: {
      Ip: 'Printer.Network.Ip',
      Port: 'Printer.Network.Port',
    },
    Offline: 'Printer.Offline',
    Online: 'Printer.Online',
    Type: 'Printer.Type',
    Types: {
      Debug: 'Printer.Types.Debug',
      Network: 'Printer.Types.Network',
      USBPrinter: 'Printer.Types.USBPrinter',
      Webhook: 'Printer.Types.Webhook',
    },
    Version: 'Printer.Version',
    Warehouse: 'Printer.Warehouse',
    WebhookConfigTitle: 'Printer.WebhookConfigTitle',
  },
  PrinterPicker: {
    ItemGroups: {
      Offline: 'PrinterPicker.ItemGroups.Offline',
      Online: 'PrinterPicker.ItemGroups.Online',
    },
    Items: {
      DoNotPrint: 'PrinterPicker.Items.DoNotPrint',
      NoPrintersAvailable: 'PrinterPicker.Items.NoPrintersAvailable',
    },
    Placeholder: 'PrinterPicker.Placeholder',
  },
  PutAway: {
    Cart: {
      Placeholder: 'PutAway.Cart.Placeholder',
    },
    Confirmation: {
      Dialog: {
        Message: 'PutAway.Confirmation.Dialog.Message',
        Title: 'PutAway.Confirmation.Dialog.Title',
      },
    },
    DestinationBin: {
      Label: 'PutAway.DestinationBin.Label',
    },
    LPNInfo: {
      Label: 'PutAway.LPNInfo.Label',
    },
    Label: 'PutAway.Label',
    ScanLpn: {
      Label: 'PutAway.ScanLpn.Label',
    },
    SuggestedBin: {
      Long: 'PutAway.SuggestedBin.Long',
    },
    SuggestedBins: {
      Label: 'PutAway.SuggestedBins.Label',
    },
    Warehouse: {
      Placeholder: 'PutAway.Warehouse.Placeholder',
    },
  },
  QuarantineDialog: {
    Buttons: {
      Quarantine: 'QuarantineDialog.Buttons.Quarantine',
    },
    Confirmation: 'QuarantineDialog.Confirmation',
    Errors: {
      Failed: 'QuarantineDialog.Errors.Failed',
    },
  },
  Recipients: {
    Dialog: {
      Create: {
        Title: 'Recipients.Dialog.Create.Title',
      },
      Edit: {
        Title: 'Recipients.Dialog.Edit.Title',
      },
    },
  },
  RejectMasterPartDialog: {
    Confirmation: 'RejectMasterPartDialog.Confirmation',
    Title: 'RejectMasterPartDialog.Title',
  },
  ReleaseDialog: {
    Buttons: {
      Release: 'ReleaseDialog.Buttons.Release',
    },
    Confirmation: 'ReleaseDialog.Confirmation',
    Errors: {
      Failed: 'ReleaseDialog.Errors.Failed',
    },
    Title: 'ReleaseDialog.Title',
  },
  ResolveDialog: {
    SelectAction: 'ResolveDialog.SelectAction',
    Title: 'ResolveDialog.Title',
  },
  ResourceGroups: {
    Administrators: 'ResourceGroups.Administrators',
    Available: 'ResourceGroups.Available',
    AvailableCustomers: 'ResourceGroups.AvailableCustomers',
    AvailableWarehouses: 'ResourceGroups.AvailableWarehouses',
    Create: {
      Title: 'ResourceGroups.Create.Title',
    },
    Created: 'ResourceGroups.Created',
    Edit: {
      Title: 'ResourceGroups.Edit.Title',
    },
    Edited: 'ResourceGroups.Edited',
    EntityType: 'ResourceGroups.EntityType',
    Selected: 'ResourceGroups.Selected',
    SelectedCustomers: 'ResourceGroups.SelectedCustomers',
    SelectedWarehouses: 'ResourceGroups.SelectedWarehouses',
  },
  Role: {
    Description: 'Role.Description',
    DisplayName: 'Role.DisplayName',
    Value: 'Role.Value',
  },
  RoleList: {
    Button: {
      CreateRole: 'RoleList.Button.CreateRole',
      ExportRoleMappings: 'RoleList.Button.ExportRoleMappings',
      ImportRoleMappings: 'RoleList.Button.ImportRoleMappings',
    },
    Grid: {
      Columns: {
        Description: 'RoleList.Grid.Columns.Description',
        Permission: 'RoleList.Grid.Columns.Permission',
        PermissionCount: 'RoleList.Grid.Columns.PermissionCount',
        Policies: 'RoleList.Grid.Columns.Policies',
        Role: 'RoleList.Grid.Columns.Role',
        Roles: 'RoleList.Grid.Columns.Roles',
      },
    },
    RoleImport: {
      ImportConfirm: {
        Report: {
          Columns: {
            PermissionCount: 'RoleList.RoleImport.ImportConfirm.Report.Columns.PermissionCount',
            Role: 'RoleList.RoleImport.ImportConfirm.Report.Columns.Role',
          },
        },
        Title: 'RoleList.RoleImport.ImportConfirm.Title',
      },
      InProgress: 'RoleList.RoleImport.InProgress',
      ResultAlert: {
        Title: 'RoleList.RoleImport.ResultAlert.Title',
      },
    },
    Tabs: {
      Permissions: 'RoleList.Tabs.Permissions',
      Roles: 'RoleList.Tabs.Roles',
    },
  },
  ScrapDialog: {
    Confirmation: 'ScrapDialog.Confirmation',
    Errors: {
      Failed: 'ScrapDialog.Errors.Failed',
    },
  },
  SelectDeliveryType: {
    Message: 'SelectDeliveryType.Message',
    Title: 'SelectDeliveryType.Title',
  },
  SelectDockReceipt: {
    Body: 'SelectDockReceipt.Body',
    ItemCount: 'SelectDockReceipt.ItemCount',
    Title: 'SelectDockReceipt.Title',
  },
  SelectMasterPart: {
    Body: 'SelectMasterPart.Body',
    ItemCount: 'SelectMasterPart.ItemCount',
    Title: 'SelectMasterPart.Title',
  },
  SerialNumberConflictDialog: {
    Body: 'SerialNumberConflictDialog.Body',
    Title: 'SerialNumberConflictDialog.Title',
  },
  StockLevels: {
    Create: 'StockLevels.Create',
    DesiredCount: 'StockLevels.DesiredCount',
    Edit: 'StockLevels.Edit',
    OnHand: 'StockLevels.OnHand',
    Title: 'StockLevels.Title',
    Variance: 'StockLevels.Variance',
  },
  TextBox: {
    JobNumber: {
      Note: 'TextBox.JobNumber.Note',
    },
  },
  Toast: {
    Bin: {
      Created: 'Toast.Bin.Created',
      Updated: 'Toast.Bin.Updated',
    },
    Carrier: {
      Created: 'Toast.Carrier.Created',
      Updated: 'Toast.Carrier.Updated',
    },
    Customer: {
      Created: 'Toast.Customer.Created',
      Updated: 'Toast.Customer.Updated',
    },
    DockReceipt: {
      Close: 'Toast.DockReceipt.Close',
      Created: 'Toast.DockReceipt.Created',
      Deleted: 'Toast.DockReceipt.Deleted',
      Failed: 'Toast.DockReceipt.Failed',
      Open: 'Toast.DockReceipt.Open',
      Undeliver: 'Toast.DockReceipt.Undeliver',
    },
    Inbound: {
      Failed: 'Toast.Inbound.Failed',
      Success: 'Toast.Inbound.Success',
      SuccessWithDelay: 'Toast.Inbound.SuccessWithDelay',
    },
    MasterPart: {
      Accepted: 'Toast.MasterPart.Accepted',
      Created: 'Toast.MasterPart.Created',
      Escalated: 'Toast.MasterPart.Escalated',
      Rejected: 'Toast.MasterPart.Rejected',
    },
    NotificationChannel: {
      Updated: 'Toast.NotificationChannel.Updated',
    },
    NotificationClients: {
      Created: 'Toast.NotificationClients.Created',
      Deleted: 'Toast.NotificationClients.Deleted',
    },
    NotificationTemplates: {
      Deleted: 'Toast.NotificationTemplates.Deleted',
    },
    Outbound: {
      LegacyTransfer: 'Toast.Outbound.LegacyTransfer',
    },
    Part: {
      Defective: 'Toast.Part.Defective',
      Good: 'Toast.Part.Good',
      InformationUpdated: 'Toast.Part.InformationUpdated',
      LegacyTransfer: 'Toast.Part.LegacyTransfer',
      Lost: 'Toast.Part.Lost',
      Moved: 'Toast.Part.Moved',
      MovedToDock: 'Toast.Part.MovedToDock',
      Quarantine: 'Toast.Part.Quarantine',
      ReInbound: 'Toast.Part.ReInbound',
      Resolved: 'Toast.Part.Resolved',
      Scraped: 'Toast.Part.Scraped',
      Unquarantine: 'Toast.Part.Unquarantine',
      Updated: 'Toast.Part.Updated',
    },
    Printer: {
      Failed: 'Toast.Printer.Failed',
      Sent: 'Toast.Printer.Sent',
      Success: 'Toast.Printer.Success',
    },
    Recipient: {
      Created: 'Toast.Recipient.Created',
      Updated: 'Toast.Recipient.Updated',
    },
    Warehouse: {
      Created: 'Toast.Warehouse.Created',
      Updated: 'Toast.Warehouse.Updated',
    },
    Zone: {
      Created: 'Toast.Zone.Created',
    },
  },
  TransactionHistory: {
    Errors: {
      Unidentifiable: {
        Message: 'TransactionHistory.Errors.Unidentifiable.Message',
        Title: 'TransactionHistory.Errors.Unidentifiable.Title',
      },
    },
  },
  TransactionHistoryDialog: {
    Header: 'TransactionHistoryDialog.Header',
    PartInformation: 'TransactionHistoryDialog.PartInformation',
    Title: 'TransactionHistoryDialog.Title',
    TransactionHistory: 'TransactionHistoryDialog.TransactionHistory',
  },
  TransferDialog: {
    Errors: {
      Failed: 'TransferDialog.Errors.Failed',
    },
  },
  TransferOrder: {
    OrderInfo: {
      Title: 'TransferOrder.OrderInfo.Title',
    },
  },
  Undeliver: {
    Button: {
      Undo: 'Undeliver.Button.Undo',
    },
    Message: {
      Confirm: 'Undeliver.Message.Confirm',
    },
    Title: {
      Undo: 'Undeliver.Title.Undo',
    },
  },
  UnquarantineDialog: {
    Buttons: {
      Unquarantine: 'UnquarantineDialog.Buttons.Unquarantine',
    },
    Confirmation: 'UnquarantineDialog.Confirmation',
    Errors: {
      Failed: 'UnquarantineDialog.Errors.Failed',
    },
  },
  UpdateDataSubscription: {
    Toast: {
      Success: 'UpdateDataSubscription.Toast.Success',
    },
  },
  Users: {
    Actions: {
      AppRoles: 'Users.Actions.AppRoles',
      ForceTokenRefresh: 'Users.Actions.ForceTokenRefresh',
    },
  },
  Warehouse: {
    Code: 'Warehouse.Code',
    Info: 'Warehouse.Info',
    Name: 'Warehouse.Name',
    Select: 'Warehouse.Select',
  },
  WarehouseManagement: {
    AgentName: 'WarehouseManagement.AgentName',
    Dialog: {
      Create: {
        Title: 'WarehouseManagement.Dialog.Create.Title',
      },
      Edit: {
        Title: 'WarehouseManagement.Dialog.Edit.Title',
      },
    },
    RequireDeliveryReceipt: 'WarehouseManagement.RequireDeliveryReceipt',
    UseCart: 'WarehouseManagement.UseCart',
    UseZones: 'WarehouseManagement.UseZones',
  },
  Zone: {
    Code: 'Zone.Code',
    CreateDialog: {
      Title: 'Zone.CreateDialog.Title',
    },
    Label: 'Zone.Label',
  },
};

export type I18NKeysType = typeof I18NKeys;
