import { useIntl, IntlShape, MessageDescriptor } from 'react-intl';
import { useMemo } from 'react';
import { StandardError } from 'core/errors';
import { I18NKeys, I18NKeysType } from './i18nKeys';

export interface Translate {
  (selector: (keys: I18NKeysType) => MessageDescriptor | string, values?: Record<string, any>): string;
  translate: (selector: (intl: IntlShape, keys: I18NKeysType) => string) => string;
  translateError: (error: Error, values?: Record<string, any>) => string;
  // translateAPIError: (error: APIError, values?: Record<string, any>) => string;
}

export function useI18n(): Translate {
  const intl = useIntl();
  const i18n = useMemo(() => {
    const fn = (selector: (key: I18NKeysType) => MessageDescriptor | string, values?: Record<string, any>) => {
      try {
        let descriptor = selector(I18NKeys);
        if (typeof descriptor === 'string') {
          descriptor = { id: descriptor, defaultMessage: descriptor };
        }
        return intl.formatMessage(descriptor, values);
      } catch (e) {
        return 'TRANSLATION NOT FOUND';
      }
    };
    fn.translate = (selector: (intl: IntlShape, keys: I18NKeysType) => string) => selector(intl, I18NKeys);
    fn.translateError = (error: Error, values?: Record<string, any>) => {
      let { message } = error;
      if (error instanceof StandardError && I18NKeys.ERRORS[error.code]) {
        const { code } = error;

        const messageKey = I18NKeys.ERRORS[code];
        message = intl.formatMessage({ id: messageKey }, values);
      }

      return message;
    };

    return fn;
  }, [intl]);

  return i18n;
}

export class APIError {
  data: APIData = {
    detail: '',
    instance: '',
    status: 0,
    title: '',
    type: '',
  };
  status: number = 0;
}

export class APIData {
  detail: string = '';
  instance: string = '';
  status: number = 0;
  title: string = '';
  type: string = '';
}
