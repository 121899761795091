/* eslint-disable global-require */
import 'extensions/application';
import { isLeft } from 'fp-ts/lib/Either';
import { Authentication } from 'shared/services/Authentication';

(async function () {
  const result = await Authentication.login({ forceRefresh: true });
  if (isLeft(result)) {
    setTimeout(() => {
      window.location.href = '/pages/401.html';
    });
    return;
  }

  import('./root');
})();
