import clsx from 'clsx';

type ValidationMetadata = {
  invalid?: boolean;
  valid?: boolean;
  pristine?: boolean;
  touched?: boolean;
  active?: boolean;
  validating?: boolean;
};

export const validationStyle = (meta: ValidationMetadata, baseStyle?: string) =>
  clsx(baseStyle, {
    'is-invalid': meta.invalid && meta.touched,
    'is-valid': meta.valid,
    touched: meta.touched,
    pristine: meta.pristine,
  });

export function validationStatus({ invalid, touched, valid, active, validating }: ValidationMetadata, showOnLoad: boolean = false) {
  if (validating) {
    return 'pending';
  }

  if ((touched || showOnLoad) && invalid) {
    return 'invalid';
  }

  if (touched && valid && !active) {
    return 'valid';
  }

  // devex defaults the value to valid.
  return 'valid';
}
