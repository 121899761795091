/* eslint-disable max-classes-per-file */
import { flatten } from 'lodash';
import type { UserProfile } from './UserProfile';

export interface IPolicy {
  name: string;

  permissions: Array<string>;

  policies: Array<IPolicy>;

  evaluate(profile?: UserProfile): boolean;

  contains(policy: IPolicy | string): boolean;
}

export abstract class Policy implements IPolicy {
  private _permissions: Array<string> | undefined;
  private _policies: Array<IPolicy> | undefined;

  constructor(public name: string, protected values: Array<string | IPolicy>) {}

  abstract evaluate(profile: UserProfile): boolean;

  get permissions() {
    if (!this._permissions) {
      const { values } = this;
      this._permissions = flatten(
        values.map((x) => {
          if (typeof x === 'string') {
            return x;
          }

          return x.permissions;
        }),
      );
    }

    return this._permissions;
  }

  get policies() {
    if (!this._policies) {
      const { values } = this;
      this._policies = values.filter((x) => typeof x !== 'string') as Array<IPolicy>;
    }

    return this._policies;
  }

  contains(policy: IPolicy | string): boolean {
    return this.values.includes(policy);
  }

  static some(name: string, ...values: Array<string | IPolicy>): IPolicy {
    return new SomeOfEvaluator(name, values);
  }

  static all(name: string, ...values: Array<string | IPolicy>): IPolicy {
    return new AllOfEvaluator(name, values);
  }

  toString() {
    return this.name;
  }
}

class SomeOfEvaluator extends Policy {
  evaluate = (profile?: UserProfile): boolean => {
    if (!profile) {
      console.warn(`Missing User Profile`);
      return false;
    }

    const { values } = this;
    const result = values.some((value) => {
      if (typeof value === 'string') {
        return profile.permissions.includes(value);
      }

      return value.evaluate(profile);
    });

    if (!result) {
      console.warn(`User Profile did not meet the following criteria: ${this.toString()}`);
    }

    return result;
  };

  toString() {
    return `(${this.values.map((x) => x.toString()).join(' or ')})`;
  }
}

class AllOfEvaluator extends Policy {
  evaluate = (profile?: UserProfile): boolean => {
    if (!profile) {
      console.warn(`Missing User Profile`);
      return false;
    }

    const { values } = this;
    const result = values.reduce((good: boolean, value) => {
      if (typeof value === 'string') {
        return profile.permissions.includes(value) && good;
      }

      return value.evaluate(profile) && good;
    }, true);

    if (!result) {
      console.warn(`User Profile did not meet the following criteria: ${this.toString()}`);
    }

    return result;
  };

  toString() {
    return `(${this.values.map((x) => x.toString()).join(' and ')})`;
  }
}
