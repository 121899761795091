import { capitalize, first, isArray, toLower } from 'lodash';

export type ODataQuery = {
  $count?: boolean;
  $filter?: Exp;
  $orderBy?: Array<{ field: string; desc: boolean }>;
  $top?: number;
  $skip?: number;
  $raw?: string;
};

export type ColumnMetadata = {
  name: string;
  type: string;
  isArray: boolean;
  nullable: boolean;
  odataType: string;
  elementType?: Entity<any>;
  elementKind?: 'enum' | 'type' | 'primitive';
  onExpCreated?: (exp: Exp) => Exp;
};

export type ColumnsMetadata<T> = {
  [P in keyof Required<T>]: ColumnMetadata;
};

export type Entity<T> = {
  new (...args: Array<any>): T;
  Name: string;
  Columns: ColumnsMetadata<T>;
};

export class QueryBuilder<Resource> {
  constructor(entity: Entity<Resource>, public state: ODataQuery) {
    const ComparableKlass: ComparableObjConstructor<Resource> = createComparable(entity);
    const OrderableKlass: OrderableObjConstructor<Resource> = createOrderable(entity);
    const context = this;

    this.filter = createFilterFn<Resource, QueryBuilder<Resource>>(context, ComparableKlass, (exp) => {
      context.state.$filter = new Exp([new GroupExp([exp])]);
    });

    this.orderBy = (builder: OrderExp<Resource>) => {
      builder(new OrderableKlass((exp) => (context.state.$orderBy = exp)));
      return this;
    };

    this.skip = (value: number) => {
      this.state.$skip = value;
      return this;
    };

    this.take = (value: number) => {
      this.state.$top = value;
      return this;
    };

    this.and = () => {
      const $filter = context.state.$filter;

      return {
        filter: createFilterFn<Resource, QueryBuilder<Resource>>(context, ComparableKlass, (exp) => {
          if (!$filter) {
            throw new Error('Invalid state error.');
          }

          context.state.$filter = $filter.and.push(new GroupExp([exp]));
        }),
      };
    };

    this.or = () => {
      const $filter = context.state.$filter;
      return {
        filter: createFilterFn<Resource, QueryBuilder<Resource>>(context, ComparableKlass, (exp) => {
          if (!$filter) {
            throw new Error('Invalid state error.');
          }

          context.state.$filter = $filter.or.push(new GroupExp([exp]));
        }),
      };
    };

    this.raw = (query: string) => {
      context.state.$raw = query;

      return this;
    };
  }

  filter: FilterFn<Resource, QueryBuilder<Resource>>;
  and: () => { filter: FilterFn<Resource, QueryBuilder<Resource>> };
  or: () => { filter: FilterFn<Resource, QueryBuilder<Resource>> };
  orderBy: (builder: OrderExp<Resource>) => Pick<QueryBuilder<Resource>, 'skip' | 'take'>;
  skip: (value: number) => Pick<QueryBuilder<Resource>, 'take'>;
  take: (value: number) => Pick<QueryBuilder<Resource>, 'skip'>;
  raw: (query: string) => {
    orderBy: (builder: OrderExp<Resource>) => {
      skip: (value: number) => Pick<QueryBuilder<Resource>, 'take'>;
      take: (value: number) => Pick<QueryBuilder<Resource>, 'skip'>;
    };
    skip: (value: number) => Pick<QueryBuilder<Resource>, 'take'>;
    take: (value: number) => Pick<QueryBuilder<Resource>, 'skip'>;
  };
}

export interface NoSkipAndTakeBuilder<Resource> {
  filter: FilterFn<Resource, NoSkipAndTakeBuilder<Resource>>;
  and: () => { filter: FilterFn<Resource, NoSkipAndTakeBuilder<Resource>> };
  or: () => { filter: FilterFn<Resource, NoSkipAndTakeBuilder<Resource>> };
  orderBy: (builder: OrderExp<Resource>) => Pick<NoSkipAndTakeBuilder<Resource>, 'filter'>;
  raw: (query: string) => { orderBy: (builder: OrderExp<Resource>) => boolean };
}

export class Exp {
  private _operands: Array<any>;
  private _operators: Array<string>;

  constructor(expressions: Array<Exp> = [], operators: Array<string> = []) {
    this._operands = expressions;
    this._operators = operators;
  }

  get operands() {
    return this._operands;
  }

  get operators() {
    return this._operators;
  }

  get and() {
    const context = this;
    return {
      push(exp: Exp) {
        return new GroupExp([...context._operands, exp], [...context._operators, 'and']);
      },
    };
  }

  get or() {
    const context = this;
    return {
      push(exp: Exp) {
        return new GroupExp([...context._operands, exp], [...context._operators, 'or']);
      },
    };
  }

  clone() {
    return new Exp([...this._operands], [...this._operators]);
  }

  any() {
    return this.operands.length > 0;
  }

  static parse = parseExp;
  static isExpression = isExpression;
  static except = (exp: Exp, predicate: (exp: BinaryExp) => boolean) => selectExp(exp, (exp: BinaryExp) => !predicate(exp));
  static select = (exp: Exp, predicate: (exp: BinaryExp) => boolean) => selectExp(exp, predicate);

  static empty: Exp = new Exp();

  toString() {
    const operands = [...this.operands];
    const operators = [...this.operators];

    while (operands.length > 1) {
      const op = operators.shift()!;
      const left = operands.shift()!;
      const right = operands.shift()!;

      operands.unshift(`${left} ${op} ${right}`);
    }

    return operands[0].toString();
  }
}

export class UnaryExp extends Exp {
  constructor(op: string, expression: Exp) {
    super([expression], [op]);
  }

  public get op() {
    return this.operators[0];
  }

  public get expression() {
    return this.operands[0];
  }

  toString() {
    const {
      operators: [op],
      operands: [expression],
    } = this;
    return `${op}(${expression})`;
  }
}

export class BinaryExp extends Exp {
  constructor(left: any, op: string, right: any) {
    super([left, right], [op]);
  }

  public get left() {
    return this.operands[0];
  }

  public get op() {
    return this.operators[0];
  }

  public get right() {
    return this.operands[1];
  }

  toString() {
    const { left, op, right } = this;
    return `${left} ${op} ${right}`;
  }
}

export class GroupExp extends Exp {
  toString() {
    return `(${super.toString()})`;
  }
}

export function createODataQuery({ $count, $filter, $orderBy, $top, $skip, $raw }: ODataQuery) {
  let query = [];

  if ($count) {
    query.push('$count=true');
  }

  if (typeof $top !== 'undefined') {
    query.push(`$top=${$top}`);
  }

  if (typeof $skip !== 'undefined') {
    query.push(`$skip=${$skip}`);
  }

  if ($filter) {
    const value = reduceExpToOdataQueryString($filter);
    if (value) {
      query.push(`$filter=${value}`);
    }
  }

  if ($raw) {
    query.push(`${$raw}`);
  }

  if ($orderBy) {
    const value = reduceOrderbyExpQueryString($orderBy);
    if (value) {
      query.push(`$orderBy=${value}`);
    }
  }

  return query.join('&');
}

export type ScalarComparable<T1, T2> = {
  gt(value: T1): LogicalOperators<T2>;
  ge(value: T1): LogicalOperators<T2>;
  lt(value: T1): LogicalOperators<T2>;
  le(value: T1): LogicalOperators<T2>;
  eq(value: T1): LogicalOperators<T2>;
  ne(value: T1): LogicalOperators<T2>;
  startsWith(value: T1): LogicalOperators<T2>;
  endsWith(value: T1): LogicalOperators<T2>;
  isNull(): LogicalOperators<T2>;
  search(attributeId?: string | number): {
    allOf(builder: (exp: LambdaExp<T1 extends Array<infer Item> ? Item : T1>) => void): LogicalOperators<T2>;
    anyOf(builder: (exp: LambdaExp<T1 extends Array<infer Item> ? Item : T1>) => void): LogicalOperators<T2>;
  };
  in(value: Array<T1>): LogicalOperators<T2>;
};

export type ArrayComparable<T1, T2> = {
  allOf(
    builder: (
      exp: T1 extends Array<string | number | Date | null>
        ? LambdaExp<string | number | Date | null>
        : T1 extends Array<infer Item>
        ? ComparableObj<Item>
        : ComparableObj<NonNullable<T1>>,
    ) => void,
  ): LogicalOperators<T2>;
  anyOf(
    builder: (
      exp: T1 extends Array<string | number | Date | null>
        ? LambdaExp<string | number | Date | null>
        : T1 extends Array<infer Item>
        ? ComparableObj<Item>
        : ComparableObj<NonNullable<T1>>,
    ) => void,
  ): LogicalOperators<T2>;
};

export function extendEntity<Resource>(parent: Entity<Resource>, metadata?: { columns: RecursivePartial<ColumnsMetadata<Resource>> }) {
  if (!metadata) {
    return parent;
  }

  function child(this: any) {
    parent.apply(this, Array.prototype.slice.call(arguments));
  }
  Object.setPrototypeOf(child.prototype, parent.prototype);
  Object.setPrototypeOf(child, parent);

  const target = child as unknown as Entity<Resource>;
  target.Name = `${target.Name}Copy`;
  target.Columns = { ...target.Columns };
  Object.keys(metadata.columns).forEach((key) => {
    target.Columns[key] = {
      ...target.Columns[key],
      ...metadata.columns[key],
    };
  });
  return target;
}

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

type LambdaExp<T1> = {
  eq(value: T1): LogicalOperators<LambdaExp<T1>>;
  ne(value: T1): LogicalOperators<LambdaExp<T1>>;
  gt(value: T1): LogicalOperators<LambdaExp<T1>>;
  ge(value: T1): LogicalOperators<LambdaExp<T1>>;
  lt(value: T1): LogicalOperators<LambdaExp<T1>>;
  le(value: T1): LogicalOperators<LambdaExp<T1>>;
  eq(value: T1): LogicalOperators<LambdaExp<T1>>;
  ne(value: T1): LogicalOperators<LambdaExp<T1>>;
  startsWith(value: T1): LogicalOperators<LambdaExp<T1>>;
  endsWith(value: T1): LogicalOperators<LambdaExp<T1>>;
};

class PartAttributeValue {
  constructor(public id: string, public value: string) {}
}

type ComparableObj<T> = {
  [P in keyof Required<T>]: T[P] extends Array<any> | null ? ArrayComparable<T[P], ComparableObj<T>> : ScalarComparable<T[P], ComparableObj<T>>;
};

interface ComparableObjConstructor<T> {
  new (setExp: (exp: Exp) => void, prefix?: string): ComparableObj<T>;
}

type Orderable<T> = {
  asc(): { and(): T };
  desc(): { and(): T };
};

type OrderableObj<T> = {
  [P in keyof Required<T>]: Orderable<OrderableObj<T>>;
};

interface OrderableObjConstructor<T> {
  new (setExp: (exp: Array<{ field: string; desc: boolean }>) => void): OrderableObj<T>;
}

interface OrderExp<T> {
  (queriable: OrderableObj<T>): void;
}

interface FilterExpFn<T> {
  (queriable: ComparableObj<T>): void;
}

type LogicalOperators<T> = {
  and(): T;
  or(): T;
};

class LogicalOp<C> {
  constructor(private setExp: (exp: Exp, op: 'and' | 'or') => void, private builder: (setter: (x: Exp) => void) => C) {}

  and() {
    const { builder } = this;
    return builder((exp) => this.setExp(exp, 'and'));
  }

  or() {
    const { builder } = this;
    return builder((exp) => this.setExp(exp, 'or'));
  }
}

interface FilterFn<Resource, Builder extends { filter: FilterFn<Resource, Builder> }> {
  (filterBuilder: FilterExpFn<Resource> | { state: ODataQuery }): LogicalOperators<Pick<Builder, 'filter'>> & Omit<Builder, 'filter'>;
  not(filterBuilder: FilterExpFn<Resource> | { state: ODataQuery }): LogicalOperators<Pick<Builder, 'filter'>> & Omit<Builder, 'filter'>;
}

const comparableCache: Record<string, ComparableObjConstructor<any>> = {};
function createComparable<T>(target: Entity<T>): ComparableObjConstructor<T> {
  if (!comparableCache[target.Name]) {
    const comparable: any = function (this: any, setExp: (exp: Exp) => void, prefix?: string) {
      this.prefix = prefix;
      this.setExp = setExp;
    };
    Object.keys(target.Columns).forEach((key) => {
      Object.defineProperty(comparable.prototype, key, {
        get() {
          return new ComplexComparer(
            this.prefix ? `${this.prefix}/${key}` : key,
            target.Columns[key],
            this.setExp,
            (x) => new comparable(x, this.prefix),
          );
        },
      });
    });

    comparableCache[target.Name] = comparable;
  }
  return comparableCache[target.Name] as ComparableObjConstructor<T>;
}

class SimpleComparer {
  createExp: (left: any, op: any, right: any) => Exp;
  constructor(private property: string, private setExp: (exp: Exp) => void, createExp?: (left: any, op: any, right: any) => Exp) {
    this.createExp =
      createExp ??
      function (left, op, right) {
        return new BinaryExp(left, op, right);
      };
  }

  eq(value: any) {
    const { property, setExp, createExp } = this;
    const a = createExp(property, '=', value);
    setExp(a);

    return new LogicalOp<SimpleComparer>(
      (b, op) => setExp(new BinaryExp(a, op, b)),
      (setter) => new SimpleComparer(property, setter, createExp),
    );
  }

  ne(value: any) {
    const { property, setExp, createExp } = this;
    const a = createExp(property, '<>', value);
    setExp(a);

    return new LogicalOp<SimpleComparer>(
      (b, op) => setExp(new BinaryExp(a, op, b)),
      (setter) => new SimpleComparer(property, setter, createExp),
    );
  }

  gt(value: any) {
    const { property, setExp, createExp } = this;
    const a = createExp(property, '>', value);
    setExp(a);

    return new LogicalOp<SimpleComparer>(
      (b, op) => setExp(new BinaryExp(a, op, b)),
      (setter) => new SimpleComparer(property, setter, createExp),
    );
  }

  ge(value: any) {
    const { property, setExp, createExp } = this;
    const a = createExp(property, '>=', value);
    setExp(a);

    return new LogicalOp<SimpleComparer>(
      (b, op) => setExp(new BinaryExp(a, op, b)),
      (setter) => new SimpleComparer(property, setter, createExp),
    );
  }

  lt(value: any) {
    const { property, setExp, createExp } = this;
    const a = createExp(property, '<', value);
    setExp(a);

    return new LogicalOp<SimpleComparer>(
      (b, op) => setExp(new BinaryExp(a, op, b)),
      (setter) => new SimpleComparer(property, setter, createExp),
    );
  }

  le(value: any) {
    const { property, setExp, createExp } = this;
    const a = createExp(property, '<=', value);
    setExp(a);

    return new LogicalOp<SimpleComparer>(
      (b, op) => setExp(new BinaryExp(a, op, b)),
      (setter) => new SimpleComparer(property, setter, createExp),
    );
  }

  startsWith(value: any) {
    const { property, setExp, createExp } = this;
    const a = createExp(property, 'startswith', value);
    setExp(a);

    return new LogicalOp<SimpleComparer>(
      (b, op) => setExp(new BinaryExp(a, op, b)),
      (setter) => new SimpleComparer(property, setter, createExp),
    );
  }

  endsWith(value: any) {
    const { property, setExp, createExp } = this;
    const a = createExp(property, 'endsWith', value);
    setExp(a);

    return new LogicalOp<SimpleComparer>(
      (b, op) => setExp(new BinaryExp(a, op, b)),
      (setter) => new SimpleComparer(property, setter, createExp),
    );
  }

  contains(value: any) {
    const { property, setExp, createExp } = this;
    const a = createExp(property, 'contains', value);
    setExp(a);

    return new LogicalOp<SimpleComparer>(
      (b, op) => setExp(new BinaryExp(a, op, b)),
      (setter) => new SimpleComparer(property, setter, createExp),
    );
  }

  isNull() {
    const { property, setExp, createExp } = this;
    const a = createExp(property, '=', null);
    setExp(a);

    return new LogicalOp<SimpleComparer>(
      (b, op) => setExp(new BinaryExp(a, op, b)),
      (setter) => new SimpleComparer(property, setter, createExp),
    );
  }
}

class ComplexComparer<T> {
  constructor(
    private property: string,
    private propertyInfo: ColumnMetadata,
    private setExp: (exp: Exp) => void,
    private builder: (setter: (x: Exp) => void) => T,
  ) {}

  eq(value: any) {
    const { property, setExp, builder } = this;
    const a = new BinaryExp(property, '=', value);
    setExp(a);

    return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
  }

  ne(value: any) {
    const { property, setExp, builder } = this;
    const a = new BinaryExp(property, '<>', value);
    setExp(a);

    return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
  }

  gt(value: any) {
    const { property, setExp, builder } = this;
    const a = new BinaryExp(property, '>', value);
    setExp(a);

    return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
  }

  ge(value: any) {
    const { property, setExp, builder } = this;
    const a = new BinaryExp(property, '>=', value);
    setExp(a);

    return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
  }

  lt(value: any) {
    const { property, setExp, builder } = this;
    const a = new BinaryExp(property, '<', value);
    setExp(a);

    return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
  }

  le(value: any) {
    const { property, setExp, builder } = this;
    const a = new BinaryExp(property, '<=', value);
    setExp(a);

    return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
  }

  startsWith(value: any) {
    const { property, setExp, builder } = this;
    const a = new BinaryExp(property, 'startswith', value);
    setExp(a);

    return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
  }

  endsWith(value: any) {
    const { property, setExp, builder } = this;
    const a = new BinaryExp(property, 'endsWith', value);
    setExp(a);

    return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
  }

  contains(value: any) {
    const { property, setExp, builder } = this;
    const a = new BinaryExp(property, 'contains', value);
    setExp(a);

    return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
  }

  isNull() {
    const { property, setExp, builder } = this;
    const a = new BinaryExp(property, '=', null);
    setExp(a);

    return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
  }

  in(value: any) {
    const { property, setExp, builder } = this;
    const a = new BinaryExp(property, 'in', value);
    setExp(a);

    return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
  }

  anyOf(fn: (query: LambdaExp<any> | ComparableObj<any>) => void) {
    const { property, propertyInfo, setExp, builder } = this;
    let a: Exp | null = null;

    if (propertyInfo.isArray && propertyInfo.elementType) {
      const klass = createComparable(propertyInfo.elementType);
      fn(
        new klass((e) => {
          a = new BinaryExp(property, 'any', e);
          setExp(a);
        }, 't'),
      );
    } else {
      fn(
        new SimpleComparer('t', (e) => {
          a = new BinaryExp(property, 'any', e);
          setExp(a);
        }),
      );
    }

    if (!a) {
      throw new Error('Invalid expression');
    }

    return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
  }

  allOf(fn: (query: LambdaExp<any> | ComparableObj<any>) => void) {
    const { property, setExp, builder, propertyInfo } = this;
    let a: Exp | null = null;
    if (propertyInfo.isArray && propertyInfo.elementType) {
      const klass = createComparable(propertyInfo.elementType);
      fn(
        new klass((e) => {
          a = new BinaryExp(property, 'all', e);
          setExp(a);
        }, 't'),
      );
    } else {
      fn(
        new SimpleComparer('t', (e) => {
          a = new BinaryExp(property, 'all', e);
          setExp(a);
        }),
      );
    }

    if (!a) {
      throw new Error('Invalid expression');
    }

    return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
  }

  search(partAttributeId?: string) {
    const { setExp, builder } = this;
    const property = `search${capitalize(this.property)}`;
    const wrapValue = (value: any) => (partAttributeId ? new PartAttributeValue(partAttributeId, value) : value);

    return {
      anyOf(fn: (query: LambdaExp<any>) => void) {
        let a: Exp | null = null;
        fn(
          new SimpleComparer(
            't',
            (e) => {
              a = new BinaryExp(property, 'any', e);
              setExp(a);
            },
            (name, op, value) => new BinaryExp(name, op, wrapValue(value)),
          ),
        );

        if (!a) {
          throw new Error('Invalid expression');
        }

        return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
      },
      eq(value: any) {
        const a = new BinaryExp(property, '=', wrapValue(value));
        setExp(a);

        return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
      },
      ne(value: any) {
        const a = new BinaryExp(property, '<>', wrapValue(value));
        setExp(a);

        return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
      },
      startsWith(value: any) {
        const a = new BinaryExp(property, 'startswith', wrapValue(value));
        setExp(a);

        return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
      },
      endsWith(value: any) {
        const a = new BinaryExp(property, 'endsWith', wrapValue(value));
        setExp(a);

        return new LogicalOp((b, op) => setExp(new BinaryExp(a, op, b)), builder);
      },
    };
  }
}

const orderableCache: Record<string, OrderableObjConstructor<any>> = {};
function createOrderable<T>(target: Entity<T>): OrderableObjConstructor<T> {
  if (!orderableCache[target.Name]) {
    const orderable: any = function (this: any, setOrder: (order: Array<{ field: string; desc: boolean }>) => void) {
      this.setOrder = setOrder;
    };
    Object.keys(target.Columns).forEach((key) => {
      Object.defineProperty(orderable.prototype, key, {
        get() {
          const { setOrder } = this;
          const property = key;
          const Klass: OrderableObjConstructor<T> = orderable;
          return {
            desc() {
              const orderExp = [{ field: property, desc: true }];
              setOrder(orderExp);

              return {
                and() {
                  return new Klass((exp) => setOrder([...orderExp, ...exp]));
                },
              };
            },
            asc() {
              const orderExp = [{ field: property, desc: false }];
              setOrder(orderExp);

              return {
                and() {
                  return new Klass((exp) => setOrder([...orderExp, ...exp]));
                },
              };
            },
          };
        },
      });
    });
    orderableCache[target.Name] = orderable;
  }

  return orderableCache[target.Name] as OrderableObjConstructor<T>;
}

function createFilterFn<Resource, Builder extends { filter: FilterFn<Resource, Builder> }>(
  context: Builder,
  ComparableKlass: ComparableObjConstructor<Resource>,
  setter: (exp: Exp) => void,
) {
  const fn = function (filterBuilder: FilterExpFn<Resource> | { state: ODataQuery }) {
    if (typeof filterBuilder === 'function') {
      const target = new ComparableKlass(setter);
      filterBuilder(target);
    } else if (filterBuilder.state.$filter) {
      setter(filterBuilder.state.$filter);
    }
    return context;
  };

  fn.not = function (filterBuilder: FilterExpFn<Resource> | { state: ODataQuery }) {
    if (typeof filterBuilder === 'function') {
      const target = new ComparableKlass((exp) => {
        exp = new UnaryExp('!', exp);
        setter(exp);
      });
      filterBuilder(target);
    } else if (filterBuilder.state.$filter) {
      setter(new UnaryExp('!', filterBuilder.state.$filter));
    }

    return context;
  };

  return fn;
}

// Do not replace with the lodash equivalent.
// The lodash version returns false when given a value of 0
// and that is not correct in the context this function is used.
function isEmpty(val: any) {
  if (isArray(val) && val.length === 0) {
    return true;
  }

  if (val === null || typeof val === 'undefined') {
    return true;
  }

  return false;
}

function selectExp(exp: Exp, predicate: (exp: BinaryExp) => boolean): Exp | undefined {
  if (exp instanceof UnaryExp) {
    let { expression } = exp;

    if (isEmpty(selectExp(expression, predicate))) {
      return undefined;
    }

    return exp;
  }

  if (exp instanceof BinaryExp && !predicate(exp)) {
    return undefined;
  }

  // reduce to a single exp
  const operands = [...exp.operands];
  const operators = [...exp.operators];

  while (operators.length > 0) {
    const op = operators.shift()!;
    let left = operands.shift();
    let right = operands.shift();

    if (left instanceof Exp) {
      left = selectExp(left, predicate);
    }

    if (right instanceof Exp) {
      right = selectExp(right, predicate);
    }

    if (!left && !right) {
      continue;
    }

    let value: Exp;
    if (left && !right) {
      value = left.clone();
    } else if (!left && right) {
      value = right.clone();
    } else {
      if (left instanceof Exp && left.operators.some((x: string) => validLogicalOperators.includes(x))) {
        value = left[op].push(right);
      } else {
        value = new Exp([left, right], [op]);
      }
    }

    operands.unshift(value);
  }

  const [result] = operands;

  // if the final expression is a logical group, return it as a GroupExp to help with query generation.
  if (result && result.operators.some((x: string) => validLogicalOperators.includes(x))) {
    return new GroupExp(result.operands, result.operators);
  }

  return result;
}

const validBinaryOperators = [
  'contains',
  'notcontains',
  'startswith',
  'endswith',
  'isblank',
  'isnotblank',
  'between',
  'any',
  'all',
  '=',
  '<>',
  '<',
  '>',
  '<=',
  '>=',
  '!',
];

const validLogicalOperators = ['and', 'or'];

const validOperators = [...validLogicalOperators, ...validBinaryOperators];

function isExpression(exp: Array<any>, operators = validOperators): boolean {
  return exp.some((x) => {
    if (x instanceof Array) {
      return isExpression(x, operators);
    }

    return operators.includes(x);
  });
}

function parseExp(filter: Array<any>, entity: Entity<any>): Exp {
  if (filter.length === 0) {
    throw new Error('Cannot build exp from empty filter.');
  }

  if (filter.length === 1) {
    return parseExp(filter[0], entity);
  }

  if (filter.length === 2) {
    const [logicalOp, expression] = filter;
    return new UnaryExp(logicalOp, parseExp(expression, entity));
  }

  let [left, op, right, ...others] = filter;
  if (left instanceof Array && isExpression(left)) {
    left = parseExp(left, entity);
  }

  if (right instanceof Array && isExpression(right)) {
    right = parseExp(right, entity);
  }

  if (left instanceof Exp && right instanceof Exp) {
    // peek ahead
    const exps: Array<Exp> = [left, right];
    const ops = [op];

    others.forEach((token) => {
      if (validLogicalOperators.includes(token)) {
        ops.push(token);
      } else {
        exps.push(parseExp(token, entity));
      }
    });

    if (isArrayGroup(exps, entity)) {
      let column = exps[0].left;
      const metadata: ColumnMetadata = entity.Columns[column];
      const arrayElementIsEnum = metadata.elementKind === 'enum';
      const operation = arrayElementIsEnum ? 'all' : 'any';

      const exp = new BinaryExp(
        column,
        operation,
        new GroupExp(
          exps.map((exp) => exp.right),
          exps.slice(1).map(() => op),
        ),
      );

      return metadata.onExpCreated ? metadata.onExpCreated(exp) || exp : exp;
    }

    return new GroupExp(exps, ops);
  }

  let exp: Exp = new BinaryExp(left, op, right);
  if (entity && entity.Columns[left] && entity.Columns[left].isArray) {
    const metadata: ColumnMetadata = entity.Columns[left];
    const col = metadata.elementType && metadata.elementType.Columns['value'] ? 't/value' : 't';
    exp = new BinaryExp(left, metadata.elementKind === 'enum' ? 'all' : 'any', new BinaryExp(col, op, right));

    exp = metadata.onExpCreated ? metadata.onExpCreated(exp) || exp : exp;
  }

  if (others.length > 0) {
    return parseExp([exp, ...others], entity);
  }

  return exp;
}

function reduceOrderbyExpQueryString(orderBy: Array<{ field: string; desc: boolean }>): string {
  return orderBy
    .map(({ field, desc }) => {
      if (!desc) {
        return field;
      }

      return `${field} desc`;
    })
    .join(', ');
}

function reduceExpToOdataQueryString(exp: Exp): string | undefined {
  if (exp === Exp.empty) {
    return;
  }

  if (exp instanceof UnaryExp) {
    let { op, expression } = exp;
    const operation = OdataOperators[toLower(op)];
    const value = reduceExpToOdataQueryString(expression);

    return operation([value]);
  }

  const ops = [...exp.operators];
  const exps = exp.operands.map((operand) => {
    if (operand instanceof Exp) {
      const value = reduceExpToOdataQueryString(operand);
      if (operand instanceof GroupExp) {
        return `(${value})`;
      }

      return value;
    }

    return operand;
  });

  while (exps.length > 1) {
    const op = ops.shift()!;
    const left = exps.shift()!;
    const right = exps.shift()!;

    const operation = OdataOperators[toLower(op)];
    exps.unshift(operation([left, right]));
  }

  return exps[0];
}

function isArrayGroup<Resource>(exps: Array<Exp>, entity: Entity<Resource>): exps is Array<BinaryExp> {
  if (!exps.every((exp) => isArrayExp(exp, entity))) {
    return false;
  }

  const bExps = exps as Array<BinaryExp>;
  return bExps.reduce(
    (result: { match: boolean; left: string }, exp: BinaryExp) => {
      result.match = exp.left === result.left && result.match;
      return result;
    },
    { match: true, left: first(bExps)?.left },
  ).match;
}

function isArrayExp<Resource>(exp: Exp, entity: Entity<Resource>): exp is BinaryExp {
  return exp instanceof BinaryExp && typeof exp.left === 'string' && entity.Columns[exp.left]?.isArray;
}

function escapeSingleQuote(value: string) {
  return value.replace(/'/g, "''");
}

function prepareOdataValue(value: any): string {
  if (value instanceof PartAttributeValue) {
    return `'${value.id}:${value.value}'`;
  }

  if (value === null) {
    return 'null';
  }

  if (value instanceof Date) {
    return `cast(${value.toISOString()}, Edm.DateTimeOffset)`;
  }

  if (typeof value === 'string') {
    return `'${escapeSingleQuote(encodeURIComponent(value))}'`;
  }

  return value;
}

const OdataOperators: Record<string, (args: Array<any>) => string> = {
  '!': ([exp]: Array<any>) => `not(${exp})`,
  '>': ([left, right]: Array<any>) => `${left} gt ${prepareOdataValue(right)}`,
  '>=': ([left, right]: Array<any>) => `${left} ge ${prepareOdataValue(right)}`,
  '<': ([left, right]: Array<any>) => `${left} lt ${prepareOdataValue(right)}`,
  '<=': ([left, right]: Array<any>) => `${left} le ${prepareOdataValue(right)}`,
  '=': ([left, right]: Array<any>) => `${left} eq ${prepareOdataValue(right)}`,
  '<>': ([left, right]: Array<any>) => `${left} ne ${prepareOdataValue(right)}`,
  and: (expressions: any[]) => expressions.join(' and '),
  or: (expressions: any[]) => expressions.join(' or '),
  startswith: ([left, right]: Array<any>) => `startswith(${left}, ${prepareOdataValue(right)})`,
  contains: ([left, right]: Array<any>) => `contains(${left}, ${prepareOdataValue(right)})`,
  endswith: ([left, right]: Array<any>) => `endswith(${left}, ${prepareOdataValue(right)})`,
  any: ([left, right]: Array<any>) => {
    return `${left}/any(t: ${right})`;
  },
  all: ([left, right]: Array<any>) => {
    return `${left}/all(t: ${right})`;
  },
  in: ([left, right]: Array<any>) => {
    // Name in ('Milk', 'Cheese', 'Donut')
    return `${left} in (${right.map(prepareOdataValue)})`;
  },
};
