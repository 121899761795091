import { camelCase, snakeCase, toUpper, memoize } from 'lodash';

export const readConfig = memoize(<T>(prefix: string, defaultValue?: T): T => {
  const keyExp = new RegExp(`^VITE_${toUpper(snakeCase(prefix))}_(.*)$`);
  return Object.keys(Application.env).reduce((config: any, key: string) => {
    const result = keyExp.exec(key);
    // console.log(result);
    if (result) {
      const actualKey = result[1];
      const segments = actualKey.split('__').map((segment) => camelCase(segment));

      segments.reduce((all, segment, index) => {
        if (index === segments.length - 1) {
          // eslint-disable-next-line no-param-reassign
          all[segment] = tryParse(Application.env[key]);
          return all;
        }

        // eslint-disable-next-line no-param-reassign
        all[segment] = all[segment] || {};
        return all[segment];
      }, config);
    }
    return config;
  }, {} ?? defaultValue);
});

function tryParse(value: string) {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}
